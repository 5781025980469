import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Business1 } from "../Constant/Index";
import Footer from "./Footer";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format, parse } from "date-fns";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
// Import Swiper styles

import "swiper/css/free-mode";

import "swiper/css/thumbs";
// import "swiper/swiper-bundle.min.css";

import birthdayLeft2 from "../Assets/birthday/Left 2.jpeg";
import birthdayLeft1 from "../Assets/birthday/Left 1.jpg";
import birthdayRight2 from "../Assets/birthday/Right 2.jpg";
import birthdayRight1 from "../Assets/birthday/Right 1.jpg";
import birthdayCenter from "../Assets/birthday/Center.jpeg";

import businessLeft2 from "../Assets/business/Left 2.jpg";
import businessLeft1 from "../Assets/business/Left 1.jpg";
import businessRight2 from "../Assets/business/Right 2.jpg";
import businessRight1 from "../Assets/business/Right 1.jpg";
import businessCenter from "../Assets/business/Center.jpg";

import maternityLeft2 from "../Assets/maternity/Left 2.jpg";
import maternityLeft1 from "../Assets/maternity/Left 1.jpg";
import maternityRight2 from "../Assets/maternity/Right 2.jpg";
import maternityRight1 from "../Assets/maternity/Right 1.jpg";
import maternityCenter from "../Assets/maternity/Center.jpg";

import weddingLeft2 from "../Assets/wedding/Left 2.avif";
import weddingLeft1 from "../Assets/wedding/Left 1.jpg";
import weddingRight2 from "../Assets/wedding/Right 2.jpg";
import weddingRight1 from "../Assets/wedding/Right 1.jpeg";
import weddingCenter from "../Assets/wedding/Center.jpg";

import newbornLeft2 from "../Assets/newborn/Left 2.jpg";
import newbornLeft1 from "../Assets/newborn/Left 1.jpg";
import newbornRight2 from "../Assets/newborn/Right 2.jpg";
import newbornRight1 from "../Assets/newborn/Right 1.jpg";
import newbornCenter from "../Assets/newborn/Center.jpg";

const baseUrl = process.env.REACT_APP_BASE_URL;

const BookingForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    startDate: null,
    endDate: null,
    city: "Bengaluru",
    budget: "",
  });

  const [showModal, setShowModal] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const queryParams = new URLSearchParams(location.search);
  const category = queryParams.get("category");
  const eventType = queryParams.get("name");
  console.log(category, eventType);

  const [eventDes, setEventDes] = useState({
    type: "Secure Your Birthday Photographer Now",
    description: `This time don’t miss those moments Hire the Best Photographer for your Birthday Celebration !!`,
  });
  useEffect(() => {
    if (category === "17") {
      setEventDes({
        type: "Secure Your Wedding Photographer Now!!",
        description: `Hurry before our Top Photographers get booked! Capture memories that will last a lifetime.`,
      });
    } else if (category === "18") {
      setEventDes({
        type:
          "Capture the Magic of Motherhood Hire the Best Maternity Photographer !!",
        description: `So, hurry and book your photographer to embrace the elegance and charm of motherhood and
cherish those precious memories forever!`,
      });
    } else if (category === "25") {
      setEventDes({
        type: "Say Goodbye to Stock Photos! ",
        description:
          "Get authentic, high-quality images that reflect your brand’s unique identity. Book your business photoshoot with Boopho today.",
      });
    } else if (category === "22") {
      setEventDes({
        type: "Celebrate Your Baby’s First Moments",
        description:
          "So, don't wait—secure a talented photographer to capture your baby's early days and create timeless memories you'll treasure forever!",
      });
    } else {
      setEventDes({
        type: "Secure Your Birthday Photographer Now!",
        description: `This time don’t miss those moments Hire the Best Photographer for your Birthday Celebration !!`,
      });
    }
  }, [category]);

  const images = {
    Birthday: [
      { image: birthdayLeft2, index: 0 },
      { image: birthdayLeft1, index: 1 },
      { image: birthdayCenter, index: 2 },
      { image: birthdayRight1, index: 3 },
      { image: birthdayRight2, index: 4 },
    ],
    Business: [
      { image: businessLeft2, index: 0 },
      { image: businessLeft1, index: 1 },
      { image: businessCenter, index: 2 },
      { image: businessRight1, index: 3 },
      { image: businessRight2, index: 4 },
    ],
    Maternity: [
      { image: maternityLeft2, index: 0 },
      { image: maternityLeft1, index: 1 },
      { image: maternityCenter, index: 2 },
      { image: maternityRight1, index: 3 },
      { image: maternityRight2, index: 4 },
    ],
    Wedding: [
      { image: weddingLeft2, index: 0 },
      { image: weddingLeft1, index: 1 },
      { image: weddingCenter, index: 2 },
      { image: weddingRight1, index: 3 },
      { image: weddingRight2, index: 4 },
    ],
    Newborn: [
      { image: newbornLeft2, index: 0 },
      { image: newbornLeft1, index: 1 },
      { image: newbornCenter, index: 2 },
      { image: newbornRight1, index: 3 },
      { image: newbornRight2, index: 4 },
    ],
  };

  const eventImages = images[eventType] || images.Newborn;

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({ ...errors, [name]: "" });
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const validatePhoneNumber = (phoneNumber) => {
    // Check if the phone number is exactly 10 digits and contains only numbers
    const regex = /^\d{10}$/;
    return regex.test(phoneNumber);
  };

  const sendFormData = async (formData, eventType) => {
    try {
      const token = localStorage.getItem("authToken");
      const response = await axios.post(
        `${baseUrl}/booking/bookingInfo`,
        {
          ...formData,
          eventCategory: eventType,
        },
        {
          headers: {
            "x-auth-token": token,
          },
        }
      );
      return response;
    } catch (error) {
      console.error("Error sending form data:", error);
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {};

    if (!formData.name) {
      newErrors.name = "Name is required.";
    }
    if (!formData.email) {
      newErrors.email = "Email is required.";
    } else if (!validateEmail(formData.email)) {
      newErrors.email = "Please enter a valid email address.";
    }
    if (!formData.phoneNumber) {
      newErrors.phoneNumber = "Phone number is required.";
    } else if (!validatePhoneNumber(formData.phoneNumber)) {
      newErrors.phoneNumber = "Please enter a valid 10 digit phone number.";
    }
    if (!formData.startDate) {
      newErrors.date = "Photoshoot Dates are required.";
    }

    if (!formData.budget) {
      newErrors.budget = "Please select a budget.";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const formattedPhoneNumber = `+91${formData.phoneNumber}`;

    try {
      const response = await sendFormData(
        {
          ...formData,
          phoneNumber: formattedPhoneNumber,
        },
        eventType
      );
      console.log("Response:", response.data);
      if (response.data.message) {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
        setShowModal(true);
      }
    } catch (error) {
      toast.error(error, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
    }
  };

  const today = new Date();

  return (
    <div class="min-h-screen  bg-white text-main">
      <div class="grid md:grid-cols-12 gap-4 xl:px-32 py-4 md:py-8">
        <div class="md:col-span-8  bg-white  px-3  ">
          <h1 className="text-2xl  md:text-3xl md:font-bold md:mb-4">
            {eventDes.type}
          </h1>
          <p className="my-3 text-base md:text-lg md:font-semibold  text-black ">
            {eventDes.description}
          </p>
          <div className="container ">
            <Swiper
              loop={true}
              spaceBetween={10}
              navigation={false}
              thumbs={{ swiper: thumbsSwiper }}
              initialSlide={2}
              modules={[FreeMode, Navigation, Thumbs]}
              className="mySwiper2"
            >
              {eventImages.map((image, index) => (
                <SwiperSlide key={image.index}>
                  <img src={image.image} alt={`Slide ${index + 1}`} />
                </SwiperSlide>
              ))}
            </Swiper>
            <Swiper
              onSwiper={setThumbsSwiper}
              loop={true}
              spaceBetween={10}
              slidesPerView={5}
              initialSlide={2}
              freeMode={true}
              watchSlidesProgress={true}
              modules={[FreeMode, Navigation, Thumbs]}
              className="mySwiper"
            >
              {eventImages.map((image, index) => (
                <SwiperSlide key={image.index}>
                  <img src={image.image} alt={`Slide ${index + 1}`} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          {/* <Swiper
            effect={"coverflow"}
            grabCursor={true}
            centeredSlides={true}
            loop={true}
            // slidesPerView={"auto"}
            initialSlide={2}
            coverflowEffect={{
              rotate: 0,
              stretch: 0,
              depth: 100,
              modifier: 2.5,
            }}
            pagination={{ el: ".swiper-pagination", clickable: true }}
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
              clickable: true,
            }}
            modules={[EffectCoverflow, Pagination, Navigation]}
            breakpoints={{
              640: {
                slidesPerView: 1, // For mobile screens (up to 640px)
              },
              768: {
                slidesPerView: 2, // For tablet screens (768px and above)
              },
              1024: {
                slidesPerView: 2, // For medium screens (1024px and above)
              },
              1280: {
                slidesPerView: 3, // For large screens (1280px and above)
              },
              1440: {
                slidesPerView: 3, // For extra-large screens (1440px and above)
              },
            }}
            className="swiper_container"
          >
            {eventImages.map((image, index) => (
              <SwiperSlide key={index}>
                <img src={image} alt={`Slide ${index + 1}`} />
              </SwiperSlide>
            ))}
          </Swiper> */}
          {/* <div className="slider-controler ">
             <div className="swiper-pagination md:hidden"></div> 
            <div className="swiper-button-next"></div>
            <div className="swiper-button-prev"></div>
          </div> */}

          <h3 className="text-xl font-semibold mt-4 mb-2 text-black">
            Book Now Before It's Too Late!
          </h3>
          <div className="mb-3">
            <p className="md:text-xl text-sm text-black">
              For direct chat, you can reach us by clicking the button below.
            </p>
            <a
              href="https://api.whatsapp.com/send/?phone=919939805666"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="py-2 px-6 mt-2 text-neutral-100 bg-main rounded-md duration-75 hover:text-neutral-300">
                Contact Us
              </button>
            </a>
          </div>
        </div>
        <div className="md:col-span-4 lg:order-2 md:order-2 sm:order-1  bg-[#faf3ed] p-4 flex flex-col justify-center items-center">
          <p className="text-lg mb-4 text-center leading-5">
            Write to us, and we will get back to you asap.
          </p>
          <form
            onSubmit={handleSubmit}
            className="flex flex-col w-full max-w-full space-y-3 text-base"
          >
            <p className="flex flex-col gap-1">
              <label className="text-sm md:text-base">Name</label>
              <input
                type="text"
                name="name"
                placeholder="Name"
                value={formData.name}
                onChange={handleChange}
                className={`p-2 border-1 focus:outline-none ${
                  errors.name ? "border-red-600" : "border-main"
                } rounded-md text-sm md:text-base w-full`}
              />
              {errors.name && (
                <span className="text-red-600 text-sm">{errors.name}</span>
              )}
            </p>
            <p className="flex flex-col gap-1">
              <label className="text-sm md:text-base">Email</label>
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
                className={`p-2 border-1 focus:outline-none ${
                  errors.email ? "border-red-600" : "border-main"
                } rounded-md text-sm md:text-base w-full`}
              />
              {errors.email && (
                <span className="text-red-600 text-sm">{errors.email}</span>
              )}
            </p>

            <p className="flex flex-col gap-1">
              <label className="text-sm md:text-base">Phone Number</label>
              <div
                className={`flex items-center border-1 ${
                  errors.phoneNumber ? "border-red-600" : "border-main"
                } rounded-md w-full`}
              >
                <span className="pl-2 pr-1 py-1 flex-shrink-0">
                  <span className="flag-icon flag-icon-in flag-icon-squared"></span>
                </span>
                <span className="border-r border-main px-2 py-1 flex-shrink-0">
                  +91
                </span>
                <input
                  type="number"
                  placeholder="Phone Number"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  className={` number-appearence-none p-2 flex-1 border-none focus:outline-none focus:ring-0 ${
                    errors.phoneNumber ? "border-red-600" : "border-main"
                  } rounded-md text-sm md:text-base w-full`}
                />
              </div>
              {errors.phoneNumber && (
                <span className="text-red-600 text-sm">
                  {errors.phoneNumber}
                </span>
              )}
            </p>
            <p className="flex flex-col gap-1">
              <label className="text-sm md:text-base">Photoshoot Date(s)</label>
              <div
                className={`flex flex-col border-1 ${
                  errors.date ? "border-red-600" : "border-main"
                } rounded-md w-full`}
              >
                <DatePicker
                  selected={formData.startDate}
                  onChange={(dates) => {
                    const [start, end] = dates;
                    setFormData({
                      ...formData,
                      startDate: start,
                      endDate: end,
                    });
                  }}
                  startDate={formData.startDate}
                  endDate={formData.endDate}
                  selectsRange
                  minDate={today}
                  placeholderText="Select start and end date"
                  dateFormat="dd/MM/yyyy"
                  className={`p-2 w-full border-none focus:outline-none rounded-md text-sm md:text-base`}
                />
              </div>
              {errors.date && (
                <span className="text-red-600 text-sm">{errors.date}</span>
              )}
            </p>
            <p className="flex flex-col gap-1">
              <label className="text-sm md:text-base">City</label>
              <select
                name="city"
                value={formData.city}
                onChange={handleChange}
                className={`p-2 border-1 focus:outline-none ${
                  errors.city ? "border-red-600" : "border-main"
                } rounded-md text-sm md:text-base h-10 w-full`}
              >
                <option className="" value="Bengaluru">
                  Bengaluru
                </option>
              </select>
            </p>
            <p className="flex flex-col gap-1">
              <label className="text-sm md:text-base">Budget</label>
              <select
                name="budget"
                value={formData.budget}
                onChange={handleChange}
                className={`p-2 border-1 focus:outline-none ${
                  errors.budget ? "border-red-600" : "border-main"
                } rounded-md text-sm md:text-base h-10 w-full`}
              >
                <option value="" disabled hidden>
                  Select Budget
                </option>
                <option value="Less than ₹ 50,000 per day">
                  Less than ₹ 50,000 per day
                </option>
                <option value="₹ 50,000 - ₹ 1,00,000 per day">
                  ₹ 50,000 - ₹ 1,00,000 per day
                </option>
                <option value="₹ 1,00,000 - ₹ 2,00,000 per day">
                  ₹ 1,00,000 - ₹ 2,00,000 per day
                </option>
                <option value="More than ₹ 2,00,000 per day">
                  More than ₹ 2,00,000 per day
                </option>
              </select>
              {errors.budget && (
                <span className="text-red-600 text-sm">{errors.budget}</span>
              )}
            </p>
            <div className="flex justify-center items-center w-full">
              <button
                type="submit"
                className="py-2 px-8 mt-2 text-neutral-100 bg-main rounded-md duration-75 hover:text-neutral-200 text-sm md:text-base"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>

      <div className=" px-10 md:px-32 py-4 md:py-8 text-left  ">
        <h3 className="md:text-xl md:font-semibold mb-10 text-center ">
          Why Boopho?
        </h3>
        <ul className="space-y-4 text-lg ">
          <li>
            <span className="text-lg font-semibold">
              Curated Selection Process:{" "}
            </span>
            Our platform connects you with professional photographers who have
            undergone a rigorous selection process, ensuring quality and
            reliability.
          </li>
          <li>
            <span className="text-lg font-semibold">Unique Styles: </span>Our
            photographers not only possess skill but also offer unique styles,
            ensuring that your special moments are captured in a distinctive and
            memorable way.
          </li>
          <li>
            <span className="text-lg font-semibold">
              Capture for All Occasions:{" "}
            </span>
            Whether it's a wedding, corporate event, or milestones like a
            newborn's arrival or maternity shoot, our photographers are
            committed to capturing precious moments across various occasions.
          </li>
          <li>
            <span className="text-lg font-semibold">
              Tech-Enabled Preservation:{" "}
            </span>
            Our tech platform enhances the preservation of memories by combining
            the artistry of photography with innovative technological
            capabilities.
          </li>
          <li>
            <span className="text-lg font-semibold">Cherished Memories: </span>
            By leveraging photography expertise and technology, we ensure that
            your precious memories are preserved and cherished for generations
            to come.
          </li>
          <li>
            <span className="text-lg font-semibold">Transparent Pricing: </span>
            Boopho employs a dynamic pricing method, where prices quoted to
            customers depend on the charges set by individual photographers.
          </li>
        </ul>
      </div>

      <Footer />
      <ToastContainer />

      {/* Modal */}
      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg relative max-w-md w-full">
            <button
              className="absolute top-2 right-2 text-gray-600 hover:text-gray-900"
              onClick={() => {
                setShowModal(false);
                navigate("/");
              }}
            >
              &times;
            </button>
            <h3 className="text-2xl font-semibold mb-4">Thank You!</h3>
            <p className="mb-4">
              Your request is submitted succesfully. We will get back to you
              shortly.
            </p>
            <button
              className="py-2 px-4 bg-main text-neutral-100 rounded-md duration-75 hover:text-neutral-200"
              onClick={() => navigate("/")}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default BookingForm;
