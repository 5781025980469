import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL; // Assuming your environment variable for base URL is REACT_APP_BASE_URL

// Function to save photographer details

export const generateLink = async (paymentData) => {
  try {
    const token = localStorage.getItem("authToken");
    const response = await axios.post(
      `${baseUrl}/payment/generate`,
      paymentData,
      {
        headers: {
          "x-auth-token": token,
        },
      }
    );
    return response.data; // Assuming your API returns data in a certain format
  } catch (error) {
    // Handle error
    console.error("Error saving photographer details:", error);
    throw error; // Rethrow the error to handle it in the calling code
  }
};

export const updateUserPaymentStatus = async (paymentData) => {
  try {
    const token = localStorage.getItem("authToken");
    const response = await axios.post(
      `${baseUrl}/payment/update-status`,
      paymentData,
      {
        headers: {
          "x-auth-token": token,
        },
      }
    );
    return response.data; // Assuming your API returns data in a certain format
  } catch (error) {
    // Handle error
    console.error("Error saving photographer details:", error);
    throw error; // Rethrow the error to handle it in the calling code
  }
};

export const confirmBooking = async (paymentData, id) => {
  try {
    const token = localStorage.getItem("authToken");
    const response = await axios.patch(
      `${baseUrl}/booking/${id}/confirm`,
      paymentData,
      {
        headers: {
          "x-auth-token": token,
        },
      }
    );
    return response.data; // Assuming your API returns data in a certain format
  } catch (error) {
    // Handle error
    console.error("Error saving photographer details:", error);
    throw error; // Rethrow the error to handle it in the calling code
  }
};

export const createTimeline = async (timelineData, id) => {
  try {
    const token = localStorage.getItem("authToken");
    const response = await axios.post(
      `${baseUrl}/booking/${id}/timeline`,
      timelineData,
      {
        headers: {
          "x-auth-token": token,
        },
      }
    );
    return response.data; // Assuming your API returns data in a certain format
  } catch (error) {
    // Handle error
    console.error("Error in creating timeline", error);
    throw error; // Rethrow the error to handle it in the calling code
  }
};
export const LTA = async (url) => {
  try {
    const token = localStorage.getItem("authToken");
    const response = await axios.get(
      `${baseUrl}/share/get-all-files-size-from-s3/${url}`,

      {
        headers: {
          "x-auth-token": token,
        },
      }
    );
    return response.data; // Assuming your API returns data in a certain format
  } catch (error) {
    // Handle error
    console.error("Error in creating timeline", error);
    throw error; // Rethrow the error to handle it in the calling code
  }
};

export const updatePhotographerShare = async (data) => {
  try {
    const token = localStorage.getItem("authToken");
    const response = await axios.put(
      `${baseUrl}/share/photographer-share-with-user`,
      data,
      {
        headers: {
          "x-auth-token": token,
        },
      }
    );
    return response.data; // Assuming your API returns data in a certain format
  } catch (error) {
    // Handle error
    console.error("Error in creating timeline", error);
    throw error; // Rethrow the error to handle it in the calling code
  }
};

export const getPhotographerReviews = async (id) => {
  try {
    const token = localStorage.getItem("authToken");
    const response = await axios.get(
      `${baseUrl}/photographer/google-reviews/${id}`,
      {
        headers: {
          "x-auth-token": token,
        },
      }
    );
    return response; // Assuming your API returns data in a certain format
  } catch (error) {
    // Handle error
    console.error("Error in creating timeline", error);
    throw error; // Rethrow the error to handle it in the calling code
  }
};

export const getPaymentDetails = async (paymentId) => {
  try {
    const token = localStorage.getItem("authToken");
    console.log(paymentId, "PAYMENTIDIDDII");
    const response = await axios.post(
      `${baseUrl}/payment/payment-details`,
      { paymentId },
      {
        headers: {
          "x-auth-token": token,
        },
      }
    );
    return response.data; // Assuming your API returns data in a certain format
  } catch (error) {
    // Handle error
    console.error("Error saving photographer details:", error);
    throw error; // Rethrow the error to handle it in the calling code
  }
};
