import React, { useState, useEffect } from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { About1Img, About2Img, About3Img } from "../../Constant/Index";
import Banner from "../../Components/banner";
import axios from "axios";

const AboutUs = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [allAbout, setAllAbout] = useState([]);

  const getAllAboutUs = () => {
    axios
      .get(`${baseUrl}/admin/about`)
      .then((response) => {
        console.log(response.data.data);
        setAllAbout(response.data.data.rows);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  };

  useEffect(() => {
    getAllAboutUs();
  }, []);
  return (
    <>
      <main className="site-front-page">
        <Header />
        <Banner home="Home" currenturl="/aboutus" current="About Us" />
        {/* <!-- Objective Start Here --> */}
        {/* <section className="objective">
          <div className="bootstrap-container">
            <div className="col-md-10 mx-auto overflow-hidden">
              <div className="text_start text-center wow animate__animated animate__fadeInDown animate__delay-1s">
                <h2>Objective</h2>
                <p className="mt-4">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in volup...
                </p>
              </div>
            </div>
          </div>
        </section> */}
        {/* <!-- Objective End Here --> */}
        {/* <!-- About Double Secton Start Here --> */}
        {allAbout.map((item, index) => (
          <section
            className={`text_image ${index % 2 === 0 ? "left" : "right"}`}
          >
            <div className="bootstrap-container">
              <div className="section_start">
                {/* className="row align-items-center overflow-hidden" */}
                <div className="row align-items-center overflow-hidden">
                  {item.imageUrl && (
                    <div
                      className={`col-lg-6 col-md-12 ${
                        index % 2 === 0 ? "order-md-1" : ""
                      }`}
                    >
                      <figure className="">
                        <img src={item.imageUrl} className="img-fluid" alt="" />
                      </figure>
                    </div>
                  )}
                  <div
                    className={`${
                      item.imageUrl === undefined ||
                      item.imageUrl === null ||
                      item.imageUrl === ""
                        ? "text-center"
                        : "col-lg-6"
                    }  col-md-12 mt-5 mt-lg-0 ${
                      index % 2 === 0 ? "" : "order-md-1"
                    }`}
                  >
                    <div className="text_here">
                      {/* <h6 className="blue_text mb-0">{item.heading}</h6> */}
                      {/* <h2 className="mb-4">Our History</h2> */}
                      <h2 className="mb-4 !text-[21px]">{item.heading}</h2>
                      <div className="paragraphs mt-4">
                        <p className="mb-lg-3 mb-md-3 mb-2">
                          {item.description}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ))}
        {/* <!-- Text With Image End Here --> */}

        <Footer />
      </main>
    </>
  );
};
export default AboutUs;
