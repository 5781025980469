import React, { useState, useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import "./Assets/Css/variable.css";
import "./Assets/Css/style.css";
import PublicRoutes from "./Routes/PublicRoutes";
import AOS from "aos";
import { getSocket, initiateSocket } from "./Utils/socket";
import { MyContext } from "./Context/MyContext";
import { Helmet } from "react-helmet";
import WhatsAppLink from "./Components/Whatsapp";

function App() {
  const [loading, setLoading] = useState(true);
  let [socket, setSocket] = useState(null);
  const [messages, setMessages] = useState({});

  useEffect(() => {
    new window.WOW().init(); // Initialize wow.js
    const token = localStorage.getItem("authToken");
    if (token) initiateSocket(token);
    setSocket(getSocket());
  }, []);

  useEffect(() => {
    socket?.on("new_message", (data) => {
      console.log("New Message: ", data);
      const senderId = Number(data?.senderId);
      console.log(senderId, messages, "senderId, messages,");
      // const updatedMsg =  ;
      setMessages((prev) => ({
        ...prev,
        [senderId]: prev[senderId]
          ? [...prev[senderId], { ...data, senderId }]
          : [{ ...data, senderId }],
      }));
    });
  }, [socket]);

  console.log(messages, "messages:App.js");
  return (
    <>
      <Helmet>
        <title>Boopho</title>
        <meta name="description" content="Book premium photographers now" />
      </Helmet>
      <MyContext.Provider value={{ messages, setMessages }}>
        <PublicRoutes />
      </MyContext.Provider>
      <WhatsAppLink />
    </>
  );
}

export default App;
