import React, { useState } from "react";
import { AiOutlineDown } from "react-icons/ai";

const SortFilter = ({ selectedSortFilter, setSelectedSortFilter, options }) => {
  const [showSortOptions, setShowSortOptions] = useState(false);

  const handleSortFilter = (e) => {
    console.log(e.target.innerText);
    setSelectedSortFilter(e.target.innerText);
    setShowSortOptions(false);
  };

  return (
    <div className="relative z-20">
      <button
        onClick={() => setShowSortOptions(!showSortOptions)}
        className="flex items-center gap-1 text-main"
      >
        Sort: <p className="capitalize">{selectedSortFilter}</p>{" "}
        <AiOutlineDown />
      </button>
      {showSortOptions && (
        <div className="absolute top-full right-0 bg-white text-main min-w-full shadow-md">
          <ul className="flex flex-col justify-start w-full">
            {options?.map((option, index) => (
              <li
                key={index}
                className="hover:bg-main hover:text-white pl-2 pr-5 py-2 cursor-pointer"
                onClick={handleSortFilter}
              >
                {option}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SortFilter;
