import React, { useEffect, useState } from "react";
import Folder from "../../Components/Folder";
import Header from "../../Components/Header";
import CreateFolder from "../../Components/Modals/CreateFolder";
import BackButton from "../../Components/BackButton";
import ShareFolder from "../../Components/Modals/ShareFolder";

import { Menu, Item, useContextMenu } from "react-contexify";
import { useParams, useNavigate, useLocation } from "react-router-dom";

//react-icons
import { AiOutlineFolderOpen } from "react-icons/ai";
import { BsClockHistory } from "react-icons/bs";
import authApi from "../../Utils/axiosConfig";

// these are dynamic pages and data are on behalf on helpers: MY_FILES & SHARED_FILES
// example url of this page:
// http://localhost:3000/my-footage/my-files/58814

const MY_FILES = [
  {
    id: 58814,
    folderName: "Rahul Wed Nandhini",
    expiresInDays: 20,
    files: ["Photos", "Videos", "Other"],
    photos: [
      "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg",
      "https://dfstudio-d420.kxcdn.com/wordpress/wp-content/uploads/2019/06/digital_camera_photo-1080x675.jpg",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
    ],
  },
  {
    id: 64586,
    folderName: "Ibrahim Birthday",
    expiresInDays: 10,
    files: ["Photos", "Videos", "Other"],
    photos: [
      "https://t4.ftcdn.net/jpg/02/80/16/37/360_F_280163743_Ux10gTObj9YdQU86pXK75KRgdJllQlPk.jpg",
      "https://t4.ftcdn.net/jpg/02/80/16/37/360_F_280163743_Ux10gTObj9YdQU86pXK75KRgdJllQlPk.jpg",
      "https://t4.ftcdn.net/jpg/02/80/16/37/360_F_280163743_Ux10gTObj9YdQU86pXK75KRgdJllQlPk.jpg",
      "https://t4.ftcdn.net/jpg/02/80/16/37/360_F_280163743_Ux10gTObj9YdQU86pXK75KRgdJllQlPk.jpg",
      "https://t4.ftcdn.net/jpg/02/80/16/37/360_F_280163743_Ux10gTObj9YdQU86pXK75KRgdJllQlPk.jpg",
      "https://t4.ftcdn.net/jpg/02/80/16/37/360_F_280163743_Ux10gTObj9YdQU86pXK75KRgdJllQlPk.jpg",
      "https://t4.ftcdn.net/jpg/02/80/16/37/360_F_280163743_Ux10gTObj9YdQU86pXK75KRgdJllQlPk.jpg",
      "https://t4.ftcdn.net/jpg/02/80/16/37/360_F_280163743_Ux10gTObj9YdQU86pXK75KRgdJllQlPk.jpg",
      "https://t4.ftcdn.net/jpg/02/80/16/37/360_F_280163743_Ux10gTObj9YdQU86pXK75KRgdJllQlPk.jpg",
      "https://t4.ftcdn.net/jpg/02/80/16/37/360_F_280163743_Ux10gTObj9YdQU86pXK75KRgdJllQlPk.jpg",
      "https://t4.ftcdn.net/jpg/02/80/16/37/360_F_280163743_Ux10gTObj9YdQU86pXK75KRgdJllQlPk.jpg",
      "https://t4.ftcdn.net/jpg/02/80/16/37/360_F_280163743_Ux10gTObj9YdQU86pXK75KRgdJllQlPk.jpg",
      "https://t4.ftcdn.net/jpg/02/80/16/37/360_F_280163743_Ux10gTObj9YdQU86pXK75KRgdJllQlPk.jpg",
      "https://t4.ftcdn.net/jpg/02/80/16/37/360_F_280163743_Ux10gTObj9YdQU86pXK75KRgdJllQlPk.jpg",
    ],
  },
  {
    id: 5489,
    folderName: "Mahima Maternity Shoot",
    files: ["Photos", "Videos", "Other"],
    expiresInDays: 30,
    photos: [
      "https://dfstudio-d420.kxcdn.com/wordpress/wp-content/uploads/2019/06/digital_camera_photo-1080x675.jpg",
      "https://dfstudio-d420.kxcdn.com/wordpress/wp-content/uploads/2019/06/digital_camera_photo-1080x675.jpg",
      "https://dfstudio-d420.kxcdn.com/wordpress/wp-content/uploads/2019/06/digital_camera_photo-1080x675.jpg",
      "https://dfstudio-d420.kxcdn.com/wordpress/wp-content/uploads/2019/06/digital_camera_photo-1080x675.jpg",
      "https://dfstudio-d420.kxcdn.com/wordpress/wp-content/uploads/2019/06/digital_camera_photo-1080x675.jpg",
      "https://dfstudio-d420.kxcdn.com/wordpress/wp-content/uploads/2019/06/digital_camera_photo-1080x675.jpg",
      "https://dfstudio-d420.kxcdn.com/wordpress/wp-content/uploads/2019/06/digital_camera_photo-1080x675.jpg",
      "https://dfstudio-d420.kxcdn.com/wordpress/wp-content/uploads/2019/06/digital_camera_photo-1080x675.jpg",
      "https://dfstudio-d420.kxcdn.com/wordpress/wp-content/uploads/2019/06/digital_camera_photo-1080x675.jpg",
      "https://dfstudio-d420.kxcdn.com/wordpress/wp-content/uploads/2019/06/digital_camera_photo-1080x675.jpg",
      "https://dfstudio-d420.kxcdn.com/wordpress/wp-content/uploads/2019/06/digital_camera_photo-1080x675.jpg",
    ],
  },
];

const SHARED_FILES = [
  {
    id: 54782,
    folderName: "Test folder",
    expiresInDays: 20,
    files: ["Photos", "Videos", "Other"],
    photos: [
      "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg",
      "https://dfstudio-d420.kxcdn.com/wordpress/wp-content/uploads/2019/06/digital_camera_photo-1080x675.jpg",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
    ],
  },
  {
    id: 7895,
    folderName: "Shared Folder",
    expiresInDays: 10,
    files: ["Photos", "Videos", "Other"],
    photos: [
      "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg",
      "https://dfstudio-d420.kxcdn.com/wordpress/wp-content/uploads/2019/06/digital_camera_photo-1080x675.jpg",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
    ],
  },
];

const MENU_ID = "customer-files";

const CustomerFolders = () => {
  const params = useParams();
  const location = useLocation();

  const navigate = useNavigate();

  const [files, setFiles] = useState(
    params.slug === "my-files"
      ? MY_FILES
      : params.slug === "shared-files"
      ? SHARED_FILES
      : ""
  );

  const [filesContent, setFilesContent] = useState([]);

  const { show } = useContextMenu({
    id: MENU_ID,
  });

  function handleContextMenu(event) {
    show({
      event,
      props: {
        id: MENU_ID,
      },
    });
  }

  const [customer, setCustomer] = useState("");
  useEffect(() => {
    // const customer = files.find((item) => item.id == params.id);
    // let s3Hash = `photographer-uploads/${params.id}`
    authApi.get(`/share/get-all-files-from-s3/${encodeURIComponent(params.id)}`).then(res => {
      console.log(res.data, "res:get-all-files");
    })
    // setCustomer(customer);
    // setFilesContent(customer?.files);
    setFilesContent(["Photos", "Videos"]);
  }, [files]);

  const [showCreateFolder, setShowCreateFolder] = useState(false);
  const [showShare, setShowShare] = useState(false);

  const handleCreateFolder = (folderName) => {
    const updatedFiles = [...filesContent];
    const newFolder = folderName;

    updatedFiles.push(newFolder);
    setFilesContent(updatedFiles);

    setShowCreateFolder(false);
  };

  return (
    <>
      <Header />
      <section
        className="w-full p-4 min-h-screen"
        onContextMenu={handleContextMenu}
      >
        <div className="w-[80%] mx-auto">
          <BackButton />
          <h1 className="md:text-3xl text-xl text-main font-bold text-center">
            {customer?.folderName}
          </h1>
        </div>
        {/* <div className="text-xs md:text-sm w-[80%] rounded-md bg-[#f1c0b5] text-[#9f606d] text-center font-bold p-2 mx-auto my-4">
          Some footages will be expired in 20 days. If you want to extend, click
          here or buy Lifetime Accessibility.
        </div> */}
        <div className="w-[80%] mx-auto grid grid-cols-2 md:grid-cols-6 my-5 gap-4">
          {filesContent.length > 0 &&
            filesContent?.map((item, index) => (
              <Folder
                key={index}
                color="#54adef"
                text={item}
                id={`customer_${item.split(" ").join("")}_${index}`}
                href={`/my-footage/${params.slug}/${params.id}/${item
                  .trim()
                  .split(" ")
                  .join("")
                  .toLowerCase()}`}
                open={() => {
                  navigate(
                    `/my-footage/${params.slug}/${params.id}/${item
                      .trim()
                      .split(" ")
                      .join("")
                      .toLowerCase()}`
                  );
                }}
                images={location.state}
                share={() => setShowShare(true)}
                addToFolder={() => setShowCreateFolder(true)}
                onlyOpenOption={params.slug === "shared-files"}
              />
            ))}
        </div>
      </section>
      {params.slug !== "shared-files" && (
        <Menu id={MENU_ID} className="bg-white border border-gray-500">
          <Item className="">
            <div
              className="flex gap-2 items-center cursor-pointer"
              onClick={() => setShowCreateFolder(true)}
            >
              <AiOutlineFolderOpen className="text-lg" />
              <span className="text-sm">Create Folder</span>
            </div>
          </Item>
          <Item className="">
            <div className="flex gap-2 items-center cursor-pointer">
              <BsClockHistory className="text-lg" />{" "}
              <span className="text-sm">Lifetime Access</span>
            </div>
          </Item>
        </Menu>
      )}
      <CreateFolder
        showModal={showCreateFolder}
        setShowModal={setShowCreateFolder}
        handleCreateFolder={(folderName) => handleCreateFolder(folderName)}
      />
      <ShareFolder showModal={showShare} setShowModal={setShowShare} />
    </>
  );
};

export default CustomerFolders;
