import React from "react";

const PopupMessage = ({ message, message2 }) => {
  return (
    <div className="popup-container">
      <div className="popup">
        <p className="message">{message}</p>
        <br />
        {message2 && <p className="message">{message2}</p>}
      </div>
    </div>
  );
};

export default PopupMessage;
