import React, { useEffect, useState } from "react";
import Folder from "../../Components/Folder";
import Header from "../../Components/Header";
import CreateFolder from "../../Components/Modals/CreateFolder";
import BackButton from "../../Components/BackButton";
import ShareFolder from "../../Components/Modals/ShareFolder";
import { Modal } from "antd";
import { Menu, Item, useContextMenu } from "react-contexify";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import "react-contexify/dist/ReactContexify.css";

//react-icons
import { AiOutlineFolderOpen } from "react-icons/ai";
import { BsClockHistory } from "react-icons/bs";
import authApi from "../../Utils/axiosConfig";
import {
  LTAAsync,
  generateLinkAsync,
  selectCurrentLTA,
  setCurrentLTA,
} from "../../Redux/slice/mainSlice";

const Files = () => {
  const params = useParams();

  const MENU_ID = params.slug;

  const navigate = useNavigate();

  const { show } = useContextMenu({
    id: MENU_ID,
  });

  function handleContextMenu(event) {
    show({
      event,
      props: {
        id: MENU_ID,
      },
    });
  }

  const [files, setFiles] = useState(
    []
    // params.slug === "my-files"
    //   ? MY_FILES
    //   : params.slug === "shared-files"
    //   ? SHARED_FILES
    //   : ""
  );
  const [userToShare, setUserToShare] = useState([]);
  const [showCreateFolder, setShowCreateFolder] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const [shareItem, setShareItem] = useState(null);
  const [size, setSize] = useState({});
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  console.log(userToShare, "=userToShareuserToShare");

  useEffect(() => {
    if (MENU_ID === "my-files") {
      authApi
        .get("/share/photographer-share-with-user")
        .then((data) => {
          console.log(data.data?.data, "get-pg-share-useEffect");
          setFiles((prev) => [...prev, ...data.data?.data]);
        })
        .catch((err) => {
          console.log(err, "error:useEffect");
        });
    } else if (MENU_ID === "shared-files") {
      const role = localStorage.getItem("role");
      authApi
        .get("/share/editor-share-with-user")
        .then((data) => {
          console.log(data.data?.data, "get-pg-share-useEffect");
          setFiles((prev) => [...prev, ...data.data?.data]);
        })
        .catch((err) => {
          console.log(err, "error:useEffect");
        });
      authApi
        .get("/share/user-share-with-user")
        .then((resp) => {
          console.log(resp.data?.data, "get-user-share-user-useEffect");
          const data = resp.data?.data?.map((share) => {
            const a = {
              endDate: share.endDate,
            };

            if(share.images){
              a["images"] = JSON.parse(share.images);
              a["sharedUrl"] = "folder-share-by-" + share.User.name
            }
            else {
              a["images"] = []
            }
            if (share.PhotoGrapherShare) {
              a["sharedUrl"] = share.PhotoGrapherShare.sharedUrl;
            }

            if (share.User) {
              a["name"] = share.User.name;
              a["email"] = share.User.email;
              a["phoneNo"] = share.User.phoneNo;
            }

            return a;
          });
          setUserToShare(data);
        })
        .catch((err) => {
          console.log(err, "error:useEffect");
        });
      // if(role && role === 'EDITOR'){
      // }
      // else {}
    }
  }, [MENU_ID]);

  const handleCreateFolder = (folderName) => {
    const updatedFiles = [...files];
    const newFolder = {
      id: files.length + 1,
      folderName,
    };

    updatedFiles.push(newFolder);
    setFiles(updatedFiles);

    setShowCreateFolder(false);
  };
  const handleLifeTimeAccess = (url) => {
    dispatch(LTAAsync(url)).then((x) => {
      let size = x.payload.data;
      size.url = url;
      setSize(size);
      setOpen(true);
      console.log(x, "LTAAAA");
    });
  };

  function calculateStorageCost(amount, unit) {
    // Convert amount to GB if unit is MB
    if (unit.toLowerCase() === "mb") {
      amount /= 1024; // 1 GB = 1024 MB
    }

    // Monthly storage cost per GB
    const monthlyCostPerGB = 0.023; // $0.023 per GB per month

    // Number of months in 25 years
    const numberOfMonths = 25 * 12;

    // Total storage cost
    const totalCost = monthlyCostPerGB * amount * numberOfMonths;

    return totalCost.toFixed(2); // Round to 2 decimal places
  }

  const handlePay = (amount) => {
    const inrAmount = convertUSDToINR(amount);
    const paymentData = {
      amount: 20,
      purpose: "LTA",
    };

    dispatch(setCurrentLTA(size.url));

    dispatch(generateLinkAsync(paymentData))
      .then((x) => {
        const url = x.payload.data.longurl;
        window.location.href = url;
        console.log(console.log(x, "RESPONSSDASDASDADASDASD"));
      })
      .catch((e) => {
        console.log(e);
      });
  };
  function convertUSDToINR(amountUSD) {
    // Current exchange rate for USD to INR (replace with actual value)
    const exchangeRateUSDToINR = 75.0;

    // Convert amount from USD to INR
    const amountINR = amountUSD * exchangeRateUSDToINR;

    // Round the amount to 2 decimal places
    const roundedAmountINR = amountINR.toFixed(2);

    return roundedAmountINR;
  }
  return (
    <>
      <Modal
        title="Life Time Accessability "
        open={open}
        onCancel={() => setOpen(false)}
        footer={null}
      >
        <div>
          <div className="mt-2">
            {/* Display Folder Size */}
            <p className="text-sm text-gray-500 mb-2">Folder Size:</p>
            <p className="text-lg font-semibold text-gray-900 mb-4">
              {size?.totalSizeInMB} Mbs
            </p>

            {/* Display Storage Cost */}
            <p className="text-sm text-gray-500 mb-2">
              Storage Cost (25 years):
            </p>
            <p className="text-lg font-semibold text-gray-900 mb-4">
              {calculateStorageCost(size?.totalSizeInMB, "mb")}$
            </p>
          </div>

          {/* Pay Now Button */}
          <button
            style={{ backgroundColor: "rgb(158, 96, 109)" }}
            onClick={() =>
              handlePay(calculateStorageCost(size?.totalSizeInMB, "mb"))
            }
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2  text-base font-medium text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:w-auto sm:text-sm"
          >
            Pay Now
          </button>
        </div>
      </Modal>
      <Header />
      <section
        className="w-full p-4 min-h-screen"
        onContextMenu={handleContextMenu}
      >
        <div className="w-[80%] mx-auto">
          <BackButton />
          <h1 className="md:text-3xl text-xl text-main font-bold text-center">
            {params.slug
              .split("-")
              .join(" ")
              .toUpperCase()}
          </h1>
        </div>
        <div className="w-[80%] mx-auto grid grid-cols-2 md:grid-cols-6 my-5 gap-4">
          {files?.map((item, index) => {
            console.log(item, "ITEMDASDAD");
            return (
              <Folder
                key={index}
                color={
                  item.expiry <= 10
                    ? "#ff3131"
                    : item.expiry <= 20
                    ? "#e7a911"
                    : "#54adef"
                }
                text={
                  item.id +
                  "-" +
                  item.userId +
                  " " +
                  `${item.LTA ? "(LTA)" : ""}`
                }
                href={`/my-footage/${params.slug}/${item.sharedUrl?.replace(
                  "photographer-uploads/",
                  "photographer-uploads-"
                )}`}
                open={() =>
                  navigate(
                    `/my-footage/${params.slug}/${item.sharedUrl?.replace(
                      "photographer-uploads/",
                      ""
                    )}`
                  )
                }
                addToFolder={() => setShowCreateFolder(true)}
                share={() => {
                  setShowShare(true);
                  setShareItem(item);
                }}
                id={item.id}
                onlyOpenOption={params.slug === "shared-files" ? true : false}
                sharedUrl={item.sharedUrl}
                lifetimeAccess={() => handleLifeTimeAccess(item.sharedUrl)}
              />
            );
          })}
          {userToShare?.map((item, index) => {
            let textJsx = (
              <div className="flex flex-col justify-center items-center">
                <span>Share by - {item.name}</span>
                {/* <br /> */}
                <span>{item.phoneNo || item.email}</span>
              </div>
            );
            return (
              <Folder
                key={index}
                color={
                  item.expiresInDays <= 10
                    ? "#ff3131"
                    : item.expiresInDays <= 20
                    ? "#e7a911"
                    : "#54adef"
                }
                text={textJsx}
                images={item.images}
                href={`/my-footage/${params.slug}/${item.sharedUrl?.replace(
                  "photographer-uploads/",
                  "photographer-uploads-"
                )}`}
                open={() =>
                  navigate(
                    `/my-footage/${params.slug}/${item.sharedUrl?.replace(
                      "photographer-uploads/",
                      ""
                    )}`
                  )
                }
                addToFolder={() => setShowCreateFolder(true)}
                share={() => {
                  setShowShare(true);
                  setShareItem(item);
                }}
                id={item.id}
                onlyOpenOption={params.slug === "shared-files" ? true : false}
              />
            );
          })}
        </div>
      </section>
      {params.slug !== "shared-files" && (
        <Menu id={MENU_ID} className="bg-white border border-gray-500">
          <Item onClick={() => setShowCreateFolder(true)}>
            <div className="flex gap-2 items-center cursor-pointer">
              <AiOutlineFolderOpen className="text-lg" />
              <span className="text-sm">Create Folder</span>
            </div>
          </Item>
          <Item className="">
            <div className="flex gap-2 items-center cursor-pointer">
              <BsClockHistory className="text-lg" />{" "}
              <span className="text-sm">Lifetime Access</span>
            </div>
          </Item>
        </Menu>
      )}
      <CreateFolder
        showModal={showCreateFolder}
        setShowModal={setShowCreateFolder}
        handleCreateFolder={(folderName) => handleCreateFolder(folderName)}
      />
      <ShareFolder
        item={shareItem}
        showModal={showShare}
        setShowModal={setShowShare}
      />
    </>
  );
};

export default Files;
