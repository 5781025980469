import { RiWhatsappFill } from "react-icons/ri"

const WhatsAppLink = () => {


    return (
        <div className="whatsapp-link mb-3">

            <a
                href="https://api.whatsapp.com/send/?phone=919939805666"
                target="_blank"
                // className="text-green-500 hover:no-underline "
                style={{ color: 'green', textDecoration: 'none' }}

            >
                <RiWhatsappFill style={{ fontSize: '64px', position: 'fixed', bottom: 40, right: 20 }} />
            </a>
        </div >
    );
};

export default WhatsAppLink;
