import React, { useState } from "react";
import { Link } from "react-router-dom";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";

const ListYourBusiness = () => {
  const photo_status = localStorage.getItem("photo_status");
  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem("loggedIn") === "true"
  );

  return (
    <>
      <main className="site-front-page">
        <div className="photographer-page">
          <Header />
          <section className="list-your-business py-5 my-lg-5 text-center">
            <div className="bootstrap-container">
              <div className="col-lg-7 mx-auto">
                <div className="list-business-start">
                  <h2 className="business-heading text-main">
                    List Your Business
                  </h2>
                  <p className="text mt-4">
                    Elevate your business by listing with us and expanding your
                    reach. Maximize exposure, connect with a wider audience, and
                    watch your business flourish. Join today to nurture growth
                    and tap into new opportunities.{" "}
                  </p>
                  <p className="text">
                    Its completely <strong>FREE</strong>
                  </p>
                  <div className="d-flex gap-4 mt-4 justify-content-center">
                    {photo_status !== "APPROVED" && photo_status !== "HOLD"  && (
                      <>
                        {loggedIn ? (
                          <Link
                            className="btn px-4 site-btn"
                            to="/photographer/register"
                          >
                            For Photographer
                          </Link>
                        ) : (
                          <a
                            className="btn px-4 site-btn"
                            data-bs-toggle="modal"
                            data-bs-target="#LoginModal"
                            role="button"
                          >
                            For Photographer
                          </a>
                        )}
                      </>
                    )}

                    {loggedIn ? (
                      <Link
                        className="btn px-4 site-btn"
                        to="/editor/photographer"
                      >
                        For Editor
                      </Link>
                    ) : (
                      <a
                        className="btn px-4 site-btn"
                        data-bs-toggle="modal"
                        data-bs-target="#LoginModal"
                        role="button"
                      >
                        For Editor
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </div>
      </main>
    </>
  );
};
export default ListYourBusiness;
