import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { UploadIcon } from "../../Constant/Index";
const EditorChangePassword = () => {
  const [checkedEvents, setCheckedEvents] = useState([]);

  // Function to handle checkbox change

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    // Your form submission logic here

    // Redirect to another page
    navigate("/editor/profile"); // Replace '/other' with the desired page URL
  };
  return (
    <>
      <div className="photographer-page">
        <Header />
        <section className="profile-prohotgrapher mt-5">
          <div className="bootstrap-container">
            <h1 className=" mt-lg-5 text-center position-relative">
              <Link
                className="site-btn position-md-absolute d-block w-fit"
                to="/editor/profile"
                style={{ left: "12px" }}
              >
                <i
                  className="fa fa-angle-left"
                  style={{ fontSize: "23px" }}
                ></i>
              </Link>
              Change Password
            </h1>
            <span className="detail">
              <strong>Details</strong>
            </span>
          </div>
          <hr className="mb-5" />
          <div className="bootstrap-container   py-5 mt-t">
            <div className="col-xl-10 col-lg-11 mx-auto">
              <div className="col-lg-10">
                <div className="profile-upate-satrt">
                  <form onSubmit={handleSubmit}>
                    <div class=" form-group">
                      <div className="row">
                        <div className="col-md-3">
                          <label htmlFor="">Old Password *</label>
                        </div>
                        <div className="col-md-9">
                          <div class="input-group mb-3">
                            <span
                              class="input-group-text fit-content"
                              id="basic-addon1"
                            >
                              <span class="fa fa-eye"></span>
                            </span>

                            <input
                              type="password"
                              class="form-control"
                              placeholder="Enter Old Password"
                              aria-label="Phone Number"
                              required=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-3">
                          <label htmlFor="">New Password *</label>
                        </div>
                        <div className="col-md-9">
                          <div class="input-group mb-3">
                            <span
                              class="input-group-text fit-content"
                              id="basic-addon1"
                            >
                              <span class="fa fa-eye"></span>
                            </span>

                            <input
                              type="password"
                              class="form-control"
                              placeholder="Enter New Password"
                              aria-label="Phone Number"
                              required=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-3">
                          <label htmlFor="">Confirm New Password *</label>
                        </div>
                        <div className="col-md-9">
                          <div class="input-group mb-3">
                            <span
                              class="input-group-text fit-content"
                              id="basic-addon1"
                            >
                              <span class="fa fa-eye"></span>
                            </span>

                            <input
                              type="password"
                              class="form-control"
                              placeholder="Confirm New Password"
                              aria-label="Phone Number"
                              required=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <button
                      type="submit"
                      class="btn btn-primary d-block mx-auto site-btn px-5"
                    >
                      Change Passowrd
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};
export default EditorChangePassword;
