import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { CheckIcon, CloseIcon, ReportIcon } from "../Constant/Index";
import BookingInfo from "./Modals/BookingInfo";
import axios from "axios";

const RequestedBookingCard = ({ 
  id,
  image,
  name,
  area,
  startDate,
  endDate,
  lastBtn,
  handleShowChat,
  category,
  photographerId,

  setCurrentTab,
}) => {
  const token = localStorage.getItem("authToken");
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();
  // console.log(lastBtn, " = lastBtnnnnnnn:ReqBookingCard");

  const handleDelete = async () => {
    try {
      const apiUrl = `${baseUrl}/photographer/booking/${id}`;
      const headers = {
        "x-auth-token": token,
      };
      const data = {
        status: "CANCELLED",
      };

      const response = await axios.patch(apiUrl, data, {
        headers: headers,
      });

      if (response.status === 200) {
        setCurrentTab("cancelledBooking");

        const responseData = response.data.data;
        if (Array.isArray(responseData.rows)) {
          console.log(responseData);
          // setCardData(responseData.rows);
        } else {
          console.error("Invalid data format: rows is not an array.");
        }
      } else {
        console.error("Unable to fetch data.");
      }
    } catch (error) {
      console.error("Error while fetching data:", error);
    }
  };

  const handleEdit = async () => {
    console.log(id, " = iddddddddddddddddddddd:handleEdit");
    navigate(
      `/booking-details/${category}/${photographerId}/${id}?startDate=${startDate}&endDate=${endDate}`
    );
    // try {
    //   const apiUrl = `${baseUrl}/photographer/booking/${id}`;
    //   const headers = {
    //     "x-auth-token": token,
    //   };
    //   const data = {
    //     status: "CONFIRMED",
    //   };

    //   const response = await axios.patch(apiUrl, data, {
    //     headers: headers,
    //   });

    //   if (response.status === 200) {
    //     const responseData = response.data.data;
    //     if (Array.isArray(responseData.rows)) {
    //       console.log(responseData);
    //       // setCardData(responseData.rows);
    //     } else {
    //       console.error("Invalid data format: rows is not an array.");
    //     }
    //   } else {
    //     console.error("Unable to fetch data.");
    //   }
    // } catch (error) {
    //   console.error("Error while fetching data:", error);
    // }
  };

  return (
    <>
      <div className="single-booking wow animate__animated animate__fadeIn animate__delay-1s">
        <div className="row">
          <div className="col-md-3 overflow-hidden">
            <figure className="studio-image  " style={{ height: "100px" }}>
              <img src={image} className=" " alt="" />
            </figure>
          </div>
          <div className="col-md-9">
            <div className="booking-data h-full">
              <button
                className="report-studio"
                data-bs-toggle="modal"
                data-bs-target="#bookingInfo"
                data-bs-dismiss="modal"
              >
                <img src={ReportIcon} className="report-image" alt="" />
              </button>
              <div className="studio-data">
                <Link to="" className=" !text-[#620808] ">
                  {name}
                </Link>
                <p className="location">{area}</p>
              </div>
              <div className="about-booking h-full d-flex flex-column justify-content-between py-lg-4 ">
                Event Date:
                <p className="date text-main">
                  {startDate} - {endDate}
                </p>
                <div className="buttons-here">
                  <button className="cancel" onClick={handleDelete}>
                    <img src={CloseIcon} alt="" /> Cancel
                  </button>
                  <button
                    onClick={handleEdit}
                    className={`other-btn ${
                      lastBtn === "Awaiting Call" ? "disabled" : ""
                    }`}
                    disabled={lastBtn === "Awaiting Call"}
                  >
                    <img src={CheckIcon} alt="" />
                    Confirm
                  </button>
                </div>
                <p className="text-end contact-ous-booking font-bold text-main">
                  Any Issues ?{" "}
                  <button
                    onClick={() => handleShowChat("admin", -1)}
                    className="font-bold"
                  >
                    Contact boopho.com
                  </button>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BookingInfo />
    </>
  );
};

export default RequestedBookingCard;
