import React, { useState } from 'react';
import { MdCloudDownload } from 'react-icons/md';
// import './ImageComponent.css'; // Assuming you have the CSS in this file

const ImageComponent = ({ src, alt, hideMenu }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (src, alt) => {
    setSelectedImage({ src, alt });
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
  };

  const handleDownload = () => {
    fetch(selectedImage.src)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        
        // Extract the file extension from the image src
        const src = selectedImage.src;
        const fileExtension = src.substring(src.lastIndexOf('.'));
        
        // Ensure the download attribute includes the file extension
        let fileName = selectedImage.alt || 'download';
        if (!fileName.endsWith(fileExtension)) {
          fileName += fileExtension;
        }
        link.download = fileName;
        
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch(error => console.error('Error downloading the image:', error));
  };

  const handleCloseWhenClickOutside = (e) => {
    if(e.target === e.currentTarget){
        setIsModalOpen(false);
    }
  }

  return (
    <>
        <div className="relative cursor-pointer">
          <img
            src={src}
            alt={alt}
            onContextMenu={(e) => e.preventDefault()}
            onClick={() => handleImageClick(src, alt)}
            style={{ border: '1px solid gray' }}
          />
        </div>
      
      {isModalOpen && selectedImage && (
        <div className={`fixed top-0 left-0 flex flex-col justify-center items-center bg-gray-600/70 z-20 h-screen w-screen`} onClick={handleCloseWhenClickOutside}>
            <div style={{ width: 'calc(100% - 300px)', marginBottom: '10px', }} className="flex justify-between w-full">
                <button style={{ background: 'brown', width: '50px', height: '50px', borderRadius: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', }}  onClick={handleDownload}>
                    <MdCloudDownload style={{ color: '#FFF', fontSize: '25px' }} />
                </button>
                <div style={{ background: 'brown', width: '50px', height: '50px', borderRadius: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', }} 
                    className="text-2xl font-bold rounded-full text-white" onClick={closeModal}
                >
                    &times;
                </div>
            </div>
          <div className={`bg-white `}>
            <img src={selectedImage.src} alt={selectedImage.alt} className='modal_image_share' />
          </div>
        </div>
      )}
    </>
  );
};

export default ImageComponent;
