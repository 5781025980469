import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import { UploadIcon } from "../../Constant/Index";
import axios from "axios";
import PopupMessage from "../../Components/PopupMessage";
import Select from "react-select";

import ProgressLoading from "../../Components/ProgressLoading";
import { useRef } from "react";
import { uploadChunksByChunks } from "../../Utils/uploadChunks";

const EditProfile = () => {
    const [formData, setFormData] = useState({
        studioName: "",
        aboutYou: "",
        serviceLocation: "",
        languagesKnown: "",
        experienceInPhotography: "",
        willingToTravel: "",
        travelState: "",
        deliveryTime: "",
        individualOrTeam: "",
        numberOfTeamMembers: "",
        businessLink: "",
        anyHighligths: "",
        typesOfPhotography: [],
        typesOfVideography: [],
        termsAndConditions: false,
        googlePlaceId: "",
        states: [],
        confirmBox: [],
        additionalInfo: "",
    });

    console.log("dd", formData.additionalInfo);
    const [checkedEvents, setCheckedEvents] = useState([]);
    const [uploadingMessage, setUploadingMessage] = useState(null);
    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState(null);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const [loading, setLoading] = useState(false);
    const [generateAbout, setGenerateAbout] = useState(false);
    const [showAllPhotosModal, setShowAllPhotosModal] = useState(false);
    const [allPhotos, setAllPhotos] = useState([]);
    const errorMessageRef = useRef();
    const [errorMessage, setErrorMessage] = useState("");
    const weddingIndex = checkedEvents.findIndex(
        (event) => event === "EventsList0"
    );
    const maternityIndex = checkedEvents.findIndex(
        (event) => event === "EventsList1"
    );
    const newbornIndex = checkedEvents.findIndex(
        (event) => event === "EventsList2"
    );
    const birthdayIndex = checkedEvents.findIndex(
        (event) => event === "EventsList3"
    );
    const businessIndex = checkedEvents.findIndex(
        (event) => event === "EventsList4"
    );

    const defaultAboutYouText = `At ${formData.studioName
        }, we're more than just photographers; we're a dedicated team of visual storytellers. With combined experience spanning Many Years of Experience, our passion for photography knows no bounds.
      Our studio offers a rich tapestry of photography styles, including candid, drone, natural, vintage, traditional, and intimate boudoir sessions. We have the expertise to capture every moment in its unique essence. From cinematic narratives to dynamic reels, short films, breathtaking drone footage, destination shoots, and mesmerizing time-lapse videos, our videography services add an extra dimension to your memories.
      Occasions We Serve
      From ${weddingIndex !== -1 ? "wedding" : ""} and ${maternityIndex !== -1 ? "maternity" : ""
        } shoots to precious ${newbornIndex !== -1 ? "newborn" : ""
        } moments, joyful ${birthdayIndex !== -1 ? "birthdays" : ""
        }, and professional ${businessIndex !== -1 ? "business" : ""
        } events, we've got every occasion covered.
      Delivery Time:
      We understand the importance of prompt delivery. Expect your beautifully edited photos and videos within ${formData.deliveryTime
        }.
      Additional Skills:
      Beyond our primary areas, we excel in professional photo editing, studio photography, and conducting engaging photography workshop`;

    const [PhotographyList, setPhotographyList] = useState([
        "Destination",
        "Group",
        "Traditional",
        "Drone",
        "Natural",
        "Boudoir",
        "Candid",
        "Cinematic",
        "Vintage",
        "Fine Art",
        "Dramatic",
    ]);

    const [VideographyList, setVideographyList] = useState([
        "Destination",
        "Group",
        "Traditional",
        "Drone",
        "Natural",
        "Boudoir",
        "Candid",
        "Cinematic",
        "Vintage",
        "Fine Art",
        "Dramatic",
    ]);

    const navigate = useNavigate();

    const token = localStorage.getItem("authToken");
    const userId = localStorage.getItem("userId");
    const [states, setStates] = useState([]);
    const photographerStatus = localStorage.getItem("photographer_status");

    const handleCheckboxChange = (event) => {
        const { id, checked } = event.target;

        if (checked) {
            setCheckedEvents((prevCheckedEvents) => [...prevCheckedEvents, id]);
        } else {
            setCheckedEvents((prevCheckedEvents) =>
                prevCheckedEvents.filter((eventId) => eventId !== id)
            );
        }
    };

    console.log("events", checkedEvents);

    const handleInputChange = (event) => {
        const { name, value, type, checked } = event.target;

        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: "",
        }));

        if (type === "checkbox") {
            let updatedArray = [...formData[name]];

            if (checked) {
                // If checked, add it to the array
                updatedArray.push(value);
            } else {
                // If unchecked, remove it from the array
                updatedArray = updatedArray.filter((item) => item !== value);
            }

            setFormData({
                ...formData,
                [name]: updatedArray,
            });
        } else if (name === "aboutYou") {
            // If the input is aboutYou, update the value
            setFormData({
                ...formData,
                [name]: value,
            });
        } else {
            // For other input types (text, etc.), update as usual
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const missingImagesRef = useRef(null);

    const formRefs = useRef({
        studioName: null,
        serviceLocation: null,
        experienceInPhotography: null,
        languagesKnown: null,
        typesOfPhotography: null,
        typesOfVideography: null,
        missingImages: missingImagesRef,
        willingToTravel: null,
        deliveryTime: null,
        individualOrTeam: null,
        numberOfTeamMembers: null,
        businessLink: null,
        googlePlaceId: null,
        aboutYou: null,
        confirmBox: null,
        // ... add refs for other fields
    });

    const validateForm = () => {
        let valid = true;
        const newErrors = {};
        let firstErrorField = null;

        const requiredFields = [
            { fieldValue: "studioName", fieldName: "Studio Name" },
            { fieldValue: "serviceLocation", fieldName: "Service Location" },
            {
                fieldValue: "experienceInPhotography",
                fieldName: "Experience in Photography",
            },
            { fieldValue: "languagesKnown", fieldName: "Languages Known" },
            { fieldValue: "typesOfPhotography", fieldName: "Types of Photography" },
            { fieldValue: "typesOfVideography", fieldName: "Types of Videography" },
            { fieldValue: "willingToTravel", fieldName: "Willing To Travel" },
            { fieldValue: "deliveryTime", fieldName: "Deliver Time" },
            { fieldValue: "individualOrTeam", fieldName: "Individual Team" },
            { fieldValue: "businessLink", fieldName: "Business Link" },
            { fieldValue: "googlePlaceId", fieldName: "Google Place Id" },
            { fieldValue: "aboutYou", fieldName: "About You" },
            { fieldValue: "confirmBox", fieldName: "Confirmation Box" },
        ];

        console.log(requiredFields);

        requiredFields.forEach((field) => {
            if (field.fieldValue === "confirmBox") {
                // Check if confirmBox is not checked
                if (
                    formData.confirmBox.length === 0 ||
                    formData.confirmBox[0] !== "on"
                ) {
                    valid = false;
                    newErrors[field.fieldValue] = `${field.fieldName} must be checked`;

                    if (!firstErrorField) {
                        firstErrorField = field.fieldValue;
                    }
                }
            } else if (!formData[field.fieldValue]) {
                valid = false;
                // newErrors[field.fieldValue] = `${field.fieldName} is required`;
                newErrors[field.fieldValue] = `This is a required field`;

                if (!firstErrorField) {
                    firstErrorField = field.fieldValue;
                }
            }
        });

        if (
            formData.businessLink &&
            !/^(http|https):\/\/[^ "]+$/.test(formData.businessLink)
        ) {
            valid = false;
            newErrors.businessLink = "Please enter a valid URL";

            if (!firstErrorField) {
                firstErrorField = "businessLink";
            }
        }

        if (formData.typesOfPhotography.length === 0) {
            valid = false;
            newErrors.typesOfPhotography =
                "At least one type of photography is required";
            if (formRefs.current["typesOfPhotography"]) {
                formRefs.current["typesOfPhotography"].focus();
            }

            if (!firstErrorField) {
                firstErrorField = "typesOfPhotography";
            }
        }

        if (formData.typesOfVideography.length === 0) {
            valid = false;
            newErrors.typesOfVideography =
                "At least one type of videography is required";
            if (formRefs.current["typesOfVideography"]) {
                formRefs.current["typesOfVideography"].focus();
            }

            if (!firstErrorField) {
                firstErrorField = "typesOfVideography";
            }
        }

        const missingImages = Uploaders.filter(
            (category, index) =>
                checkedEvents.includes(`EventsList${index}`) &&
                uploadImagesByCategory[index].length === 0
        );

        if (missingImages.length > 0) {
            valid = false;
            newErrors.missingImages = `Please upload images for the following categories: ${missingImages.join(
                ", "
            )}`;
            missingImagesRef?.current?.scrollIntoView({
                behavior: "smooth",
                block: "center",
            });
        }

        if (
            formData.individualOrTeam === "Team" &&
            formData.numberOfTeamMembers.length === 0
        ) {
            valid = false;
            newErrors.numberOfTeamMembers = "This field is required";
            formRefs?.current?.numberOfTeamMembers.scrollIntoView({
                behavior: "smooth",
                block: "center",
            });
        }

        setErrors(newErrors);

        // if (!valid && fieldsWithErrors.length > 0) {
        //   const firstErrorField = fieldsWithErrors.find(field => !formData[field]);
        //   console.log('eroField',firstErrorField);
        //   if (firstErrorField) {
        //     formRefs.current[firstErrorField].focus();
        //   }
        // }

        if (!valid && firstErrorField) {
            if (firstErrorField === "confirmBox") {
                // Focus on the checkbox label or any other suitable element
                const checkboxLabel = document.querySelector(
                    `label[for=${firstErrorField}]`
                );
                if (checkboxLabel) {
                    checkboxLabel.scrollIntoView({ behavior: "smooth", block: "center" });
                }
            } else {
                // Focus on other input types
                formRefs.current[firstErrorField].focus();
            }
        }

        return valid;
    };

    const handleGenerateButtonClick = () => {
        const requiredFields = [
            "studioName",
            "languagesKnown",
            "serviceLocation",
            "typesOfPhotography",
            "typesOfVideography",
            "willingToTravel",
            "individualOrTeam",
            "businessLink",
            "googlePlaceId",
            "deliveryTime",
        ];

        const anyFieldNotFilled = requiredFields.some((field) => {
            const value = formData[field];

            if (Array.isArray(value)) {
                return value.length === 0; // Check if array is empty
            }

            return !value; // Check if value is falsy (empty string, undefined, null, etc.)
        });

        if (anyFieldNotFilled) {
            setErrorMessage("Please complete the form to generate about you");
            return;
        } else {
            setErrorMessage("");
        }

        setFormData({
            ...formData,
            aboutYou: defaultAboutYouText,
        });
        setGenerateAbout(true);
    };

    useEffect(() => {
        getAllStates();
        const photo_user_id = localStorage.getItem("photoGrapherUser");

        if (photo_user_id) {
            setFormSubmitted(true);
        }

        if (errorMessageRef.current) {
            errorMessageRef.current.focus();
        }
    }, [message]);
    const uploadedFilesProgress = (name, status) => {
        console.log(name, "+++++", status, "HELLOOOOO");
        // setUploadProgress(prev => ({ ...prev, [name]: status, }));
    };

    function generateRandomString(length) {
        const characters =
            "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let result = "";
        const charactersLength = characters.length;

        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }

        return result;
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(formData, "ASDASDASDASDASDASASD");
        setErrors({});
        const validationErrors = validateForm(formData);

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
        console.log(validateForm(), "VALIDIDID");
        if (validateForm()) {
            try {
                setLoading(true);
                const imageData = {};
                console.log(uploadImagesByCategory, "uploadImagesByCategory");
                for (
                    let categoryIndex = 0;
                    categoryIndex < uploadImagesByCategory.length;
                    categoryIndex++
                ) {
                    const images = uploadImagesByCategory[categoryIndex];
                    const imageCount = images.length;

                    if (imageCount === 0) {
                        continue;
                    }

                    let imageUrl = [];
                    let videoUrl = [];
                    for (let imageIndex = 0; imageIndex < imageCount; imageIndex++) {
                        const image = images[imageIndex];
                        console.log(
                            `Uploading ${imageIndex + 1}/${images.length} of ${Uploaders[imageIndex]
                            } Category`,
                            "hello"
                        );
                        // setUploadingMessage(
                        //   `Uploading ${imageIndex + 1}/${images.length} of ${
                        //     Uploaders[imageIndex]
                        //   } Category`
                        // );
                        setUploadingMessage(
                            `Uploading ${imageIndex + 1}/${images.length
                            } of categories Images and Videos`
                        );
                        console.log(image, "imageimageimageimage");
                        const random = generateRandomString(20);
                        const path = `photographer-uploads/${random}`;
                        const response = await uploadChunksByChunks(
                            image,
                            path,
                            uploadedFilesProgress
                        );

                        console.log(response, "Uploading:response:data");
                        if (image.type.includes("image")) {
                            imageUrl.push(response.Location);
                        } else if (image.type.includes("video")) {
                            videoUrl.push(response.Location);
                        }
                        // data.append("media", image);
                    }
                    switch (categoryIndex) {
                        case 0:
                            imageData["17"] = { image: imageUrl, video: videoUrl };
                            break;
                        case 1:
                            imageData["18"] = { image: imageUrl, video: videoUrl };
                            break;
                        case 2:
                            imageData["22"] = { image: imageUrl, video: videoUrl };
                            break;
                        case 3:
                            imageData["24"] = { image: imageUrl, video: videoUrl };
                            break;
                        case 4:
                            imageData["25"] = { image: imageUrl, video: videoUrl };
                            break;
                        default:
                            break;
                    }
                }
                console.log(imageData, "IMAGE");

                formData.category = imageData;
                // formData.aboutYou = aboutYouText;

                // const response2 = await axios.put(
                //   `${baseUrl}/photographer/save/details`,
                //   formData,
                //   {
                //     headers: {
                //       "x-auth-token": token,
                //     },
                //   }
                // );

                // if (response2.status === 200) {
                //   console.log(
                //     "FormData sent to the second API successfully:",
                //     response2.data
                //   );
                //   const userId = localStorage.getItem("userId");
                //   localStorage.setItem("photo_status", "PENDING");
                //   localStorage.setItem("photoGrapherUser", userId);
                //   setFormSubmitted(true);
                //   setMessage("Form submitted successfully!");
                // } else {
                //   console.error(
                //     "Failed to send FormData to the second API:",
                //     response2.data
                //   );
                //   // Handle the error for the second API call
                //   // You can set an error message or take appropriate action
                // }
            } catch (error) {
                console.log(error.message);
                if (
                    error.response &&
                    error.response.data &&
                    error.response.data.error
                ) {
                    // Display the error message from the API response
                    setMessage(error.response.data.error.message);
                    if (errorMessageRef.current) {
                        errorMessageRef.current.focus();
                    }
                } else {
                    setMessage("Error submitting the form. Please try again."); // Set a generic error message
                }
            } finally {
                setLoading(false); // Set loading back to false after API calls are done
            }
        }
        console.log(validateForm(), "validateForm()");
    };

    const EventsList = [
        "Wedding",
        "Maternity",
        "New Born Baby",
        "Birthday",
        "Business",
    ];
    const Uploaders = [
        "Wedding Photos and Videos",
        "Maternity Photos and Videos",
        "New Born Photos and Videos",
        "Birthday Photos and Videos",
        "Business Photos and Videos",
    ];
    const [willingToTravel, setWillingToTravel] = useState("");
    const [selectedStates, setSelectedStates] = useState([]);
    const [selectedLanguages, setSelectedLanguages] = useState([]);

    const languagesData = (items) => {
        console.log(items);
        setErrors((prevErrors) => ({
            ...prevErrors,
            languagesKnown: "",
        }));
        setSelectedLanguages(items);

        const languages = items.map((el) => el.value); // Use map to extract values into an array

        setFormData({
            ...formData,
            languagesKnown: JSON.stringify(languages),
        });
    };

    console.log(selectedLanguages, formData, errors);
    const travelOptions = [
        { value: "Yes", label: "Yes" },
        { value: "No", label: "No" },
    ];
    const stateOptions = [
        { id: 0, value: "All India", label: "All India" },
        ...states.map((state) => ({
            id: state.id,
            value: state.name,
            label: state.name,
        })),
    ];

    const languages = [
        "Ahirani",
        "Assamese",
        "Awadhi",
        "Baghel Khandi",
        "Chhattisgarhi",
        "Banjari",
        "Bengali",
        "Bhojpuri",
        "Brajbhasha",
        "Bundel khandi",
        "Chhattisgarhi",
        "Dhundhari",
        "Dogri",
        "English",
        "Garhwali",
        "Garo",
        "Gojri",
        "Gondi",
        "Gujarati",
        "Haryanvi",
        "Hindi",
        "Kannada",
        "Kashmiri",
        "Khasi",
        "Konkani",
        "Kumauni",
        "Magahi",
        "Maithili",
        "Malayalam",
        "Malvi",
        "Manipuri",
        "Marathi",
        "Marwari",
        "Mewari",
        "Mundari",
        "Nepali",
        "Nimadi",
        "Odia",
        "Punjabi",
        "Rajasthani",
        "Sambalpuri",
        "Santali",
        "Sindhi",
        "Surgujia",
        "Surjapuri",
        "Tamil",
        "Telugu",
        "Tulu",
        "Urdu",
        "Wagdi",
    ];

    const languagesOptions = [
        ...languages.map((language, index) => ({
            id: index,
            value: language.toLowerCase(),
            label: language,
        })),
    ];

    const handleStateChange = (selectedOptions) => {
        const isAllIndiaSelected = selectedOptions.some(
            (option) => option.value === "All India"
        );

        if (isAllIndiaSelected) {
            selectedOptions = [stateOptions[0]];
        }

        setSelectedStates(selectedOptions);

        const state = selectedOptions.map((state) => state.id);
        console.log("state", state);
        setFormData({
            ...formData,
            states: state,
        });
    };

    const other = (mode) => {
        let val = document.querySelector("#anyOther" + mode).value;
        const lt = mode === "Photo" ? "Photography" : "Videography";
        val = val.replace(new RegExp(lt, "gi"), "");
        const lowerCaseVal = val.toLowerCase();

        const list = mode === "Photo" ? PhotographyList : VideographyList;
        // Use lower case for comparison
        if (val && !list.some((item) => item.toLowerCase() === lowerCaseVal)) {
            const updatedList = [...list, val];

            mode === "Photo"
                ? setPhotographyList(updatedList)
                : setVideographyList(updatedList);

            // Clear the value of the input field
            document.querySelector("#anyOther" + mode).value = "";

            // Automatically check the corresponding checkbox
            const updatedTypesOf =
                mode === "Photo"
                    ? formData.typesOfPhotography
                    : formData.typesOfVideography;
            const updatedTypesOfList = [
                ...updatedTypesOf,
                `${val} ${mode === "Photo" ? "Photography" : "Videography"}`,
            ];
            setFormData({
                ...formData,
                [mode === "Photo"
                    ? "typesOfPhotography"
                    : "typesOfVideography"]: updatedTypesOfList,
            });
        } else {
            // Handle case where the value already exists
            window.alert(
                `${val.charAt(0).toUpperCase() + val.slice(1)} ${mode === "Photo" ? "Photography" : "Videography"
                } is already added in the list.`
            );
        }
    };

    const getAllStates = () => {
        const apiUrl = `${baseUrl}/state/states`;

        axios
            .get(apiUrl)
            .then((response) => {
                const data = response.data.data;
                console.log(data);
                setStates(data);
            })
            .catch((error) => {
                console.error("Error fetching states:", error);
            });
    };
    const handleTravelChange = (selectedOption) => {
        setErrors((prevErrors) => ({
            ...prevErrors,
            willingToTravel: "",
        }));
        setWillingToTravel(selectedOption.value);
        setFormData({
            ...formData,
            willingToTravel: JSON.stringify(selectedOption.value),
        });
        setSelectedStates([]); // Reset selected states when changing the travel option
    };

    const [selectedImagesByCategory, setSelectedImagesByCategory] = useState(
        Uploaders.map(() => [])
    );

    const [uploadImagesByCategory, setUploadImagesByCategory] = useState(
        Uploaders.map(() => [])
    );

    const [showAllImages, setShowAllImages] = useState(
        Uploaders.map(() => false)
    );

    const handleFileChange = (event, categoryIndex, SIngleUpload) => {
        const files = event.target.files;

        if (files.length > 0) {
            // Process each selected file
            for (let i = 0; i < files.length; i++) {
                const file = files[i];

                // Create a FileReader to read the selected image file
                const reader = new FileReader();

                reader.onload = (e) => {
                    const imagePreview = e.target.result;

                    // Add the selected image and its preview URL to the state for the specific category
                    setSelectedImagesByCategory((prevImagesByCategory) => {
                        const newImagesByCategory = [...prevImagesByCategory];
                        newImagesByCategory[categoryIndex] = [
                            ...prevImagesByCategory[categoryIndex],
                            imagePreview,
                        ];

                        return newImagesByCategory;
                    });
                    setUploadImagesByCategory((prevImagesByCategory) => {
                        const uploadNewImagesByCategory = [...prevImagesByCategory];
                        uploadNewImagesByCategory[categoryIndex] = [
                            ...prevImagesByCategory[categoryIndex],
                            file,
                        ];

                        return uploadNewImagesByCategory;
                    });
                };

                reader.readAsDataURL(file);
            }
        }
        // Check if the number of selected images exceeds 10
        if (selectedImagesByCategory[categoryIndex].length > 7) {
            // Set showAllImages to true for this category
            setShowAllImages((prevShowAllImages) => {
                const newShowAllImages = [...prevShowAllImages];
                newShowAllImages[categoryIndex] = true;
                return newShowAllImages;
            });
        }
    };

    console.log(errors);
    const handleRemoveImage = (categoryIndex, imageIndex) => {
        // Remove the selected image from the state for the specific category
        setSelectedImagesByCategory((prevImagesByCategory) => {
            const newImagesByCategory = [...prevImagesByCategory];
            newImagesByCategory[categoryIndex].splice(imageIndex, 1);
            return newImagesByCategory;
        });
    };

    const handleSeeAllClick = (categoryIndex) => {
        setAllPhotos(selectedImagesByCategory[categoryIndex]);
        setShowAllPhotosModal(true);
        // Set showAllImages to true for this category
        setShowAllImages((prevShowAllImages) => {
            const newShowAllImages = [...prevShowAllImages];
            newShowAllImages[categoryIndex] = true;
            return newShowAllImages;
        });
    };



    return (
        <div>
            <section className="profile-photographer mt-5">
                <div className="bootstrap-container relative">
                    <h1 className="mt-lg-5 text-center position-relative text-main">
                        Update your Photographer Profile
                    </h1>
                    {/* <Link
                        to="/editor/photographer"
                        className="absolute top-[-20px] md:top-1/2 right-6 md:right-0 text-white bg-main px-3 py-1 rounded-md"
                    >
                        SignUp As Editor
                    </Link> */}
                </div>
                <div className="bootstrap-container py-5 mt-t">
                    <div className="col-xl-10 col-lg-11 mx-auto">
                        {loading ? (
                            <ProgressLoading message={uploadingMessage} />
                        ) : (
                            <div className="col-lg-10">
                                <div className="profile-update-start">
                                    {!formSubmitted ? (
                                        <form onSubmit={handleSubmit}>
                                            {message && (
                                                <div
                                                    ref={errorMessageRef}
                                                    className={`mb-2 text-${message.includes("success") ? "success" : "danger"
                                                        }`}
                                                >
                                                    {message}
                                                </div>
                                            )}

                                            {Object.keys(errors).length > 0 && (
                                                <div className="mb-2 text-danger">
                                                    Please fill all required fields.
                                                </div>
                                            )}

                                            <div className="border-b-2 border-main mb-4">
                                                <p className="bg-main p-2 w-max text-white text-lg">
                                                    Basic Details
                                                </p>
                                            </div>

                                            <div className="mb-3 form-group">
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <label htmlFor="studioName">Studio Name *</label>
                                                    </div>
                                                    <div className="col-md-9">
                                                        <div className="input-group mb-3">
                                                            <input
                                                                type="text"
                                                                className={`form-control ${errors.studioName &&
                                                                    "is-invalid"}`}
                                                                placeholder="Studio Name"
                                                                name="studioName"
                                                                onChange={handleInputChange}
                                                                ref={(input) =>
                                                                    (formRefs.current.studioName = input)
                                                                }
                                                            />
                                                        </div>
                                                        {errors.studioName && (
                                                            <div className="text-danger">
                                                                {errors.studioName}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="mb-3 form-group">
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <label htmlFor="serviceLocation">
                                                            Service Location*
                                                        </label>
                                                    </div>
                                                    <div className="col-md-9">
                                                        <div className="input-group mb-3">
                                                            <select
                                                                name="serviceLocation"
                                                                className={`form-control select ${errors.serviceLocation &&
                                                                    "is-invalid "}`}
                                                                onChange={handleInputChange}
                                                                ref={(select) =>
                                                                    (formRefs.current.serviceLocation = select)
                                                                }
                                                            >
                                                                <option value="">
                                                                    Select Service Location
                                                                </option>
                                                                <option value="bengaluru">Bengaluru</option>
                                                            </select>
                                                        </div>
                                                        {errors.serviceLocation && (
                                                            <div className="text-danger">
                                                                {errors.serviceLocation}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-3 form-group">
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <label>Experience in Photography?*</label>
                                                    </div>
                                                    <div className="col-md-9">
                                                        <div className="input-group mb-3">
                                                            <input
                                                                type="text"
                                                                className={`form-control ${errors.experienceInPhotography &&
                                                                    "is-invalid"}`}
                                                                name="experienceInPhotography"
                                                                placeholder="In Years"
                                                                onChange={handleInputChange}
                                                                ref={(input) =>
                                                                    (formRefs.current.experienceInPhotography = input)
                                                                }
                                                            />
                                                        </div>
                                                        {errors.experienceInPhotography && (
                                                            <div className="text-danger">
                                                                {errors.experienceInPhotography}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="mb-3 form-group">
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <label htmlFor="languagesKnown">
                                                            Languages Known
                                                        </label>
                                                    </div>
                                                    <div className="col-md-9">
                                                        <div className="input-group mb-3">
                                                            <Select
                                                                isMulti
                                                                options={languagesOptions}
                                                                value={selectedLanguages}
                                                                onChange={languagesData}
                                                                ref={(input) =>
                                                                    (formRefs.current.languagesKnown = input)
                                                                }
                                                                className="w-100"
                                                            />
                                                        </div>
                                                        {errors.languagesKnown && (
                                                            <div className="text-danger">
                                                                {errors.languagesKnown}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="mb-3 form-group">
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <label>Type of Photography *</label>
                                                        {errors.typesOfPhotography && (
                                                            <div className="text-danger">
                                                                {errors.typesOfPhotography}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-md-9">
                                                        <div
                                                            className={`input-group mb-3 ${errors.typesOfPhotography &&
                                                                "is-invalid"}`}
                                                        >
                                                            <div className="main-select-check">
                                                                {PhotographyList.map((singlecheck, index) => (
                                                                    <div className="single-check" key={index}>
                                                                        <label
                                                                            className="check-container"
                                                                            htmlFor={`photography${index}`}
                                                                        >
                                                                            {singlecheck} Photography
                                                                            <input
                                                                                type="checkbox"
                                                                                name="typesOfPhotography"
                                                                                id={`photography${index}`}
                                                                                value={`${singlecheck} Photography`}
                                                                                checked={formData.typesOfPhotography.includes(
                                                                                    singlecheck + " Photography"
                                                                                )}
                                                                                onChange={handleInputChange}
                                                                                ref={(input) =>
                                                                                    (formRefs.current.typesOfPhotography = input)
                                                                                }
                                                                            />
                                                                            <span className="checkmark"></span>
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            <div className="flex flex-col md:flex-row md:items-center gap-2 w-full">
                                                                <label
                                                                    htmlFor="anyOtherPhoto"
                                                                    className="min-w-max"
                                                                >
                                                                    Any other?
                                                                </label>
                                                                <div className="flex gap-3 items-center w-full">
                                                                    <input
                                                                        type="text"
                                                                        name="anyOtherPhoto"
                                                                        id="anyOtherPhoto"
                                                                        className="px-2 py-1 border-gray-200 border outline-none w-3/4 md:w-full"
                                                                        onKeyDown={(e) => {
                                                                            if (e.key === "Enter") {
                                                                                e.preventDefault();
                                                                                other("Photo");
                                                                            }
                                                                        }}
                                                                    />
                                                                    <button
                                                                        type="button"
                                                                        className="w-6 h-6 rounded-full bg-green-500 text-white text-center"
                                                                        onClick={() => other("Photo")}
                                                                    >
                                                                        <p className="font-bold">+</p>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="mb-3 form-group">
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <label>Type of Videography *</label>
                                                        {errors.typesOfVideography && (
                                                            <div className="text-danger">
                                                                {errors.typesOfVideography}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-md-9">
                                                        <div
                                                            className={`input-group mb-3 ${errors.typesOfVideography &&
                                                                "is-invalid"}`}
                                                        >
                                                            <div className="main-select-check">
                                                                {VideographyList.map((singlecheck, index) => (
                                                                    <div className="single-check" key={index}>
                                                                        <label
                                                                            className="check-container"
                                                                            htmlFor={`videography${index}`}
                                                                        >
                                                                            {singlecheck} Videography
                                                                            <input
                                                                                type="checkbox"
                                                                                name="typesOfVideography"
                                                                                id={`videography${index}`}
                                                                                value={`${singlecheck} Videography`}
                                                                                checked={formData.typesOfVideography.includes(
                                                                                    singlecheck + " Videography"
                                                                                )}
                                                                                onChange={handleInputChange}
                                                                                ref={(input) =>
                                                                                    (formRefs.current.typesOfVideography = input)
                                                                                }
                                                                            />
                                                                            <span className="checkmark"></span>
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            <div className="flex flex-col md:flex-row md:items-center gap-2 w-full">
                                                                <label
                                                                    htmlFor="anyOtherVideo"
                                                                    className="min-w-max"
                                                                >
                                                                    Any other?
                                                                </label>
                                                                <div className="flex gap-3 items-center w-full">
                                                                    <input
                                                                        type="text"
                                                                        name="anyOtherVideo"
                                                                        id="anyOtherVideo"
                                                                        className="px-2 py-1 border-gray-200 border outline-none w-3/4 md:w-full"
                                                                        onKeyDown={(e) => {
                                                                            if (e.key === "Enter") {
                                                                                e.preventDefault();
                                                                                other("Video");
                                                                            }
                                                                        }}
                                                                    />
                                                                    <button
                                                                        type="button"
                                                                        className="w-6 h-6 rounded-full bg-green-500 text-white text-center"
                                                                        onClick={() => other("Video")}
                                                                    >
                                                                        <p className="font-bold">+</p>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="border-b-2 border-main mb-4">
                                                <p className="bg-main p-2 w-max text-white text-lg">
                                                    Upload Sample Footages
                                                </p>
                                            </div>

                                            <div className="mb-3 form-group">
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <label>Experience in ? </label>
                                                    </div>
                                                    <div className="col-md-9">
                                                        <div className="input-group mb-3">
                                                            <div className="main-select-check">
                                                                {EventsList.map((singelevent, index) => (
                                                                    <div className="single-check" key={index}>
                                                                        <label
                                                                            className="check-container"
                                                                            htmlFor={`EventsList${index}`}
                                                                        >
                                                                            {singelevent}
                                                                            <input
                                                                                type="checkbox"
                                                                                name="EventsListinput"
                                                                                id={`EventsList${index}`}
                                                                                onChange={handleCheckboxChange}
                                                                                checked={checkedEvents.includes(
                                                                                    `EventsList${index}`
                                                                                )}
                                                                            />
                                                                            <span className="checkmark"></span>
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                        {errors.missingImages && (
                                                            <div
                                                                className="text-danger"
                                                                ref={missingImagesRef}
                                                            >
                                                                {errors.missingImages}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>

                                            {Uploaders.map((SIngleUpload, categoryIndex) => (
                                                <div className="mb-3 form-group" key={categoryIndex}>
                                                    {checkedEvents.includes(
                                                        `EventsList${categoryIndex}`
                                                    ) && (
                                                            <>
                                                                <div className="row align-items-center">
                                                                    <div className="col-md-3">
                                                                        <label>{SIngleUpload} clicked by you</label>
                                                                    </div>
                                                                    <div className="col-md-9 ps-md-5">
                                                                        <div className="input-group mb-3">
                                                                            <div className="showd-images-in-photographer">
                                                                                {selectedImagesByCategory[
                                                                                    categoryIndex
                                                                                ].map((image, imageIndex) => (
                                                                                    <div
                                                                                        key={imageIndex}
                                                                                        className="image-preview"
                                                                                    >
                                                                                        <img src={image} alt="Preview" />
                                                                                        <button
                                                                                            className="remove-button"
                                                                                            type="button"
                                                                                            onClick={() =>
                                                                                                handleRemoveImage(
                                                                                                    categoryIndex,
                                                                                                    imageIndex
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <i className="fa fa-close"></i>
                                                                                        </button>
                                                                                    </div>
                                                                                ))}
                                                                            </div>
                                                                            <label
                                                                                htmlFor={`coverupload${categoryIndex}`}
                                                                                // className="d-block"
                                                                                style={{ width: "70px" }}
                                                                            >
                                                                                <span className="image-here d-block">
                                                                                    {selectedImagesByCategory[
                                                                                        categoryIndex
                                                                                    ]?.length > 0 ? (
                                                                                        <div
                                                                                            type="button"
                                                                                            className="text-sm rounded-md my-1 w-max bg-green-500 px-4 py-2 text-white"
                                                                                        >
                                                                                            Upload More
                                                                                        </div>
                                                                                    ) : (
                                                                                        <img src={UploadIcon} alt="" />
                                                                                    )}
                                                                                    <input
                                                                                        type="file"
                                                                                        name=""
                                                                                        hidden
                                                                                        id={`coverupload${categoryIndex}`}
                                                                                        multiple
                                                                                        onChange={(e) =>
                                                                                            handleFileChange(e, categoryIndex)
                                                                                        }
                                                                                    />
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                </div>
                                            ))}
                                            <div className="border-b-2 border-main mb-4">
                                                <p className="bg-main p-2 w-max text-white text-lg">
                                                    Other Details
                                                </p>
                                            </div>

                                            <div className="mb-3 form-group">
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <label htmlFor="">Willing to Travel</label>
                                                    </div>
                                                    <div className="col-md-9">
                                                        <div className="input-group mb-3">
                                                            <Select
                                                                options={travelOptions}
                                                                onChange={handleTravelChange}
                                                                ref={(input) =>
                                                                    (formRefs.current.willingToTravel = input)
                                                                }
                                                                value={travelOptions.find(
                                                                    (option) => option.value === willingToTravel
                                                                )}
                                                                className="w-100 d-block z-20"
                                                            />
                                                        </div>
                                                        {errors.willingToTravel && (
                                                            <div className="text-danger">
                                                                {errors.willingToTravel}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>

                                            {willingToTravel === "Yes" && (
                                                <div className="mb-3 form-group">
                                                    <div className="row">
                                                        <div className="col-md-3">
                                                            <label htmlFor="">
                                                                Which State You can travel
                                                            </label>
                                                        </div>
                                                        <div className="col-md-9">
                                                            <div className="input-group mb-3">
                                                                <Select
                                                                    isMulti
                                                                    options={stateOptions}
                                                                    value={selectedStates}
                                                                    onChange={handleStateChange}
                                                                    className="w-100 z-10"
                                                                />
                                                            </div>
                                                            {/* Display an error if no states selected */}
                                                            {selectedStates.length === 0 && (
                                                                <div className="text-danger">
                                                                    Which State You can travel is required.
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            <div className="mb-3 form-group">
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <label>Maximum delivery time</label>
                                                    </div>
                                                    <div className="col-md-9">
                                                        <div className="input-group mb-3">
                                                            <select
                                                                name="deliveryTime"
                                                                className={`form-control form-select select ${errors.deliveryTime &&
                                                                    "is-invalid"}`}
                                                                onChange={handleInputChange}
                                                                ref={(input) =>
                                                                    (formRefs.current.deliveryTime = input)
                                                                }
                                                            >
                                                                <option value="" defaultChecked disabled>
                                                                    Select
                                                                </option>
                                                                <option value="30-60 days">
                                                                    30 - 60 days
                                                                </option>
                                                                <option value="60-90 days">
                                                                    60 - 90 days
                                                                </option>
                                                                <option value="90-120 days">
                                                                    90 - 120 days
                                                                </option>
                                                            </select>
                                                        </div>
                                                        {errors.deliveryTime && (
                                                            <div className="text-danger">
                                                                {errors.deliveryTime}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="mb-3 form-group">
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <label>Are you an individual/ a team ?*</label>
                                                    </div>
                                                    <div className="col-md-9">
                                                        <div className="input-group mb-3">
                                                            <select
                                                                name="individualOrTeam"
                                                                className={`form-control form-select select ${errors.individualOrTeam &&
                                                                    "is-invalid"}`}
                                                                onChange={handleInputChange}
                                                                ref={(input) =>
                                                                    (formRefs.current.individualOrTeam = input)
                                                                }
                                                            >
                                                                <option value="">Select</option>
                                                                <option value="Individual">Individual</option>
                                                                <option value="Team">Team</option>
                                                            </select>
                                                        </div>
                                                        {errors.individualOrTeam && (
                                                            <div className="text-danger">
                                                                {errors.individualOrTeam}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>

                                            {formData?.individualOrTeam === "Team" && (
                                                <div className="mb-3 form-group">
                                                    <div className="row">
                                                        <div className="col-md-3">
                                                            <label>Number of members in the team</label>
                                                        </div>
                                                        <div className="col-md-9">
                                                            <div className="input-group mb-3">
                                                                <input
                                                                    type="text"
                                                                    className={`form-control ${errors.numberOfTeamMembers &&
                                                                        "is-invalid"}`}
                                                                    name="numberOfTeamMembers"
                                                                    onChange={handleInputChange}
                                                                    ref={(input) =>
                                                                        (formRefs.current.numberOfTeamMembers = input)
                                                                    }
                                                                />
                                                            </div>
                                                            {formData.numberOfTeamMembers.length === 0 && (
                                                                <div className="text-danger">
                                                                    {errors.numberOfTeamMembers}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                            <div className="mb-3 form-group">
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <label>Your Business google link*</label>
                                                    </div>
                                                    <div className="col-md-9">
                                                        <div className="input-group mb-3">
                                                            <input
                                                                type="text"
                                                                className={`form-control ${errors.businessLink &&
                                                                    "is-invalid"}`}
                                                                placeholder=""
                                                                name="businessLink"
                                                                onChange={handleInputChange}
                                                                ref={(input) =>
                                                                    (formRefs.current.businessLink = input)
                                                                }
                                                            />
                                                        </div>
                                                        {errors.businessLink && (
                                                            <div className="text-danger">
                                                                {errors.businessLink}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="mb-3 form-group">
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <label>Any highlights about your services</label>
                                                    </div>
                                                    <div className="col-md-9">
                                                        <div className="input-group mb-3">
                                                            <textarea
                                                                name="anyHighligths"
                                                                rows="4"
                                                                className={`form-control resize-none ${errors.anyHighligths &&
                                                                    "is-invalid"}`}
                                                                onChange={handleInputChange}
                                                            ></textarea>
                                                        </div>
                                                        {errors.anyHighligths && (
                                                            <div className="text-danger">
                                                                {errors.anyHighligths}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="mb-3 form-group">
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <label>Your google place id *</label>
                                                    </div>
                                                    <div className="col-md-9">
                                                        <div className="input-group mb-3">
                                                            <input
                                                                type="text"
                                                                className={`form-control ${errors.googlePlaceId &&
                                                                    "is-invalid"}`}
                                                                placeholder=""
                                                                name="googlePlaceId"
                                                                onChange={handleInputChange}
                                                                ref={(input) =>
                                                                    (formRefs.current.googlePlaceId = input)
                                                                }
                                                            />
                                                        </div>
                                                        {errors.googlePlaceId && (
                                                            <div className="text-danger">
                                                                {errors.googlePlaceId}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="mb-3 form-group">
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <label>About You *</label>
                                                    </div>
                                                    {!generateAbout ? (
                                                        <div className="col-md-9">
                                                            <div className="input-group mb-3">
                                                                <textarea
                                                                    name="aboutYou"
                                                                    rows="3"
                                                                    className={`form-control resize-none ${errors.aboutYou &&
                                                                        "is-invalid"}`}
                                                                    onChange={handleInputChange}
                                                                    value={formData.aboutYou}
                                                                    ref={(input) =>
                                                                        (formRefs.current.aboutYou = input)
                                                                    }
                                                                ></textarea>
                                                            </div>
                                                            {errors.aboutYou && (
                                                                <div className="text-danger">
                                                                    {errors.aboutYou}
                                                                </div>
                                                            )}
                                                        </div>
                                                    ) : (
                                                        <div className="col-md-9">
                                                            <div className="input-group mb-3">
                                                                <textarea
                                                                    name="aboutYou"
                                                                    rows="3"
                                                                    className={`form-control resize-none ${errors.aboutYou &&
                                                                        "is-invalid"}`}
                                                                    onChange={handleInputChange}
                                                                    value={
                                                                        generateAbout ? formData.aboutYou : ""
                                                                    }
                                                                    ref={(input) =>
                                                                        (formRefs.current.aboutYou = input)
                                                                    }
                                                                ></textarea>
                                                            </div>
                                                            {errors.aboutYou && (
                                                                <div className="text-danger">
                                                                    {errors.aboutYou}
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="text-end mb-4">
                                                <button
                                                    type="button"
                                                    className="py-2 text-white rounded-md text-sm px-5 !bg-main"
                                                    onClick={handleGenerateButtonClick}
                                                >
                                                    Generate About
                                                </button>
                                                {errorMessage && (
                                                    <div className="text-danger">{errorMessage}</div>
                                                )}
                                            </div>
                                            <div className="mb-3 form-group">
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <label>
                                                            Any Additional Info you would like to provide us
                                                            ?
                                                        </label>
                                                    </div>
                                                    <div className="col-md-9">
                                                        <div className="input-group mb-3">
                                                            <textarea
                                                                name="additionalInfo"
                                                                rows="3"
                                                                onChange={handleInputChange}
                                                                className={`form-control resize-none`}
                                                            ></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="mb-3 form-group">
                                                <div className="row">
                                                    <div className="input-group mb-3">
                                                        <div className="main-select-check text-start">
                                                            <div className="single-check w-100">
                                                                <label
                                                                    className="check-container"
                                                                    htmlFor={`EventsListabcd`}
                                                                >
                                                                    By checking this box, I acknowledge that I
                                                                    have read, understood, and agree to abide by
                                                                    the{" "}
                                                                    <a
                                                                        href="/terms"
                                                                        target="_blank"
                                                                        className="underline !text-main"
                                                                    >
                                                                        Terms and Conditions
                                                                    </a>{" "}
                                                                    of Boopho.com. I understand that my use of
                                                                    the platform is subject to these terms, and
                                                                    I agree to comply with all applicable laws
                                                                    and regulations. I also acknowledge that my
                                                                    personal information will be handled in
                                                                    accordance with the{" "}
                                                                    <a
                                                                        href="/privacy_policy"
                                                                        target="_blank"
                                                                        className="underline !text-main"
                                                                    >
                                                                        Privacy Policy
                                                                    </a>{" "}
                                                                    of the platform.
                                                                    <input
                                                                        type="checkbox"
                                                                        name="confirmBox"
                                                                        id={`EventsListabcd`}
                                                                        onChange={handleInputChange}
                                                                        ref={(input) =>
                                                                            (formRefs.current.confirmBox = input)
                                                                        }
                                                                    />
                                                                    <span className="checkmark"></span>
                                                                </label>
                                                            </div>
                                                            {errors.confirmBox && (
                                                                <div className="text-danger">
                                                                    {errors.confirmBox}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <button
                                                type="submit"
                                                className="mx-auto d-block bg-green-500 px-5 py-2 text-white rounded-md text-sm"
                                            // disabled={Array.from(formData).every((item) =>
                                            //   item === "" || item.length === 0 || item === false
                                            //     ? true
                                            //     : false
                                            // )}
                                            >
                                                Submit the details
                                            </button>
                                        </form>
                                    ) : photographerStatus === "REJECT" ? (
                                        <PopupMessage message="Your registration request has been rejected by our team" />
                                    ) : (
                                        <PopupMessage
                                            message={`Thank you for submitting the form. Our admin team will review it and get back to you at the earliest. `}
                                            message2={
                                                "Kindly logout and login again to see the updated message. If you think this is an error, please write to us on support@boopho.com"
                                            }
                                        />
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </section>
        </div>
    )
}

export default EditProfile