import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { getSocket, getUnreadMsgCount } from "../../Utils/socket";

const PhotographerSidebar = ({ isEditor=false, }) => {
  let all_urls = isEditor ? 
  [
    {
      pageUrl: "/editor/upcomingevents",
      pageName: "Upcoming event",
      isParam: false,
    },
    {
      pageUrl: "/editor/progressevents",
      pageName: "In-progress event",
      isParam: false,
    },
    {
      pageUrl: "/editor/pastevents",
      pageName: "Past event",
      isParam: false,
    },
  ] 
  :
  [
    {
      pageUrl: "/photographer/upcomingevents",
      pageName: "Upcoming event",
      isParam: true,
    },
    {
      pageUrl: "/photographer/progressevents",
      pageName: "In-progress event",
      isParam: true,
    },
    {
      pageUrl: "/photographer/pastevents",
      pageName: "Past event",
      isParam: true,
    },
    {
      pageUrl: "/photographer/busydays",
      pageName: "Mark calendar busy",
      isParam: false,
    },
    {
      pageUrl: "/photographer/requested-calls",
      pageName: "Requested Calls",
      isParam: false,
    },
    {
      pageUrl: "/photographer/customer-chats",
      pageName: "Customer Chats",
      isParam: false,
    },
    {
      pageUrl: "/photographer/payments",
      pageName: "Payments",
      isParam: false,
    },
    {
      pageUrl: "/photographer/generate-coupon-code",
      pageName: "Generate Coupon Code",
      isParam: false,
    },
  ];
  const { pathname } = useLocation();
  const [msgCount, setMsgCount] = useState({});
  let socket = getSocket();

  console.log(pathname, "==pathname:sidebar.js");
  
  useEffect(()=>{
    socket = getSocket();
    // const userId = Number(localStorage.getItem("userId"));
    socket?.on("unread_msg_count", (data) => {
      console.log("unread_msg_count2", data);
      // setMsgCount(data);
      setMsgCount(data);
    });
  }, [socket])
  

  return (
    <>
      <div className="col-md-3 px-0">
        <aside className="photgrapher-sidebar">
          <ul className="siderbar-list">
            {all_urls.map((singleUrl, index) => (
              <li className="single-item">
                <NavLink
                  to={
                    singleUrl.isParam
                      ? `${singleUrl.pageUrl}/${index}`
                      : singleUrl.pageUrl
                  }
                  className={`sidebar-link flex justify-center items-center relative ${
                    pathname === singleUrl.pageUrl ||
                    (singleUrl.isParam &&
                      index.toString() === pathname.split("/")[4]) // Use || (logical OR) here
                      ? "active"
                      : ""
                  }`}
                >
                  {singleUrl.pageName} {singleUrl.pageName === "Customer Chats" && Object.keys(msgCount)?.length > 0 ? <span className={`w-[14px] h-[14px] rounded-full bg-[#ff0000] text-[10px] font-bold absolute right-4`}>{Object.keys(msgCount)?.length}</span>:null}
                </NavLink>
              </li>
            ))}
            {/* <li className="single-item">
                            <NavLink to="/photographer/progressevents" className="sidebar-link" activeClassName="active">
                                In-progress event
                            </NavLink>
                        </li>
                        <li className="single-item">
                            <NavLink to="/photographer/pastevents" className="sidebar-link" activeClassName="active">
                                Past event
                            </NavLink>
                        </li>
                        <li className="single-item">
                            <NavLink to="/photographer/busydays" className="sidebar-link" activeClassName="active">
                                Mark calendar busy
                            </NavLink>
                        </li>
                        <li className="single-item">
                            <NavLink to="/photographer/payments" className="sidebar-link" activeClassName="active">
                                Payments
                            </NavLink>
                        </li> */}
          </ul>
        </aside>
      </div>
    </>
  );
};
export default PhotographerSidebar;
