import React, { useEffect, useState } from "react";
import ModalLayout from "../ModalLayout";
import _ from "lodash";
// react-icons
import { FaLocationDot } from "react-icons/fa6";
import { BiPlus } from "react-icons/bi";
import { RxCross1 } from "react-icons/rx";
import { AiFillCloseCircle } from "react-icons/ai";
import { useParams } from "react-router";

const CustomizePackage = ({
  showModal,
  setShowModal,
  handleConfirm,
  pckg,
  photographer,
}) => {
  console.log(photographer, "PHOTOTOTO");
  const closeModal = () => {
    setShowModal(false);
  };
  const { id, category, bookingId } = useParams();
  const [service, setservice] = useState([]);

  const [p, setp] = useState({});

  useEffect(() => {
    let data = photographer?.PhotographerPrice?.find(
      (x) => x.type === "Packages"
    )?.services[category];
    let allServices = [];

    let newData = _.cloneDeep(data);
    newData?.forEach((package1) => {
      package1?.services?.forEach((service) => {
        // Check if the service already exists in allServices
        const exists = allServices.some((existingService) => {
          return (
            existingService.label === service.label &&
            existingService.amount === service.amount
          );
        });

        // If the service doesn't exist, add it to allServices
        if (!exists) {
          service.isCustom = true;
          allServices.push(service);
        }
      });
    });
    setservice(allServices);
    console.log(allServices, "servicess");
    if (showModal === true) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "visible";
    }

    return () => {
      document.body.style.overflowY = "visible";
    };
  }, [showModal]);

  useEffect(() => {
    if (pckg?.services?.length > 0) {
      setp({
        ...pckg,
        services: [...pckg.services],
      });
    }
  }, [pckg]);
  console.log(p, "PPPPP");
  const customeServices = [
    {
      label: "service1",
      amount: "2000",
      isCustom: true,
    },
    {
      label: "service2",
      amount: "2000",
      isCustom: true,
    },
    {
      label: "service3",
      amount: "2000",
      isCustom: true,
    },
    {
      label: "service4",
      amount: "2000",
      isCustom: true,
    },
  ];
  const addService = (service) => {
    console.log(p.services, "PAPASPAPSD");
    setp({
      ...p,
      services: [...p.services, service],
    });
  };
  const removeService = (service) => {
    setp({
      ...p,
      services: p.services.filter((s) => {
        return s.label !== service.label;
      }),
    });
  };
  return (
    <>
      {showModal && (
        <section className={` fixed inset-0 flex justify-center z-50`}>
          <div
            className="bg-black/50 absolute w-full h-full top-0"
            onClick={closeModal}
          ></div>

          <div className="fixed z-20 flex flex-col gap-10 p-3 min-w-[80vw] max-w-[80vw] md:min-w-[60vw] bg-white rounded-lg max-h-full overflow-auto">
            <div className="relative flex justify-center items-centerw-full bg-main p-2 rounded-t-md">
              <p className="text-white">My Package</p>
              <RxCross1
                className="absolute right-2 text-white text-xl cursor-pointer"
                onClick={closeModal}
              />
            </div>

            <div className="flex flex-col justify-center gap-3">
              <div className="flex flex-col gap-2 items-center justify-center ">
                <h2 className="!text-main">{photographer?.studioName}</h2>
                <p className="!text-main flex gap-2">
                  <FaLocationDot />
                  <span className="text-sm">
                    {photographer?.serviceLocation}
                  </span>
                </p>
                <p className="text-main text-sm">
                  Event Date:18th Apr 2022 - 22nd Apr 2022
                </p>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
                  {/* Package Details Starts */}
                  <div className="rounded-xl shadow-md border border-gray-700 w-full flex flex-col gap-3 justify-center">
                    <div className="flex justify-center bg-main rounded-t-xl py-2">
                      <h3 className="!text-white">{pckg?.name}</h3>
                    </div>
                    <div className="flex flex-col gap-3 p-3">
                      {p?.services?.length > 0 &&
                        p?.services?.map((s) => {
                          if (!s.isCustom)
                            return (
                              <div className="flex flex-col">
                                <p className="text-main font-bold">{s.label}</p>
                                <p className="text-main">{s.amount}</p>
                              </div>
                            );
                        })}

                      {/* If the items will be added from Additional Services then map this div */}
                      {p?.services?.length > 0 &&
                        p?.services?.map((s) => {
                          if (s.isCustom)
                            return (
                              <div className="flex items-center justify-between">
                                <div className="flex flex-col">
                                  <p className="text-main font-bold">
                                    {s.label}
                                  </p>
                                  <p className="text-main">{s.amount}</p>
                                </div>
                                <button
                                  onClick={() => {
                                    removeService(s);
                                  }}
                                >
                                  <AiFillCloseCircle className="text-red-500 text-2xl" />
                                </button>
                              </div>
                            );
                        })}
                    </div>
                  </div>
                  {/* Package Details Ends */}

                  {/* Additional service, total and confirm btn Starts */}

                  <div className="flex flex-col h-full justify-evenly">
                    <div className="flex flex-col gap-3 w-full">
                      <h3 className="!text-main font-bold">
                        Additional Services
                      </h3>
                      {service?.map((s) => {
                        return (
                          <div className="flex w-full justify-between">
                            <div className="flex flex-col">
                              <p className="text-main font-bold">{s.label}</p>
                              <p className="text-main">{s.amount}</p>
                            </div>
                            <button
                              onClick={() => addService(s)}
                              className="p-1 rounded-full bg-green-500 h-max"
                            >
                              <BiPlus className="text-white" />
                            </button>
                          </div>
                        );
                      })}
                    </div>
                    {/* Total and confirm btn */}
                    <div className="flex flex-col items-center gap-2">
                      <div className="flex w-full justify-between text-lg text-main ">
                        <p>Total</p>
                        <p className="font-bold">
                          {p?.services?.reduce(
                            (acc, item) => acc + parseInt(item.amount),
                            0
                          )}
                          /-
                        </p>
                      </div>
                      <div>
                        <button
                          className="bg-main py-1 px-3 text-white md:text-lg"
                          onClick={() => {
                            handleConfirm(p);
                          }}
                        >
                          Confirm
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* Additional service, total and confirm btn Ends */}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default CustomizePackage;
