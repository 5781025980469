// socket.js
import io from "socket.io-client";

let socket = null, unReadMsg={};
// console.log(process.env.REACT_APP_BASE_URL, "REACT_APP_BASE_URL");
export const initiateSocket = (token) => {
  socket = io.connect(process.env.REACT_APP_BACKEND_BASE_URL, {
    extraHeaders: {
      "Authorization": token,
    },
  });

  socket?.on("connect", () => {
    console.log("Socket connected");
    // sendMessage({ senderId: 178, receiverId: 177, message: { text: "Hello New One", type: "text" }, isForAdmin:false });
    // sendMessage({ senderId: 178, receiverId: null, message: { text: "Socket done hogya", type: "text" }, isForAdmin: true });
    // readMessageAndUpdateStatus({ userId: 177 });
  });

  socket?.on('disconnect', () => {
    console.log('Socket disconnected');
  });
  
  // Get all Online Users list (photographer, admins, users)
  socket?.on('online_user_list', (data) => {
    console.log('online_user_list: ', data);
  });
  
  // Get all new messages
  // socket?.on('new_message', (data) => {
  //   console.log('New Message: ', data);
  // });

  // After send message if there is any problem in msg then this function is called - use it to SHOW user that msg is not send.
  socket?.on("msg_not_send", (data)=>{
    console.log("msg_not_send");
  });

  socket?.on("uploadProgress", (data)=>{
    console.log("uploadProgress", data);
  });

  // After Login (back to app) if user has any unread msg from any user (or multiple msg from multiple user)
  // then this function gives you a key value pairs of userId (msg_sender_id) and unread_msg_count 
  // for example: { 178: 4 } mean user whose id is 178 has send you 4 msg which you have not read yet.
  // socket?.on("unread_msg_count", (data) => {
  //   console.log("unread_msg_count", data);
  //   unReadMsg = data;
  // });

  
  // socket.on("message_read", (data) => {
  //   console.log("unread_msg_count", data);
  // });
}

export function getUnreadMsgCount(){
  return unReadMsg;
}

export function sendMessage(data, callback){
  // data = { senderId=1, receiverId=2, isForAdmin=false, message={ text: "hi, this is msg", type: "text" }}
  // isForAdmin=true when user send msg to admin
  // isForAdmin=false when user send msg to user/photographer
  console.log(data, "sendMessagesendMessage", socket);
  socket?.emit("new_message", data, callback);
}

// when user  click on specific user msg then this function is called with current User Msg.
export function readMessageAndUpdateStatus(data, callback){
  // data = { userId: 178 }
  socket?.emit("get_user_message", data, callback);
  // socket.emit("message_read", data, (ackData)=>{
  //   console.log(ackData, "ackData : message_read");
  // });
}


export const getSocket = () => socket;
