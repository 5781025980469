import React, { useState, useRef, useEffect } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { Studio1 } from "../../Constant/Index";
import Swal from "sweetalert2";
import CustomizePackage from "../../Components/Modals/CustomizePackage";
import { useDispatch } from "react-redux";
import {
  getPhotographerDetailAsync,
  setCurrentBooking,
} from "../../Redux/slice/mainSlice";
import _ from "lodash";

const BookingDetails = () => {
  const inputRefs = useRef([]);
  const navigate = useNavigate();
  const [packageSelected, SetPackageSelected] = useState(false);
  const [showCustomize, setShowCustomize] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState({});
  const [confirmedPackage, setConfirmedPackage] = useState("");
  const [Image, setImage] = useState("");
  const selectPackage = () => {
    SetPackageSelected(true);
  };
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const startDate = searchParams.get("startDate");
  const endDate = searchParams.get("endDate");
  const [state, setState] = useState({});
  const [packages, setPackages] = useState({});
  const [address, setAddress] = useState("");
  const dispatch = useDispatch();
  const { id, category, bookingId } = useParams();
  useEffect(() => {
    dispatch(getPhotographerDetailAsync(id)).then((x) => {
      setState(x?.payload?.Photographer);
      setImage(x?.payload?.Photographer.category[category]?.image[0]);
      setPackages(
        x?.payload?.Photographer?.PhotographerPrice?.find(
          (x) => x?.type === "Packages"
        ).services[category]
      );
    });
  }, []);
  console.log(packages, "PACKAEGS");

  const redirectToPayment = () => {
    // Display the SweetAlert2 dialog
    Swal.fire({
      title: "Booking Confirmation",
      text: "Would you like to book this Studio",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        // Redirect to the payment page using the Link component
        if (Object.keys(confirmedPackage).length > 0) {
          let newConfirm = _.cloneDeep(confirmedPackage);
          newConfirm.address = address;
          dispatch(setCurrentBooking(newConfirm));
          console.log(newConfirm, "CONFIASDASD");
          navigate(`/booking-summary/${category}/${id}/${bookingId}`);
        } else {
          alert("please select a package first");
        }
      } else {
        // The user clicked "No" or closed the dialog
        // Swal.fire('Payment Cancelled', '', 'info');
        // navigate("/");
      }
    });
  };

  const handleCustomizePackage = (p) => {
    // setSelectedPackage(packageName);
    // setConfirmedPackages({
    //   ...packages,
    //   packageName: { ...packageName, selected: true },
    // });
    setSelectedPackage(p);
    setShowCustomize(true);
  };
  console.log(selectedPackage, "SELECTEDPACKAGE");
  const handleConfirmPackage = (p) => {
    console.log(p);
    setSelectedPackage({
      ...p,
      confirmed: true,
    });
    console.log(packages);
    setPackages([
      ...packages.map((pckg) => {
        if (pckg.name === p.name) {
          pckg.services = p.services;
          return pckg;
        } else {
          return pckg;
        }
      }),
    ]);
    setConfirmedPackage(p);

    setShowCustomize(false);
    // console.log(confirmedPackage);
    // console.log(selectedPackage);
  };

  const handleAddress = (e) => {
    setAddress(e.target.value);
  };

  return (
    <>
      <main className="site-front-page">
        <Header />
        <section className="booking-details" style={{ paddingTop: "20px" }}>
          <div className="bootstrap-container">
            <div className="details-start">
              <div className="col-xxl-10 col-xl-11 c0l-lg-12 mx-auto">
                <div
                  className="studio-image"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <figure className="image-main h-[500px] w-[500px]">
                    <img src={Image} alt="" className="the-img" />
                  </figure>
                  <div className="studio-event">
                    <div className="box">
                      <h3>{state?.studioName}</h3>
                      <span className="d-block">{state.serviceLocation}</span>
                      <button className="share-booking bg-transparent border-0 position-absolute">
                        <i className="fa fa-share-alt fa-2x"></i>
                      </button>
                    </div>
                    <p>
                      Event Date: {startDate} - {endDate}
                    </p>
                  </div>
                </div>
                <div className="event-complete-details">
                  {/* <div className="photograph-table mt-5">
                                    <table>
                                        <tbody>
                                            {
                                                PackagesList.map((SinglePackage, index) => (
                                                    <tr>
                                                        <td>{SinglePackage.Name}</td>
                                                        <td className="the-counter">
                                                            <div className="counter">
                                                                <button className="counter-btn decrement" onClick={() => handleDecrement(index)} >-</button>
                                                                <input type="text" className="counted" readOnly defaultValue={SinglePackage.Total} ref={(el) => (inputRefs.current[index] = el)} />
                                                                <button className="counter-btn increment" onClick={() => handleIncrement(index)} >+</button>
                                                            </div>
                                                        </td>
                                                        <td>{SinglePackage.TIming}</td>
                                                        <td>
                                                            <span className="price">{SinglePackage.Price}</span>
                                                        </td>
                                                        <td>{SinglePackage.TotalTime}</td>
                                                    </tr>
                                                ))
                                            }
                                            <tr>
                                                <td>Additional charge(s)</td>
                                                <td className="the-counter">
                                                    <div className="counter">     
                                                    </div>
                                                </td>
                                                <td></td>
                                                <td>
                                                    <span className="price">₹ 20,000</span>
                                                </td>
                                                <td>for 4 days</td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td className="the-counter">
                                                    <div className="counter">     
                                                    </div>
                                                </td>
                                                <td></td>
                                                <td>
                                                    <span className="price">₹ 2,75,000</span>
                                                </td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div> */}
                  <section className="studio-tabs mt-5 pt-4">
                    <div className="bootstrap-container">
                      <div className="studio-tabs-start">
                        <div className="modal-plans col-xl-10 mx-auto">
                          <div className="row">
                            {packages?.length > 0 &&
                              packages?.map((p) => {
                                return (
                                  <div className="col-md-4">
                                    <div
                                      className={`single-plans px-3 h-100 ${
                                        confirmedPackage?.name === p.name &&
                                        selectedPackage?.name === p.name
                                          ? "!text-green-500 !bg-green-100 before:!bg-gradient-to-b before:!from-green-300 before:!via-white before:!to-white"
                                          : "!text-main"
                                      }`}
                                    >
                                      <div className="icon-here">
                                        {/* <img src={Plan1Img} className="plan-img" alt="" /> */}
                                        <h3 className="md-heading z-index-999 position-relative">
                                          {p.name}
                                        </h3>
                                      </div>

                                      <div class="single-data pt-4 px-3">
                                        <div class="package-list">
                                          <div class="row">
                                            <div class="col-md-12 mb-4">
                                              {p?.services.map((s) => {
                                                return (
                                                  <div
                                                    class={`flex flex-col text-sm gap-1 mb-3 ${
                                                      s.isCustom === true
                                                        ? "bg-green-700 bg-opacity-10 p-2 rounded"
                                                        : " p-2 rounded"
                                                    }`}
                                                  >
                                                    <b>{s?.label}</b>
                                                    <span>
                                                      {s?.amount} per day
                                                    </span>
                                                  </div>
                                                );
                                              })}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      {/* className="w-full min-h-[45px] p-3 absolute bottom-0 left-0 text-center bg-[#efe3e4] text-[#b4838e] text-lg" */}
                                      <div
                                        className={` ${
                                          confirmedPackage?.name === p.name &&
                                          selectedPackage?.name === p.name
                                            ? "!text-green-600 !font-bold !bg-green-300"
                                            : ""
                                        } primary-btn `}
                                      >
                                        {p.services.reduce(
                                          (acc, item) =>
                                            acc + parseInt(item.amount),
                                          0
                                        )}{" "}
                                        Per Day
                                      </div>
                                    </div>
                                    <button
                                      className={`btn px-4 d-block w-100 site-btn mt-3 ${
                                        confirmedPackage?.name === p.name &&
                                        selectedPackage?.name === p.name
                                          ? "!text-white !font-bold !bg-green-500 !border-green-500"
                                          : ""
                                      }`}
                                      onClick={() => handleCustomizePackage(p)}
                                    >
                                      {confirmedPackage?.name === p.name &&
                                      selectedPackage?.name === p.name
                                        ? "Customise"
                                        : "Select/Customise"}
                                    </button>
                                  </div>
                                );
                              })}
                          </div>
                        </div>

                        {/* The Modal Place end Here */}
                        {/* <div className="tabs-data pt-5 mt-5 ms-lg-5">
                          <h2 className="!text-main">
                            Customisable additional services*
                          </h2>
                          <div className="single-data">
                            <div className="package-list col-lg-10 mt-5 pb-5">
                              <div className="row">
                                {Packages.map((Package, index) => (
                                  <div
                                    className="col-md-12 mb-4 pe-md-5"
                                    key={index}
                                  >
                                    <div className="single-package">
                                      <span className="question">
                                        {Package.question}
                                      </span>
                                      <span className="d-flex ">
                                        <Link className="answer">
                                          {Package.answer}
                                        </Link>
                                        <>
                                          {packageSelected && (
                                            <span className="ps-4">
                                              <input
                                                type="checkbox"
                                                name="customize"
                                                id={`customize${index}`}
                                              />
                                              <label
                                                htmlFor={`customize${index}`}
                                                className="ps-3"
                                              >
                                                Select
                                              </label>
                                            </span>
                                          )}
                                        </>
                                      </span>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </section>
                  {/* <div className="col-lg-9 mb-5">
                    <div className="d-flex justify-content-between">
                      <span className="text">Total</span>
                      <span>
                        <strong>₹ 2,75,000</strong>
                      </span>
                    </div>
                  </div>
                  <div className="row align-items-center">
                    <div className="col-md-2">
                      <label htmlFor="">
                        <strong>Discount coupons:</strong>
                      </label>
                    </div>
                    <div className="col-md-8">
                      <input
                        type="text"
                        name=""
                        id=""
                        className="form-control"
                      />
                    </div>
                  </div> */}
                  <div className="form-group mt-32">
                    <label htmlFor="" className="address pb-2">
                      Complete address of the event:
                    </label>
                    <textarea
                      type="text"
                      className="form-control py-2 resize-none"
                      placeholder="Enter Address Here"
                      rows={"3"}
                      value={address}
                      onChange={handleAddress}
                    ></textarea>
                  </div>
                  <div className="payment text-center mt-20">
                    {/* <p className="large-text mt-4">You Pay: ₹ 2,75,000/-</p> */}
                    <button
                      onClick={redirectToPayment}
                      className="btn mx-auto site-btn mt-4"
                    >
                      Confirm
                    </button>
                    {/* <div className="text text-end mt-4"><span>No Cost Emi or 100% payment</span></div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </main>
      <CustomizePackage
        showModal={showCustomize}
        setShowModal={setShowCustomize}
        handleConfirm={handleConfirmPackage}
        pckg={selectedPackage}
        photographer={state}
      />
    </>
  );
};

export default BookingDetails;
