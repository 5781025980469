import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getPhotographerReviewsAsync } from "../../Redux/slice/mainSlice";
import ReviewCard from "./ReviewCard";

export default function ReviewComponent({ id }) {
  const dispatch = useDispatch();
  const [state, setState] = useState([]);
  useEffect(() => {
    if (id) {
      dispatch(getPhotographerReviewsAsync(id)).then((x) => {
        if (x.error) {
          setState([]);
        } else {
          console.log(x.payload.data.data.reviews);
          setState(x.payload.data.data.reviews);
        }
      });
    } else {
      setState([]);
    }
  }, []);

  return (
    <>
      {state.length > 1 ? (
        state.map((review) => {
          return <ReviewCard review={review} />;
        })
      ) : (
        <div className="text-center p-5">No Reviews yet</div>
      )}
    </>
  );
}
