import React from "react";
import { Link } from 'react-router-dom'
import { NavLink } from 'react-router-dom';

const PhotographerSidebar = () => {

    return (
        <>
            <div className="col-md-3 px-0">
                <aside className="photgrapher-sidebar">
                    <ul className="siderbar-list">
                        <li className="single-item">
                            <NavLink to="/editor/upcomingevents" className="sidebar-link" activeClassName="active">
                                Upcoming event
                            </NavLink>
                        </li>
                        <li className="single-item">
                            <NavLink to="/editor/progressevents" className="sidebar-link" activeClassName="active">
                                In-progress event
                            </NavLink>
                        </li>
                        <li className="single-item">
                            <NavLink to="/editor/pastevents" className="sidebar-link" activeClassName="active">
                                Past event
                            </NavLink>
                        </li>
                        
                    </ul>

                </aside>
            </div>
        </>
    )
}
export default PhotographerSidebar;