import React from "react";
import { Link } from "react-router-dom";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import PhotographerSidebar from "../Components/Sidebar";
import Card from "../Components/Card";

const EditorProgressEvents = () => {
  return (
    <>
      <div className="photographer-page">
        <Header />
        <section className="photographer-main pt-5">
          <div className="container-fluid px-0">
            <div className="photgrapher-main-start">
              <div className="row mx-0">
                <PhotographerSidebar />
                <div className="col-md-9 px-3">
                  {/* <div className="single-detail">
                                        <div className="events">
                                            <div className="events-list">
                                                <Link className="single-event" to="">
                                                    <div className="event-top-data">
                                                        <h5 className="event-user">Rahul</h5>
                                                        <span className="event-name normal-text">Birthday</span>
                                                        <span className="event-number normal-text">+91 903XX XXXXX</span>
                                                    </div>
                                                    <div className="d-flex justify-content-between event-other-details mb-3">
                                                        <span className="map-marker normal-text">Bengaluru </span>
                                                        <span className="event-date normal-text">Event Date: 01st Sep 2022 - 03rd Sep 2022</span>
                                                    </div>
                                                    <p className="main-location normal-text">Outer Ring Rd, 7 Block, Banagirinagara, Banashankari 3rd Stage, Banashankari, Bengaluru, Karnataka 560070, India</p>
                                                </Link>
                                                <Link className="single-event" to="">
                                                    <div className="event-top-data">
                                                        <h5 className="event-user">Komal</h5>
                                                        <span className="event-name normal-text">Birthday</span>
                                                        <span className="event-number normal-text">+91 95786 95678</span>
                                                    </div>
                                                    <div className="d-flex justify-content-between event-other-details mb-3">
                                                        <span className="map-marker normal-text">Bengaluru </span>
                                                        <span className="event-date normal-text">Event Date: 10th Sep 2022 - 17th Sep 2022</span>
                                                    </div>
                                                    <p className="main-location normal-text">Outer Ring Rd, 7 Block, Banagirinagara, Banashankari 3rd Stage, Banashankari, Bengaluru, Karnataka 560070, India</p>
                                                </Link>
                                            </div>
                                        </div>
                                    </div> */}
                  <div className="grid grid-cols-2 gap-2">
                    <Card
                      href="/editor/events/single"
                      name="Rahul"
                      city="Bengaluru"
                      phone="+91 903XX XXXXX"
                      eventStartDate="01st Sep 2022"
                      eventEndDate="03rd Sep 2022"
                      location="Outer Ring Rd, 7 Block, Banagirinagara, Banashankari 3rd Stage, Banashankari, Bengaluru, Karnataka 560070, India"
                      btn={true}
                      btnText="Back Upcoming Event"
                      btnBg="green"
                      btnHandleClick={() => console.log("hey")}
                    />
                    <Card
                      href="/editor/events/single"
                      name="Komal"
                      city="Bengaluru"
                      phone="+91 95786 95678"
                      eventStartDate="10st Sep 2022"
                      eventEndDate="17rd Sep 2022"
                      location="Outer Ring Rd, 7 Block, Banagirinagara, Banashankari 3rd Stage, Banashankari, Bengaluru, Karnataka 560070, India"
                      btn={true}
                      btnText="Back Upcoming Event"
                      btnBg="green"
                      btnHandleClick={() => console.log("hey")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};
export default EditorProgressEvents;
