import React from "react";
import { Link } from "react-router-dom";
import bannerImg from "../Assets/Img/photography/banner.png";

const Banner = (props) => {
  return (
    <>
      <section className="w-full relative">
        <img src={bannerImg} alt="" className="w-full h-[200px]" />
        <div className="flex w-full justify-center absolute top-1/3 text-white">
          <p className="!text-[50px]">{props.current}</p>
        </div>
      </section>
    </>
  );
};
export default Banner;
