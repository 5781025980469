import React, { useEffect, useRef, useState } from "react";
import { Menu, Item, useContextMenu } from "react-contexify";

//react-icons
import { FaFolder } from "react-icons/fa";
import { AiOutlineFolderOpen, AiOutlinePlus } from "react-icons/ai";
import { PiShareFatFill } from "react-icons/pi";
import { HiOutlineDownload } from "react-icons/hi";
import { BsClockHistory } from "react-icons/bs";
import { FaCheck } from "react-icons/fa";
import { IoMdHeartEmpty } from "react-icons/io";
import { IoHeart } from "react-icons/io5";
import axios from "axios";
import ImageComponent from "./ImageComponent/ImageComponent";

const baseUrl = process.env.REACT_APP_BASE_URL;

const Image = ({
  src,
  alt,
  id,
  open,
  share,
  download,
  addToFolder,
  onlyOpenOption = false,
  onSelect,
  canSelect = true,
  item,
  allFavourites=[],
  hideMenu,
  setAllFavourites=(data)=>{},
}) => {
  const { show } = useContextMenu({
    id: id,
  });

  function handleContextMenu(event) {
    show({
      event,
      props: {
        id: id,
      },
    });
  }

  const [checked, setChecked] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);

  const handleCheck = (e) => {
    setChecked(!checked);
    onSelect();
  };
console.log(src, "srccccccccccccccccccc, ", item);

  const handleToggleFavourite = () => {
    setIsFavorite(prev => !prev);
    const arr = item.split("/");
    const imageName = arr.pop();
    const pathUrl = arr.join("/");
    // console.log({ imageName, pathUrl, });
    const token = localStorage.getItem("authToken");
    axios.post(`${baseUrl}/fav-pic/add-remove-favourite`, { imageName, folderHash: pathUrl }, { headers: { "x-auth-token": token, }, })
      .then(res => {
        console.log(res.data, "===res:favouriteeess");
        setAllFavourites(res.data.data);
      })
  }

  useEffect(()=>{
    const arr = item.split("/");
    const imageName = arr.pop();
    if(allFavourites.includes(imageName)){
      setIsFavorite(true);
    }
    else {
      setIsFavorite(false);
    }
  }, [])

  if(hideMenu){
    return(
      <ImageComponent src={src} hideMenu={hideMenu} alt={alt} />
      // <div className="relative cursor-pointer">
      //   <img
      //     src={src}
      //     alt={alt}
      //     onContextMenu={handleContextMenu}
      //     // onDoubleClick={selectImg}
      //     onClick={handleCheck}
      //     onDoubleClick={open}
      //     style={{ border: '1px solid gray' }}
      //   />
      // </div>
    )
  }

  return (
    <>
      <div className="relative cursor-pointer">
        <img
          src={src}
          alt={alt}
          onContextMenu={handleContextMenu}
          // onDoubleClick={selectImg}
          onClick={handleCheck}
          onDoubleClick={open}
          style={{ border: '1px solid gray' }}
        />
        {/* {canSelect && (
          <input
            type="checkbox"
            className="absolute right-0 top-0 p-2 rounded-full"
            onChange={handleCheck}
          />
        )} */}

        {canSelect && checked && (
          <div className="bg-main absolute right-0 top-0 p-2 rounded-full">
            <FaCheck className="text-white" />
          </div>
        )}

        <div className="absolute left-0 top-0 p-2 rounded-full" onClick={handleToggleFavourite}>
          {isFavorite ? <IoHeart className="text-red-600 text-[30px]" /> : <IoMdHeartEmpty className="text-red-600 text-[30px]" />}
        </div>
      </div>
      <Menu id={id} className="bg-white border border-main">
        <Item className="" onClick={open}>
          <div className="flex gap-2 text-sm items-center">
            <AiOutlineFolderOpen className="text-xl" /> <p>Open</p>
          </div>
        </Item>
        {!onlyOpenOption && (
          <>
            <Item className="" onClick={share}>
              <div className="flex gap-2 text-sm items-center">
                <PiShareFatFill className="text-xl" /> <p>Share</p>
              </div>
            </Item>
            <Item className="" onClick={download}>
              <div className="flex gap-2 text-sm items-center">
                <HiOutlineDownload className="text-xl" /> <p>Download</p>
              </div>
            </Item>
            <Item className="" onClick={addToFolder}>
              <div className="flex gap-2 text-sm items-center">
                <AiOutlinePlus className="text-xl" /> <p>Add to Folder</p>
              </div>
            </Item>
          </>
        )}
      </Menu>
    </>
  );
};

export default Image;
