import React, { useRef } from "react";
import ModalLayout from "../ModalLayout";
import authApi from "../../Utils/axiosConfig";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

const ShareFolder = ({ showModal, setShowModal, handleCreateFolder, item, removeImages, images=[], }) => {
  const refInput = useRef();
  const refEndDate = useRef();

  const handleSubmitShare = () => {
    const emailOrPhone = refInput.current.value;
    const endDate = refEndDate.current.value;
    const dataToBeSend = {
      userId: item?.userId,
      pgShareId: item?.id,
      endDate: endDate,
      images,
    };
    console.log(emailOrPhone, " ===emailOrPhone:input, ", endDate);
    if(emailOrPhone?.includes("@")) {
      dataToBeSend["sharedUserEmail"] = emailOrPhone;
    }
    else {
      dataToBeSend["sharedUserPhone"] = emailOrPhone;
    }

    console.log(dataToBeSend, "===dataToBeSenddataToBeSenddataToBeSend:sharefolder");

    authApi.post("/share/user-share-with-user", dataToBeSend).then(resp => {
      console.log(resp.data, "postt-user-share-useEffect");
      if(resp.data.status === 200){
        toast.success("Successfully Shared!");
        setShowModal(false);
      }
    }).catch(err => {
      console.log(err, "error:useEffect");
      // toast.error(err?.response?.data?.error?.message);
      Swal.fire({
        title: "Share Error",
        text: err?.response?.data?.error?.message,
        icon: "error",
        showCancelButton: false,
        confirmButtonText: "Ok",
      })
    });
  }

  const handleClose = () => {
    removeImages();
    setShowModal(false);
  }

  return (
    <ModalLayout
      showModal={showModal}
      setShowModal={handleClose}
      heading="Share on Boopho"
    >
      <div className="flex flex-col justify-center gap-3 ">
        <div className="flex flex-col md:flex-row items-center text-main">
          <label htmlFor="emailId" className="w-1/3">
            Recepient eMail ID or Phone Number:
          </label>
          <input
            id="emailId"
            type="text"
            ref={refInput}
            placeholder="Enter your input here..."
            className="border border-main rounded-lg p-2 w-2/3"
          />
        </div>
        <div className="flex flex-col md:flex-row items-center text-main">
          <label htmlFor="shareUntil" className="w-1/3">
            Share until:
          </label>
          <input
            ref={refEndDate}
            id="shareUntil"
            type="date"
            placeholder="Enter your input here..."
            className="border border-main rounded-lg p-2 w-2/3 outline-none"
          />
        </div>
        <div className="text-center">
          <button onClick={handleSubmitShare} className="px-4 py-1 bg-main text-white rounded-md">
            Share
          </button>
        </div>
      </div>
    </ModalLayout>
  );
};

export default ShareFolder;
