import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL; // Assuming your environment variable for base URL is REACT_APP_BASE_URL

// Function to save photographer details

export const getExclusiveDeal = async (category) => {
  try {
    const token = localStorage.getItem("authToken");
    const response = await axios.get(`${baseUrl}/exclusive-deal/${category}`, {
      headers: {
        "x-auth-token": token,
      },
    });
    return response.data; // Assuming your API returns data in a certain format
  } catch (error) {
    // Handle error
    console.error("Error saving photographer details:", error);
    throw error; // Rethrow the error to handle it in the calling code
  }
};

export const getDynamicFilters = async (category) => {
  try {
    const token = localStorage.getItem("authToken");
    const response = await axios.get(`${baseUrl}/photographer/types-list`, {
      headers: {
        "x-auth-token": token,
      },
    });
    return response.data; // Assuming your API returns data in a certain format
  } catch (error) {
    // Handle error
    console.error("Error saving photographer details:", error);
    throw error; // Rethrow the error to handle it in the calling code
  }
};

export const getPhotographerDetail = async (id) => {
  try {
    const token = localStorage.getItem("authToken");
    const response = await axios.get(`${baseUrl}/photographer/${id}/details`, {
      headers: {
        "x-auth-token": token,
      },
    });
    return response.data; // Assuming your API returns data in a certain format
  } catch (error) {
    // Handle error
    console.error("Error saving photographer details:", error);
    throw error; // Rethrow the error to handle it in the calling code
  }
};

export const getMyBookings = async (id) => {
  try {
    const token = localStorage.getItem("authToken");
    const response = await axios.get(
      `${baseUrl}/booking?status=PENDING&limit=10&offset=0`,
      {
        headers: {
          "x-auth-token": token,
        },
      }
    );
    return response.data; // Assuming your API returns data in a certain format
  } catch (error) {
    // Handle error
    console.error("Error saving photographer details:", error);
    throw error; // Rethrow the error to handle it in the calling code
  }
};
