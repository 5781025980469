import React, { useState, useRef, useEffect, useMemo } from "react";
import { formatDate } from "@fullcalendar/core";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import multiMonthPlugin from "@fullcalendar/multimonth";
import { INITIAL_EVENTS, createEventId } from "./event-utils";
import axios, { all } from "axios";

//react-icons
import { AiOutlinePauseCircle, AiOutlinePoweroff } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";

//modals
import Confirmation from "../Modals/Confirmation";
import TurnOffEvents from "../Modals/TurnOffEvents";
import ConfirmHandleTurnOff from "../Modals/ConfirmHandleTurnOff";
import { useLayoutEffect } from "react";
import { event } from "jquery";
import { useScrollTo } from "react-scroll-to-bottom";

function MarkCalendarBusy() {
  const calendarRef = useRef();

  const [currentEvents, setCurrentEvents] = useState([]);
  const [selectedDates, setSelectedDates] = useState({
    startDate: "",
    endDate: "",
  });

  const [calendarView, setCalendarView] = useState("monthView");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRetriveModal, setShowRetriveModal] = useState(false);
  const [showHoldModal, setShowHoldModal] = useState(false);
  const [showUnHoldModal, setShowUnHoldModal] = useState(false);
  const [showTurnOffEvents, setShowTurnOffEvents] = useState(false);
  const [showConfirmHandleTurnOff, setShowConfirmHandleTurnOff] = useState(
    false
  );
  const [holdConfirmation, setHoldConfirmation] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const id = localStorage.getItem("photoGrapherUser");
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const token = localStorage.getItem("authToken");
  const [events, setEvents] = useState([]);
  const [allEvents, setAllEvents] = useState([]);
  const [isProfileOnHold, setIsProfileOnHold] = useState("");
  const [isProfileDelete, setIsProfileDelete] = useState("");
  const [markBusy, setMarkBusy] = useState([]);
  const [completedEventData, setCompletedEventData] = useState([]);
  const [eventCategory, setEventCategory] = useState({});

  const handleDateSelect = (selectInfo) => {
    const calendarApi = selectInfo.view.calendar;

    const { startStr, endStr } = selectInfo;
    setSelectedDates({ startDate: startStr, endDate: endStr });
  };

  const handleEventClick = (clickInfo) => {
    if (
      window.confirm(
        `Are you sure you want to delete the event '${clickInfo.event.title}'`
      )
    ) {
      clickInfo.event.remove();
    }
  };

  const handleEvents = (events) => {
    setCurrentEvents(events);
  };

  const renderEventContent = (eventInfo) => {
    const date = eventInfo.date;

    const isBusy = markBusy.some((busyDate) => busyDate.date === date);
    return (
      <div
        className={`bg-${eventInfo.event.backgroundColor} text-white p-1 rounded`}
        title={eventInfo.event.title}
        style={isBusy ? { backgroundColor: "red", color: "white" } : {}}
      >
        <p style={{ fontSize: "8px" }}>Client Name: {eventInfo.event.title}</p>
        <p style={{ fontSize: "12px" }}>
          Booking ID: {eventInfo.event.extendedProps.publicId}
        </p>
      </div>
    );
  };

  const handleMarkBusy = async () => {
    const calendarApi = calendarRef.current.getApi();

    const existingEvents = calendarApi.getEvents();

    const overlappingEvents = existingEvents.filter((event) => {
      return (
        event.startStr < selectedDates.endDate &&
        event.endStr > selectedDates.startDate
      );
    });

    overlappingEvents.forEach((event) => {
      event.remove();
    });

    const startDate = new Date(selectedDates.startDate);
    const endDate = new Date(selectedDates.endDate);
    const dateRange = [];
    while (startDate < endDate) {
      const dateStr = startDate.toISOString().split("T")[0]; // Get the date part
      dateRange.push(dateStr);
      startDate.setDate(startDate.getDate() + 1);
    }

    dateRange.forEach((date) => {
      calendarApi.addEvent({
        id: createEventId(),
        start: date,
        end: date,
        allDay: true,
        backgroundColor: "#ff5757",
      });
    });

    calendarApi.unselect();

    try {
      const data = {
        photographerId: id,
        operation: "busy",
        range: dateRange,
      };
      const apiUrl = `${baseUrl}/photographer/mark-busy-or-free`;

      const response = await axios.post(apiUrl, data, {
        headers: {
          "x-auth-token": token,
        },
      });

      if (response.status === 200) {
        const responseData = response.data.data;
        console.log(responseData);
        fetchData();
        getPhotographer();
      }
    } catch (error) {
      console.error("Error while fetching data:", error);
    }
  };

  const handleMarkFree = async () => {
    const calendarApi = calendarRef.current.getApi();

    const existingEvents = calendarApi.getEvents();
    const overlappingEvents = existingEvents.filter((event) => {
      return (
        event.startStr < selectedDates.endDate &&
        event.endStr > selectedDates.startDate
      );
    });
    overlappingEvents.forEach((event) => {
      event.remove();
    });

    const startDate = new Date(selectedDates.startDate);
    const endDate = new Date(selectedDates.endDate);
    const dateRange = [];
    while (startDate < endDate) {
      const dateStr = startDate.toISOString().split("T")[0]; // Get the date part
      dateRange.push(dateStr);
      startDate.setDate(startDate.getDate() + 1);
    }

    dateRange.forEach((date) => {
      calendarApi.addEvent({
        id: createEventId(),
        start: date,
        end: date,
        allDay: true,
        backgroundColor: "#ff5757",
      });
    });

    calendarApi.unselect();

    try {
      const data = {
        photographerId: id,
        operation: "free",
        range: dateRange,
      };
      const apiUrl = `${baseUrl}/photographer/mark-busy-or-free`;

      const response = await axios.post(apiUrl, data, {
        headers: {
          "x-auth-token": token,
        },
      });

      if (response.status === 200) {
        const responseData = response.data.data;
        console.log(responseData);
        fetchData();
        getPhotographer();
      }
    } catch (error) {
      console.error("Error while fetching data:", error);
    }
  };

  const toggleCalendarView = () => {
    const calendarApi = calendarRef.current.getApi();

    if (calendarView === "monthView") {
      calendarApi.changeView("multiMonthYear");
      setCalendarView("yearView");
    }
    if (calendarView === "yearView") {
      calendarApi.changeView("dayGridMonth");
      setCalendarView("monthView");
    }
  };

  const handleDeleteProfile = async () => {
    setDeleteConfirmation(true);
    setShowRetriveModal(false);

    try {
      const apiUrl = `${baseUrl}/photographer/delete-or-undelete`;
      const response = await axios.post(apiUrl, null, {
        headers: {
          "x-auth-token": token,
        },
      });

      if (response.status === 200) {
        const responseData = response.data;
        console.log(responseData);
        setShowDeleteModal(false);
        getPhotographer();
      }
    } catch (error) {
      console.error("Error while fetching data:", error);
    }
  };

  const toggleHoldProfile = async () => {
    setShowHoldModal(false);
    setShowUnHoldModal(false);
    try {
      const apiUrl = `${baseUrl}/photographer/hold-or-unhold`;
      const response = await axios.patch(apiUrl, null, {
        headers: {
          "x-auth-token": token,
        },
      });

      if (response.status === 200) {
        const responseData = response.data;
        console.log(responseData);
        setHoldConfirmation(true);
        getPhotographer();
      }
    } catch (error) {
      console.error("Error while fetching data:", error);
    }
  };

  const handleTurnOff = () => {
    setShowTurnOffEvents(false);
    setShowConfirmHandleTurnOff(true);
  };

  const fetchData = async () => {
    try {
      const apiUrl = `${baseUrl}/photographer/bookings`;
      const headers = {
        "x-auth-token": token,
      };

      const response = await axios.get(apiUrl, {
        params: {
          status: "CONFIRMED",
        },
        headers: headers,
      });

      if (response.status === 200) {
        const responseData = response.data.data.rows;
        console.log(responseData);

        const eventData = responseData.reduce((acc, booking) => {
          const currentDate = new Date(booking.startDate);
          const endDate = new Date(booking.endDate);

          while (currentDate <= endDate) {
            const year = currentDate.getFullYear();
            const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based
            const day = String(currentDate.getDate()).padStart(2, "0");
            const formattedDate = `${year}-${month}-${day}`;
            acc.push({
              publicId: booking.id,
              title: booking.BookingUser.name,
              start: formattedDate,
              end: formattedDate,
            });
            currentDate.setDate(currentDate.getDate() + 1);
          }

          return acc;
        }, []);

        // Now you have a single flat array of objects for all the date ranges
        setEvents(eventData);

        const combinedEvents = [
          ...eventData,
          ...completedEventData,
          ...markBusy,
        ];
        console.log("combine", combinedEvents);
        setAllEvents(combinedEvents);
      } else {
        console.error("Unable to fetch data.");
      }
    } catch (error) {
      console.error("Error while fetching data:", error);
    }
  };

  const fetchCompletedData = async () => {
    try {
      const apiUrl = `${baseUrl}/photographer/bookings`;
      const headers = {
        "x-auth-token": token,
      };

      const response = await axios.get(apiUrl, {
        params: {
          status: "COMPLETED",
        },
        headers: headers,
      });

      if (response.status === 200) {
        const responseData = response.data.data.rows;
        console.log(responseData);

        const eventCompletedData = responseData.reduce((acc, booking) => {
          const currentDate = new Date(booking.startDate);
          const endDate = new Date(booking.endDate);

          while (currentDate <= endDate) {
            const year = currentDate.getFullYear();
            const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based
            const day = String(currentDate.getDate()).padStart(2, "0");
            const formattedDate = `${year}-${month}-${day}`;
            acc.push({
              publicId: booking.id,
              title: booking.BookingUser.name,
              start: formattedDate,
              end: formattedDate,
            });
            currentDate.setDate(currentDate.getDate() + 1);
          }

          return acc;
        }, []);

        setCompletedEventData(eventCompletedData);
        fetchData();

        // Now you have a single flat array of objects for all the date ranges

        // const combinedEvents = [...events, ...eventCompletedData, ...markBusy];
        // console.log("combine", combinedEvents);
        // setAllEvents(combinedEvents);
      } else {
        console.error("Unable to fetch data.");
      }
    } catch (error) {
      console.error("Error while fetching data:", error);
    }
  };

  const getPhotographer = async () => {
    try {
      const apiUrl = `${baseUrl}/photographer/me`;
      const response = await axios.get(apiUrl, {
        headers: {
          "x-auth-token": token,
        },
      });

      if (response.status === 200) {
        const responseData = response.data.data;
        console.log(responseData.Photographer.status);

        setIsProfileOnHold(responseData.Photographer.status);
        setIsProfileDelete(responseData.Photographer.deletedAt);
        setEventCategory(
          responseData?.Photographer?.EventCategories?.map((x) => ({
            name: x.name,
            id: x.id,
          }))
        );
        console.log(responseData.Photographer, "PHOTO");
        const busyEvents = responseData.Photographer.Slots.map((slot) => {
          const currentDate = new Date(slot.date);
          const year = currentDate.getFullYear();
          const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based
          const day = String(currentDate.getDate()).padStart(2, "0");
          const formattedDate = `${year}-${month}-${day}`;
          return {
            publicId: 9999,
            title: "Busy",
            start: formattedDate,
            end: formattedDate,
          };
        });

        setMarkBusy([...busyEvents]);
        fetchData();
      }
    } catch (error) {
      console.error("Error while fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [completedEventData, markBusy]);

  useEffect(() => {
    fetchCompletedData();
  }, []);

  useEffect(() => {
    getPhotographer();
  }, [isProfileDelete, isProfileOnHold]);

  return (
    <>
      <section className="w-full flex flex-col gap-10 items-center py-10">
        <div className="w-full flex flex-col items-center">
          <div className="bg-white p-4 rounded-lg shadow-md md:w-[70%] w-[95%]">
            <FullCalendar
              ref={calendarRef}
              events={allEvents}
              plugins={[dayGridPlugin, interactionPlugin, multiMonthPlugin]}
              headerToolbar={{
                left: "prev",
                center: "title",
                right: "next",
                dayHeaderFormat: null, // Set to null or an empty string to hide day names
              }}
              // height={500}
              // dayHeaders={false}
              dayHeaderContent={false}
              eventDisplay="background"
              multiMonthMinWidth={100}
              // initialView={"dayGridMonth"}
              // editable={true}

              selectable={true}
              // dayCellContent={handleDayCellContent}

              // selectMirror={true}
              // weekends={true}
              // initialEvents={INITIAL_EVENTS} // alternatively, use the `events` setting to fetch from a feed
              select={handleDateSelect}
              selectAllow={(selectInfo) => {
                const now = new Date();
                const selectedDate = new Date(selectInfo.startStr);
                // Return false if the selected date is in the past
                return selectedDate >= now;
              }}

              // eventContent={renderEventContent} // custom render function
              // eventClick={handleEventClick}
              // eventsSet={handleEvents} // called after events are initialized/added/changed/removed
              /* you can update a remote database when these fire:
            eventAdd={function(){}}
            eventChange={function(){}}
            eventRemove={function(){}}
          */
            />
          </div>

          {/* Calendar Buttons */}
          <div className="flex flex-wrap gap-3 mt-4 justify-center">
            <button
              onClick={handleMarkBusy}
              className="bg-main text-white py-2 px-4 rounded"
            >
              Mark Busy
            </button>
            <button
              onClick={handleMarkFree}
              className="bg-main text-white py-2 px-4 rounded"
            >
              Mark Free
            </button>
            <button
              onClick={toggleCalendarView}
              className="bg-main text-white py-2 px-4 rounded"
            >
              Change Calendar View
            </button>
          </div>
        </div>

        {/* Other Buttons */}
        <div className="flex flex-wrap w-full gap-2 justify-center">
          <button
            className="bg-main px-3 py-1 flex items-center text-white rounded gap-2"
            onClick={() => setShowTurnOffEvents(true)}
          >
            Turn off events
            <AiOutlinePoweroff className="text-white" />
          </button>
          {isProfileOnHold == "HOLD" ? (
            <button
              className="bg-main text-white py-2 px-4 rounded"
              onClick={setShowUnHoldModal}
            >
              Unhold
            </button>
          ) : (
            <button
              className="bg-main px-3 py-1 flex items-center text-white rounded gap-2"
              onClick={setShowHoldModal}
            >
              Keep me on Hold
              <AiOutlinePauseCircle />
            </button>
          )}
          {isProfileDelete !== null ? (
            <button
              className="bg-main px-3 py-1 text-white"
              onClick={() => setShowRetriveModal(true)}
            >
              Retrieve my profile
            </button>
          ) : (
            <button
              className="bg-main px-3 py-1 flex items-center text-white rounded gap-2"
              onClick={() => setShowDeleteModal(true)}
            >
              Delete my profile
              <RiDeleteBin6Line />
            </button>
          )}
        </div>
      </section>
      <Confirmation
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        handleConfirm={handleDeleteProfile}
        heading="Message"
        text="Your profile will be deleted in 30 days. You will not receive any booking post today. Are you sure you want to proceed ?"
        btnText="Ok"
      />
      <Confirmation
        showModal={showRetriveModal}
        setShowModal={setShowRetriveModal}
        handleConfirm={handleDeleteProfile}
        heading="Message"
        text="Are you sure you want to retrive your profile ?"
        btnText="Ok"
      />
      <Confirmation
        showModal={showUnHoldModal}
        setShowModal={setShowUnHoldModal}
        handleConfirm={toggleHoldProfile}
        heading="Message"
        text=" Are you sure you want to change your profile from hold to unhold ?"
        btnText="Ok"
      />
      <Confirmation
        showModal={showHoldModal}
        setShowModal={setShowHoldModal}
        handleConfirm={toggleHoldProfile}
        heading="Message"
        text="You will not receive any booking post today. Are you sure you want to keep your profile on hold ?"
        btnText="Ok"
      />
      {eventCategory && (
        <TurnOffEvents
          showModal={showTurnOffEvents}
          setShowModal={setShowTurnOffEvents}
          handleTurnOff={handleTurnOff}
          eventCategory={eventCategory}
        />
      )}
      <ConfirmHandleTurnOff
        message="Event - XX and XX are turned off for new booking from date this to date this."
        showModal={showConfirmHandleTurnOff}
        setShowModal={setShowConfirmHandleTurnOff}
      />
    </>
  );
}

export default MarkCalendarBusy;
