import React, { useState } from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import axios from "axios";

import { MdLock } from "react-icons/md";

const ChangePassword = () => {
  const [formData, setFormData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const email = localStorage.getItem("email");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  const token = localStorage.getItem("authToken");
  const [passwordShown, setPasswordShown] = useState(false);
  const [oldPasswordShown, setOldPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };
  const toggleOldPasswordVisibility = () => {
    setOldPasswordShown(!oldPasswordShown);
  };
  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordShown(!confirmPasswordShown);
  };

  const validatePassword = (password) => {
    const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    return regex.test(password);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate passwords
    if (formData.newPassword !== formData.confirmPassword) {
      setError("New Password and Confirm Password do not match.");
      setSuccess(null);
      return;
    }

    if (!validatePassword(formData.newPassword)) {
      setError(
        "New Password should be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, and one number."
      );
      setSuccess(null);
      return;
    }

    setError(null);
    setLoading(true);

    const headers = {
      "x-auth-token": token,
    };

    axios
      .post(`${baseUrl}/auth/change-password`, formData, {
        headers: headers,
      })
      .then((response) => {
        console.log("Password changed successfully:", response.data);
        setSuccess(response.data.message);
        setError(null);
        setFormData({
          oldPassword: "",
          newPassword: "",
          confirmPassword: "",
        });
      })
      .catch((error) => {
        console.log(error.response?.data?.error?.message);
        setError(error.response?.data?.error?.message);
        setSuccess(null);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Header />
      <section className="px-5 py-5">
        <div className="md:w-[50%] mx-auto flex flex-col justify-center">
          <h1 className="text-main">Change Password</h1>
          <form className="flex flex-col gap-3 my-3" onSubmit={handleSubmit}>
            <div class="mb-3 form-group">
              <label for="oldPassword" class="form-label">
                Old Password:
              </label>
              <div className="input-group">
                <span className="input-group-text" id="basic-addon1">
                  <MdLock />
                </span>
                <input
                  id="oldPassword"
                  type={oldPasswordShown ? "text" : "password"}
                  className="form-control"
                  placeholder="Old Password"
                  value={formData.oldPassword}
                  onChange={handleChange}
                  required
                />
                <i
                  className={`z-10 cursor-pointer absolute top-1/4 right-1  fa ${
                    passwordShown ? "fa-eye" : "fa-eye-slash"
                  }`}
                  onClick={toggleOldPasswordVisibility}
                ></i>
              </div>
            </div>
            <div class="mb-3 form-group">
              <label for="newPassword" class="form-label">
                New Password:
              </label>
              <div className="input-group">
                <span className="input-group-text" id="basic-addon1">
                  <MdLock />
                </span>
                <input
                  id="newPassword"
                  // type="password"
                  type={passwordShown ? "text" : "password"}
                  className="form-control"
                  placeholder="New Password"
                  value={formData.newPassword}
                  onChange={handleChange}
                  required
                />
                <i
                  className={`z-10 cursor-pointer absolute top-1/4 right-1  fa ${
                    passwordShown ? "fa-eye" : "fa-eye-slash"
                  }`}
                  onClick={togglePasswordVisibility}
                ></i>
              </div>
            </div>
            {/* <div className="flex flex-col gap-2">
              <label htmlFor="oldPassword" className="min-w-max">
                Old Password:
              </label>
              <div class="input-group">
                <span className="input-group-text" id="basic-addon1">
                  <MdLock />
                </span>
                <input
                  id="oldPassword"
                  type={oldPasswordShown ? "text" : "password"}
                  className="p-2 outline-none w-full border border-gray-400"
                  placeholder="Old Password"
                  value={formData.oldPassword}
                  onChange={handleChange}
                  required
                />
                <i
                  className={`z-10 cursor-pointer absolute top-1/4 right-1  fa ${
                    passwordShown ? "fa-eye" : "fa-eye-slash"
                  }`}
                  onClick={toggleOldPasswordVisibility}
                ></i>
              </div>
            </div> */}
            {/* <div className="flex flex-col gap-2">
              <label htmlFor="newPassword" className="min-w-max">
                New Password:
              </label>
              <div class="input-group">
                <span className="input-group-text" id="basic-addon1">
                  <MdLock />
                </span>
                <input
                  id="newPassword"
                  // type="password"
                  type={passwordShown ? "text" : "password"}
                  className="p-2 outline-none w-full border border-gray-400"
                  placeholder="New Password"
                  value={formData.newPassword}
                  onChange={handleChange}
                  required
                />
                <i
                  className={`z-10 cursor-pointer absolute top-1/4 right-1  fa ${
                    passwordShown ? "fa-eye" : "fa-eye-slash"
                  }`}
                  onClick={togglePasswordVisibility}
                ></i>
              </div>
            </div> */}
            {/* <div className="flex flex-col gap-2">
              <label htmlFor="confirmPassword" className="min-w-max">
                Confirm Password:
              </label>
              <div class="input-group">
                <span className="input-group-text" id="basic-addon1">
                  <MdLock />
                </span>
                <input
                  id="confirmPassword"
                  type={confirmPasswordShown ? "text" : "password"}
                  className="p-2 outline-none w-full border border-gray-400"
                  placeholder="Confirm Password"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  required
                />
                <i
                  className={`z-10 cursor-pointer absolute top-1/4 right-1  fa ${
                    passwordShown ? "fa-eye" : "fa-eye-slash"
                  }`}
                  onClick={toggleConfirmPasswordVisibility}
                ></i>
              </div>
            </div> */}
            <div class="mb-3 form-group">
              <label for="confirmPassword" class="form-label">
                Confirm Password:
              </label>
              <div className="input-group">
                <span className="input-group-text" id="basic-addon1">
                  <MdLock />
                </span>
                <input
                  id="confirmPassword"
                  type={confirmPasswordShown ? "text" : "password"}
                  className="form-control"
                  placeholder="Confirm Password"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  required
                />
                <i
                  className={`z-10 cursor-pointer absolute top-1/4 right-1  fa ${
                    confirmPasswordShown? "fa-eye" : "fa-eye-slash"
                  }`}
                  onClick={toggleConfirmPasswordVisibility}
                ></i>
              </div>
            </div>
            {loading && <p>Loading...</p>}
            {error && <p className="text-red-500">{error}</p>}
            {success && <p className="text-green-500">{success}</p>}
            <div className="self-center">
              <button
                type="submit"
                className="text-white bg-main px-3 py-2 rounded-md"
                disabled={loading}
              >
                Update Password
              </button>
            </div>
          </form>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default ChangePassword;
