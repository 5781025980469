import React from "react";

const HomeAbout = () => {
  return (
    <section className="my-4 mx-3 text-main wow animate__animated animate__fadeIn animate__delay-3s">
      <h1>Why boopho.com ?</h1>

      <div className="flex flex-col gap-2 my-3">
        <p>
          Boopho offers several benefits, depending on your specific needs and
          requirements. Here are some potential advantages of choosing us:
        </p>
        <ul>
          <li className="list-item list-inside list-disc">
            Professional Expertise
          </li>
          <li className="list-item list-inside list-disc">
            Specialized Equipment
          </li>
          <li className="list-item list-inside list-disc">Diverse Services</li>
          <li className="list-item list-inside list-disc">
            Consistency and Reliability
          </li>
          <li className="list-item list-inside list-disc">Creative Vision</li>
          <li className="list-item list-inside list-disc">
            Time and Efficiency
          </li>
          <li className="list-item list-inside list-disc">
            Post-Production Services
          </li>
          <li className="list-item list-inside list-disc">
            Professionalism and Client Service
          </li>
          <li className="list-item list-inside list-disc">
            Backup and Security
          </li>
        </ul>
        <p>
          It is important to research and carefully select a photography company
          based on their portfolio, reputation, and client testimonials to
          ensure they align with your specific needs and expectations and we
          will provide 100% satisfaction as a user and a Customer.
        </p>
        <p>
          Please look into below video to have detailed infomration of why we
          are the best in market:{" "}
        </p>
      </div>

      <div className="flex justify-center">
        <iframe
          width="716"
          height="403"
          src="https://www.youtube.com/embed/Rdu0K70jzoQ"
          title="Boopho: Capture every moments. #photo #photography #album #weddingphotographer"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
    </section>
  );
};

export default HomeAbout;
