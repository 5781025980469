import React, { useEffect, useState } from "react";

//components
import CouponCard from "../Components/CouponCard";

//react-icons
import { BiSolidCoupon } from "react-icons/bi";
import GenerateCoupon from "../Modals/GenerateCoupon";
import EditCoupon from "../Modals/EditCouponCode";
import axios from "axios";

const GenerateCouponCode = () => {
  const [showGenerateCoupon, setShowGenerateCoupan] = useState(false);
  const [showEditCoupon, setShowEditCoupon] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [storeAmount, setStoreAmount] = useState("");
  const [coupon, setCoupon] = useState([]);
  const [editCoupon, setEditCoupon] = useState(null);
  const id = localStorage.getItem('photoGrapherUser');
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const token = localStorage.getItem("authToken");
  const [photographer, setPhotographer] = useState([]);

  const getAllCoupons = async() => {
    try {
      const apiUrl = `${baseUrl}/photographer-coupon-code`;
  
      const response = await axios.get(apiUrl, {
        params: {
          photographerId: id,
        },
        headers: {
          "x-auth-token": token,
        },
      });
  
      if (response.status === 200) {
        const responseData = response.data.data;
        console.log(responseData);
        setPhotographer(responseData);
      }
    } catch (error) {
      console.error("Error while fetching data:", error);
    }
  }

  useEffect(() => {
    getAllCoupons();
  }, []);

  const handleEdit = async(id) => {
    setShowEditCoupon(true);

    try {
      const headers = {
        "x-auth-token": token
      };
      const apiUrl =  `${baseUrl}/photographer-coupon-code/${id}`;
      const response = await axios.get(apiUrl, {headers});
      console.log("Response:", response.data);
      setEditCoupon(response.data.data);
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage(error.response.data.error.message);
    }

  };

  const handleDelete = async(id) => {
    try {
      const headers = {
        "x-auth-token": token
      };
      const apiUrl =  `${baseUrl}/photographer-coupon-code/delete/${id}`;
      const response = await axios.delete(apiUrl, {headers});
      console.log("Response:", response.data);
      getAllCoupons();
      setSuccessMessage(response.data.message);
    } catch (error) {
      console.error("Error:", error);
      // setErrorMessage(error.response.data.error.message);
    }
  }
  return (
    <section className="overflow-x-hidden">
      <div className="flex flex-col gap-3 md:gap-0 md:flex-row md:items-center justify-between my-5">
        <h1 className="text-main md:text-3xl font-bold">
          Generate Coupon Code
        </h1>

        <button
          className="px-4 py-2 text-white bg-main rounded-md flex gap-2"
          data-bs-toggle="modal"
          data-bs-target="#GenerateCoupon"
        >
          <BiSolidCoupon />
          <span>Generate Coupon Code</span>
        </button>
      </div>
      <div className="grid md:grid-cols-2 gap-3">
        {photographer?.map((item, index) => (
         
        <CouponCard
          key={index}
          item={item}
          handleEdit={(id) => handleEdit(id)}
          handleDelete={(id) => handleDelete(id)} 
          />
        ))}
      </div>

      <GenerateCoupon 
      getAllCoupons={getAllCoupons}
      />
       <EditCoupon
        showModal={showEditCoupon}
        setShowModal={setShowEditCoupon}
        editCoupon={editCoupon}
        // editCodeFunc={editCodeFunc}
        getAllCoupons={getAllCoupons}
      />
    </section>
  );
};

export default GenerateCouponCode;
