import React from "react";

const Modal = ({ isOpen, onClose, deal }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed z-50 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen px-4">
        <div className="fixed inset-0 transition-opacity" onClick={onClose}>
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <div className="modal-dialog modal-dialog-centered  modal-lg w-100">
          <div className="modal-content  px-3 px-lg-4 py-4">
            <div className="top-heading text-center d-flex align-items-center flex-column-reverse flex-sm-row">
              <h2
                className="text-2xl md:text-4xl font-bold text-main"
                style={{ textShadow: "none" }}
              >
                {deal?.heading}
              </h2>
              <button
                type="button"
                className="btn-close modal-lg-close ms-auto text-lg font-bold"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                X
              </button>
            </div>
            <hr />
            <div className="body-text">
              <p className="text py-5">{deal?.intro}</p>
              {/* <div className="terms">
                                    <h4 className="md-heading-2 mb-3">Terms and Conditions:</h4>
                                    <ol>
                                        <li className="text pb-2">The 10% discount offer is applicable to <span className="wight-lg">Ultra </span> member of Boopho only.</li>
                                        <li className="text pb-2">The discount offer is valid for a <span className="wight-lg">limited time </span> only.</li>
                                        <li className="text pb-2">The discount is only applicable to the regular price of the products/services and cannot be combined with any other ongoing promotions, discounts, or offers.</li>
                                        <li className="text pb-2">The discount is applicable to <span className="wight-lg">both new and existing customers </span> of Boopho.</li>
                                        <li className="text pb-2">This discount is non-transferable and cannot be exchanged for cash or any other form of compensation.</li>
                                        <li className="text pb-2">The discount is applicable to the total purchase amount, excluding any taxes, shipping fees, or other additional charges.</li>
                                        <li className="text pb-2">The discount offer is subject to availability and may be withdrawn or modified by Boopho at any time without prior notice.</li>
                                        <li className="text pb-2">Boopho reserves the right to verify the eligibility of customers and decline or cancel the discount if any fraudulent activity is suspected.</li>
                                    </ol>
                                </div> */}
              <p className="d-block w-fit ms-auto mt-3 text wight-lg">
                Click here for{" "}
                <a
                  href="/terms"
                  target="_blank"
                  className="!text-main link-hover"
                >
                  Terms and Conditions
                </a>{" "}
                *
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
