import React from "react";

import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { Studio1, CloseIcon } from "../../Constant/Index";

const ReportStudio = () => {
  return (
    <>
      <main className="site-front-page">
        <Header />
        <section className="booking-details overflow-hidden position-relative">
          <div className="bootstrap-container">
            <div className="details-start">
              {/* Reported Studio Line */}
              <div className="reported-studio-line"></div>
              {/* Reported Studio Line */}
              <div className="col-xxl-10 col-xl-11 c0l-lg-12 mx-auto">
                <div className="studio-image">
                  <figure className="image-main">
                    <img src={Studio1} alt="" className="the-img" />
                  </figure>
                  <div className="studio-event">
                    <div className="box">
                      <h3>Photowale Studio</h3>
                      <span className="d-block">New Delhi</span>
                    </div>

                    <div className="card p-4 bg-transparent border-0">
                      <h3 className="text-start mb-4">Booked for </h3>
                      <dl class="param  d-sm-flex ">
                        <dt className="col-md-4 col-sm-4 text-start">
                          Event Date:{" "}
                        </dt>
                        <dd className="text-start ps-sm-4 ps-md-0">
                          18th Apr 2022 - 22nd Apr 2022
                        </dd>
                      </dl>
                      <dl class="param  d-sm-flex ">
                        <dt className="col-md-4 col-sm-4 text-start">
                          Event Type:{" "}
                        </dt>
                        <dd className="text-start ps-sm-4 ps-md-0"> Wedding</dd>
                      </dl>
                      <dl class="param  d-sm-flex ">
                        <dt className="col-md-4 col-sm-4 text-start">
                          Complete address:{" "}
                        </dt>
                        <dd className="text-start ps-sm-4 ps-md-0">
                          {" "}
                          Outer Ring Rd, 7 Block, Banagirinagara, Banashankari
                          3rd Stage, Banashankari, Bengaluru, Karnataka 560070
                          India
                        </dd>
                      </dl>
                    </div>
                    {/* <div className="about-booking ">
                                        
                                        <div className="buttons-here w-100  d-flex  justify-content-center py-lg-4">
                                            <button className="other-btn px-4">Chat</button>
                                            <button className="cancel ps-2 d-flex align-items-center pe-5"><img src={CloseIcon} style={{maxWidth: "35px"}} alt="" /> Cancel</button>
                                        </div>
                                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </main>
    </>
  );
};

export default ReportStudio;
