import React from "react";
import NoResultImg from "../Assets/Img/no-results.png";
const NoResults = ({ heading }) => {
  return (
    <section>
      {heading && (
        <h1 className="text-xl md:text-3xl text-main font-bold mb-3">
          {heading}
        </h1>
      )}
      <div className="flex flex-col justify-center items-center">
        <img src={NoResultImg} alt="" width={300} />
        <h3 className="text-xl font-bold text-gray-700">
          Sorry! No result found:(
        </h3>
      </div>
    </section>
  );
};

export default NoResults;
