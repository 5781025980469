import React, { useEffect, useState } from "react";

//react-icons
import { ImCross } from "react-icons/im";
import { BiSolidUserCircle, BiDownload } from "react-icons/bi";
import { FaShare } from "react-icons/fa";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";

const OpenImage = ({
  showModal,
  setShowModal,
  share,
  download,
  allComments,
  user = "Guest",
  image,
}) => {
  const closeModal = () => {
    setShowModal(false);
    setLiked(false);
  };

  useEffect(() => {
    if (showModal === true) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "visible";
    }

    return () => {
      document.body.style.overflowY = "visible";
    };
  }, [showModal]);

  const [liked, setLiked] = useState(false);

  return (
    <>
      {showModal && (
        <section className="fixed inset-0 flex w-screen h-screen justify-center items-center z-50">
          <div
            className="bg-black/50 absolute w-full h-full top-0"
            onClick={closeModal}
          ></div>

          <div className="fixed top-0 z-20 flex flex-col p-3 min-w-[80vw] max-w-[80vw] md:min-w-[60vw] md:max-w-[60vw]">
            <div className="relative w-full">
              {/* Cross btn */}
              <button
                className="absolute right-[-6px] top-[-6px] bg-white p-2 rounded-full"
                onClick={closeModal}
              >
                <ImCross className=" text-main text-2xl font-extrabold cursor-pointer" />
              </button>
              {/* Cross btn end */}

              {/* Share and Download Btns Start */}
              <div className="absolute left-[-6px] top-[-6px] flex gap-2 ">
                <button className="bg-white p-2 rounded-full" onClick={share}>
                  <FaShare className=" text-main text-2xl font-extrabold" />
                </button>
                <button
                  className="bg-white p-2 rounded-full"
                  onClick={download}
                >
                  <BiDownload className=" text-main text-2xl font-extrabold " />
                </button>
              </div>
              {/* Share and Download Btns Ends */}

              {/* Like Btn Start */}
              {/* <div className="absolute right-[-6px] bottom-[-6px] flex flex-col items-center justify-center bg-white/50 rounded-full w-[50px] h-[50px] md:w-[80px] md:h-[80px]"> */}
              <button
                className="absolute right-[12px] bottom-[12px]"
                onClick={() => setLiked(!liked)}
              >
                {liked ? (
                  <AiFillHeart className="text-red-600 text-2xl md:text-5xl" />
                ) : (
                  <AiOutlineHeart className="text-red-600 text-2xl md:text-5xl" />
                )}
              </button>
              {/* </div> */}
              {/* <span className="text-main text-xs">12 likes</span> */}
              {/* </button> */}
              {/* Like Btn End */}

              {/* Img start */}
              <div className="w-full md:max-h-[80vh] overflow-auto">
                <img src={image} alt="" className="w-full" />
              </div>
              {/* Img end */}
            </div>
            {/* Comment section start */}
            {/* <div className="flex flex-col">
              <h1 className="font-bold text-white">Add a Comment</h1>
              <div className="bg-white rounded-md w-full flex flex-col gap-2 p-2 max-h-[300px] md:max-h-[150px] overflow-auto"> */}
            {/* all comments start */}

            {/* {allComments?.map((item, index) => {
                  return (
                    <div key={index} className="flex flex-col gap-1">
                      <div className="flex items-center gap-2 text-main">
                        <BiSolidUserCircle className="text-2xl" />
                        <b>{item.user}</b>
                      </div>
                      <p className="w-[90%] mx-auto text-sm text-main">
                        {item.comment}
                      </p>
                    </div>
                  );
                })} */}

            {/* all comments end */}

            {/* add a comment starts */}
            {/* <div className="flex flex-col gap-1">
                  <div className="flex items-center gap-2 text-main">
                    <BiSolidUserCircle className="text-2xl" />
                    <b>{user}</b>
                  </div>
                  <div className="w-[90%] mx-auto">
                    <input
                      type="text"
                      className="rounded-xl text-sm border border-main p-2 w-full outline-none placeholder:text-main"
                      placeholder="Add a comment here..."
                    />
                  </div>
                </div> */}
            {/* add a comment ends */}
            {/* </div>
            </div> */}
            {/* Comment section end */}
          </div>
        </section>
      )}
    </>
  );
};

export default OpenImage;
