import React, { useState, useEffect } from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { toast, ToastContainer } from "react-toastify";
import {
  Button,
  CalendarCell,
  CalendarGrid,
  DateInput,
  DateRangePicker,
  DateSegment,
  Dialog,
  Group,
  Heading,
  Label,
  Popover,
  RangeCalendar,
} from "react-aria-components";
import "react-toastify/dist/ReactToastify.css";
import {
  Studio1,
  Studio2,
  Studio3,
  Studio4,
  Studio5,
  Studio6,
  Studio7,
  CallIcon,
  CommentIcon,
  SpeakerIcon,
  Baricon,
} from "../../Constant/Index";
const StudioDetails1 = () => {
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const [callClick, SetCallClick] = useState(false);
  const [wish, setWish] = useState(false);
  const images = [
    Studio1,
    Studio2,
    Studio3,
    Studio4,
    Studio5,
    Studio6,
    Studio7,
  ];

  const [navSlider, setNavSlider] = useState(null);
  const tabsList = ["Package", "Photos", "Videos", "Reviews", "About"];
  const Packages = [
    {
      question: "Registered with Boopho since",
      answer: "Nov 2022",
    },
    {
      question: "Candid Photography",
      answer: "15000 per day",
    },
    {
      question: "Album",
      answer: "Album",
    },
    {
      question: "Traditional Videography",
      answer: "10000 per day",
    },
    {
      question: "Travel  Cost",
      answer: "Outstation Travel & Stay charges borne by client",
    },
    {
      question: "Budget (Photo Package)",
      answer: "25000 per day",
    },
    {
      question: "Delivery Time",
      answer: "4 Weeks",
    },
    {
      question: "Cinematography",
      answer: "25000 per day",
    },
    {
      question: "Services offered",
      answer: "Maternity Shoots, Fashion Shoots, Pre wedding Films per day",
    },
    {
      question: "Studio Photography",
      answer: "10000 per day",
    },
    {
      question: "Budget (Photo + Video)",
      answer: "35000 per day",
    },
  ];

  const CallClickHandle = () => {
    SetCallClick(!callClick);
  };
  const CustomToast = ({ type, message }) => {
    const icon =
      type === "success" ? (
        <span style={{ color: "green" }}>
          {/* Use your "check" icon here */}
          &#10003;
        </span>
      ) : (
        <span style={{ color: "red" }}>
          {/* Use your "close" icon here */}
          &#10005;
        </span>
      );

    return (
      <div>
        {icon} {message}
      </div>
    );
  };

  const showToast = (type, message) => {
    toast.success(<CustomToast type={type} message={message} />, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      bodyClassName: type === "success" ? "success-toast" : "error-toast",
    });
  };
  const toggleWishItem = () => {
    let CurrentSTate = !wish;
    setWish(CurrentSTate);
    wish
      ? toast.error("Removed From WIshlist!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000, // Auto close the toast after 3 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        })
      : toast.success("Added to wishlist!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000, // Auto close the toast after 3 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
    // };
  };
  return (
    <>
      <main className="site-front-page">
        <Header />
        <section className="studio-details">
          <div className="bootstrap-container">
            <div className="detials-start">
              <div className="main-details">
                <div className="row">
                  <div className="col-xxl-5 col-xl-5 col-lg-6 col-md-6 order-1 order-md-0">
                    <div className="detail-image">
                      <Slider
                        asNavFor={nav2}
                        ref={(slider1) => setNav1(slider1)}
                      >
                        {images.map((image, index) => (
                          <div
                            key={index}
                            className="large-image px-lg-3 wow animate__animated animate__fadeInLeft animate__delay-1s"
                          >
                            <img
                              src={image}
                              alt={`Image ${index + 1}`}
                              className="w-100"
                            />
                          </div>
                        ))}
                      </Slider>
                      <div className="slider-nav-here  px-lg-3">
                        <Slider
                          asNavFor={nav1}
                          ref={(slider2) => setNav2(slider2)}
                          slidesToShow={3}
                          swipeToSlide={true}
                          focusOnSelect={true}
                          nextArrow={false}
                          prevArrow={false}
                          arrows={false}
                        >
                          {images.map((image, index) => (
                            <div
                              key={index}
                              className="slider-nav-here wow animate__animated animate__fadeInLeft animate__delay-1s"
                            >
                              <img
                                src={image}
                                alt={`Image ${index + 1}`}
                                className="nav-image w-100"
                              />
                            </div>
                          ))}
                        </Slider>
                        <button
                          className="show-more-pics wow animate__animated animate__fadeInRight animate__delay-1s"
                          data-bs-toggle="modal"
                          data-bs-target="#MorePicsModal"
                        >
                          +115
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-4 col-xl-5 col-lg-6 col-md-6">
                    <div className="main-details">
                      <div className="studio-reviews-data">
                        <div className="share-main">
                          <h2 className="!text-main">Photowale Studio</h2>
                          <div className="other-option">
                            <button className="social-option">
                              <i class="fa fa-share-alt" aria-hidden="true"></i>
                            </button>
                            <button
                              className="social-option"
                              onClick={toggleWishItem}
                            >
                              <i
                                class={`fa fa-heart${
                                  wish ? "" : "-o"
                                } !text-red-500`}
                                aria-hidden="true"
                              ></i>
                            </button>
                          </div>
                        </div>
                        <p className="place-name flex items-center gap-1 my-2">
                          <img src={Baricon} width={"25px"} alt="" />
                          <span className="!text-main text-sm">New Delhi</span>
                        </p>
                        <div className="reviews mt-2">
                          <p className="text total-reviews">
                            <span className="text-sm !text-main">
                              132 Reviews
                            </span>
                            <div class="rating">
                              <i
                                class="fa fa-star"
                                style={{ color: "rgb(34, 197, 94)" }}
                              ></i>
                              <span className="mx-1 font-extrabold !text-green-500">
                                4.7
                              </span>
                            </div>
                            <button
                              className="add-button flex items-center"
                              data-bs-toggle="modal"
                              data-bs-target="#ReviewForm"
                            >
                              <img src={CommentIcon} alt="" />{" "}
                              <span className="text-sm">Write a review</span>
                            </button>
                          </p>
                        </div>
                        <div className="price-estimate">
                          <h5 className="price">Price per day estimate</h5>
                          <div className="packages">
                            <div className="single-package">
                              <p className="name-price">
                                ₹ 25,000 <span>per day</span>
                              </p>
                              <p className="package mb-0">Photo only</p>
                            </div>
                            <div className="single-package">
                              <p className="name-price">
                                ₹ 40,000 <span>per day</span>
                              </p>
                              <p className="package mb-0">Photo and Video</p>
                            </div>
                          </div>
                        </div>
                        <Link
                          to=""
                          className="promote-here !flex gap-1 items-center"
                        >
                          <img src={SpeakerIcon} alt="" />{" "}
                          <span className="text-sm !text-main">
                            Report inaccurate info
                          </span>
                        </Link>

                        <div className="form-group py-4">
                          <div className="row">
                            <div className="col-md-4 py-0">
                              <label htmlFor="">
                                <strong>Wedding Date:</strong>
                              </label>
                            </div>
                            <div className="col-md-8">
                              <DateRangePicker className="form-control input-date-range d-flex w-100">
                                <Label>Trip dates</Label>
                                <Group>
                                  <DateInput slot="start">
                                    {(segment) => (
                                      <DateSegment segment={segment} />
                                    )}
                                  </DateInput>
                                  <span aria-hidden="true">–</span>
                                  <DateInput slot="end">
                                    {(segment) => (
                                      <DateSegment segment={segment} />
                                    )}
                                  </DateInput>
                                  <Button>▼</Button>
                                </Group>
                                <Popover>
                                  <Dialog>
                                    <RangeCalendar>
                                      <header>
                                        <Button slot="previous">◀</Button>
                                        <Heading />
                                        <Button slot="next">▶</Button>
                                      </header>
                                      <CalendarGrid>
                                        {(date) => <CalendarCell date={date} />}
                                      </CalendarGrid>
                                    </RangeCalendar>
                                  </Dialog>
                                </Popover>
                              </DateRangePicker>
                            </div>
                          </div>
                        </div>
                        <button
                          className={`site-btn ${callClick ? "active" : ""}`}
                          onClick={CallClickHandle}
                          data-bs-toggle="modal"
                          data-bs-target="#MessageModal"
                        >
                          <img src={CallIcon} alt="" />{" "}
                          {callClick ? "Cancel request" : "Request a call back"}{" "}
                        </button>
                        <span className="call-option">
                          no payment required at this time*
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="studio-tabs">
          <div className="bootstrap-container">
            <div className="studio-tabs-start">
              <ul className="tabs-list">
                {tabsList.map((singleTab, index) => (
                  <li className={`single-tab ${index == 0 ? "active" : ""}`}>
                    <button className="tab-btn">{singleTab}</button>
                  </li>
                ))}
              </ul>
              {/* The Modal Place */}

              {/* The Modal Place end Here */}
              <div className="tabs-data ms-lg-5">
                {/* <h2>Customisable additional services*</h2> */}
                <div className="single-data">
                  <div className="package-list col-lg-10 mt-5 pb-5">
                    <div className="row">
                      {Packages.map((Package, index) => (
                        <div className="col-md-6 mb-4 pe-md-5 wow animate__animated animate__fadeInDown animate__delay-1s">
                          <div className="single-package">
                            <span className="question">{Package.question}</span>
                            <Link className="answer">{Package.answer}</Link>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="more-pics-modal">
          {/* <!-- Modal --> */}
          <div
            class="modal fade"
            id="MorePicsModal"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabindex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-lg">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="staticBackdropLabel">
                    All Photos
                  </h5>
                  <button
                    type="button"
                    class="btn-close text-lg font-bold"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    X
                  </button>
                </div>
                <div class="modal-body">
                  <div className="detail-image">
                    <Slider asNavFor={nav2} ref={(slider1) => setNav1(slider1)}>
                      {images.map((image, index) => (
                        <div key={index} className="large-image px-lg-3">
                          <img
                            src={image}
                            alt={`Image ${index + 1}`}
                            className="w-100"
                          />
                        </div>
                      ))}
                    </Slider>
                    <div className="slider-nav-here  px-lg-3">
                      <Slider
                        asNavFor={nav1}
                        ref={(slider2) => setNav2(slider2)}
                        slidesToShow={4}
                        swipeToSlide={true}
                        focusOnSelect={true}
                        nextArrow={false}
                        prevArrow={false}
                        arrows={false}
                      >
                        {images.map((image, index) => (
                          <div key={index} className="slider-nav-here px-2">
                            <img
                              src={image}
                              alt={`Image ${index + 1}`}
                              className="nav-image w-100"
                            />
                          </div>
                        ))}
                      </Slider>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  {/* <button type="button" class="btn btn-primary">Understood</button> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="write-review">
          <div
            class="modal fade"
            id="ReviewForm"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabindex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="staticBackdropLabel">
                    Write a Review
                  </h5>
                  <button
                    type="button"
                    class="btn-close text-lg font-bold"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    X
                  </button>
                </div>
                <div class="modal-body">
                  <form>
                    <div class="mb-3 form-group">
                      <label htmlFor="">Rating *</label>

                      <div className="col-md-12">
                        <div class="input-group star-rating">
                          <div class="rate">
                            <input
                              type="radio"
                              id="star5"
                              name="rate"
                              value="5"
                            />
                            <label for="star5" title="text">
                              5 stars
                            </label>
                            <input
                              type="radio"
                              id="star4"
                              name="rate"
                              value="4"
                            />
                            <label for="star4" title="text">
                              4 stars
                            </label>
                            <input
                              type="radio"
                              id="star3"
                              name="rate"
                              value="3"
                            />
                            <label for="star3" title="text">
                              3 stars
                            </label>
                            <input
                              type="radio"
                              id="star2"
                              name="rate"
                              value="2"
                            />
                            <label for="star2" title="text">
                              2 stars
                            </label>
                            <input
                              type="radio"
                              id="star1"
                              name="rate"
                              value="1"
                            />
                            <label for="star1" title="text">
                              1 star
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mb-3 form-group">
                      <label htmlFor="">Review *</label>

                      <div className="col-md-12 mt-2">
                        <div class="input-group mb-3">
                          <textarea
                            type="text"
                            class="form-control resize-none"
                            placeholder="Enter Your Review"
                            aria-label="Name"
                            rows={"5"}
                          ></textarea>
                        </div>
                      </div>
                      <button
                        type="submit"
                        class="btn btn-primary d-block mx-auto site-btn px-5"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="write-review">
          <div
            class="modal fade"
            id="MessageModal"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabindex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="staticBackdropLabel">
                    Message
                  </h5>
                  <button
                    type="button"
                    class="btn-close text-lg font-bold"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    X
                  </button>
                </div>
                <div class="modal-body">
                  <div className="text-center">
                    <p className="px-5">
                      Request sent. You will receive a call in 48 hours. Thank
                      you!{" "}
                    </p>
                    <a
                      href="/booking"
                      class="btn btn-primary mx-auto site-btn px-5"
                    >
                      Ok
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ToastContainer />
        <Footer />
      </main>
    </>
  );
};
export default StudioDetails1;
