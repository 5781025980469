import React, { useEffect, useState } from "react";
import ModalLayout from "../ModalLayout";
import Select from "react-select";
import CreateFolder from "./CreateFolder";

const AddToFolder = ({
  showModal,
  setShowModal,
  handleAddToFolder,
  options=[],
  handleCreateFolder,
  handleAddImagesInFolder,
}) => {
  const [folderName, setFolderName] = useState("");
  const [givenOptions, setGivenOptions] = useState([]);
  const [showCreateFolder, setShowCreateFolder] = useState(false);

  let allOptions = options?.map((item) => {
    return {
      value: item
        .split(" ")
        .join("")
        .toLowerCase(),
      label: item,
    };
  });

  console.log(folderName, "===folderNamefolderName");
  // console.log(options, "====options:AddToFolder");
  // useEffect(() => {
  //   const generateOptions = () => {
  //     let allOptions = options.map((item) => {
  //       return {
  //         value: item
  //           .split(" ")
  //           .join("")
  //           .toLowerCase(),
  //         label: item,
  //       };
  //     });

  //     setGivenOptions(allOptions);
  //   };

  //   generateOptions();
  // }, [options]);

  const createFolder = () => {
    setShowModal(false);
    setShowCreateFolder(true);
  };

  const handleCreate = (folderName) => {
    handleCreateFolder(folderName);
    setShowCreateFolder(false);
  };

  return (
    <>
      <ModalLayout
        showModal={showModal}
        setShowModal={setShowModal}
        heading="Add To Folder"
      >
        <div className="flex flex-col gap-3">
          <div className="flex flex-col items-center gap-1">
            <label htmlFor="folderName" className="text-center">
              Folder Name:
            </label>
            <Select
              className="basic-single w-full"
              classNamePrefix="select"
              defaultValue={options[0]}
              value={folderName}
              onChange={(value) => setFolderName(value)}
              isSearchable={true}
              name="folderName"
              options={allOptions}
            />
          </div>
          <div className="w-full flex gap-3 justify-center">
            <button
              className="bg-main rounded-md px-3 py-1 text-white"
              onClick={() => { handleAddToFolder(folderName.label); handleAddImagesInFolder(folderName.label) }}
            >
              Add To Folder
            </button>
            <button
              className="bg-main rounded-md px-3 py-1 text-white"
              onClick={createFolder}
            >
              + Create Folder
            </button>
          </div>
        </div>
      </ModalLayout>
      <CreateFolder
        showModal={showCreateFolder}
        setShowModal={setShowCreateFolder}
        handleCreateFolder={handleCreate}
      />
    </>
  );
};

export default AddToFolder;
