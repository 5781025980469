import React from "react";

const BookingInfo = () => {
  return (
    <div className="login-modal">
      <div
        className="modal fade"
        id="bookingInfo"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-md modal-dialog-centered ">
          <div className="modal-content p-3">
            <div className="modal-header border-0">
              <button
                type="button"
                className="btn-close text-lg font-bold"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                X
              </button>
            </div>
            <h3 className="text-center font-bold">Booking Info</h3>

            <div className="modal-body">
              <div className="w-full flex flex-col gap-3">
                <div className="grid grid-cols-3 w-full gap-1">
                  <p className="col-span-1 font-bold">Wedding Date:</p>
                  <p className="col-span-2 text-sm">
                    18th Apr 2022 - 22nd Apr 2022
                  </p>
                </div>

                <div className="grid grid-cols-3 w-full gap-1">
                  <p className="col-span-1 font-bold">Complete address:</p>
                  <p className="col-span-2 text-sm">
                    Outer Ring Rd, 7 Block, Banagirinagara, Banashankari 3rd
                    Stage, Banashankari, Bengaluru, Karnataka 560070 India
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingInfo;
