import React, {useState, useEffect} from "react";
import axios from "axios";

const EditCoupon = ({editCoupon, getAllCoupons}) => {
  const [dateStarts, setDateStarts] = useState("");
  const [dateEnds, setDateEnds] = useState("");
  const [categorySelected, setCategorySelected] = useState("");
  const [amount, setAmount] = useState("");
  const token = localStorage.getItem("authToken");
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');


  const formatDate = (date) => {
    const formattedDate = new Date(date).toISOString().split("T")[0];
    return formattedDate;
  };

  const applyDiscount = async() => {
    console.log(dateStarts, dateEnds, categorySelected);
    try {
      const apiUrl = `${baseUrl}/photographer-coupon-code/update/${editCoupon.id}`;
      const data = {
        discount: amount,
        startDate: dateStarts,
        endDate: dateEnds,
        categoryId: categorySelected,
        photographerId: editCoupon.photographerId
      };

      const response = await axios.put(apiUrl, data, {
        headers: {
          "x-auth-token": token,
        },
      });
  
      if (response.status === 200) {
        const responseData = response.data.data;
         console.log(responseData);
        setSuccessMessage(response.data.message);
        getAllCoupons();
      }
    } catch (error) {
      console.error("Error while fetching data:", error);
      setErrorMessage(error.response.data.error.message);
    } 
  }
  useEffect(() => {
    if (editCoupon) {
      setAmount(editCoupon.discount || "");
      setCategorySelected(editCoupon.categoryId || "");
      setDateStarts(formatDate(editCoupon.startDate) || "");
      setDateEnds(formatDate(editCoupon.endDate) || "");
    }
  }, [editCoupon]);
  return (
    <section className="coupon-form">
      <div
        className="modal fade"
        id="EditCoupon"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header bg-main">
              <h5 className="modal-title text-white" id="staticBackdropLabel">
                Message
              </h5>
              <button
                type="button"
                className="btn-close text-lg font-bold text-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                X
              </button>
            </div>
            {errorMessage && <p style={{color:"red"}}>{errorMessage}</p>}
            {successMessage && <p style={{color:"green"}}>{successMessage}</p>}
            <div className="modal-body">
              <form>
                <div className="form-group row mb-3">
                  <div className="col-lg-5">
                    <label htmlFor="discount">Enter the Discount (%)</label>
                  </div>
                  <div className="col-lg-7">
                    <input
                      id="discount"
                      type="number"
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                      className="p-2 border border-gray-300 outline-none w-full rounded-md"
                      placeholder="In Percent %"
                    />
                  </div>
                </div>
                <div className="form-group row mb-3">
                  <div className="col-lg-5">
                    <label htmlFor="">Date range</label>
                  </div>
                  <div className="col-lg-7">
                    <div className="w-full p-2 rounded-md flex gap-2 outline-none border border-gray-300">
                      {/* <Calendar
                  value={dates}
                  onChange={(e) => setDates(e.value)}
                  selectionMode="range"
                  readOnlyInput
                /> */}
                      <input
                        type="date"
                        className="outline-none"
                        id="startDate"
                        value={dateStarts}
                        onChange={(e) => setDateStarts(e.target.value)}
                      />
                      <input
                        type="date"
                        className="outline-none"
                        id="endDate"
                        value={dateEnds}
                        onChange={(e) => setDateEnds(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group row mb-3">
                  <div className="col-lg-5">
                    <label htmlFor="">Event Type</label>
                  </div>
                  <div className="col-lg-7">
                    <select
                      name=""
                      id=""
                      value={categorySelected}
                      onChange={(e) => setCategorySelected(e.target.value)}
                      className="form-control form-select rounded-md"
                    >
                      <option value="17">Wedding</option>
                      <option value="24">Birthday</option>
                      <option value="25">Business</option>
                      <option value="22">New Born</option>
                      <option value="18">Maternity</option>
                    </select>
                  </div>
                </div>
                <div className="text-center mt-5 mb-3">
                <button
                    className="btn site-btn w-fit mx-auto d-block"
                    type="button"
                    onClick={applyDiscount}
                  >
                    Apply Discount
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EditCoupon;
