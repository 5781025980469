// FileExplorer.js
import React, { useState } from 'react';
// import './FileExplorer.css';

function FileExplorer({ data }) {
  const [openFolders, setOpenFolders] = useState([]);

  const toggleFolder = (folderId) => {
    const folderHasChildren = data.some((item) => item.parentId === folderId && item.type === 'folder');

    if (folderHasChildren) {
      if (openFolders.includes(folderId)) {
        setOpenFolders(openFolders.filter((id) => id !== folderId));
      } else {
        setOpenFolders([...openFolders, folderId]);
      }
    }
  };

  const renderFolder = (folder) => (
    <div key={folder.id} className="folder" onClick={() => toggleFolder(folder.id)}>
      {openFolders.includes(folder.id) ? '📂 ' : '📁 '}
      {folder.name}
      {openFolders.includes(folder.id) && (
        <div style={{ marginLeft: '20px' }}>
          {data
            .filter((item) => item.parentId === folder.id)
            .map((item) =>
              item.type === 'folder' ? renderFolder(item) : renderFile(item)
            )}
        </div>
      )}
    </div>
  );

  const renderFile = (file) => (
    <div key={file.id} style={{ marginLeft: '20px' }}>
      📄 {file.name}
    </div>
  );

  return (
    <div className="FileExplorer">
      {data
        .filter((item) => item.parentId === null) // Assuming the root folder has a parentId of null
        .map((item) => (item.type === 'folder' ? renderFolder(item) : renderFile(item)))}
    </div>
  );
}

export default FileExplorer;
