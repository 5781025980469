import React, { useState, useEffect } from "react";

import Banner from "../../Components/banner";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import {
  About1Img,
  About2Img,
  About3Img,
  Candid1Img,
  Candid2Img,
  Candid3Img,
  Candid4Img,
  Candid5Img,
  Candid6Img,
  Candid7Img,
  Candid8Img,
  Candid9Img,
  Traditional1Img,
  Traditional2Img,
  Traditional3Img,
  Traditional4Img,
  Traditional5Img,
  Traditional6Img,
  Traditional7Img,
  Traditional8Img,
  Traditional9Img,
} from "../../Constant/Index";

const Gallery = () => {
  const [currentType, SetCurrentType] = useState(0);
  const [typeSelected, SetTypeSelected] = useState(false);
  const [loading, setLoading] = useState(true);

  const GalleryImage = [
    {
      TypeId: 0,
      ImgSrc: About1Img,
    },
    {
      TypeId: 0,
      ImgSrc: About3Img,
    },
    {
      TypeId: 0,
      ImgSrc: About2Img,
    },
    {
      TypeId: 0,
      ImgSrc: About2Img,
    },
    {
      TypeId: 0,
      ImgSrc: About1Img,
    },
    {
      TypeId: 0,
      ImgSrc: About3Img,
    },
    {
      TypeId: 0,
      ImgSrc: About2Img,
    },
    {
      TypeId: 0,
      ImgSrc: About1Img,
    },
    {
      TypeId: 0,
      ImgSrc: About3Img,
    },
    {
      TypeId: 0,
      ImgSrc: About1Img,
    },
    {
      TypeId: 0,
      ImgSrc: About3Img,
    },
    {
      TypeId: 0,
      ImgSrc: About2Img,
    },
    {
      TypeId: 1,
      ImgSrc: Candid1Img,
    },
    {
      TypeId: 1,
      ImgSrc: Candid2Img,
    },
    {
      TypeId: 1,
      ImgSrc: Candid3Img,
    },
    {
      TypeId: 1,
      ImgSrc: Candid4Img,
    },
    {
      TypeId: 1,
      ImgSrc: Candid5Img,
    },
    {
      TypeId: 1,
      ImgSrc: Candid6Img,
    },
    {
      TypeId: 1,
      ImgSrc: Candid7Img,
    },
    {
      TypeId: 1,
      ImgSrc: Candid8Img,
    },
    {
      TypeId: 1,
      ImgSrc: Candid9Img,
    },
    {
      TypeId: 2,
      ImgSrc: Traditional1Img,
    },
    {
      TypeId: 2,
      ImgSrc: Traditional2Img,
    },
    {
      TypeId: 2,
      ImgSrc: Traditional3Img,
    },
    {
      TypeId: 2,
      ImgSrc: Traditional4Img,
    },
    {
      TypeId: 2,
      ImgSrc: Traditional5Img,
    },
    {
      TypeId: 2,
      ImgSrc: Traditional6Img,
    },
    {
      TypeId: 2,
      ImgSrc: Traditional7Img,
    },
    {
      TypeId: 2,
      ImgSrc: Traditional8Img,
    },
    {
      TypeId: 2,
      ImgSrc: Traditional9Img,
    },
    {
      TypeId: 3,
      ImgSrc: Candid1Img,
    },
    {
      TypeId: 3,
      ImgSrc: Candid2Img,
    },
    {
      TypeId: 3,
      ImgSrc: Candid3Img,
    },
    {
      TypeId: 3,
      ImgSrc: Candid4Img,
    },
    {
      TypeId: 3,
      ImgSrc: Candid5Img,
    },
    {
      TypeId: 3,
      ImgSrc: Candid6Img,
    },
    {
      TypeId: 3,
      ImgSrc: Candid7Img,
    },
    {
      TypeId: 3,
      ImgSrc: Candid8Img,
    },
    {
      TypeId: 3,
      ImgSrc: Candid9Img,
    },
    {
      TypeId: 4,
      ImgSrc: Traditional1Img,
    },
    {
      TypeId: 4,
      ImgSrc: Traditional2Img,
    },
    {
      TypeId: 4,
      ImgSrc: Traditional3Img,
    },
    {
      TypeId: 4,
      ImgSrc: Traditional4Img,
    },
    {
      TypeId: 4,
      ImgSrc: Traditional5Img,
    },
    {
      TypeId: 4,
      ImgSrc: Traditional6Img,
    },
    {
      TypeId: 4,
      ImgSrc: Traditional7Img,
    },
    {
      TypeId: 4,
      ImgSrc: Traditional8Img,
    },
    {
      TypeId: 4,
      ImgSrc: Traditional9Img,
    },
  ];
  const ImageType = [
    {
      Name: "All Images",
      Id: 0,
    },
    {
      Name: "Candid Photography",
      Id: 1,
    },
    {
      Name: "Traditional Videography",
      Id: 2,
    },
    {
      Name: "Cinematography",
      Id: 3,
    },
    {
      Name: "Studio Photography",
      Id: 4,
    },
  ];
  useEffect(() => {
    // Simulate loading delay
    setTimeout(() => {
      setLoading(false);
    }, 1000); // Adjust the timeout as needed
  }, [currentType]);
  const ChangeType = (id) => {
    SetCurrentType(id);
    SetTypeSelected(true);
    setLoading(true); // Show loader when type is changed
  };
  return (
    <>
      <Header />
      <Banner home="Home" currenturl="/gallery" current="Gallery" />
      <section class="faqs_section gallery-section">
        <div class="bootstrap-container">
          <div className="row">
            <div className="col-md-3">
              <nav
                id="sidebarMenu"
                class="d-lg-block sidebar collapse bg-white"
                style={{ visibility: "visible" }}
              >
                <div class="position-sticky">
                  <div class="list-group list-group-flush mx-3 mt-4 pb-4">
                    {ImageType.map((sinlgetype, index) => (
                      <button
                        className={`list-group-item  py-2 ripple ${
                          sinlgetype.Id == currentType
                            ? "site-btn text-start"
                            : "list-group-item-action"
                        }`}
                        onClick={() => ChangeType(sinlgetype.Id)}
                      >
                        <span>{sinlgetype.Name}</span>
                      </button>
                    ))}
                  </div>
                </div>
              </nav>
              {/* <!-- Sidebar --> */}
            </div>

            <div className="col-lg-9 mx-auto">
              <div className="row">
                {GalleryImage.filter(
                  (Current) => Current.TypeId === currentType
                ).map((filteredItem, index) => (
                  <div
                    className="col-lg-4 col-md-4 col-sm-6 col-6 mb-4"
                    key={index}
                  >
                    <figure>
                      <img className="w-100" src={filteredItem.ImgSrc} />
                    </figure>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};
export default Gallery;
