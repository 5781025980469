import React from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";

const BookingInfo = () => {
  return (
    <>
      <Header />
      <section className="px-5 py-3 min-h-[65vh]">
        <div className="w-full flex flex-col gap-3">
          <div className="grid grid-cols-3 w-full gap-1">
            <p className="col-span-1 font-bold">Event Date:</p>
            <p className="col-span-1">18th Apr 2022 - 22nd Apr 2022</p>
          </div>
          <div className="grid grid-cols-3 w-full gap-1">
            <p className="col-span-1 font-bold">Event Type:</p>
            <p className="col-span-1">Wedding</p>
          </div>
          <div className="grid grid-cols-3 w-full gap-1">
            <p className="col-span-1 font-bold">Complete address:</p>
            <p className="col-span-1">
              Outer Ring Rd, 7 Block, Banagirinagara, Banashankari 3rd Stage,
              Banashankari, Bengaluru, Karnataka 560070 India
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default BookingInfo;
