import React from "react";

const Pagination = (props) => {
  console.log(props, "proopsss")
  const pageNumbers = [];

  for (let i = 1; i <= props.total; i++) {
    pageNumbers?.push(i);
  }

  return (
    <nav aria-label="..." className="w-full flex justify-center my-4">
      <div className="flex text-sm">
        {props?.current > 1 && (
          <button
            className="py-[6px] px-3 border border-gray-200 text-secondary"
            onClick={props?.onPrevPage}
          >
            Previous
          </button>
        )}

        {pageNumbers?.map((page) => (
          <button
            key={page}
            className={`${
              props.current === page
                ? " bg-secondary text-white border-secondary"
                : "text-secondary bg-transparent border-[#dee2e6]"
            } py-[6px] px-3 border `}
            onClick={() => props?.onPageChange(page)}
          >
            {page}
          </button>
        ))}

        {props?.current < props?.total && (
          <button
            className="py-[6px] px-3 border border-[#dee2e6] text-secondary"
            onClick={props?.onNextPage}
          >
            Next
          </button>
        )}
      </div>
    </nav>
  );
};

export default Pagination;
