import React, { useState, useEffect } from "react";
import Header from "../../Components/Header";
import { Link, useParams } from "react-router-dom";
import Footer from "../../Components/Footer";
import PhotographyModals from "./PhotographyModals";
import TheDate from "../../Components/TheDate";
import StudioCompnent from "../StudioComponent/StudioComponent";
import Pagination from "../../Components/Pagination";
import { toast, ToastContainer } from "react-toastify";
import SortFilter from "../../Components/SortFilter";
import axios from "axios";

import {
  Wedding1,
  Wedding2,
  Wedding3,
  Studio1,
  Studio2,
  Studio3,
  Studio4,
  Studio5,
  Studio6,
  Baricon,
} from "../../Constant/Index";

let studios = [
  {
    studioname: "Photo Wale Studio",
    location: "Delhi",
    price: "16,000",
    rating: 4.7,
    img: Studio1,
    Discount: "25%",
    availability: true,
  },
  {
    studioname: "Lorem Studio",
    location: "Mumbai",
    price: "18,000",
    rating: 4.2,
    img: Studio2,
    Discount: "17%",
    availability: true,
  },
  {
    studioname: "Ipsum Sutdio",
    location: "Chennai",
    price: "19,000",
    rating: 4.3,
    img: Studio3,
    Discount: "32%",
    availability: false,
  },
  {
    studioname: "Dolor Studio",
    location: "Punjab",
    price: "22,000",
    rating: 4.4,
    img: Studio4,
    Discount: "05%",
    availability: true,
  },
  {
    studioname: "Mega Studio",
    location: "Punjab",
    price: "23,000",
    rating: 4.5,
    img: Studio5,
    Discount: "40%",
    availability: true,
  },
  {
    studioname: "Alpha Studio",
    location: "Delhi",
    price: "27,000",
    rating: 4.6,
    img: Studio6,
    Discount: "04%",
    availability: true,
  },
];

const WeddingPhotography = () => {
  const [wish, setWish] = useState([]);
  const [selectedFilter, SetSelectedFilter] = useState(0);
  const [showFilter, SetShowFilter] = useState(false);
  const [selectedSortFilter, setSelectedSortFilter] = useState("Featured");
  const [priceFilter, setPriceFilter] = useState("");
  const [ratingFilter, setRatingFilter] = useState("");
  const [photoFilter, setPhotoFilter] = useState("");
  const [videoFilter, setVideoFilter] = useState("");
  const [discountFilter, setDiscountFilter] = useState("");
  const [locationFilter, setLocationFilter] = useState("");
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const params = useParams();

  const photoGrapherSearch = () => {
    // let fromPrice ="";
    // let toPrice="";
    // if(priceFilter) {
    //   const priceRangeArray = priceFilter.split('-');
    //   fromPrice = priceRangeArray[0].trim();
    //   toPrice = priceRangeArray[1].trim();

    // }

    // console.log(fromPrice, toPrice);
    // const params = {
    //   priceFrom: fromPrice,
    //   priceTo: toPrice
    // };

    axios
      .get(`${baseUrl}/photographer/search`)
      .then((response) => {
        console.log(response.data); // Check the response data
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  };

  useEffect(() => {
    photoGrapherSearch();
  }, [
    priceFilter,
    locationFilter,
    photoFilter,
    videoFilter,
    discountFilter,
    ratingFilter,
  ]);

  const handleFilterChange = (newValue) => {
    SetSelectedFilter(newValue);
  };

  const toggleWishItem = (index) => {
    setWish((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = !newStates[index];
      newStates[index]
        ? toast.success("Added to wishlist!", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000, // Auto close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          })
        : toast.error("Removed From WIshlist!", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000, // Auto close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
      return newStates;
    });
    // const showToast = () => {

    // };
  };

  const toggleFilters = () => {
    SetShowFilter(!showFilter);
  };

  const handlePriceFilter = (value) => {
    console.log(value);
    setPriceFilter(value);
  };

  const handleRatingFilter = (value) => {
    console.log(value);
    setRatingFilter(value);
  };

  const handlePhotoFilters = (value) => {
    console.log(value);
    setPhotoFilter(value);
  };

  const handleVideoFilters = (value) => {
    console.log(value);
    setVideoFilter(value);
  };

  const handleDiscountFilter = (value) => {
    console.log(value);
    setDiscountFilter(value);
  };

  const handleLocationFilter = (value) => {
    console.log(value);
    setLocationFilter(value);
  };

  return (
    <>
      <Header />
      <section className="photography-banner">
        <div className="top-heading">
          <h1 className="xl-heading mb-4 !text-main ">Wedding Photography</h1>
        </div>
        <div className="row mx-0">
          <div className="col-md-3 px-0">
            <Link to="/photography/Maternity" className="page-link">
              Maternity
            </Link>
          </div>
          <div className="col-md-3 px-0">
            <Link to="/photography/Baby" className="page-link">
              New Born Baby
            </Link>
          </div>
          <div className="col-md-3 px-0">
            <Link to="/photography/Birthday" className="page-link">
              Birthday
            </Link>
          </div>
          <div className="col-md-3 px-0">
            <Link to="/photography/Business" className="page-link">
              Business
            </Link>
          </div>
        </div>
        <div className="banner-photo-page">
          <div className="bootstrap-container">
            <TheDate />
          </div>
        </div>
      </section>
      <section className="exclusive-deails">
        <div className="bootstrap-container">
          <div className="deals-start">
            <h2 className="lg-heading mb-5 !text-main">Exclusive Deals</h2>
            <div className="deals-list">
              <div className="row">
                <div className="col-lg-4 col-md-6 my-3">
                  <div className="single-deal">
                    <button
                      className="d-block w-100 simple-btn"
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#DiscountModal"
                    >
                      <figure className="deals-img">
                        <img
                          src={Wedding1}
                          alt=""
                          className="the-image img-fluid w-100"
                        />
                        <span>10% Discount*</span>
                      </figure>
                    </button>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 my-3">
                  <div className="single-deal">
                    <button
                      className="d-block w-100 simple-btn"
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#PlansModal"
                    >
                      <figure className="deals-img">
                        <img
                          src={Wedding2}
                          alt=""
                          className="the-image img-fluid w-100"
                        />
                        <span>Membership Plans*</span>
                      </figure>
                    </button>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 my-3">
                  <div className="single-deal">
                    <button
                      className="d-block w-100 simple-btn"
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#LifeTImeModal"
                    >
                      <figure className="deals-img">
                        <img
                          src={Wedding3}
                          alt=""
                          className="the-image img-fluid w-100"
                        />
                        <span>Lifetime Accessibility*</span>
                      </figure>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* <p className="text-center wight-lg">
              Join Us and become ultimate lifetime member of Boopho Pro*
            </p> */}
          </div>
        </div>
      </section>

      <section className="filters-section">
        <div className="bootstrap-container">
          <div className="filters-start">
            <h2 className="top-heading lg-heading text-center mb-5 wow animate__animated animate__rubberBand animate__delay-1s">
              Premium wedding photographers in Your location
            </h2>
          </div>
          <TheDate />
          <div className="filter-list-2 mt-4 flex-column">
            <div className="normal-filter wow animate__animated animate__fadeInLeft animate__delay-1s me-auto">
              {/* <select name="" id="" className='form-select form-control'>
                                <option value="">Price</option>
                                <option value="100">100</option>
                                <option value="1000">1000</option>
                            </select>
                            <select name="" id="" className='form-select form-control'>
                                <option value="">Rating</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                            </select>
                            <select name="" id="" className='form-select form-control'>
                                <option value="">Services</option>
                                <option value="Wedding">Wedding</option>
                                <option value="Maternity">Maternity</option>
                                <option value="Baby">Baby</option>
                                <option value="Birthday">Birthday</option>
                                <option value="Business">Business</option>
                            </select>
                            <select name="" id="" className='form-select form-control'>
                                <option value="">Discount</option>
                                <option value="10">10%</option>
                                <option value="20">20%</option>
                                <option value="30">30%</option>
                                <option value="40">40%</option>
                                <option value="50">50%</option>
                            </select> */}
              <button className="clear-filter btn btn-warning me-auto px-0">
                Clear All Filters
              </button>
            </div>
            <div className="w-full normal-filter wow animate__animated animate__fadeInLeft animate__delay-1s position-relative me-auto">
              <div className="w-full flex justify-between">
                <button
                  className="filters-here clear-filter"
                  onClick={toggleFilters}
                >
                  Filters <i className="fa fa-angle-down ps-3"></i>
                </button>
                <SortFilter
                  selectedSortFilter={selectedSortFilter}
                  setSelectedSortFilter={setSelectedSortFilter}
                />
              </div>
              {showFilter && (
                <>
                  <div class="d-flex flex-column flex-shrink-0 p-3 bg-light the-first-filters">
                    <ul class="nav nav-pills flex-column mb-auto">
                      <li class="nav-item">
                        <button
                          className={`inner-btn ${
                            selectedFilter === 0 ? "active" : ""
                          }`}
                          onClick={() => handleFilterChange(0)}
                        >
                          Price per day
                        </button>
                      </li>
                      <li>
                        <button
                          className={`inner-btn ${
                            selectedFilter === 1 ? "active" : ""
                          }`}
                          onClick={() => handleFilterChange(1)}
                        >
                          Rating
                        </button>
                      </li>
                      <li class="nav-item">
                        <button
                          className={`inner-btn ${
                            selectedFilter === 2 ? "active" : ""
                          }`}
                          onClick={() => handleFilterChange(2)}
                        >
                          Services
                        </button>
                      </li>
                      <li>
                        <button
                          className={`inner-btn ${
                            selectedFilter === 3 ? "active" : ""
                          }`}
                          onClick={() => handleFilterChange(3)}
                        >
                          Discount
                        </button>
                      </li>
                      <li>
                        <button
                          className={`inner-btn ${
                            selectedFilter === 4 ? "active" : ""
                          }`}
                          onClick={() => handleFilterChange(4)}
                        >
                          Travel
                        </button>
                      </li>
                      <li class="nav-item">
                        <button
                          className={`inner-btn ${
                            selectedFilter === 5 ? "active" : ""
                          }`}
                        >
                          Delivery Time
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div class="d-flex flex-column flex-shrink-0 p-3 bg-light the-first-filters second">
                    {selectedFilter == 0 && (
                      <ul class="nav nav-pills flex-column mb-auto">
                        <li class="nav-item">
                          <button
                            className="inner-btn"
                            onClick={() =>
                              handlePriceFilter("less than 10,000")
                            }
                          >
                            Less than 10,000
                          </button>
                        </li>
                        <li>
                          <button
                            className="inner-btn"
                            onClick={() => handlePriceFilter("10,000 - 20,000")}
                          >
                            10,000 - 20,000
                          </button>
                        </li>
                        <li class="nav-item">
                          <button
                            className="inner-btn"
                            onClick={() => handlePriceFilter("20,000 - 30,000")}
                          >
                            20,000 - 30,000
                          </button>
                        </li>
                        <li>
                          <button
                            className="inner-btn"
                            onClick={() => handlePriceFilter("40,000 - 50,000")}
                          >
                            40,000 - 50,000
                          </button>
                        </li>
                      </ul>
                    )}
                    {selectedFilter == 1 && (
                      <ul class="nav nav-pills flex-column mb-auto with-stars">
                        <li class="nav-item">
                          <button
                            className="inner-btn"
                            onClick={() => handleRatingFilter("3 & above")}
                          >
                            3 <i className="fa fa-star"></i> & above
                          </button>
                        </li>
                        <li class="nav-item">
                          <button
                            className="inner-btn"
                            onClick={() => handleRatingFilter("3 & below")}
                          >
                            3 <i className="fa fa-star"></i> & below
                          </button>
                        </li>
                      </ul>
                    )}
                    {selectedFilter == 2 && (
                      <ul class="nav nav-pills flex-column mb-auto pe-3">
                        <h6 className="px-2">Photography</h6>
                        <li class="nav-item">
                          <div
                            class="form-check"
                            onClick={() => handlePhotoFilters("Traditional")}
                          >
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="photography1"
                            />
                            <label class="form-check-label" for="photography1">
                              Traditional
                            </label>
                          </div>
                          <div
                            class="form-check"
                            onClick={() => handlePhotoFilters("Destination")}
                          >
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="photography2"
                            />
                            <label class="form-check-label" for="photography2">
                              Destination
                            </label>
                          </div>
                          <div
                            class="form-check"
                            onClick={() => handlePhotoFilters("Drone")}
                          >
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="photography3"
                            />
                            <label class="form-check-label" for="photography3">
                              Drone
                            </label>
                          </div>
                          <div
                            class="form-check"
                            onClick={() => handlePhotoFilters("Cinematic")}
                          >
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="photography4"
                            />
                            <label class="form-check-label" for="photography4">
                              Cinematic
                            </label>
                          </div>
                          <div
                            class="form-check"
                            onClick={() => handlePhotoFilters("Group")}
                          >
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="photography5"
                            />
                            <label class="form-check-label" for="photography5">
                              Group
                            </label>
                          </div>
                        </li>
                        <h6 className="px-2">Videography</h6>
                        <li class="nav-item ">
                          <div
                            class="form-check"
                            onClick={() => handleVideoFilters("Candid")}
                          >
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="videorgraphy0"
                            />
                            <label class="form-check-label" for="videorgraphy0">
                              Candid
                            </label>
                          </div>
                          <div
                            class="form-check"
                            onClick={() => handleVideoFilters("Traditional")}
                          >
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="videorgraphy1"
                            />
                            <label class="form-check-label" for="videorgraphy1">
                              Traditional
                            </label>
                          </div>
                          <div
                            class="form-check"
                            onClick={() => handleVideoFilters("Destination")}
                          >
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="videorgraphy2"
                            />
                            <label class="form-check-label" for="videorgraphy2">
                              Destination
                            </label>
                          </div>
                          <div
                            class="form-check"
                            onClick={() => handleVideoFilters("Drone")}
                          >
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="videorgraphy3"
                            />
                            <label class="form-check-label" for="videorgraphy3">
                              Drone
                            </label>
                          </div>
                          <div
                            class="form-check"
                            onClick={() => handleVideoFilters("Cinematic")}
                          >
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="videorgraphy4"
                            />
                            <label class="form-check-label" for="videorgraphy4">
                              Cinematic
                            </label>
                          </div>
                          <div
                            class="form-check"
                            onClick={() => handleVideoFilters("Group")}
                          >
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="videorgraphy5"
                            />
                            <label class="form-check-label" for="videorgraphy5">
                              Group
                            </label>
                          </div>
                        </li>
                      </ul>
                    )}
                    {selectedFilter == 3 && (
                      <ul class="nav nav-pills flex-column mb-auto">
                        <li class="nav-item">
                          <button
                            className="inner-btn"
                            onClick={() => handleDiscountFilter("60")}
                          >
                            60%
                          </button>
                        </li>
                        <li class="nav-item">
                          <button
                            className="inner-btn"
                            onClick={() => handleDiscountFilter("50")}
                          >
                            50%
                          </button>
                        </li>
                        <li class="nav-item">
                          <button
                            className="inner-btn"
                            onClick={() => handleDiscountFilter("40")}
                          >
                            40%
                          </button>
                        </li>
                        <li class="nav-item">
                          <button
                            className="inner-btn"
                            onClick={() => handleDiscountFilter("30")}
                          >
                            30%
                          </button>
                        </li>
                        <li class="nav-item">
                          <button
                            className="inner-btn"
                            onClick={() => handleDiscountFilter("20")}
                          >
                            20%
                          </button>
                        </li>
                        <li class="nav-item">
                          <button
                            className="inner-btn"
                            onClick={() => handleDiscountFilter("10")}
                          >
                            10%
                          </button>
                        </li>
                      </ul>
                    )}
                    {selectedFilter == 4 && (
                      <ul class="nav nav-pills flex-column mb-auto">
                        <li class="nav-item">
                          <button
                            className="inner-btn"
                            onClick={() => handleLocationFilter("All India")}
                          >
                            All India
                          </button>
                        </li>
                        <li class="nav-item">
                          <button
                            className="inner-btn"
                            onClick={() => handleLocationFilter("Bengaluru")}
                          >
                            Bengaluru
                          </button>
                        </li>
                        <li class="nav-item">
                          <button
                            className="inner-btn"
                            onClick={() => handleLocationFilter("Punjab")}
                          >
                            Punjab
                          </button>
                        </li>
                        <li class="nav-item">
                          <button
                            className="inner-btn"
                            onClick={() => handleLocationFilter("UP")}
                          >
                            UP
                          </button>
                        </li>
                      </ul>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
          {/* <div className="sorting d-flex gap-2 align-items-center wow animate__animated animate__fadeInRight animate__delay-1s ">
                        <label htmlFor="" className="sort">Sort:</label>
                        <select name="" id="" className='form-select form-control'>
                            <option value="Featured">Featured</option>
                        </select>
                    </div> */}
        </div>
      </section>

      <section className="studio-section overflow-hidden">
        <div className="bootstrap-container">
          <div className="studio-start-here pb-5">
            <div className="row">
              {studios.map((studio, index) => (
                <div
                  className={`col-lg-3 col-md-6 my-3  wow animate__animated animate__fadeInDown animate__delay-${
                    index < 3 ? index : "2"
                  }s`}
                >
                  {/* <Link to="/studio-details" style={{textDecoration: "none"}}> */}
                  <div
                    className={`single-studio ${
                      studio.availability ? "" : "outstock"
                    }`}
                  >
                    <span className="discount-offer">
                      <span className="discount-text">
                        {studio.Discount} off
                      </span>
                    </span>
                    <figure className="sutdio-img">
                      <img src={studio.img} alt="" className="w-100" />
                      <button
                        onClick={() => toggleWishItem(index)}
                        className="heart-icon"
                      >
                        <i class={`fa fa-heart${wish[index] ? "" : "-o"}`}></i>
                      </button>
                    </figure>
                    <div className="studio-text">
                      <h6 className="name">{studio.studioname}</h6>
                      <div className="rating-place">
                        <span className="place-name">
                          <img src={Baricon} alt="" />
                          {studio.location}
                        </span>
                        <div className="rating">
                          <i className="fa fa-star"></i> {studio.rating}
                        </div>
                      </div>
                      <div className="price d-flex pt-3 gap-1 align-items-center">
                        <h6 className="md-heading mb-0 !text-2xl">
                          ₹ {studio.price}{" "}
                          <span className="!text-sm font-medium">Per Day</span>
                        </h6>
                      </div>
                      <Link
                        className="btn btn-primary w-100 d-block mt-3"
                        to={`/photography/${params.type}/40`}
                      >
                        View Details
                      </Link>
                    </div>
                  </div>
                  {/* </Link> */}
                </div>
              ))}
            </div>
          </div>
          <Pagination total={5} current={3} />
        </div>
      </section>

      <ToastContainer />
      <StudioCompnent />
      <PhotographyModals />
      <Footer />
    </>
  );
};
export default WeddingPhotography;
