import React, { useState, useEffect } from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import PhotographyModals from "../Photography/PhotographyModals";

// Range Slider In Choose Us Form
import Banner from "../../Components/banner";
import "react-range-slider-input/dist/style.css";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  confirmBookingAsync,
  createTimelineAsync,
  getConfirmBooking,
  getPaymentDetailsAsync,
  selectCurrentLTA,
  updatePaymentStatusAsync,
  updatePhotographerShareAsync,
} from "../../Redux/slice/mainSlice";

const PaymentSuccess = () => {
  const [paymentStatus, setPaymentStatus] = useState("");
  const [mode, setMode] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(window.location.search);
  const confirmBooking = useSelector(getConfirmBooking);
  const currentLta = useSelector(selectCurrentLTA);
  console.log(confirmBooking, "CONGIRM");
  const updateUserAndRedirect = () => {
    const userId = localStorage.getItem("userId");
    const paymentId = searchParams.get("payment_request_id"); // payment_id

    // Handle the payment status here (e.g., update state, make API call, etc.)
    dispatch(getPaymentDetailsAsync(paymentId)).then((x) => {
      console.log(x, "PAYMENT DETAIL");
      if (x.payload.purpose === "Membership") {
        dispatch(
          updatePaymentStatusAsync({
            userId,
            paymentStatus: x.payload.amount < 500 ? "PRO" : "ULTRA",
          })
        ).then((x) => {
          console.log(x, "PAYMENTSTATUS");
          localStorage.setItem("paymentStatus", x.payload.paymentStatus);
          setTimeout(() => {
            navigate("/");
          }, 3000);
        });
        setMode("membership");
      } else if (x.payload.purpose === "LTA") {
        console.log("ALTALATALA", currentLta);
        dispatch(
          updatePhotographerShareAsync({ sharedUrl: currentLta, lta: true })
        ).then((x) => {
          console.log(x, "RESPOSNEIVE");
          setTimeout(() => {
            // navigate("/my-footage/my-files");
          }, 3000);
        });
        setMode("LTA");
      } else {
        // we will update booking payment status here if payment is for booking
        console.log(x.payload.purpose);
        dispatch(
          confirmBookingAsync({
            packageData: confirmBooking.packageData,
            id: confirmBooking.bookingId,
          })
        ).then((x) => {
          dispatch(
            createTimelineAsync({
              timelineData: { title: "Booking Confirmed" },
              bookingId: confirmBooking.bookingId,
            })
          );
          setTimeout(() => {
            navigate("/booking");
          }, 3000);
        });
        setMode("booking");
      }
    });
  };
  useEffect(() => {
    // Function to extract payment status from URL parameters
    const extractPaymentStatus = () => {
      const paymentStatus = searchParams.get("payment_status");

      console.log("Payment Status:", paymentStatus);
      setPaymentStatus(paymentStatus);
      if (paymentStatus === "Failed") {
        setTimeout(() => {
          navigate("/");
        }, 3000);
      } else {
        updateUserAndRedirect(paymentStatus);
      }
    };

    extractPaymentStatus(); // Call the function when component mounts
  }, []);

  return (
    <>
      <main className="site-front-page">
        <Header />
        <Banner
          home="Home"
          currenturl="/payment-success"
          current={
            paymentStatus === "Failed" ? "Payment Failed" : "Successful Payment"
          }
        />
        <section className="studio-section overflow-hidden">
          <div className="bootstrap-container">
            {mode === "membership" ? (
              <div className="studio-start-here pb-5 text-center">
                {paymentStatus === "Failed" ? (
                  <p>Oops Payment Failed!!</p>
                ) : (
                  <p>Congratulation you are a Paid user now !!</p>
                )}

                <p>Redirecting to Home screen now...</p>
              </div>
            ) : mode === "booking" ? (
              <div className="studio-start-here pb-5 text-center">
                {paymentStatus === "Failed" ? (
                  <p>Oops Payment Failed!!</p>
                ) : (
                  <>
                    <p>Congratulation your Booking is confirmed !!</p>
                    <p>Redirecting to Home screen now...</p>
                  </>
                )}
              </div>
            ) : (
              <div className="studio-start-here pb-5 text-center">
                {paymentStatus === "Failed" ? (
                  <p>Oops Payment Failed!!</p>
                ) : (
                  <>
                    <p>Congratulation your Life time access is confirmed !!</p>
                    <p>Redirecting to Home screen now...</p>
                  </>
                )}
              </div>
            )}
          </div>
        </section>
        <Footer />
        <PhotographyModals />
      </main>
    </>
  );
};
export default PaymentSuccess;
