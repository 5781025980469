import React, { useState } from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { MdNavigateNext, MdNavigateBefore } from "react-icons/md";
import { useRef } from "react";

const MorePicsModal = ({ showModal, setShowModal, photographer, id }) => {
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();

  const slider = useRef();

  console.log(photographer?.Photographer?.category[id.type]?.images);

  return (
    <>
      {showModal && (
        <div className="w-full h-screen bg-black/70 fixed top-0 right-0 left-0 bottom-0 z-[99999]">
          <button
            className="w-12 h-12 p-2 rounded-full text-center bg-main text-white absolute right-3 top-3"
            onClick={() => setShowModal(false)}
          >
            X
          </button>
          <div className="w-[80%] h-[98%] mx-auto my-4 z-[99999]">
            <div className="">
              <Slider
                asNavFor={nav2}
                ref={slider}
                // ref={(slider1) => setNav1(slider1)}
                arrows={false}
                variableWidth={true}
                slidesToShow={1}
                slidesToScroll={1}
              >
                {photographer?.Photographer?.category[id.type]?.images?.map(
                  (image, index) => (
                    <div key={index} className="h-[500px] px-lg-3">
                      <img
                        src={image}
                        alt={`Image ${index + 1}`}
                        className="h-full"
                      />
                    </div>
                  )
                )}
              </Slider>
              {/* <div className="px-lg-3">
                <Slider
                  asNavFor={nav1}
                  ref={(slider2) => setNav2(slider2)}
                  //   slidesToShow={
                  //     photographer?.Photographer?.category[id.type]?.images
                  //       ?.length > 4
                  //       ? 4
                  //       : photographer?.Photographer?.category[id.type]?.images
                  //           .length
                  //   }
                  slidesToScroll={1}
                  slidesToShow={1}
                  swipeToSlide={true}
                  focusOnSelect={true}
                  nextArrow={false}
                  prevArrow={false}
                  arrows={false}
                  variableWidth={true}
                  className="slider variable-width"
                >
                  {photographer?.Photographer?.category[id.type]?.images?.map(
                    (image, index) => (
                      <div key={index} className="h-[150px]">
                        <img
                          src={image}
                          alt={`Image ${index + 1}`}
                          className="h-full mx-1"
                        />
                      </div>
                    )
                  )}
                </Slider>
              </div> */}
            </div>
          </div>

          <button
            className="w-12 h-12 p-2 rounded-full flex items-center justify-center bg-main text-white absolute right-3 top-[40%]"
            onClick={() => slider?.current?.slickNext()}
          >
            <MdNavigateNext className="text-white font-bold text-4xl" />
          </button>

          <button
            className="w-12 h-12 p-2 rounded-full flex items-center justify-center bg-main text-white absolute left-3 top-[40%]"
            onClick={() => slider?.current?.slickPrev()}
          >
            <MdNavigateBefore className="text-white font-bold text-4xl" />
          </button>
        </div>
      )}
    </>
  );
};

export default MorePicsModal;
