import React, { useState } from "react";
import { Logo, NewsLetter2Img } from "../Constant/Index";
import { Link } from "react-router-dom";
import SubscriptionEmail from "./Modals/SubscriptionEmail.js";

import { BsFacebook, BsInstagram, BsYoutube } from "react-icons/bs";
import { AiFillTwitterCircle, AiFillInstagram } from "react-icons/ai";
import { RiWhatsappFill } from "react-icons/ri";

const Footer = () => {
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [email, setEmail] = useState("");

  const loggedIn = localStorage.getItem("loggedIn");

  const subscribeNewsLetter = (e) => {
    e.preventDefault();
    setEmail("");
    setShowSubscriptionModal(true);
  };

  return (
    <>
      <section className="news-letter ">
        <div className="bootstrap-container">
          <div className="col-xxl-10 col-xl-11 mx-auto text-center">
            <div className="news-letter-box">
              <h4 className="  !text-main mb-2">
                Subscribe to our newsletter and become ultimate lifetime member
                of Boopho fam!
              </h4>
              <form class="input-group mb-3" onSubmit={subscribeNewsLetter}>
                <input
                  type="email"
                  className="form-control pl-4"
                  placeholder="Enter Your Email Address"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <button
                  // class="input-group-text fit-content"
                  className="bg-main text-white px-3 py-1"
                  id="basic-addon1"
                  // onClick={subscribeNewsLetter}
                  type="submit"
                >
                  {/* <img
                    src={NewsLetter2Img}
                    style={{ maxWidth: "50px" }}
                    alt=""
                    className="invert"
                  /> */}
                  Subscribe
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
      <footer class="footer">
        <div class="bootstrap-container">
          <div class="footer_start responsive_container_margin col-xl-11 mx-auto">
            <div class="row">
              <div class="col-xl-3 col-lg-3 col-md-6  ">
                <div class="footer_text">
                  <figure class="logo w-max mx-auto">
                    <img
                      src={Logo}
                      class="img-fluid invert !brightness-[0]"
                      alt=""
                    />
                  </figure>
                  <p></p>
                  <ul class="social_links  text-2xl">
                    <li>
                      <a
                        href="https://www.facebook.com/profile.php?id=100091743916407"
                        target="_blank"
                        className="!text-[#f9f3ed]"
                      >
                        <BsFacebook />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/boopho_official/"
                        target="_blank"
                        className="!text-[#f9f3ed]"
                      >
                        <AiFillInstagram />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://x.com/boopho_official?s=21"
                        target="_blank"
                        className="!text-[#f9f3ed]"
                      >
                        <AiFillTwitterCircle />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://youtube.com/@boopho_official"
                        target="_blank"
                        className="!text-[#f9f3ed]"
                      >
                        <BsYoutube />
                      </a>
                    </li>
                    <li>
                      
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-xl-8 col-lg-9 col-md-12 ms-auto">
                <div class="row">
                  <div class="col-md-4 col-xl-3 pe-xl-0 ps-lg-3 px-md-4 pe-lg-5 pt-5 pt-lg-0 pt-md-0">
                    <div class="footer_links">
                      <h6 class="heading !text-[#faf3ed] font-bold">
                        Get to Know Us
                      </h6>
                      <ul>
                        <li>
                          <Link to="/aboutus">About Us</Link>
                        </li>
                        <li>
                          <Link to="/faqs">FAQs</Link>
                        </li>
                        <li>
                          <Link to="/contactus">Contact Us</Link>
                        </li>
                        <li>
                          <Link to="/gallery">Gallery</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-md-4 col-xl-4 ps-xl-5 pe-4 pt-5 pt-lg-0 pt-md-0">
                    <div class="footer_links">
                      <h6 class="heading !text-[#faf3ed] font-bold">
                        Terms and Conditions
                      </h6>
                      <ul>
                        <li>
                          <Link to="/privacy_policy">Privacy Policy</Link>
                        </li>
                        <li>
                          <Link to="/terms">Terms &amp; Conditions</Link>
                        </li>
                        <li>
                          <Link to="/cancellation">Cancellation Policy</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-md-4 col-xl-4 ps-xl-5 pe-4 pt-5 pt-lg-0 pt-md-0">
                    <div class="footer_links">
                      <h6 class="heading !text-[#faf3ed] font-bold">
                        Business With Us
                      </h6>
                      <ul>
                        <li>
                          {loggedIn ? (
                            <Link to="/photographer/register">
                              List Your Business
                            </Link>
                          ) : (
                            <a
                              href=""
                              data-bs-toggle="modal"
                              data-bs-target="#LoginModal"
                              role="button"
                              className="font-sans"
                            >
                              List Your Business
                            </a>
                          )}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr class="mt-5 text-[#faf3ed]" />
            <div class="copy_right my-2 mb-0">
              <p class="text-[#faf3ed] text-center pop_light sm_text m-0">
                © Copyright 2023. All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </footer>

      <SubscriptionEmail
        showModal={showSubscriptionModal}
        setShowModal={setShowSubscriptionModal}
      />
    </>
  );
};
export default Footer;
