import React from "react";
// import { Link, useLocation } from "react-router-dom";

const BookingTabs = ({ currentTab, setCurrentTab }) => {
  const tabs = [
    {
      value: "requestedBooking",
      label: "Requested Bookings",
    },
    {
      value: "confirmedBooking",
      label: "Confirmed Bookings",
    },
    {
      value: "pastBooking",
      label: "Past Bookings",
    },
    {
      value: "cancelledBooking",
      label: "Cancelled Bookings",
    },
  ];

  return (
    <ul className="flex border-b-2 border-main mb-4 text-sm md:text-xl wow animate__animated animate__fadeInDown animate__delay-1s overflow-auto">
      {tabs?.map((tab, index) => (
        <li
          key={index}
          className={`${
            currentTab === tab.value ? "!text-white bg-main" : "!text-main"
          }   min-w-max px-4 py-2 cursor-pointer`}
          onClick={() => setCurrentTab(tab.value)}
        >
          {tab.label}
        </li>
      ))}
    </ul>
  );
};

export default BookingTabs;
