import Logo from "../Assets/Img/logo.png";
// Stories Images
import Baby1 from "../Assets/Img/baby/Baby1.jpg";
import Baby2 from "../Assets/Img/baby/Baby2.jpg";
import Baby3 from "../Assets/Img/baby/Baby3.jpg";
import Birthday1 from "../Assets/Img/birthday/Birthday1.jpg";
import Birthday2 from "../Assets/Img/birthday/Birthday2.jpg";
import Birthday3 from "../Assets/Img/birthday/Birthday3.jpg";
import Business1 from "../Assets/Img/business/Business1.jpg";
import Business2 from "../Assets/Img/business/Business2.jpg";
import Business3 from "../Assets/Img/business/Business3.jpg";
import Maternity1 from "../Assets/Img/maternity/Maternity1.jpg";
import Maternity2 from "../Assets/Img/maternity/Maternity2.jpg";
import Maternity3 from "../Assets/Img/maternity/Maternity3.jpg";
import Wedding1 from "../Assets/Img/wedding/Wedding1.jpg";
import Wedding2 from "../Assets/Img/wedding/Wedding2.jpg";
import Wedding3 from "../Assets/Img/wedding/Wedding3.jpg";
import Baricon from "../Assets/Img/svg/bar.png";
import PhotographyBannerImg from "../Assets/Img/photography/banner.png";
// Studio Images Start
import Studio1 from "../Assets/Img/studio/studio1.png";
import Studio2 from "../Assets/Img/studio/studio2.png";
import Studio3 from "../Assets/Img/studio/studio3.png";
import Studio4 from "../Assets/Img/studio/studio4.png";
import Studio5 from "../Assets/Img/studio/studio5.png";
import Studio6 from "../Assets/Img/studio/studio6.png";
import Studio7 from "../Assets/Img/studio/studio7.png";
//  Social Icons Here
import FbIcon from "../Assets/Img/svg/facebook-f.svg";
// import FbIcon from "../Assets/Img/svg/fb.png";
import InstaIcon from "../Assets/Img/svg/instagram.svg";
// import InstaIcon from "../Assets/Img/svg/in.png";
import TwIcon from "../Assets/Img/svg/twitter.svg";
// import TwIcon from "../Assets/Img/svg/tw.png";
// import YtIcon from "../Assets/Img/svg/yt.png";
import YtIcon from "../Assets/Img/svg/youtube.svg";
// PLans Images
import Plan1Img from "../Assets/Img/svg/plan1.png";
import Plan2Img from "../Assets/Img/svg/plan2.png";
import Plan3Img from "../Assets/Img/svg/plan3.png";
import NewsLetter1Img from "../Assets/Img/newsletter.png";
import NewsLetter2Img from "../Assets/Img/newsletter1.png";
// About Images Imported Here
import About1Img from "../Assets/Img/about/about.png";
import About2Img from "../Assets/Img/about/about2.png";
import About3Img from "../Assets/Img/about/about3.png";
// SOme Icons
import CallIcon from "../Assets/Img/svg/call-icon.png";
import CommentIcon from "../Assets/Img/svg/comment.png";
import SpeakerIcon from "../Assets/Img/svg/speaker.webp";
import CheckIcon from "../Assets/Img/svg/check.webp";
import CloseIcon from "../Assets/Img/svg/close.svg";
import UpiIcon from "../Assets/Img/svg/upi-icon.svg";
import BankingIcon from "../Assets/Img/svg/netbanking.png";
import UploadIcon from "../Assets/Img/svg/upload.svg";
import LoaderIcon from "../Assets/Img/svg/Spinner-2.gif";
// Gallery Images Start Here
import Candid1Img from "../Assets/Img/gallery/candid1.png";
import Candid2Img from "../Assets/Img/gallery/candid2.png";
import Candid3Img from "../Assets/Img/gallery/candid3.png";
import Candid4Img from "../Assets/Img/gallery/candid4.png";
import Candid5Img from "../Assets/Img/gallery/candid5.png";
import Candid6Img from "../Assets/Img/gallery/candid6.png";
import Candid7Img from "../Assets/Img/gallery/candid7.png";
import Candid8Img from "../Assets/Img/gallery/candid8.png";
import Candid9Img from "../Assets/Img/gallery/candid9.png";
import Traditional1Img from "../Assets/Img/gallery/traditional1.png";
import Traditional2Img from "../Assets/Img/gallery/traditional2.png";
import Traditional3Img from "../Assets/Img/gallery/traditional3.png";
import Traditional4Img from "../Assets/Img/gallery/traditional4.png";
import Traditional5Img from "../Assets/Img/gallery/traditional5.png";
import Traditional6Img from "../Assets/Img/gallery/traditional6.png";
import Traditional7Img from "../Assets/Img/gallery/traditional7.png";
import Traditional8Img from "../Assets/Img/gallery/traditional8.png";
import Traditional9Img from "../Assets/Img/gallery/traditional9.png";
import ReportIcon from "../Assets/Img/svg/report.png";







//payment-form
import VisaImg from "../Assets/Img/payment-form/visa.webp";
export {
  Logo,
  Baby1,
  Baby2,
  Baby3,
  Birthday1,
  Birthday2,
  Birthday3,
  Business1,
  Business2,
  Business3,
  Maternity1,
  Maternity2,
  Maternity3,
  Wedding1,
  Wedding2,
  Wedding3,
  Studio1,
  Studio2,
  Studio3,
  Studio4,
  Studio5,
  Studio6,
  Studio7,
  Baricon,
  FbIcon,
  InstaIcon,
  TwIcon,
  YtIcon,
  PhotographyBannerImg,
  Plan1Img,
  Plan2Img,
  Plan3Img,
  About1Img,
  About2Img,
  About3Img,
  CallIcon,
  CommentIcon,
  SpeakerIcon,
  Candid1Img,
  Candid2Img,
  Candid3Img,
  Candid4Img,
  Candid5Img,
  Candid6Img,
  Candid7Img,
  Candid8Img,
  Candid9Img,
  Traditional1Img,
  Traditional2Img,
  Traditional3Img,
  Traditional4Img,
  Traditional5Img,
  Traditional6Img,
  Traditional7Img,
  Traditional8Img,
  Traditional9Img,
  CheckIcon,
  CloseIcon,
  UpiIcon,
  BankingIcon,
  UploadIcon,
  LoaderIcon,
  NewsLetter1Img,
  NewsLetter2Img,
  ReportIcon,
  VisaImg,
};
