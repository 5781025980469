import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import PhotographyModals from "../Photography/PhotographyModals";
import Pagination from "../../Components/Pagination";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Slider from "react-slick";
import { FaChevronLeft, FaChevronRight } from "react-icons";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import TheDate from "../../Components/TheDate";
import { Helmet } from "react-helmet";
import { useNavigate } from 'react-router-dom';
import { RiWhatsappFill } from "react-icons/ri";


import {
  Baby1,
  Baby2,
  Baby3,
  Birthday1,
  Birthday2,
  Birthday3,
  Business1,
  Business2,
  Business3,
  Maternity1,
  Maternity2,
  Maternity3,
  Wedding1,
  Wedding2,
  Wedding3,
  Studio1,
  Studio2,
  Studio3,
  Studio4,
  Studio5,
  Studio6,
  Baricon,
} from "../../Constant/Index";
import HomeAbout from "../../Components/HomeAbout";

const Home = () => {
  const [categories, setCategories] = useState([]);
  const [currentCategoryIndex, setCurrentCategoryIndex] = useState(0);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [animateImageTransition, setAnimateImageTransition] = useState(false);
  const [wish, setWish] = useState([]);
  const [selectedFilter, SetSelectedFilter] = useState(0);
  const [showFilter, SetShowFilter] = useState(false);
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const navigate = useNavigate();

  const handleNavigation = (category) => {

    const queryParams = new URLSearchParams({
      category: category.id,
      name: category.name,
    });


    navigate(`/bookPhotographer?${queryParams.toString()}`);
  };

  useEffect(() => {
    axios
      .get(`${baseUrl}/user/event-categories`)
      .then((response) => {
        const fetchedCategories = response.data.data;
        setCategories(
          fetchedCategories.filter(
            (category) => category.EventCategoryMedia.length > 0
          )
        );
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, []);

  useEffect(() => {
    const changeCategoryTimeout = setTimeout(() => {
      setCurrentImageIndex(0);
      setCurrentCategoryIndex(
        (prevIndex) => (prevIndex + 1) % categories.length
      );
      setAnimateImageTransition(true);

      setTimeout(() => {
        setAnimateImageTransition(false);
      }, 500); // Transition duration
    }, 3000 * (categories.length + 1)); // Extra 1 second as buffer

    const changeImageInterval = setInterval(() => {
      setCurrentImageIndex(
        (prevIndex) =>
          (prevIndex + 1) %
          categories[currentCategoryIndex]?.EventCategoryMedia.length
      );
    }, 3000);

    return () => {
      clearTimeout(changeCategoryTimeout);
      clearInterval(changeImageInterval);
    };
  }, [currentCategoryIndex, categories]);

  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2500,
    prevArrow: null,
    nextArrow: null,
  };
  let studios = [
    {
      studioname: "Photo Wale Studio",
      location: "Delhi",
      price: "16,000",
      rating: 4.7,
      img: Studio1,
      Discount: "25%",
      availability: true,
    },
    {
      studioname: "Lorem Studio",
      location: "Mumbai",
      price: "18,000",
      rating: 4.2,
      img: Studio2,
      Discount: "17%",
      availability: true,
    },
    {
      studioname: "Ipsum Sutdio",
      location: "Chennai",
      price: "19,000",
      rating: 4.3,
      img: Studio3,
      Discount: "32%",
      availability: false,
    },
    {
      studioname: "Dolor Studio",
      location: "Punjab",
      price: "22,000",
      rating: 4.4,
      img: Studio4,
      Discount: "05%",
      availability: true,
    },
    {
      studioname: "Mega Studio",
      location: "Punjab",
      price: "23,000",
      rating: 4.5,
      img: Studio5,
      Discount: "40%",
      availability: true,
    },
    {
      studioname: "Alpha Studio",
      location: "Delhi",
      price: "27,000",
      rating: 4.6,
      img: Studio6,
      Discount: "04%",
      availability: true,
    },
  ];

  const toggleWishItem = (index) => {
    setWish((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = !newStates[index];
      newStates[index]
        ? toast.success("Added to wishlist!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000, // Auto close the toast after 3 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        })
        : toast.error("Removed From WIshlist!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000, // Auto close the toast after 3 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      return newStates;
    });
    // const showToast = () => {

    // };
  };
  const handleFilterChange = (newValue) => {
    SetSelectedFilter(newValue);
  };
  const toggleFilters = () => {
    SetShowFilter(!showFilter);
  };

  return (
    <>
      <Helmet>
        <title>
          Boopho photographers - Book premium photographers now | Boopho
        </title>
        <meta name="description" content="Book premium photographers now" />
      </Helmet>

      <main className="site-front-page bg-[#faf3ed]">
        <Header />
        <section className="story-section mb-2">
          <div className="container-fluid">
            <div className="md:hidden mb-2">
              <h3 className="text-main font-bold text-lg text-center">
                Book Your Photgrapher For
              </h3>
            </div>
            <div className="stories-sart">
              <div className="service-event-list">
                {categories.map((category, categoryIndex) => (
                  <div className={"single-service"} key={categoryIndex}>
                    {/* <Link to={`/photography/${category.id}`}> */}
                    <div
                      className="services-status"
                      onClick={() => handleNavigation(category)}
                      style={{ cursor: 'pointer' }}
                    >
                      <Slider {...settings}>
                        {category.EventCategoryMedia.map(
                          (media, mediaIndex) => (
                            <figure className="service-img" key={mediaIndex}>
                              <img
                                src={media.imageUrl} // Ensure that imageUrl is the correct property
                                alt=""
                                className={`img-fluid story-image ${animateImageTransition ? "fade-out" : ""
                                  } ${categoryIndex !== currentCategoryIndex
                                    ? "hidden"
                                    : ""
                                  }`}
                              />
                            </figure>
                          )
                        )}
                      </Slider>
                      <p className="service-name">{category.name}</p>
                    </div>

                  </div>
                ))}
              </div>
              {/* <h3 className="stories-select md-heading wow animate__animated animate__fadeIn animate__delay-3s">
                Select Your Occasion
              </h3> */}
            </div>
          </div>
        </section>




        {/* <HomeAbout /> */}


        <ToastContainer />
        <Footer />
        <PhotographyModals />
      </main >
    </>
  );
};
export default Home;
