import React, {useState, useEffect} from "react";
import ForgotResetPass from "./ForgotResetPass";
import axios from "axios";
import { useParams } from "react-router";

const ResetPassword = () => {

  const [resetToken, setResetToken] = React.useState(''); // Set the initial token value
  const [isValidToken, setIsValidToken] = useState('');
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get('token');

  console.log(isValidToken);

 const resetPass = async (password) => {
    try {
      console.log(password);
      if(isValidToken) {
      // Make an API call to reset the password with the token
      const response = await fetch(`${baseUrl}/auth/reset-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          password,
          token,
        }),
      });

      if (response.ok) {
        // Password changed successfully
        return true;
      } else {
        // Handle error, maybe show a toast or update state
        return false;
      }
    }
    } catch (error) {
      // Handle fetch error
      console.error('Error:', error);
      return false;
    }
  };

  useEffect(() => {
    // Validate the token here
    const validateToken = async () => {
      try {
        const response = await axios.post(
          `${baseUrl}/auth/validate-link`,
          { token: token },
          {
            headers: {
              'Content-Type': 'application/json', // Make sure to set the appropriate content type
            },
          }
        );
  
        const data = await response.data.data.isLinkValid;

        console.log(response.data.data.isLinkValid);
  
          setIsValidToken(data);
      } catch (error) {
        console.error('Error validating token:', error);
      }
    };
  
    validateToken();
  }, [token]);
  

  return <ForgotResetPass resetPass={resetPass} />;
};

export default ResetPassword;
