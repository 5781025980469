import React, { useState, useEffect } from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import PhotographerSidebar from "../Components/Sidebar";
import Card from "../Components/Card";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Swal from "sweetalert2";

const RequestedCalls = () => {
  const token = localStorage.getItem("authToken");
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [cardData, setCardData] = useState([]);

  const makeCall = async (cid, bid) => {
    try {
      console.log(cid, token);

      const apiUrl = `${baseUrl}/call/create-call`;
      const body = {
        customerId: cid,
        bookingId: bid,
      };

      const response = await axios.post(apiUrl, body, {
        headers: {
          "x-auth-token": token,
        },
      });

      if (response.data?.success) {
        Swal.fire({
          icon: "info",
          text:
            "You'll recieve a call from Boopho.com now which will then connect you to the customer!",
        });
      } else {
        toast.error(response.data?.message);
      }
    } catch (err) {
      toast.error(err.response?.data?.message);
    }
  };

  const fetchData = async () => {
    try {
      const apiUrl = `${baseUrl}/photographer/bookings`;
      const headers = {
        "x-auth-token": token,
      };

      const response = await axios.get(apiUrl, {
        params: {
          status: "PENDING",
        },
        headers: headers,
      });

      if (response.status === 200) {
        const responseData = response.data.data;
        if (Array.isArray(responseData.rows)) {
          console.log(responseData);
          setCardData(responseData.rows);
        } else {
          console.error("Invalid data format: rows is not an array.");
        }
      } else {
        console.error("Unable to fetch data.");
      }
    } catch (error) {
      console.error("Error while fetching data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [token]);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="photographer-page">
        <Header />
        <section className="photographer-main pt-5">
          <div className="container-fluid px-0">
            <div className="photgrapher-main-start">
              <div className="row mx-0">
                <PhotographerSidebar />
                <div className="col-md-9 px-3">
                  <div className="grid grid-cols-2 gap-2">
                    {cardData?.map((item, index) => (
                      <Card
                        key={index}
                        name={item?.BookingUser?.name}
                        city={item?.address}
                        phone={item?.BookingUser?.phoneNo}
                        eventStartDate={item?.startDate}
                        eventEndDate={item?.endDate}
                        location={item?.address}
                        btn={true}
                        btnText={"Make a Call"}
                        btnBg={"green"}
                        btnHandleClick={() =>
                          makeCall(item?.BookingUser?.id, item?.id)
                        }
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};

export default RequestedCalls;
