import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UploadIcon } from "../../Constant/Index";
import axios from "axios";
import PopupMessage from "../../Components/PopupMessage";
import Select from "react-select";
import MorePhotos from "../Modals/MorePhotos";
import { LoaderIcon } from "../../Constant/Index";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Banner from "../../Components/banner";

const PhotographerProfile = () => {
  const [formData, setFormData] = useState({
    studioName: "",
    serviceLocation: "",
    languagesKnown: "",
    willingToTravel: "",
    travelState: "",
    deliveryTime: "",
    individualOrTeam: "",
    numberOfTeamMembers: "",
    businessLink: "",
    anyHighligths: "",
    typesOfPhotography: [],
    typesOfVideography: [],
    termsAndConditions: false,
  });


  console.log(formData);
  const [checkedEvents, setCheckedEvents] = useState([]);
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [loading, setLoading] = useState(false);
  const [generateAbout, setGenerateAbout] = useState(false);
  const [showAllPhotosModal, setShowAllPhotosModal] = useState(false);
  const [allPhotos, setAllPhotos] = useState([]);
  const [typesOfPhotography, setTypesOfPhotography] = useState([]);
  const [typesOfVideography, setTypesOfVideography] = useState([]);
  const [occasionCategories, setOccasionCategories] = useState([]);
  const [categoryImages, setCategoryImages] = useState({});
  const [favoritePhoto, setFavoritePhoto] = useState([]);
  const [photographer, setPhotographer] = useState([]);
  // const [categoryImages, setCategoryImages] = useState([]);

  const [PhotographyList, setPhotographyList] = useState([
    "Destination",
    "Group",
    "Traditional",
    "Drone",
    "Natural",
    "Boudoir",
    "Candid",
    "Cinematic",
    "Vintage",
    "Fine Art",
    "Dramatic",
  ]);

  const [VideographyList, setVideographyList] = useState([
    "Destination",
    "Group",
    "Traditional",
    "Drone",
    "Natural",
    "Boudoir",
    "Candid",
    "Cinematic",
    "Vintage",
    "Fine Art",
    "Dramatic",
  ]);

  let aboutYouText = `At ${formData.studioName}, we're more than just photographers; we're a dedicated team of visual storytellers. With combined experience spanning Many Years of Experience, our passion for photography knows no bounds.
  Our studio offers a rich tapestry of photography styles, including candid, drone, natural, vintage, traditional, and intimate boudoir sessions. We have the expertise to capture every moment in its unique essence. From cinematic narratives to dynamic reels, short films, breathtaking drone footage, destination shoots, and mesmerizing time-lapse videos, our videography services add an extra dimension to your memories.
  Occasions We Serve:
  From weddings and maternity shoots to precious newborn moments, joyful birthdays, and professional business events, we've got every occasion covered.
  Delivery Time:
  We understand the importance of prompt delivery. Expect your beautifully edited photos and videos within ${formData.deliveryTime}.
  Additional Skills:
  Beyond our primary areas, we excel in professional photo editing, studio photography, and conducting engaging photography workshop`;

  const navigate = useNavigate();

  console.log(formData);

  console.log(categoryImages);
  const token = localStorage.getItem("authToken");
  const userId = localStorage.getItem("userId");
  const [states, setStates] = useState([]);

  const handleCheckboxChange = (event) => {
    const { id, checked } = event.target;

    if (checked) {
      setCheckedEvents((prevCheckedEvents) => [...prevCheckedEvents, id]);
    } else {
      setCheckedEvents((prevCheckedEvents) =>
        prevCheckedEvents.filter((eventId) => eventId !== id)
      );
    }
  };

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;

    if (type === "checkbox") {
      if ("typesOfVideography" === name) {
        // If it's a checkbox, handle the checked state
        let updatedTypesOfVideography = [...formData.typesOfVideography];

        if (checked) {
          // If checked, add it to the array
          updatedTypesOfVideography.push(value);
        } else {
          // If unchecked, remove it from the array
          updatedTypesOfVideography = updatedTypesOfVideography.filter(
            (item) => item !== value
          );
        }

        setFormData({
          ...formData,
          [name]: updatedTypesOfVideography,
        });
      } else {
        // If it's a checkbox, handle the checked state
        let updatedTypesOfPhotography = [...formData.typesOfPhotography];

        if (checked) {
          // If checked, add it to the array
          updatedTypesOfPhotography.push(value);
        } else {
          // If unchecked, remove it from the array
          updatedTypesOfPhotography = updatedTypesOfPhotography.filter(
            (item) => item !== value
          );
        }

        setFormData({
          ...formData,
          [name]: updatedTypesOfPhotography,
        });
      }
    } else {
      // For other input types (text, etc.), update as usual
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    const requiredFields = [
      "studioName",
      "individualOrTeam",
      "businessLink",
      "anyHighligths",
      // "aboutYou",
      "typesOfPhotography",
    ];

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        valid = false;
        newErrors[field] = `${field.charAt(0).toUpperCase() +
          field.slice(1)} is required`;
      }
    });

    if (
      formData.businessLink &&
      !/^(http|https):\/\/[^ "]+$/.test(formData.businessLink)
    ) {
      valid = false;
      newErrors.businessLink = "Please enter a valid URL";
    }

    setErrors(newErrors);
    return valid;
  };

  useEffect(() => {
    getAllStates();

  }, []);

  const handleUpdate = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        setLoading(true);
        const imageData = {};

        for (
          let categoryIndex = 0;
          categoryIndex < uploadImagesByCategory.length;
          categoryIndex++
        ) {
          const images = uploadImagesByCategory[categoryIndex];
          const imageCount = images.length;

          if (imageCount === 0) {
            continue;
          }

          const data = new FormData();

          for (let imageIndex = 0; imageIndex < imageCount; imageIndex++) {
            const image = images[imageIndex];
            data.append("media", image);
          }

          const response = await axios.post(
            `${baseUrl}/user/upload-media`,
            data,
            {
              headers: {
                "x-auth-token": token,
              },
            }
          );

          // Check if the response indicates success for this array of images
          if (response.status === 200) {
            console.log(
              `Array ${categoryIndex} uploaded successfully:`,
              response.data.data
            );
            const categoryData = response.data.data.reduce(
              (result, item) => {
                if (item.type.includes("video")) {
                  result.videos.push(item.url);
                } else {
                  result.images.push(item.url);
                }
                return result;
              },
              { images: [], videos: [] }
            );

            switch (categoryIndex) {
              case 0:
                imageData["17"] = categoryData;
                break;
              case 1:
                imageData["18"] = categoryData;
                break;
              case 2:
                imageData["22"] = categoryData;
                break;
              case 3:
                imageData["24"] = categoryData;
                break;
              case 4:
                imageData["25"] = categoryData;
                break;
              default:
                break;
            }
          } else {
            console.error(
              `Array ${categoryIndex} upload failed:`,
              response.data
            );
          }
        }

        const mergedData = Object.keys(categoryImages).reduce((result, key) => {
          if (imageData[key]) {
            // If imageData already has this key, merge the images and videos arrays
            result[key] = {
              images: [...categoryImages[key].images, ...imageData[key].images],
              videos: [...categoryImages[key].videos, ...imageData[key].videos],
            };
          } else {
            // If imageData doesn't have this key, use the data from categoryImages
            result[key] = categoryImages[key];
          }
          return result;
        }, {});

        // Add any keys in imageData that are not present in categoryImages
        Object.keys(imageData).forEach(key => {
          if (!categoryImages[key]) {
            mergedData[key] = imageData[key];
          }
        });

        console.log('mergedData', mergedData);

        formData.category = mergedData;
        formData.aboutYou = aboutYouText;
        const state = selectedStates.map((state) => state.id);
        formData.states = state;
        formData.willingToTravel = willingToTravel;

        console.log(formData);
        const response2 = await axios.put(
          `${baseUrl}/photographer/save/details`,
          formData,
          {
            headers: {
              "x-auth-token": token,
            },
          }
        );

        if (response2.status === 200) {
          console.log(
            "FormData sent to the second API successfully:",
            response2.data
          );
          setMessage("Form submitted successfully!");
          fetchPhotographers();
        } else {
          console.error(
            "Failed to send FormData to the second API:",
            response2.data
          );
          // Handle the error for the second API call
          // You can set an error message or take appropriate action
        }
      } catch (error) {
        // Display the error message from the API response
        console.log(error);
        // setMessage(error.response.data.error.message);
      } finally {
        setLoading(false); // Set loading back to false after API calls are done
      }
    }
    // }
  };

  const EventsList = [
    "Wedding",
    "Maternity",
    "New Born Baby",
    "Birthday",
    "Business",
  ];
  const Uploaders = [
    "Photos and Videos",
    "Maternity Photos and Videos",
    "New Born Photos and Videos",
    "Birthday Photos and Videos",
    "Business Photos and Videos",
  ];
  const [willingToTravel, setWillingToTravel] = useState("");
  const [selectedStates, setSelectedStates] = useState([]);

  console.log(willingToTravel, "willingToTravel13123123")

  const travelOptions = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ];
  const stateOptions = [
    { id: 0, value: "All India", label: "All India" },
    ...states.map((state) => ({
      id: state.id,
      value: state.name,
      label: state.name,
    })),
  ];

  const stateData = (items) => {
    setSelectedStates(items);
  };

  const other = (mode) => {

    let val = document.querySelector('#anyOther' + mode).value;
    val = val.replace("Photography", "");

    if (val && mode === 'Photo') {
      const updatedList = [...PhotographyList, val];
      setPhotographyList(updatedList);
    }

    else if (val && mode === 'Video') {
      const updatedList = [...VideographyList, val];
      setVideographyList(updatedList);
    }


  }


  const getAllStates = () => {
    const apiUrl = `${baseUrl}/state/states`;

    axios
      .get(apiUrl)
      .then((response) => {
        const data = response.data.data;
        console.log(data);
        setStates(data);
      })
      .catch((error) => {
        console.error("Error fetching states:", error);
      });
  };
  const handleTravelChange = (selectedOption) => {
    setWillingToTravel(selectedOption.value);
    setSelectedStates([]); // Reset selected states when changing the travel option
  };

  const [selectedImagesByCategory, setSelectedImagesByCategory] = useState(
    Uploaders.map(() => [])
  );


  console.log('selectedImagesByCategory', selectedImagesByCategory);
  const [uploadImagesByCategory, setUploadImagesByCategory] = useState(
    Uploaders.map(() => [])
  );


  console.log(uploadImagesByCategory);
  const [showAllImages, setShowAllImages] = useState(
    Uploaders.map(() => false)
  );

  const handleFileChange = (event, categoryIndex, SIngleUpload) => {
    // console.log(categoryIndex, "categoryIndex")
    const files = event.target.files;

    if (files.length > 0) {
      // Process each selected file
      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        // Create a FileReader to read the selected image file
        const reader = new FileReader();

        reader.onload = (e) => {
          const imagePreview = e.target.result;

          // Add the selected image and its preview URL to the state for the specific category
          setSelectedImagesByCategory((prevImagesByCategory) => {
            const newImagesByCategory = [...prevImagesByCategory];
            newImagesByCategory[categoryIndex] = [
              ...prevImagesByCategory[categoryIndex],
              imagePreview,
            ];

            return newImagesByCategory;
          });
          setUploadImagesByCategory((prevImagesByCategory) => {
            const uploadNewImagesByCategory = [...prevImagesByCategory];
            uploadNewImagesByCategory[categoryIndex] = [
              ...prevImagesByCategory[categoryIndex],
              file,
            ];

            return uploadNewImagesByCategory;
          });
        };

        reader.readAsDataURL(file);
      }
    }
    // Check if the number of selected images exceeds 10
    if (selectedImagesByCategory[categoryIndex].length > 7) {
      // Set showAllImages to true for this category
      setShowAllImages((prevShowAllImages) => {
        const newShowAllImages = [...prevShowAllImages];
        newShowAllImages[categoryIndex] = true;
        return newShowAllImages;
      });
    }
  };

  const handleRemoveImage = (categoryIndex, imageIndex) => {
    // Remove the selected image from the state for the specific category
    setSelectedImagesByCategory((prevImagesByCategory) => {
      const newImagesByCategory = [...prevImagesByCategory];
      newImagesByCategory[categoryIndex].splice(imageIndex, 1);
      return newImagesByCategory;
    });
  };

  const handleSeeAllClick = (categoryIndex) => {
    setAllPhotos(selectedImagesByCategory[categoryIndex]);
    setShowAllPhotosModal(true);
    // Set showAllImages to true for this category
    setShowAllImages((prevShowAllImages) => {
      const newShowAllImages = [...prevShowAllImages];
      newShowAllImages[categoryIndex] = true;
      return newShowAllImages;
    });
  };


  const fetchPhotographers = () => {
    // Define the API URL
    const apiUrl = `${baseUrl}/photographer/${userId}/details`;
    const headers = {
      "x-auth-token": token, // Replace with your actual token
    };

    axios
      .get(apiUrl, {
        headers: headers, // Pass the headers object here
      })
      .then((response) => {
        console.log(response.data.data);
        setPhotographer(response.data.data);
        const photographerData = response.data.data;
        console.log(photographerData.typesOfPhotography);

        const existingTypes = new Set(PhotographyList);
        const newTypes = photographerData?.Photographer?.typesOfPhotography || [];
        const cleanedExistingTypes = Array.from(existingTypes).map(type => type.replace(/Photography/g, '').trim());

        const uniqueNewTypes = newTypes
          .map(type => type.replace(/Photography/g, '').trim());

        const filteredNewTypes = uniqueNewTypes.filter(type => !cleanedExistingTypes.includes(type));

        const updatedList = [...PhotographyList, ...filteredNewTypes];

        setPhotographyList(updatedList);


        const existingVideoTypes = new Set(VideographyList);
        const newVideoTypes = photographerData?.Photographer?.typesOfVideography || [];
        const cleanedVideoExistingTypes = Array.from(existingVideoTypes).map(type => type.replace(/Photography/g, '').trim());

        const uniqueNewVideoTypes = newVideoTypes
          .map(type => type.replace(/Videography/g, '').trim());

        const filteredNewVideoTypes = uniqueNewVideoTypes.filter(type => !cleanedVideoExistingTypes.includes(type));

        const updatedVideoList = [...VideographyList, ...filteredNewVideoTypes];

        setVideographyList(updatedVideoList);


        setFormData({
          studioName: photographerData?.Photographer?.studioName || '',
          serviceLocation: photographerData?.Photographer?.serviceLocation || '',
          languagesKnown: photographerData?.Photographer?.languagesKnown || '',
          typesOfPhotography: photographerData?.Photographer?.typesOfPhotography || [],
          typesOfVideography: photographerData?.Photographer?.typesOfVideography || [],
          businessLink: photographerData?.Photographer?.businessLink || '',
          anyHighligths: photographerData?.Photographer?.anyHighligths || '',
          aboutYou: photographerData?.Photographer?.aboutYou || '',
          individualOrTeam: photographerData?.Photographer?.individualOrTeam || '',
          numberOfTeamMembers: photographerData?.Photographer?.numberOfTeamMembers || '',
          deliveryTime: photographerData?.Photographer?.deliveryTime || ''
        });

        setSelectedStates(photographer?.Photographer?.states);
        setWillingToTravel(photographer?.Photographer?.willingToTravel);


        const data = Object.keys(photographerData.Photographer?.category);
        const category = [];

        data.forEach((id) => {
          if (id === '17') {
            category.push('EventsList0');
          } else if (id === '18') {
            category.push('EventsList1');
          } else if (id === '22') {
            category.push('EventsList2');
          } else if (id === '24') {
            category.push('EventsList3');
          } else {
            category.push('EventsList4')
          }
        });

        setOccasionCategories(category);
        setCheckedEvents(category);

        // const images = [
        //   [],
        //   [],
        //   [],
        //   [],
        //   [],
        // ];

        const img = photographerData?.Photographer?.category;
        setCategoryImages(img);
        console.log(img, "undifendddd")

        const images = Array.from({ length: 5 }, () => []);

        // Assuming you have received data from the API
        const dynamicIndex = 17 || 18 || 22 || 24 || 25; // This could be any dynamic index, such as 20

        // Check if img[dynamicIndex] exists and has the required properties
        if (img[dynamicIndex] && img[dynamicIndex].image && img[dynamicIndex].video) {
          const imageLinks = img[dynamicIndex].image || [];
          const videoLinks = img[dynamicIndex].video || [];

          // Assuming you want to add the image links to the first index of the images array
          images[0] = images[0].concat(imageLinks, videoLinks);
        } else {
          console.error(`Invalid or missing data at index ${dynamicIndex}`);
        }

        // if (img['17']) {
        //   images[0] = images[0].concat(img[17]?.images, img[17]?.videos);
        // }

        // if (img[18]) {
        //   images[1] = images[1].concat(img[18]?.images, img[18]?.videos);
        // }

        // if (img[22]) {
        //   images[2] = images[2].concat(img[22]?.images, img[22]?.videos);
        // }

        // if (img[24]) {
        //   images[3] = images[3].concat(img[24]?.images, img[24]?.videos);
        // }

        // if (img[25]) {
        //   images[4] = images[4].concat(img[25]?.images, img[25]?.videos);
        // }

        //  images?.reduce((acc, currentArray) => acc?.concat(currentArray), [])?.filter(element => element !== undefined);

        console.log(images, "imagesssssss")


        setSelectedImagesByCategory(images);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    fetchPhotographers(token);
  }, [token]);

  return (
    <>
      <Header />
      <Banner home="Home" currenturl="/profile" current="My Profile" />

      <div className="photographer-page">
        <section className="profile-photographer mt-5">
          <div className="bootstrap-container relative">
            <h1 className="mt-lg-5 text-center position-relative text-main">
              <button
                className="site-btn position-md-absolute d-block w-fit"
                style={{ left: "12px" }}
                onClick={() => navigate(-1)}
              >
                <i
                  className="fa fa-angle-left text-white"
                  style={{ fontSize: "23px" }}
                ></i>
              </button>
              My Profile
            </h1>

          </div>
          <hr className="mb-5" />
          <div className="bootstrap-container py-5 mt-t">
            <div className="col-xl-10 col-lg-11 mx-auto">
              {loading ? (
                <div className="preloader">
                  <div className="loader-start">
                    <img src={LoaderIcon} alt="Loading..." />
                  </div>
                </div>
              ) : (
                <div className="col-lg-10">
                  <div className="profile-update-start">
                    {!formSubmitted ? (
                      <form onSubmit={handleUpdate}>
                        {message && (
                          <div
                            className={`mb-2 text-${message.includes("success") ? "success" : "danger"
                              }`}
                          >
                            {message}
                          </div>
                        )}

                        {Object.keys(errors).length > 0 && (
                          <div className="mb-2 text-danger">
                            Please fill all required fields.
                          </div>
                        )}

                        <div className="border-b-2 border-main mb-4">
                          <p className="bg-main p-2 w-max text-white text-lg">
                            Basic Details
                          </p>
                        </div>

                        <div className="mb-3 form-group">
                          <div className="row">
                            <div className="col-md-3">
                              <label htmlFor="studioName">Studio Name *</label>
                            </div>
                            <div className="col-md-9">
                              <div className="input-group mb-3">
                                <input
                                  type="text"
                                  className={`form-control ${errors.studioName &&
                                    "is-invalid"}`}
                                  placeholder="Studio Name"
                                  name="studioName"
                                  value={formData.studioName}
                                  onChange={handleInputChange}
                                />
                              </div>
                              {errors.studioName && (
                                <div className="text-danger">
                                  {errors.studioName}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="mb-3 form-group">
                          <div className="row">
                            <div className="col-md-3">
                              <label htmlFor="serviceLocation">
                                Service Location
                              </label>
                            </div>
                            <div className="col-md-9">
                              <div className="input-group mb-3">
                                <input
                                  type="text"
                                  name="serviceLocation"
                                  value={formData.serviceLocation}
                                  className={`form-control ${errors.serviceLocation &&
                                    "is-invalid"}`}
                                  placeholder="Service Location"
                                  onChange={handleInputChange}
                                />
                              </div>
                              {errors.serviceLocation && (
                                <div className="text-danger">
                                  {errors.serviceLocation}
                                </div>
                              )}
                            </div>
                            {/* <div className="col-md-9">
                              <div className="input-group mb-3">
                                <select
                                  name="serviceLocation"
                                  value={formData.serviceLocation}
                                  className={`form-control form-select ${errors.serviceLocation &&
                                    "is-invalid"}`}
                                  onChange={handleInputChange}
                                >
                                  <option value="">Service Location</option>
                                  <option value="loc1" {...photographer?.Photographer?.serviceLocation == "loc1" ? 'selected' : ''}>loc1</option>
                                  <option value="loc2" {...photographer?.Photographer?.serviceLocation == "loc2" ? 'selected' : ''}>loc2</option>
                                </select>
                              </div>
                              {errors.serviceLocation && (
                                <div className="text-danger">
                                  {errors.serviceLocation}
                                </div>
                              )}
                            </div> */}
                          </div>
                        </div>

                        <div className="mb-3 form-group">
                          <div className="row">
                            <div className="col-md-3">
                              <label htmlFor="languagesKnown">
                                Languages Known
                              </label>
                            </div>
                            <div className="col-md-9">
                              <div className="input-group mb-3">
                                <select
                                  name="languagesKnown"
                                  value={formData.languagesKnown}
                                  className={`form-control form-select ${errors.languagesKnown &&
                                    "is-invalid"}`}
                                  onChange={handleInputChange}
                                >
                                  <option value="">Languages Known</option>
                                  <option value="lang1" {...photographer?.Photographer?.serviceLocation == "lang1" ? 'selected' : ''}>lang1</option>
                                  <option value="lang2" {...photographer?.Photographer?.serviceLocation == "lang2" ? 'selected' : ''}>lang2</option>
                                </select>
                              </div>
                              {errors.languagesKnown && (
                                <div className="text-danger">
                                  {errors.languagesKnown}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="mb-3 form-group">
                          <div className="row">
                            <div className="col-md-3">
                              <label>Type of Photography *</label>
                              {errors.typesOfPhotography && (
                                <div className="text-danger">
                                  {errors.typesOfPhotography}
                                </div>
                              )}
                            </div>
                            <div className="col-md-9">
                              <div
                                className={`input-group mb-3 ${errors.typesOfPhotography &&
                                  "is-invalid"}`}
                              >
                                <div className="main-select-check">
                                  {PhotographyList.map((singlecheck, index) => (
                                    <div className="single-check" key={index}>
                                      <label
                                        className="check-container"
                                        htmlFor={`photography${index}`}
                                      >
                                        {singlecheck} Photography
                                        <input
                                          type="checkbox"
                                          name="typesOfPhotography"
                                          id={`photography${index}`}
                                          value={`${singlecheck} Photography`}
                                          checked={formData.typesOfPhotography.includes(
                                            singlecheck + " Photography"
                                          )}
                                          onChange={handleInputChange}
                                        />
                                        <span className="checkmark"></span>
                                      </label>
                                    </div>
                                  ))}
                                </div>
                                <div className="flex items-center gap-3 w-full">
                                  <label htmlFor="anyOtherPhoto">
                                    Any other?
                                  </label>
                                  <input
                                    type="text"
                                    name="anyOtherPhoto"
                                    id="anyOtherPhoto"
                                    className="px-2 py-1 border-gray-200 border outline-none"
                                  />
                                  <button type="button" className="w-6 h-6 rounded-full bg-green-500 text-white text-center" onClick={() => other("Photo")}>
                                    <p className="font-bold">+</p>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="mb-3 form-group">
                          <div className="row">
                            <div className="col-md-3">
                              <label>Type of Videography *</label>
                              {errors.typesOfVideography && (
                                <div className="text-danger">
                                  {errors.typesOfVideography}
                                </div>
                              )}
                            </div>
                            <div className="col-md-9">
                              <div
                                className={`input-group mb-3 ${errors.typesOfVideography &&
                                  "is-invalid"}`}
                              >
                                <div className="main-select-check">
                                  {VideographyList.map((singlecheck, index) => (
                                    <div className="single-check" key={index}>
                                      <label
                                        className="check-container"
                                        htmlFor={`videography${index}`}
                                      >
                                        {singlecheck} Videography
                                        <input
                                          type="checkbox"
                                          name="typesOfVideography"
                                          id={`videography${index}`}
                                          value={`${singlecheck} Videography`}
                                          checked={formData.typesOfVideography.includes(
                                            singlecheck + " Videography"
                                          )}
                                          onChange={handleInputChange}
                                        />
                                        <span className="checkmark"></span>
                                      </label>
                                    </div>
                                  ))}
                                </div>
                                <div className="flex items-center gap-3 w-full">
                                  <label htmlFor="anyOtherVideo">
                                    Any other?
                                  </label>
                                  <input
                                    type="text"
                                    name="anyOtherVideo"
                                    id="anyOtherVideo"
                                    className="px-2 py-1 border-gray-200 border outline-none"
                                  />
                                  <button type="button" className="w-6 h-6 rounded-full bg-green-500 text-white text-center" onClick={() => other("Video")}>
                                    <p className="font-bold">+</p>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="border-b-2 border-main mb-4">
                          <p className="bg-main p-2 w-max text-white text-lg">
                            Upload Sample Footages
                          </p>
                        </div>

                        <div className="mb-3 form-group">
                          <div className="row">
                            <div className="col-md-3">
                              <label>Events List </label>
                            </div>
                            <div className="col-md-9">
                              <div className="input-group mb-3">
                                <div className="main-select-check">
                                  {EventsList.map((singelevent, index) => (
                                    <div className="single-check" key={index}>
                                      <label
                                        className="check-container"
                                        htmlFor={`EventsList${index}`}
                                      >
                                        {singelevent}
                                        <input
                                          type="checkbox"
                                          name="EventsListinput"
                                          id={`EventsList${index}`}
                                          onChange={handleCheckboxChange}
                                          checked={checkedEvents.includes(
                                            `EventsList${index}`
                                          )}
                                        />
                                        <span className="checkmark"></span>
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {Uploaders.map((SIngleUpload, categoryIndex) => (
                          <div className="mb-3 form-group" key={categoryIndex}>
                            {checkedEvents.includes(
                              `EventsList${categoryIndex}`
                            ) && (
                                <>
                                  <div className="row align-items-center">
                                    <div className="col-md-3">
                                      <label>{SIngleUpload}</label>
                                    </div>
                                    <div className="col-md-9 ps-md-5">
                                      <div className="input-group mb-3">
                                        {/* <div className="showd-images-in-photographer">
                                        {selectedImagesByCategory[categoryIndex]
                                          .slice(
                                            0,
                                            showAllImages[categoryIndex]
                                              ? undefined
                                              : 7
                                          )
                                          .map((image, imageIndex) => (
                                            <div
                                              key={imageIndex}
                                              className="image-preview"
                                            >
                                              <img src={image} alt="Preview" />
                                              <button
                                                className="remove-button"
                                                type="button"
                                                onClick={() =>
                                                  handleRemoveImage(
                                                    categoryIndex,
                                                    imageIndex
                                                  )
                                                }
                                              >
                                                <i className="fa fa-close"></i>
                                              </button>
                                            </div>
                                          ))}
                                        {selectedImagesByCategory[categoryIndex]
                                          .length > 7 &&
                                          !showAllImages[categoryIndex] && (
                                            <button
                                              className="see-all-button"
                                              type="button"
                                              onClick={() =>
                                                handleSeeAllClick(categoryIndex)
                                              }
                                            >
                                              See All
                                            </button>
                                          )}
                                      </div> */}
                                        <div className="showd-images-in-photographer">
                                          {selectedImagesByCategory[
                                            categoryIndex
                                          ].map((image, imageIndex) => {
                                            console.log(image, "imagesss")
                                            return (
                                              <div
                                                key={imageIndex}
                                                className="image-preview"
                                              >
                                                <img src={image} alt="Preview" />
                                                <button
                                                  className="remove-button"
                                                  type="button"
                                                  onClick={() =>
                                                    handleRemoveImage(
                                                      categoryIndex,
                                                      imageIndex
                                                    )
                                                  }
                                                >
                                                  <i className="fa fa-close"></i>
                                                </button>
                                              </div>
                                            )
                                          })}
                                        </div>
                                        <label
                                          htmlFor={`coverupload${categoryIndex}`}
                                          // className="d-block"
                                          style={{ width: "70px" }}
                                        >
                                          <span className="image-here d-block">
                                            {selectedImagesByCategory[
                                              categoryIndex
                                            ]?.length > 0 ? (
                                              <div
                                                type="button"
                                                className="text-sm rounded-md my-1 w-max bg-green-500 px-4 py-2 text-white"
                                              >
                                                Upload More
                                              </div>
                                            ) : (
                                              <img src={UploadIcon} alt="" />
                                            )}
                                            <input
                                              type="file"
                                              name=""
                                              hidden
                                              id={`coverupload${categoryIndex}`}
                                              multiple
                                              onChange={(e) =>
                                                handleFileChange(e, categoryIndex)
                                              }
                                            />
                                          </span>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                          </div>
                        ))}
                        <div className="border-b-2 border-main mb-4">
                          <p className="bg-main p-2 w-max text-white text-lg">
                            Other Details
                          </p>
                        </div>

                        <div className="mb-3 form-group">
                          <div className="row">
                            <div className="col-md-3">
                              <label htmlFor="">Willing to Travel</label>
                            </div>
                            <div className="col-md-9">
                              <div className="input-group mb-3">
                                <Select
                                  options={travelOptions}
                                  onChange={handleTravelChange}
                                  defaultInputValue={willingToTravel}
                                  value={travelOptions.find(
                                    (option) => option.value === willingToTravel
                                  )}
                                  className="w-100 d-block"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        {willingToTravel === "Yes" && (
                          <div className="mb-3 form-group">
                            <div className="row">
                              <div className="col-md-3">
                                <label htmlFor="">
                                  Which State You can travel
                                </label>
                              </div>
                              <div className="col-md-9">
                                <div className="input-group mb-3">
                                  <Select
                                    isMulti
                                    options={stateOptions}
                                    value={selectedStates}
                                    onChange={stateData}
                                    className="w-100"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="mb-3 form-group">
                          <div className="row">
                            <div className="col-md-3">
                              <label>Delivery Time </label>
                            </div>
                            <div className="col-md-9">
                              <div className="input-group mb-3">
                                <select
                                  name="deliveryTime"
                                  value={formData.deliveryTime}
                                  className={`form-control form-select ${errors.deliveryTime &&
                                    "is-invalid"}`}
                                  onChange={handleInputChange}
                                >
                                  <option value="">Select</option>
                                  <option value="30-60 days">
                                    30 - 60 days
                                  </option>
                                  <option value="60-90 days">
                                    60 - 90 days
                                  </option>
                                  <option value="90-120 days">
                                    90 - 120 days
                                  </option>
                                </select>
                              </div>
                              {errors.deliveryTime && (
                                <div className="text-danger">
                                  {errors.deliveryTime}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="mb-3 form-group">
                          <div className="row">
                            <div className="col-md-3">
                              <label>Are you an individual/ a team ?*</label>
                            </div>
                            <div className="col-md-9">
                              <div className="input-group mb-3">
                                <select
                                  name="individualOrTeam"
                                  value={formData.individualOrTeam}
                                  className={`form-control form-select ${errors.individualOrTeam &&
                                    "is-invalid"}`}
                                  onChange={handleInputChange}
                                >
                                  <option value="">Select</option>
                                  <option value="Individual">Individual</option>
                                  <option value="Team">Team</option>
                                </select>
                              </div>
                              {errors.individualOrTeam && (
                                <div className="text-danger">
                                  {errors.individualOrTeam}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        {formData?.individualOrTeam === "Team" && (
                          <div className="mb-3 form-group">
                            <div className="row">
                              <div className="col-md-3">
                                <label>Number of members in the team</label>
                              </div>
                              <div className="col-md-9">
                                <div className="input-group mb-3">
                                  <input
                                    type="text"
                                    className={`form-control ${errors.numberOfTeamMembers &&
                                      "is-invalid"}`}
                                    placeholder="20"
                                    name="numberOfTeamMembers"
                                    value={formData.numberOfTeamMembers}
                                    onChange={handleInputChange}
                                  />
                                </div>
                                {errors.numberOfTeamMembers && (
                                  <div className="text-danger">
                                    {errors.numberOfTeamMembers}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}

                        <div className="mb-3 form-group">
                          <div className="row">
                            <div className="col-md-3">
                              <label>Business google link*</label>
                            </div>
                            <div className="col-md-9">
                              <div className="input-group mb-3">
                                <input
                                  type="text"
                                  className={`form-control ${errors.businessLink &&
                                    "is-invalid"}`}
                                  placeholder=""
                                  name="businessLink"
                                  value={formData.businessLink}
                                  onChange={handleInputChange}
                                />
                              </div>
                              {errors.businessLink && (
                                <div className="text-danger">
                                  {errors.businessLink}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="mb-3 form-group">
                          <div className="row">
                            <div className="col-md-3">
                              <label>
                                Any highlights about your services *
                              </label>
                            </div>
                            <div className="col-md-9">
                              <div className="input-group mb-3">
                                <textarea
                                  name="anyHighligths"
                                  rows="4"
                                  className={`form-control resize-none ${errors.anyHighligths &&
                                    "is-invalid"}`}
                                  value={formData.anyHighligths}
                                  onChange={handleInputChange}
                                ></textarea>
                              </div>
                              {errors.anyHighligths && (
                                <div className="text-danger">
                                  {errors.anyHighligths}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="mb-3 form-group">
                          <div className="row">
                            <div className="col-md-3">
                              <label>About You *</label>
                            </div>
                            <div className="col-md-9">
                              <div className="input-group mb-3">
                                <textarea
                                  name="aboutYou"
                                  rows="3"
                                  className={`form-control resize-none ${errors.aboutYou &&
                                    "is-invalid"}`}
                                  onChange={(e) => {
                                    handleInputChange(e);
                                  }}
                                  disabled={!generateAbout}
                                  readOnly={generateAbout}
                                  value={generateAbout ? aboutYouText : formData.aboutYou}
                                ></textarea>
                              </div>
                              {errors.aboutYou && (
                                <div className="text-danger">
                                  {errors.aboutYou}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="text-end mb-4">
                          <button
                            type="button"
                            className="py-2 text-white rounded-md text-sm px-5 !bg-main"
                            onClick={() => setGenerateAbout(true)}
                          >
                            Generate About
                          </button>
                        </div>

                        <div className="mb-3 form-group">
                          <div className="row">
                            <div className="col-md-3">
                              <label>
                                Any Additional Info you would like to provide us
                                ?
                              </label>
                            </div>
                            <div className="col-md-9">
                              <div className="input-group mb-3">
                                <textarea
                                  name="additionalInfo"
                                  rows="3"
                                  // className={`form-control resize-none ${errors.aboutYou &&
                                  //   "is-invalid"}`}
                                  className={`form-control resize-none`}
                                // onChange={(e) => {
                                //   handleInputChange(e);
                                // }}
                                // disabled={!generateAbout}
                                // readOnly={generateAbout}
                                // value={generateAbout ? aboutYouText : ""}
                                ></textarea>
                              </div>
                              {/* {errors.aboutYou && (
                                <div className="text-danger">
                                  {errors.aboutYou}
                                </div>
                              )} */}
                            </div>
                          </div>
                        </div>

                        <div className="mb-3 form-group">
                          <div className="row">
                            <div className="input-group mb-3">
                              <div className="main-select-check text-start">
                                <div className="single-check w-100">
                                  <label
                                    className="check-container"
                                    htmlFor={`EventsListabcd`}
                                  >
                                    By checking this box, I acknowledge that I
                                    have read, understood, and agree to abide by
                                    the Terms and Conditions of Boopho.com. I
                                    understand that my use of the platform is
                                    subject to these terms, and I agree to
                                    comply with all applicable laws and
                                    regulations. I also acknowledge that my
                                    personal information will be handled in
                                    accordance with the Privacy Policy of the
                                    platform.
                                    <input
                                      type="checkbox"
                                      name="EventsListinput"
                                      id={`EventsListabcd`}
                                      onChange={handleInputChange}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <button
                          type="submit"
                          className="mx-auto d-block bg-green-500 px-5 py-2 text-white rounded-md text-sm"
                        // disabled={!generateAbout}
                        >
                          Submit the details
                        </button>
                      </form>
                    ) : (
                      <PopupMessage message="Parposal sent to the admin now you have to wait for approval email " />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
        <Footer />
      </div>
      {/* <MorePhotos
        showModal={showAllPhotosModal}
        setShowModal={setShowAllPhotosModal}
        morePhotos={allPhotos}
      /> */}
    </>
  );
};
export default PhotographerProfile;
