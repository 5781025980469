import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router";
import { Link } from "react-router-dom";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import TheDate from "../../Components/TheDate";
import SortFilter from "../../Components/SortFilter";
import Pagination from "../../Components/Pagination";
import axios from "axios";
import { Baricon } from "../../Constant/Index";
import { toast, ToastContainer } from "react-toastify";
import { IoLocationSharp } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { getDynamicFiltersAsync } from "../../Redux/slice/mainSlice";

const PhotographerSearch = () => {
  const [wish, setWish] = useState([]);
  const [showFilter, SetShowFilter] = useState(false);
  const [selectedSortFilter, setSelectedSortFilter] = useState("Featured");
  const [selectedFilter, SetSelectedFilter] = useState(0);
  const [priceFilter, setPriceFilter] = useState("");
  const [ratingFilter, setRatingFilter] = useState("");
  const [photoFilter, setPhotoFilter] = useState([]);
  const [videoFilter, setVideoFilter] = useState([]);
  const [discountFilter, setDiscountFilter] = useState("");
  const [deliveryFilter, setDeliveryFilter] = useState("");
  const [locationFilter, setLocationFilter] = useState("");
  const [photographer, setPhotographer] = useState([]);
  const [categoryName, setCategoryName] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem("loggedIn") === "true"
  );
  const id = useParams();
  console.log(id, "IDIDDIDIDIIDID");
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const startDate = params.get("startDate");
  const endDate = params.get("endDate");
  const city = params.get("city");
  const dispatch = useDispatch();
  const [dynamicServices, setDynamicServices] = useState();

  useEffect(() => {
    dispatch(getDynamicFiltersAsync())
      .then((x) => {
        console.log(x, "FILTERS");
        setDynamicServices(x?.payload?.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  console.log(photographer.count, "countssssssss");

  const photoGrapherSearch = () => {
    let fromPrice = "";
    let toPrice = "";
    if (priceFilter) {
      const priceRangeArray = priceFilter.split("-");
      fromPrice = priceRangeArray[0].trim();
      toPrice = priceRangeArray[1].trim();
    }

    console.log(fromPrice, toPrice);

    const categoryId = id.type;
    const priceFrom = fromPrice;
    const priceTo = toPrice;

    let photographyServicesStr = '["' + photoFilter.join('","') + '"]';
    let videographyServicesStr = '["' + videoFilter.join('","') + '"]';

    const params = {
      categoryId: categoryId,
      priceFrom: priceFrom,
      priceTo: priceTo,
      photographyServices: photoFilter.length > 0 ? photographyServicesStr : [],
      videographyServices: videoFilter.length > 0 ? videographyServicesStr : [],
      discount: discountFilter,
      location: locationFilter,
      rating: ratingFilter,
      startDate: localStorage.getItem("startDate"),
      endDate: localStorage.getItem("endDate"),
      deliveryTime: deliveryFilter,
      limit: itemsPerPage,
      offset: (currentPage - 1) * itemsPerPage, // Add other filter parameters here
    };

    console.log("params", params);
    axios
      .get(`${baseUrl}/photographer/search`, { params })
      .then((response) => {
        console.log(response.data.data.data);
        setPhotographer(response.data.data);
        setTotalItems(response.data.data.count);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  };

  useEffect(() => {
    // photoGrapherSearch();

    if (id.type == 17) {
      setCategoryName("Wedding");
    } else if (id.type == 18) {
      setCategoryName("Maternity");
    } else if (id.type == 22) {
      setCategoryName("New-Born Baby");
    } else if (id.type == 24) {
      setCategoryName("Birthday");
    } else {
      setCategoryName("Business");
    }

    // if (hasFilterChanged) {
    photoGrapherSearch();
    // }
  }, [
    priceFilter,
    locationFilter,
    photoFilter,
    videoFilter,
    discountFilter,
    ratingFilter,
    deliveryFilter,
    currentPage,
    itemsPerPage,
  ]);

  const toggleFilters = () => {
    SetShowFilter(!showFilter);
  };

  const handleFilterChange = (newValue) => {
    SetSelectedFilter(newValue);
  };

  const handlePriceFilter = (value) => {
    console.log(value);
    setPriceFilter(value);
    // setHasFilterChanged(true);
  };

  const handleRatingFilter = (value) => {
    console.log(value);
    setRatingFilter(value);
    // setHasFilterChanged(true);
  };
  const handlePhotoFilters = (value) => {
    const updatedFilters = [...photoFilter];

    const filterIndex = updatedFilters.indexOf(value);

    if (filterIndex === -1) {
      updatedFilters.push(value);
    } else {
      updatedFilters.splice(filterIndex, 1);
    }

    const updatedFiltersWithQuotes = updatedFilters.map((filter) => {
      return filter.replace(/'/g, '"');
    });

    setPhotoFilter(updatedFiltersWithQuotes);

    // Add this console.log statement to see the updated state
    console.log("Updated Photo Filters: ", updatedFiltersWithQuotes);
  };

  const handleSearch = (selectedEndDate, selectedStartDate, selectedCity) => {
    localStorage.setItem("startDate", selectedStartDate);
    localStorage.setItem("endDate", selectedEndDate);
    localStorage.setItem("city", selectedCity);

    window.location.href = "search";
  };

  const handleVideoFilters = (value) => {
    console.log(value);
    const updatedFilters = [...videoFilter]; // Create a copy of the existing array
    const filterIndex = updatedFilters.indexOf(value);

    if (filterIndex === -1) {
      updatedFilters.push(value);
    } else {
      updatedFilters.splice(filterIndex, 1);
    }

    setVideoFilter(updatedFilters);
    // setHasFilterChanged(true);
  };

  const handleDiscountFilter = (value) => {
    console.log(value);
    setDiscountFilter(value);
    // setHasFilterChanged(true);
  };

  const handleDeliveryFilter = (value) => {
    console.log(value);
    setDeliveryFilter(value);
    // setHasFilterChanged(true);
  };

  const handleLocationFilter = (value) => {
    console.log(value);
    setLocationFilter(value);
    // setHasFilterChanged(true);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const toggleWishItem = (index) => {
    setWish((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = !newStates[index];
      // newStates[index]
      //   ? toast.success("Added to wishlist!", {
      //       position: toast.POSITION.TOP_RIGHT,
      //       autoClose: 3000, // Auto close the toast after 3 seconds
      //       hideProgressBar: false,
      //       closeOnClick: true,
      //       pauseOnHover: true,
      //     })
      //   : toast.error("Removed From WIshlist!", {
      //       position: toast.POSITION.TOP_RIGHT,
      //       autoClose: 3000, // Auto close the toast after 3 seconds
      //       hideProgressBar: false,
      //       closeOnClick: true,
      //       pauseOnHover: true,
      //     });
      return newStates;
    });
    // const showToast = () => {

    // };
  };

  const clearFilters = () => {
    setDeliveryFilter("");
    setDiscountFilter("");
    setLocationFilter("");
    setPriceFilter("");
    setRatingFilter("");
    setPhotoFilter([]);
    setVideoFilter([]);
  };
  return (
    <>
      <Header />
      <section className="filters-section">
        <div className="bootstrap-container">
          <div className="filters-start">
            <h2 className="text-center mb-3 wow animate__animated animate__rubberBand ">
              Premium {categoryName} photographers in Your location{" "}
            </h2>
          </div>
          <TheDate
            handleSearch={(selectedEndDate, selectedStartDate, selectedCity) =>
              handleSearch(selectedEndDate, selectedStartDate, selectedCity)
            }
            defaultDates={{
              startDate: localStorage.getItem("startDate"),
              endDate: localStorage.getItem("endDate"),
            }}
          />
          <div className="filter-list-2 mt-4 flex-column">
            <div className="normal-filter wow animate__animated animate__fadeInLeft  me-auto">
              <button
                className="clear-filter btn btn-warning me-auto px-0"
                onClick={clearFilters}
              >
                Clear All Filter
              </button>
            </div>
            <div
              className="w-full normal-filter wow animate__animated animate__fadeInLeft position-relative me-auto"
              onMouseLeave={() => SetShowFilter(false)}
            >
              <div className="w-full flex justify-between">
                <div className="flex gap-2">
                  <button
                    className="filters-here clear-filter"
                    onClick={toggleFilters}
                  >
                    Filters <i className="fa fa-angle-down ps-3"></i>
                  </button>
                  <div className="flex flex-wrap gap-2">
                    {priceFilter && (
                      <button
                        className="bg-main text-white  rounded-lg p-2 text-xs"
                        onClick={() => setPriceFilter("")}
                      >
                        {priceFilter}
                        <span className="ml-2 text-red-500 font-bold">X</span>
                      </button>
                    )}

                    {ratingFilter && (
                      <button
                        className="bg-main text-white  rounded-lg p-2 text-xs"
                        onClick={() => setRatingFilter("")}
                      >
                        {ratingFilter}
                        <span className="ml-2 text-red-500 font-bold">X</span>
                      </button>
                    )}
                    {photoFilter &&
                      photoFilter.map((item, index) => (
                        <button
                          className="bg-main text-white  rounded-lg p-2 text-xs"
                          key={index}
                          onClick={() => {
                            const updatedPhotoFilters = photoFilter.filter(
                              (f, i) => i != index
                            );
                            setPhotoFilter(updatedPhotoFilters);
                          }}
                        >
                          {item}
                          <span className="ml-2 text-red-500 font-bold">X</span>
                        </button>
                      ))}
                    {videoFilter &&
                      videoFilter.map((item, index) => (
                        <button
                          className="bg-main text-white  rounded-lg p-2 text-xs"
                          key={index}
                          onClick={() => {
                            const updatedVideoFilters = videoFilter.filter(
                              (f, i) => i != index
                            );
                            setVideoFilter(updatedVideoFilters);
                          }}
                        >
                          {item}
                          <span className="ml-2 text-red-500 font-bold">X</span>
                        </button>
                      ))}
                    {discountFilter && (
                      <button
                        className="bg-main text-white  rounded-lg p-2 text-xs"
                        onClick={() => setDiscountFilter("")}
                      >
                        {discountFilter}
                        <span className="ml-2 text-red-500 font-bold">X</span>
                      </button>
                    )}
                    {deliveryFilter && (
                      <button
                        className="bg-main text-white  rounded-lg p-2 text-xs"
                        onClick={() => setDeliveryFilter("")}
                      >
                        {deliveryFilter}
                        <span className="ml-2 text-red-500 font-bold">X</span>
                      </button>
                    )}
                    {locationFilter && (
                      <button
                        className="bg-main text-white  rounded-lg p-2 text-xs"
                        onClick={() => setLocationFilter("")}
                      >
                        {locationFilter}
                        <span className="ml-2 text-red-500 font-bold">X</span>
                      </button>
                    )}
                  </div>
                </div>
                <SortFilter
                  selectedSortFilter={selectedSortFilter}
                  setSelectedSortFilter={setSelectedSortFilter}
                  options={["Featured", "Low to High", "High to Low"]}
                />
              </div>
              {showFilter && (
                <>
                  <div class="d-flex flex-column flex-shrink-0 p-3 bg-light the-first-filters">
                    <ul class="nav nav-pills flex-column mb-auto">
                      <li class="nav-item">
                        <button
                          className={`inner-btn ${
                            selectedFilter === 0 ? "active" : ""
                          }`}
                          onClick={() => handleFilterChange(0)}
                        >
                          Price per day
                        </button>
                      </li>
                      <li>
                        <button
                          className={`inner-btn ${
                            selectedFilter === 1 ? "active" : ""
                          }`}
                          onClick={() => handleFilterChange(1)}
                        >
                          Rating
                        </button>
                      </li>
                      <li class="nav-item">
                        <button
                          className={`inner-btn ${
                            selectedFilter === 2 ? "active" : ""
                          }`}
                          onClick={() => handleFilterChange(2)}
                        >
                          Services
                        </button>
                      </li>
                      <li>
                        <button
                          className={`inner-btn ${
                            selectedFilter === 3 ? "active" : ""
                          }`}
                          onClick={() => handleFilterChange(3)}
                        >
                          Discount
                        </button>
                      </li>
                      {/* <li>
                        <button
                          className={`inner-btn ${
                            selectedFilter === 4 ? "active" : ""
                          }`}
                          onClick={() => handleFilterChange(4)}
                        >
                          Travel
                        </button>
                      </li> */}
                      <li class="nav-item">
                        <button
                          className={`inner-btn ${
                            selectedFilter === 5 ? "active" : ""
                          }`}
                          onClick={() => handleFilterChange(5)}
                        >
                          Delivery Time
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div class="d-flex flex-column flex-shrink-0 p-3 bg-light the-first-filters second">
                    {selectedFilter == 0 && (
                      <ul class="nav nav-pills flex-column mb-auto">
                        <li
                          className={`nav-item ${
                            priceFilter === "0 - 9999"
                              ? "bg-main text-white"
                              : ""
                          }`}
                        >
                          <button
                            className="inner-btn"
                            onClick={() => handlePriceFilter("0 - 9999")}
                          >
                            Less than 10,000
                          </button>
                        </li>
                        <li
                          className={`nav-item ${
                            priceFilter === "10000 - 20000"
                              ? "bg-main text-white"
                              : ""
                          }`}
                        >
                          <button
                            className="inner-btn"
                            onClick={() => handlePriceFilter("10000 - 20000")}
                          >
                            10,000 - 20,000
                          </button>
                        </li>
                        <li
                          className={`nav-item ${
                            priceFilter === "20000 - 30000"
                              ? "bg-main text-white"
                              : ""
                          }`}
                        >
                          <button
                            className="inner-btn"
                            onClick={() => handlePriceFilter("20000 - 30000")}
                          >
                            20,000 - 30,000
                          </button>
                        </li>
                        <li
                          className={`nav-item ${
                            priceFilter === "40000 - 50000"
                              ? "bg-main text-white"
                              : ""
                          }`}
                        >
                          <button
                            className="inner-btn"
                            onClick={() => handlePriceFilter("40000 - 50000")}
                          >
                            40,000 - 50,000
                          </button>
                        </li>
                        <li
                          className={`nav-item ${
                            priceFilter === "50000 - 99999999999"
                              ? "bg-main text-white"
                              : ""
                          }`}
                        >
                          <button
                            className="inner-btn"
                            onClick={() =>
                              handlePriceFilter("50000 - 99999999999")
                            }
                          >
                            50,000 - More than 50,000
                          </button>
                        </li>
                      </ul>
                    )}
                    {selectedFilter == 1 && (
                      <ul class="nav nav-pills flex-column mb-auto with-stars">
                        <li
                          className={`nav-item ${
                            ratingFilter === "3 & above"
                              ? "bg-main text-white"
                              : ""
                          }`}
                        >
                          <button
                            className="inner-btn"
                            onClick={() => handleRatingFilter("3 & above")}
                          >
                            3 <i className="fa fa-star"></i> & above
                          </button>
                        </li>
                        <li
                          className={`nav-item ${
                            ratingFilter === "3 & below"
                              ? "bg-main text-white"
                              : ""
                          }`}
                        >
                          <button
                            className="inner-btn"
                            onClick={() => handleRatingFilter("3 & below")}
                          >
                            3 <i className="fa fa-star"></i> & below
                          </button>
                        </li>
                      </ul>
                    )}
                    {selectedFilter == 2 && (
                      <ul class="nav nav-pills flex-column mb-auto pe-3">
                        <h6 className="px-2">Photography</h6>
                        <li class="nav-item">
                          {dynamicServices?.typesOfPhotography?.map(
                            (service) => {
                              console.log(service, "ASDSDADASD");
                              return (
                                <div
                                  class="form-check"
                                  onClick={() => handlePhotoFilters(service)}
                                >
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id={service}
                                  />
                                  <label class="form-check-label" for={service}>
                                    {service}
                                  </label>
                                </div>
                              );
                            }
                          )}
                        </li>
                        <h6 className="px-2">Videography</h6>
                        <li class="nav-item ">
                          {dynamicServices?.typesOfVideography?.map(
                            (service) => {
                              console.log(service, "ASDSDADASD");
                              return (
                                <div
                                  class="form-check"
                                  onClick={() => handleVideoFilters(service)}
                                >
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="videorgraphy0"
                                  />
                                  <label
                                    class="form-check-label"
                                    for="videorgraphy0"
                                  >
                                    {service}
                                  </label>
                                </div>
                              );
                            }
                          )}
                        </li>
                      </ul>
                    )}
                    {selectedFilter == 3 && (
                      <ul class="nav nav-pills flex-column mb-auto">
                        <li
                          className={`nav-item ${
                            discountFilter === "60" ? "bg-main text-white" : ""
                          }`}
                        >
                          <button
                            className="inner-btn"
                            onClick={() => handleDiscountFilter("60")}
                          >
                            60%
                          </button>
                        </li>
                        <li
                          className={`nav-item ${
                            discountFilter === "50" ? "bg-main text-white" : ""
                          }`}
                        >
                          <button
                            className="inner-btn"
                            onClick={() => handleDiscountFilter("50")}
                          >
                            50%
                          </button>
                        </li>
                        <li
                          className={`nav-item ${
                            discountFilter === "40" ? "bg-main text-white" : ""
                          }`}
                        >
                          <button
                            className="inner-btn"
                            onClick={() => handleDiscountFilter("40")}
                          >
                            40%
                          </button>
                        </li>
                        <li
                          className={`nav-item ${
                            discountFilter === "30" ? "bg-main text-white" : ""
                          }`}
                        >
                          <button
                            className="inner-btn"
                            onClick={() => handleDiscountFilter("30")}
                          >
                            30%
                          </button>
                        </li>
                        <li
                          className={`nav-item ${
                            discountFilter === "20" ? "bg-main text-white" : ""
                          }`}
                        >
                          <button
                            className="inner-btn"
                            onClick={() => handleDiscountFilter("20")}
                          >
                            20%
                          </button>
                        </li>
                        <li
                          className={`nav-item ${
                            discountFilter === "10" ? "bg-main text-white" : ""
                          }`}
                        >
                          <button
                            className="inner-btn"
                            onClick={() => handleDiscountFilter("10")}
                          >
                            10%
                          </button>
                        </li>
                      </ul>
                    )}
                    {selectedFilter == 4 && (
                      <ul class="nav nav-pills flex-column mb-auto">
                        <li
                          className={`nav-item ${
                            locationFilter === "All India"
                              ? "bg-main text-white"
                              : ""
                          }`}
                        >
                          <button
                            className="inner-btn"
                            onClick={() => handleLocationFilter("All India")}
                          >
                            All India
                          </button>
                        </li>
                        <li
                          className={`nav-item ${
                            locationFilter === "Bengaluru"
                              ? "bg-main text-white"
                              : ""
                          }`}
                        >
                          <button
                            className="inner-btn"
                            onClick={() => handleLocationFilter("Bengaluru")}
                          >
                            Bengaluru
                          </button>
                        </li>
                        <li
                          className={`nav-item ${
                            locationFilter === "Punjab"
                              ? "bg-main text-white"
                              : ""
                          }`}
                        >
                          <button
                            className="inner-btn"
                            onClick={() => handleLocationFilter("Punjab")}
                          >
                            Punjab
                          </button>
                        </li>
                        <li
                          className={`nav-item ${
                            locationFilter === "UP" ? "bg-main text-white" : ""
                          }`}
                        >
                          <button
                            className="inner-btn"
                            onClick={() => handleLocationFilter("UP")}
                          >
                            UP
                          </button>
                        </li>
                      </ul>
                    )}
                    {selectedFilter == 5 && (
                      <ul class="nav nav-pills flex-column mb-auto">
                        <li
                          className={`nav-item ${
                            deliveryFilter === "30-60 days"
                              ? "bg-main text-white"
                              : ""
                          }`}
                        >
                          <button
                            className="inner-btn"
                            onClick={() => handleDeliveryFilter("30-60 days")}
                          >
                            30 - 60 days
                          </button>
                        </li>
                        <li
                          className={`nav-item ${
                            deliveryFilter === "60-90 days"
                              ? "bg-main text-white"
                              : ""
                          }`}
                        >
                          <button
                            className="inner-btn"
                            onClick={() => handleDeliveryFilter("60-90 days")}
                          >
                            60 - 90 days
                          </button>
                        </li>
                        <li
                          className={`nav-item ${
                            deliveryFilter === "90-120 days"
                              ? "bg-main text-white"
                              : ""
                          }`}
                        >
                          <button
                            className="inner-btn"
                            onClick={() => handleDeliveryFilter("90-120 days")}
                          >
                            90 - 120 days
                          </button>
                        </li>
                      </ul>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
          {/* <div className="sorting d-flex gap-2 align-items-center wow animate__animated animate__fadeInRight animate__delay-1s ">
                    <label htmlFor="" className="sort">Sort:</label>
                    <select name="" id="" className='form-select form-control'>
                        <option value="Featured">Featured</option>
                    </select>
                </div> */}
        </div>
      </section>

      <section className="studio-section overflow-hidden">
        <div className="bootstrap-container">
          <div className="studio-start-here pb-5">
            <div className="row">
              {photographer?.count > 0 ? (
                photographer?.data?.map((studio, index) => {
                  console.log(studio?.Photographer, "STUDIO");
                  return (
                    <div
                      className={`col-lg-3 col-md-6 my-3  wow animate__animated animate__fadeInDown animate__delay-${
                        index < 3 ? index : "2"
                      }s`}
                    >
                      {/* <Link to="/studio-details" style={{textDecoration: "none"}}> */}
                      <div
                        className={`single-studio `}
                        // ${
                        //   studio.availability ? "" : "outstock"
                        // }
                      >
                        {studio.Discount && (
                          <span className="discount-offer">
                            <span className="discount-text">
                              {studio.Discount} off
                            </span>
                          </span>
                        )}
                        <figure className="sutdio-img">
                          <img
                            src={
                              studio?.Photographer?.FavouritePictures[0]
                                ?.imageUrl ??
                              studio?.Photographer?.category[id.type].image?.[0]
                            }
                            alt=""
                            className="w-100"
                          />
                          {loggedIn ? (
                            <button
                              onClick={(e) => {
                                e.preventDefault(); // Prevent the link from being followed
                                toggleWishItem(index);
                              }}
                              className="absolute heart-icon !top-[80%] z-10"
                            >
                              <i
                                class={`fa fa-heart${wish[index] ? "" : "-o"}`}
                              ></i>
                            </button>
                          ) : (
                            <a
                              className="absolute heart-icon !top-[80%] z-10"
                              data-bs-toggle="modal"
                              data-bs-target="#LoginModal"
                              role="button"
                            >
                              <i class={`fa fa-heart-o`}></i>
                            </a>
                          )}
                        </figure>
                        <div className="studio-text">
                          <div className="name">
                            <a
                              href={`/photography/${id.type}/${studio?.Photographer?.id}?startDate=${startDate}&endDate=${endDate}&city=${city}`}
                              target="_blank"
                              className="!text-main"
                            >
                              {studio?.Photographer?.studioName}
                            </a>
                          </div>
                          <div className="rating-place">
                            <p className="place-name">
                              {/* <img src={Baricon} alt="" /> */}
                              <IoLocationSharp className="text-xl" />
                              {studio?.Photographer?.serviceLocation}
                            </p>
                            {/* rating */}
                            <div className="bg-green-500 text-white p-1 my-1 rounded-sm">
                              <i className="fa fa-star"></i>{" "}
                              {Math.round(studio.averageRating * 10) / 10}
                            </div>
                          </div>
                          <div className="flex flex-col gap-1">
                            <p className="px-2 text-[#666666]">Average rate</p>
                            <div className="price d-flex gap-1 align-items-center text-[#666666]">
                              <p className="flex gap-2 items-center text-xl font-bold">
                                ₹ {studio?.basicPrice}{" "}
                                <p className="text-sm font-extralight">
                                  per day
                                </p>
                              </p>
                            </div>
                          </div>
                          <a
                            className="btn btn-primary w-100 d-block mt-3"
                            href={`/photography/${id.type}/${studio.Photographer?.id}?startDate=${startDate}&endDate=${endDate}&city=${city}`}
                            target="_blank"
                          >
                            View Details
                          </a>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="col-12 text-center">
                  <p>No results found</p>
                </div>
              )}
            </div>
          </div>
          {photographer.count >= 7 && (
            <Pagination
              total={totalPages}
              current={currentPage}
              onPageChange={setCurrentPage}
              onNextPage={handleNextPage}
              onPrevPage={handlePrevPage}
            />
          )}
        </div>
      </section>
      <Footer />
      <ToastContainer />
    </>
  );
};

export default PhotographerSearch;
