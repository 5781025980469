import {
    S3Client,
    CreateMultipartUploadCommand,
    UploadPartCommand,
    CompleteMultipartUploadCommand,
    AbortMultipartUploadCommand,
  } from "@aws-sdk/client-s3";
  
  const twentyFiveMB = 25 * 1024 * 1024; // Size of each part

export const uploadChunksByChunks = async (file, path, uploadProgress) => {
  const s3Client = new S3Client({
    region: process.env.REACT_APP_AWS_REGION,
    credentials: {
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_AWS_ACCESS_KEY_SECRET,
    },
  });
  const bucketName = process.env.REACT_APP_S3_BUCKET_NAME;
  const key = `${path}/${file.name}`;
  let uploadId;

  try {
    const multipartUpload = await s3Client.send(
      new CreateMultipartUploadCommand({
        Bucket: bucketName,
        Key: key,
      })
    );

    uploadId = multipartUpload.UploadId;

    const uploadPromises = [];
    const totalParts = Math.ceil(file.size / twentyFiveMB);

    for (let i = 0; i < totalParts; i++) {
      const partBuffer = file.slice(i * twentyFiveMB, (i + 1) * twentyFiveMB);

      uploadPromises.push(
        s3Client
          .send(
            new UploadPartCommand({
              Bucket: bucketName,
              Key: key,
              UploadId: uploadId,
              Body: partBuffer,
              PartNumber: i + 1,
            })
          )
          .then((d) => {
            console.log("Part", i + 1, "uploaded");
            uploadProgress(file.name, Math.ceil(((i + 1) / totalParts) * 100));
            return d;
          })
      );
    }

    const uploadResults = await Promise.all(uploadPromises);

    const formattedParts = uploadResults.map((uploadResult, index) => ({
      ETag: uploadResult.ETag, // Ensure ETags are quoted correctly
      PartNumber: index + 1,
    }));

    // Log the parts to be sent in the complete request for debugging
    console.log(
      "Parts for completion:",
      JSON.stringify(formattedParts, null, 2)
    );

    try {
      const completeResponse = await s3Client.send(
        new CompleteMultipartUploadCommand({
          Bucket: bucketName,
          Key: key,
          UploadId: uploadId,
          MultipartUpload: {
            Parts: formattedParts,
          },
        })
      );

      return completeResponse;
    } catch (error) {
      console.error("Error in completing multipart upload:", error);
      throw error;
    }
  } catch (err) {
    console.error(err);
    if (uploadId) {
      const abortCommand = new AbortMultipartUploadCommand({
        Bucket: bucketName,
        Key: key,
        UploadId: uploadId,
      });

      await s3Client.send(abortCommand);
    }
  }
};
