import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaStar, FaStarHalfAlt } from "react-icons/fa";

const ReviewCard = ({ review }) => {
  const { author_name, rating, profile_photo_url, text } = review;

  // Function to generate star components based on rating
  const generateStars = (rating) => {
    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 !== 0;
    const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);

    const stars = [];
    for (let i = 0; i < fullStars; i++) {
      stars.push(<FaStar key={i} className="inline text-yellow-500" />);
    }
    if (halfStar) {
      stars.push(
        <FaStarHalfAlt key="half" className="inline text-yellow-500" />
      );
    }
    for (let i = 0; i < emptyStars; i++) {
      stars.push(
        <FaStar key={`empty-${i}`} className="inline text-yellow-500" />
      );
    }

    return <span className="flex">{stars}</span>;
  };

  return (
    <div className="bg-white p-4 rounded-lg shadow-md mb-4">
      <div className="flex items-center mb-2">
        <img
          src={profile_photo_url}
          alt={author_name}
          className="w-10 h-10 rounded-full mr-2"
        />
        <div>
          <h2 className="text-lg font-semibold">{author_name}</h2>
          <div className="flex items-center">
            <span className="mr-1">{generateStars(rating)}</span>
          </div>
        </div>
      </div>
      <p>{text}</p>
    </div>
  );
};

export default ReviewCard;
