import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Plan1Img, Plan2Img, Plan3Img } from "../../Constant/Index";
import TheDate from "../../Components/TheDate";
import StarImg from "../../Assets/Img/star.webp";
import { useDispatch } from "react-redux";
import { generateLinkAsync } from "../../Redux/slice/mainSlice";
const PhotographyModals = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const handlePayment = (price, type) => {
    if (type === "PRO" && localStorage.getItem("paymentStatus") === "PRO") {
      alert(
        "You Are already a PRO Member, You can always increase your privileges by joining Ultra Membership "
      );
    } else if (
      type === "ULTRA" &&
      localStorage.getItem("paymentStatus") === "ULTRA"
    ) {
      alert("You Are already an ULTRA Member");
    } else {
      setIsLoading(true);
      const paymentData = {
        amount: price,
        purpose: `Membership`,
      };
      if (!localStorage.getItem("userId")) {
        alert("Please login first");
      } else {
        dispatch(generateLinkAsync(paymentData))
          .then((x) => {
            const url = x.payload.data.longurl;
            setIsLoading(false);
            window.location.href = url;
            console.log(console.log(x, "RESPONSSDASDASDADASDASD"));
          })
          .catch((e) => {
            console.log(e.message);
          });
      }
    }
  };
  return (
    <>
      <section className="photography-modals">
        {isLoading && (
          <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50 -z-50">
            <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-24 w-24"></div>
          </div>
        )}
        <div
          className="modal fade"
          id="DiscountModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered  modal-lg w-lg-50">
            <div className="modal-content  px-3 px-lg-4 py-4">
              <div className="top-heading text-center d-flex align-items-center flex-column-reverse flex-sm-row">
                <h2
                  className="text-2xl md:text-4xl font-bold text-main"
                  style={{ textShadow: "none" }}
                >
                  10% Discount
                </h2>
                <button
                  type="button"
                  className="btn-close modal-lg-close ms-auto text-lg font-bold"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  X
                </button>
              </div>
              <hr />
              <div className="body-text">
                <p className="text py-5">
                  To avail the 10% discount, customers must enter a specific
                  coupon code i.e. Boopho010 during the checkout process on our
                  website.
                </p>
                {/* <div className="terms">
                                    <h4 className="md-heading-2 mb-3">Terms and Conditions:</h4>
                                    <ol>
                                        <li className="text pb-2">The 10% discount offer is applicable to <span className="wight-lg">Ultra </span> member of Boopho only.</li>
                                        <li className="text pb-2">The discount offer is valid for a <span className="wight-lg">limited time </span> only.</li>
                                        <li className="text pb-2">The discount is only applicable to the regular price of the products/services and cannot be combined with any other ongoing promotions, discounts, or offers.</li>
                                        <li className="text pb-2">The discount is applicable to <span className="wight-lg">both new and existing customers </span> of Boopho.</li>
                                        <li className="text pb-2">This discount is non-transferable and cannot be exchanged for cash or any other form of compensation.</li>
                                        <li className="text pb-2">The discount is applicable to the total purchase amount, excluding any taxes, shipping fees, or other additional charges.</li>
                                        <li className="text pb-2">The discount offer is subject to availability and may be withdrawn or modified by Boopho at any time without prior notice.</li>
                                        <li className="text pb-2">Boopho reserves the right to verify the eligibility of customers and decline or cancel the discount if any fraudulent activity is suspected.</li>
                                    </ol>
                                </div> */}
                <p className="d-block w-fit ms-auto mt-3 text wight-lg">
                  Click here for{" "}
                  <a
                    href="/terms"
                    target="_blank"
                    className="!text-main link-hover"
                  >
                    Terms and Conditions
                  </a>{" "}
                  *
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="PlansModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-lg w-lg-60">
            <div className="modal-content px-3 px-lg-5 pt-2">
              <div className="relative top-heading text-center">
                <h2
                  className="text-2xl text-center md:text-3xl font-bold text-main mt-[20px] mb-[10px]"
                  style={{ textShadow: "none" }}
                >
                  Membership Plans
                </h2>
                <button
                  type="button"
                  className="absolute btn-close top-4 right-0 modal-lg-close ms-auto text-lg font-bold"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  X
                </button>
              </div>
              {/* <hr /> */}
              <div className="modal-body">
                <div className="grid md:grid-cols-3 gap-3">
                  {/* Free Package Starts here */}
                  <div className="bg-[#f5efef] rounded-xl py-2 flex flex-col gap-3 justify-between">
                    <div className="flex flex-col gap-3">
                      <div>
                        <h2
                          className="text-main font-bold text-center"
                          style={{ textShadow: "none" }}
                        >
                          Free
                        </h2>
                        <hr className=" border-main w-[80%] mx-auto" />
                      </div>
                      <ul className="flex flex-col gap-3 pl-10">
                        <li className="list-item list-outside list-disc">
                          Unlimited Search
                        </li>
                        <li className="list-item list-outside list-disc">
                          Booking of 1 photographer per location at any point in
                          time
                        </li>
                        <li className="list-item list-outside list-disc">
                          No Sharing allowed
                        </li>
                        <li className="list-item list-outside list-disc">
                          Unlimited downloads
                        </li>
                      </ul>
                    </div>
                    <button className="bg-main rounded-lg flex justify-center py-2 text-white font-semibold">
                      Free
                    </button>
                  </div>
                  {/* Free Package Ends here */}

                  {/* Pro Package Starts here */}
                  <div className="bg-[#f5efef] rounded-xl py-2 flex flex-col gap-3 justify-between">
                    <div className="flex flex-col gap-3">
                      <div>
                        <h2
                          className="text-main font-bold text-center"
                          style={{ textShadow: "none" }}
                        >
                          Pro
                        </h2>
                        <hr className=" border-main w-[80%] mx-auto" />
                      </div>
                      <ul className="flex flex-col gap-3 pl-10">
                        <li className="list-item list-outside list-disc">
                          Unlimited search of all the photographers through out
                          India
                        </li>
                        <li className="list-item list-outside list-disc">
                          Booking of 5 photographer per location at any point in
                          time
                        </li>
                        <li className="list-item list-outside list-disc">
                          5% Discount in lifetime Accessibility
                        </li>
                        <li className="list-item list-outside list-disc">
                          Sharing of upto 10 friends/ relatives{" "}
                        </li>
                        <li className="list-item list-outside list-disc">
                          Unlimited downloads
                        </li>
                      </ul>
                    </div>
                    <button
                      onClick={() => handlePayment(499, "PRO")}
                      className=" bg-main rounded-lg flex justify-center py-2 text-white"
                    >
                      Subscribe Now for ₹499/-
                      <p className="line-through -rotate-45 text-white font-normal">
                        999/-
                      </p>
                    </button>
                  </div>
                  {/* Pro Package Ends here */}

                  {/* Ultra Package Starts here */}
                  <div className="bg-[#f5efef] rounded-lg py-2 flex flex-col gap-3 justify-between relative">
                    <img
                      src={StarImg}
                      alt=""
                      width="70px"
                      className="absolute -top-6 -right-6"
                    />
                    <div className="flex flex-col gap-3">
                      <div>
                        <h2
                          className="text-main font-bold text-center"
                          style={{ textShadow: "none" }}
                        >
                          Ultra
                        </h2>
                        <hr className=" border-main w-[80%] mx-auto" />
                      </div>
                      <ul className="flex flex-col gap-3 pl-10">
                        <li className="list-item list-outside list-disc">
                          Unlimited search of all the photographers through out
                          India
                        </li>
                        <li className="list-item list-outside list-disc">
                          Booking of 10 photographer per location at any point
                          in time
                        </li>
                        <li className="list-item list-outside list-disc">
                          10% Discount in lifetime Accessibility
                        </li>
                        <li className="list-item list-outside list-disc">
                          Sharing of upto 20 friends/ relatives
                        </li>
                        <li className="list-item list-outside list-disc">
                          Unlimited downloads
                        </li>
                      </ul>
                    </div>
                    <button
                      onClick={() => handlePayment(999, "ULTRA")}
                      className="bg-main rounded-lg flex justify-center py-2 text-white"
                    >
                      Subscribe Now for ₹999/-
                      <p className="line-through -rotate-45 text-white font-normal">
                        1999/-
                      </p>
                    </button>
                  </div>
                  {/* Ultra Package Ends here */}
                </div>
                <p className="text-end my-[20px]">
                  Click here for{" "}
                  <a
                    href="/terms"
                    target="_blank"
                    className="!text-main underline"
                  >
                    Terms and Conditions*
                  </a>
                </p>
              </div>
              {/* <div className="modal-plans">
                <div className="row">
                  <div className="col-md-4">
                    <div className="single-plans px-3 h-100">
                      <div className="icon-here">
                        <img src={Plan1Img} className="plan-img" alt="" />
                        <h3 className="md-heading">Free</h3>
                      </div>
                      <ul className="points">
                        <li>
                          Unlimited search of all the photographers through out
                          India
                        </li>
                        <li>
                          Booking of 1 photographer per location at any point in
                          time
                        </li>
                        <li>No Sharing allowed </li>
                        <li>Unlimited downloads</li>
                      </ul>
                      <div className="primary-btn">Free</div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="single-plans px-3 h-100">
                      <div className="icon-here">
                        <img src={Plan2Img} className="plan-img" alt="" />
                        <h3 className="md-heading">Pro</h3>
                      </div>
                      <ul className="points">
                        <li>
                          Unlimited search of all the photographers through out
                          India
                        </li>
                        <li>
                          Booking of 5 photographer per location at any point in
                          time
                        </li>
                        <li>5% Discount in lifetime Accessibility</li>
                        <li>Sharing of upto 10 friends/ relatives </li>
                        <li>Unlimited downloads</li>
                      </ul>
                      <Link to="/" className="primary-btn">
                        Subscribe Now for 499/- <s>999/-</s>
                      </Link>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="single-plans px-3 h-100">
                      <div className="icon-here">
                        <img src={Plan3Img} className="plan-img" alt="" />
                        <h3 className="md-heading">Ultra</h3>
                      </div>
                      <ul className="points">
                        <li>
                          Unlimited search of all the photographers through out
                          India
                        </li>
                        <li>
                          Booking of 10 photographer per location at any point
                          in time
                        </li>
                        <li>10% Discount in lifetime Accessibility</li>
                        <li>Sharing of upto 20 friends/ relatives</li>
                        <li>Unlimited downloads</li>
                      </ul>
                      <Link to="/" className="primary-btn">
                        Subscribe Now for 999/- <s>1999/-</s>
                      </Link>
                    </div>
                  </div>
                </div>
                <p className="d-block w-fit ms-auto mt-3 text wight-lg">
                  Click here for{" "}
                  <a href="/terms" className="link-hover">
                    Terms and Conditions
                  </a>{" "}
                  *
                </p>
              </div> */}
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="LifeTImeModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-lg w-lg-50">
            <div className="modal-content  py-4">
              {/* <button type="button" className="btn-close position-fixed top-2 right-3 modal-lg-close" data-bs-dismiss="modal" aria-label="Close"></button>

<h2 className="md-heading mb-0">Lifetime accessibility </h2>
</div> */}
              <div className="top-heading text-center px-3 px-lg-4">
                <div className="top-heading text-center d-flex align-items-center flex-column-reverse flex-sm-row">
                  <h2
                    className="text-2xl md:text-4xl font-bold text-main"
                    style={{ textShadow: "none" }}
                  >
                    Lifetime Accessibility
                  </h2>
                  <button
                    type="button"
                    className="btn-close  modal-lg-close ms-auto text-lg font-bold"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    X
                  </button>
                </div>
              </div>
              <hr />
              {/* <hr /> */}
              <div className="body-text px-3 px-lg-4 ">
                <p className="my-3 text">
                  All your photos/Videos are accessible throughout the lifetime
                  on Boopho.com*
                </p>
                {/* <h4 className="md-heading-2">Terms and Conditions:</h4>
                <p>
                  Please carefully read these terms and conditions before
                  accessing our lifetime accessibility services for photos. By
                  accessing and using our lifetime accessibility services, you
                  agree to be bound by the following terms and conditions:
                </p>
                <h5 className="sm-heading">Lifetime Accessibility Scope:</h5>
                <ol>
                  <li>
                    Our lifetime accessibility services provide ongoing
                    accessibility and availability of your photos/Videos stored
                    with us.
                  </li>
                  <li>
                    The lifetime accessibility services are applicable to the
                    specific set of photos/videos for which particular services
                    are purchased.
                  </li>
                </ol>
                <h5 className="sm-heading">Duration and Transferability:</h5>
                <ol>
                  <li>
                    The lifetime accessibility services will be provided for the
                    lifetime of our platform or until the termination of our
                    services, whichever occurs earlier.
                  </li>
                  <li>
                    The lifetime accessibility services are non-transferable and
                    cannot be assigned or transferred to any third party without
                    prior written consent from us.
                  </li>
                </ol> */}
                <p className="d-block w-fit ms-auto mt-3 text wight-lg">
                  {" "}
                  <a
                    href="/terms"
                    target="_blank"
                    className="link-hover !text-main"
                  >
                    Click here for Terms and Conditions*
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default PhotographyModals;
