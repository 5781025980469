import React, { useState } from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";
import { Navigate, useNavigate, useParams } from "react-router";

import "react-toastify/dist/ReactToastify.css";

const ForgotResetPass = ({ resetPass }) => {
  const [showNewPass, setshowNewPass] = useState(false);
  const [showConfirPass, setshowConfirPass] = useState(false);

  const [NewPassword, setNewPassword] = useState("");
  const [ConfirmPassword, setConfirmPassword] = useState("");
  const [regexError,setRegexError ] = useState(false);

  const reg=new RegExp("^(.{0,7}|[^0-9]*|[^A-Z]*|[^a-z]*|[a-zA-Z0-9]*)$")
  const navigate = useNavigate();
  console.log(NewPassword);
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    console.log(reg.test(NewPassword))
    if (NewPassword == ConfirmPassword) {
     
      if(!reg.test(NewPassword)){
        const success = await resetPass(NewPassword);
        if (success) {
          toast.success('Password changed successfully!');
  
          setTimeout(() => {
            navigate('/')
          }, 3000 );
        } else {
          toast.error('Failed to change password. Please try again.');
        }
      }else{
        setRegexError(true)
      }
      
    } 
    
    else {
      toast.error("Password Doesn't Matches");
    }
  };

  return (
    <div className="mx-auto max-w-md mt-5">
      <ToastContainer
        position="bottom-left"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="rounded-xl border border-gray-200 bg-white shadow-sm">
        <div className="p-4 sm:p-7">
          <div className="text-center">
            <div className="mb-4 inline-block rounded-full bg-main p-2 text-white">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
                />
              </svg>
            </div>
            <h1
              className="block text-2xl font-bold text-gray-800"
              style={{ textShadow: "none" }}
            >
              Reset Password
            </h1>
            <p className="mt-2 text-sm text-gray-600 ">
              Enter the New Password
            </p>
          </div>

          <div className="mt-6">
            <form onSubmit={handleFormSubmit}>
              <div className="grid gap-y-4">
                <div>
                  <label
                    htmlFor="newpassword"
                    className="mb-2 block text-sm text-gray-600 "
                  >
                    New Password
                  </label>
                  <div className="relative">
                    <input
                      type={!showNewPass ? "password" : "text"}
                      id="newpassword"
                      name="newpassword"
                      value={NewPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      placeholder="New Password"
                      className="peer block w-full rounded-md border  border-gray-200 bg-gray-50 py-3 px-4 text-sm outline-none ring-offset-1 focus:bg-white"
                      required
                    />
                    <div
                      onClick={() => setshowNewPass(!showNewPass)}
                      className="cursor-pointer absolute right-2 top-[33%] text-black text-lg"
                    >
                      {showNewPass ? <AiFillEyeInvisible /> : <AiFillEye />}
                    </div>
                  </div>
                  {regexError && (
                    <p className="text-xs text-red-500">
                        Password must contain atleast 8 characters with atleast 1 lower case, 1 upper case and 1 special character
                    </p>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="confirmpassword"
                    className="mb-2 block text-sm text-gray-600 "
                  >
                    Confirm Password
                  </label>
                  <div className="relative">
                    <input
                      type={!showConfirPass ? "password" : "text"}
                      id="confirmpassword"
                      name="confirmpassword"
                      value={ConfirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      placeholder="Confirm Password"
                      className="peer block w-full rounded-md border border-gray-200 bg-gray-50 py-3 px-4 text-sm outline-none ring-offset-1 focus:bg-white"
                      required
                    />
                    <div
                      onClick={() => setshowConfirPass(!showConfirPass)}
                      className="cursor-pointer absolute right-2 top-[33%] text-black text-lg"
                    >
                      {showConfirPass ? <AiFillEyeInvisible /> : <AiFillEye />}
                    </div>
                  </div>
                  {NewPassword !== ConfirmPassword && (
                    <p className="text-xs text-red-500">
                      Password Doesn't Matches!
                    </p>
                  )}
                </div>

                <button
                  type="submit"
                  className={`${NewPassword !== ConfirmPassword &&
                    "pointer-events-none opacity-70"} inline-flex items-center justify-center gap-2 rounded-md border border-transparent bg-main py-3 px-4 text-sm font-semibold text-white transition-all focus:outline-none focus:ring-2 focus:ring-main focus:ring-offset-2`}
                >
                  Change Password
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotResetPass;
