import React, { useState, useEffect } from 'react';
import Banner from '../../Components/banner';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import FileExplorer from './FileExplorer';
import { FullFileBrowser } from 'chonky';
import { ChonkyActions } from 'chonky';


const Explorer = () => {
    // const data = [
    //     { id: 1, parentId: null, type: 'folder', name: 'Home' },

    //     { id: 3, parentId: 2, type: 'folder', name: 'Folder A' },
    //     { id: 4, parentId: 3, type: 'folder', name: 'Subfolder X' },
    //     { id: 6, parentId: 2, type: 'folder', name: 'Folder B' },
    //     { id: 9, parentId: 1, type: 'folder', name: 'Pictures' },
    //     { id: 10, parentId: 9, type: 'folder', name: 'Vacation' },
    //     { id: 11, parentId: 10, type: 'file', name: 'beach.jpg' },
    //     { id: 12, parentId: 10, type: 'file', name: 'mountains.jpg' },
    //     { id: 13, parentId: 9, type: 'folder', name: 'Family' },
    //     { id: 14, parentId: 13, type: 'file', name: 'family1.jpg' },
    //     { id: 15, parentId: 13, type: 'file', name: 'family2.jpg' },
    // ];

    // // Generate more data to reach over 100 items
    // for (let i = 19; i <= 100; i++) {
    //     const parentId = Math.floor(Math.random() * 16) + 1; // Randomly assign a parent folder ID between 1 and 16
    //     const type = Math.random() < 0.5 ? 'folder' : 'file'; // Randomly choose between folder and file
    //     const name = type === 'folder' ? `Folder ${i}` : `File ${i}.txt`;
    //     data.push({ id: i, parentId, type, name });
    // }

    // return (
    //     <>
    //         <Header />
    //         <Banner home="Home" currenturl="/explorer" current="My Footage " />
    //         <section className="explorer py-5">
    //             <div className="container">
    //                 <div className="explorer-start">
    //                     <FileExplorer data={data} />
    //                 </div>
    //             </div>
    //         </section>
    //         <Footer />
    //     </>
    // )


    const files = [
        { id: 'lht', name: 'Projects', isDir: true, childrenCount: 1, color: 'blue' },
        null,
        {
            id: 'mcd',
            name: 'rafal olbinski ambrella',
            ext: 'jpg',
            isHidden: false,
            openable: true,
            isSymlink: false,
            selectable: true,
            size: 1024,
            modDate: Date('December 17, 1995 03:24:00'),
            thumbnailUrl: 'https://static.wixstatic.com/media/2f9864_47b60a2645a345fc8e31d011cce466ef~mv2_d_1216_1575_s_2.jpg',
        },
    ];
    const folderChain = [
        { id: 'xcv', name: 'Folder 1', isDir: true },
    ];

    const chonky_actions = [
        ChonkyActions.UploadFiles,
        ChonkyActions.DeleteFiles,
        ChonkyActions.CreateFolder,
        ChonkyActions.CopyFiles
    ];

    const handleAction = (data) => {
        console.log('File action data:', data);
        //console.log("ChonkyActions");
        //console.log(ChonkyActions);

        if (data.id === ChonkyActions.OpenFiles.id) {
            console.log("open files");
        } else if (data.id === ChonkyActions.DeleteFiles.id) {
            // Delete the files
            console.log("delete files");
        }
        else if (data.id === ChonkyActions.UploadFiles.id) {
            console.log("upload files");
        }

    };


    return (
        <div style={{ height: 300 }}>
            <FullFileBrowser
                files={files}
                fileActions={chonky_actions}
                onFileAction={handleAction}
                folderChain={folderChain} />
        </div>
    );
}
export default Explorer;







