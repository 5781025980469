import React, { useState, useEffect } from "react";
import Banner from "../../Components/banner";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";

const Faqs = () => {
  return (
    <>
      <main className="site-front-page">
        <Header />
        <Banner home="Home" currenturl="/faqs" current="Faq's" />
        <section class="faqs_section">
          <div class="bootstrap-container">
            <div class="col-xxl-8 col-xl-10 col-lg-11 mx-auto">
              <div class="faqs_box">
                <div class="all_accordians">
                  <div class="accordion" id="accordionExample">
                    <div class="single_accordian">
                      <div class="accordion-item">
                        <div class="accordion-header" id="headingOne">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="false"
                            aria-controls="collapseOne"
                          >
                            <span class="the_btn_txt">
                              Lorem Ipsum is simply dummy text of the printing?
                            </span>
                          </button>
                        </div>
                        <div
                          id="collapseOne"
                          class="accordion-collapse collapse"
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            <p>
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry. Lorem Ipsum has been the
                              industry's standard dummy text ever since the
                              1500s, when an unknown printer took a galley of
                              type and scrambled it to make a type specimen
                              book.{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="single_accordian">
                      <div class="accordion-item">
                        <div class="accordion-header" id="headingTwo">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                            <span class="the_btn_txt">
                              Lorem Ipsum is simply dummy text of the printing?
                            </span>
                          </button>
                        </div>
                        <div
                          id="collapseTwo"
                          class="accordion-collapse collapse"
                          aria-labelledby="headingTwo"
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            <p>
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry. Lorem Ipsum has been the
                              industry's standard dummy text ever since the
                              1500s, when an unknown printer took a galley of
                              type and scrambled it to make a type specimen
                              book.{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="single_accordian">
                      <div class="accordion-item">
                        <div class="accordion-header" id="headingThree">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            <span class="the_btn_txt">
                              Lorem Ipsum is simply dummy text of the printing?
                            </span>
                          </button>
                        </div>
                        <div
                          id="collapseThree"
                          class="accordion-collapse collapse"
                          aria-labelledby="headingThree"
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            <p>
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry. Lorem Ipsum has been the
                              industry's standard dummy text ever since the
                              1500s, when an unknown printer took a galley of
                              type and scrambled it to make a type specimen
                              book.{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="single_accordian">
                      <div class="accordion-item">
                        <div class="accordion-header" id="headingFour">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFour"
                            aria-expanded="false"
                            aria-controls="collapseFour"
                          >
                            <span class="the_btn_txt">
                              Lorem Ipsum is simply dummy text of the printing?
                            </span>
                          </button>
                        </div>
                        <div
                          id="collapseFour"
                          class="accordion-collapse collapse"
                          aria-labelledby="headingFour"
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            <p>
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry. Lorem Ipsum has been the
                              industry's standard dummy text ever since the
                              1500s, when an unknown printer took a galley of
                              type and scrambled it to make a type specimen
                              book.{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="single_accordian">
                      <div class="accordion-item">
                        <div class="accordion-header" id="headingFive">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFive"
                            aria-expanded="false"
                            aria-controls="collapseFive"
                          >
                            <span class="the_btn_txt">
                              Lorem Ipsum is simply dummy text of the printing?
                            </span>
                          </button>
                        </div>
                        <div
                          id="collapseFive"
                          class="accordion-collapse collapse"
                          aria-labelledby="headingFive"
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            <p>
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry. Lorem Ipsum has been the
                              industry's standard dummy text ever since the
                              1500s, when an unknown printer took a galley of
                              type and scrambled it to make a type specimen
                              book.{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="single_accordian">
                      <div class="accordion-item">
                        <div class="accordion-header" id="headingSix">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseSix"
                            aria-expanded="false"
                            aria-controls="collapseSix"
                          >
                            <span class="the_btn_txt">
                              Lorem Ipsum is simply dummy text of the printing?
                            </span>
                          </button>
                        </div>
                        <div
                          id="collapseSix"
                          class="accordion-collapse collapse"
                          aria-labelledby="headingSix"
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            <p>
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry. Lorem Ipsum has been the
                              industry's standard dummy text ever since the
                              1500s, when an unknown printer took a galley of
                              type and scrambled it to make a type specimen
                              book.{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ask-question">
                <h4 className="text-center  mb-5">
                  Still have a question? Ask Below
                </h4>
                <form>
                  <div className="row">
                    <div className="col-lg-10 ">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Ask a Question"
                      />
                    </div>
                    <div className="col-4 col-md-2">
                      <button className="submit btn site-btn w-100">
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </main>
    </>
  );
};
export default Faqs;
