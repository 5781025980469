import React, { useState } from "react";
import Confirmation from "../Modals/Confirmation";
//react-icons
import { MdEdit } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";
import { useEffect } from "react";

const CouponCard = ({ handleEdit, item, handleDelete}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [categoryName, setCategoryName] = useState('');
  const [dateStarts, setDateStarts] = useState("");
  const [dateEnds, setDateEnds] = useState("");

  const [deleteItemId, setDeleteItemId] = useState(null);

  const openDeleteModal = (itemId) => {
    setShowDeleteModal(true);
    setDeleteItemId(itemId); // Set item.id in the state
  };

  const category = () => {
    if(item.categoryId === 17) {
      setCategoryName('Wedding');
    } else if(item.categoryId === 18) {
      setCategoryName('Maternity');
    } else if(item.categoryId === 22) {
      setCategoryName('New-Born Baby');
    } else if(item.categoryId === 24) {
      setCategoryName('Birthday');
    } else {
      setCategoryName('Business');
    }
  }

  const formatDate = (date) => {
    const formattedDate = new Date(date).toISOString().split("T")[0];
    return formattedDate;
  };

  useEffect(() => {
    category();
    setDateStarts(formatDate(item.startDate) || "");
    setDateEnds(formatDate(item.endDate) || "");
  }, []);
  return (
    <>
      <div className=" bg-gradient-to-br from-[#9f606d] to-[#620808] text-white text-center py-5 md:py-10 px-10 md:px-20 rounded-lg shadow-md relative">
        <div className="flex flex-col gap-2 md:gap-4">
          <h3 className="text-2xl font-semibold">{item.discount} INR</h3>
          <div className="flex justify-between gap-4">
            <p className="text-sm">
              <b>Category:</b> {categoryName}
            </p>
            <div className="flex flex-col  w-full">
              <p className="text-xs md:text-sm">*Lifetime Accessibility</p>
              <p className="text-xs md:text-sm">*More than 1 Lac</p>
            </div>
          </div>
          <div className="flex gap-3">
            <p className="text-xs">
              <b>Valid From:</b> {dateStarts}
            </p>
            <p className="text-xs">
              <b>Valid Till:</b> {dateEnds}
            </p>
          </div>
        </div>
        <div className="flex gap-2 items-center absolute top-0 right-0">
          <button
            className="bg-green-500 p-2 rounded-full"
            data-bs-toggle="modal"
            data-bs-target="#EditCoupon"
            onClick={() => handleEdit(item.id)}
          >
            <MdEdit className="text-white text-lg" />
          </button>
          <button
            className="bg-red-500 p-2 rounded-full"
            onClick={() => openDeleteModal(item.id)}
          >
            <AiFillDelete className="text-white text-lg" />
          </button>
        </div>
        <div className="w-12 h-12 bg-white rounded-full absolute top-1/2 transform -translate-y-1/2 left-0 -ml-6"></div>
        <div className="w-12 h-12 bg-white rounded-full absolute top-1/2 transform -translate-y-1/2 right-0 -mr-6"></div>
      </div>

      <Confirmation
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        heading="Are you sure?"
        text="Are you sure you want to Delete?"
        btnText="Delete"
        handleConfirm={() => handleDelete(deleteItemId)}
      />
    </>
  );
};

export default CouponCard;
