import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import Image from "../../Components/Image";
import OpenImage from "../../Components/OpenImage";
import Header from "../../Components/Header";
import BackButton from "../../Components/BackButton";
import ShareFolder from "../../Components/Modals/ShareFolder";

// these are dynamic pages and data are on behalf on helpers: MY_FILES & SHARED_FILES
// example url of this page:
// http://localhost:3000/my-footage/my-files/58814/photos/hey

const MY_FILES = [
  {
    id: 58814,
    folderName: "Rahul Wed Nandhini",
    expiresInDays: 20,
    files: ["Photos", "Videos", "Other"],
    photos: [
      "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg",
      "https://dfstudio-d420.kxcdn.com/wordpress/wp-content/uploads/2019/06/digital_camera_photo-1080x675.jpg",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
    ],
  },
  {
    id: 64586,
    folderName: "Ibrahim Birthday",
    expiresInDays: 10,
    files: ["Photos", "Videos", "Other"],
    photos: [
      "https://t4.ftcdn.net/jpg/02/80/16/37/360_F_280163743_Ux10gTObj9YdQU86pXK75KRgdJllQlPk.jpg",
      "https://t4.ftcdn.net/jpg/02/80/16/37/360_F_280163743_Ux10gTObj9YdQU86pXK75KRgdJllQlPk.jpg",
      "https://t4.ftcdn.net/jpg/02/80/16/37/360_F_280163743_Ux10gTObj9YdQU86pXK75KRgdJllQlPk.jpg",
      "https://t4.ftcdn.net/jpg/02/80/16/37/360_F_280163743_Ux10gTObj9YdQU86pXK75KRgdJllQlPk.jpg",
      "https://t4.ftcdn.net/jpg/02/80/16/37/360_F_280163743_Ux10gTObj9YdQU86pXK75KRgdJllQlPk.jpg",
      "https://t4.ftcdn.net/jpg/02/80/16/37/360_F_280163743_Ux10gTObj9YdQU86pXK75KRgdJllQlPk.jpg",
      "https://t4.ftcdn.net/jpg/02/80/16/37/360_F_280163743_Ux10gTObj9YdQU86pXK75KRgdJllQlPk.jpg",
      "https://t4.ftcdn.net/jpg/02/80/16/37/360_F_280163743_Ux10gTObj9YdQU86pXK75KRgdJllQlPk.jpg",
      "https://t4.ftcdn.net/jpg/02/80/16/37/360_F_280163743_Ux10gTObj9YdQU86pXK75KRgdJllQlPk.jpg",
      "https://t4.ftcdn.net/jpg/02/80/16/37/360_F_280163743_Ux10gTObj9YdQU86pXK75KRgdJllQlPk.jpg",
      "https://t4.ftcdn.net/jpg/02/80/16/37/360_F_280163743_Ux10gTObj9YdQU86pXK75KRgdJllQlPk.jpg",
      "https://t4.ftcdn.net/jpg/02/80/16/37/360_F_280163743_Ux10gTObj9YdQU86pXK75KRgdJllQlPk.jpg",
      "https://t4.ftcdn.net/jpg/02/80/16/37/360_F_280163743_Ux10gTObj9YdQU86pXK75KRgdJllQlPk.jpg",
      "https://t4.ftcdn.net/jpg/02/80/16/37/360_F_280163743_Ux10gTObj9YdQU86pXK75KRgdJllQlPk.jpg",
    ],
  },
  {
    id: 5489,
    folderName: "Mahima Maternity Shoot",
    files: ["Photos", "Videos", "Other"],
    expiresInDays: 30,
    photos: [
      "https://dfstudio-d420.kxcdn.com/wordpress/wp-content/uploads/2019/06/digital_camera_photo-1080x675.jpg",
      "https://dfstudio-d420.kxcdn.com/wordpress/wp-content/uploads/2019/06/digital_camera_photo-1080x675.jpg",
      "https://dfstudio-d420.kxcdn.com/wordpress/wp-content/uploads/2019/06/digital_camera_photo-1080x675.jpg",
      "https://dfstudio-d420.kxcdn.com/wordpress/wp-content/uploads/2019/06/digital_camera_photo-1080x675.jpg",
      "https://dfstudio-d420.kxcdn.com/wordpress/wp-content/uploads/2019/06/digital_camera_photo-1080x675.jpg",
      "https://dfstudio-d420.kxcdn.com/wordpress/wp-content/uploads/2019/06/digital_camera_photo-1080x675.jpg",
      "https://dfstudio-d420.kxcdn.com/wordpress/wp-content/uploads/2019/06/digital_camera_photo-1080x675.jpg",
      "https://dfstudio-d420.kxcdn.com/wordpress/wp-content/uploads/2019/06/digital_camera_photo-1080x675.jpg",
      "https://dfstudio-d420.kxcdn.com/wordpress/wp-content/uploads/2019/06/digital_camera_photo-1080x675.jpg",
      "https://dfstudio-d420.kxcdn.com/wordpress/wp-content/uploads/2019/06/digital_camera_photo-1080x675.jpg",
      "https://dfstudio-d420.kxcdn.com/wordpress/wp-content/uploads/2019/06/digital_camera_photo-1080x675.jpg",
    ],
  },
];

const SHARED_FILES = [
  {
    id: 54782,
    folderName: "Test folder",
    expiresInDays: 20,
    files: ["Photos", "Videos", "Other"],
    photos: [
      "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg",
      "https://dfstudio-d420.kxcdn.com/wordpress/wp-content/uploads/2019/06/digital_camera_photo-1080x675.jpg",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
    ],
  },
  {
    id: 7895,
    folderName: "Shared Folder",
    expiresInDays: 10,
    files: ["Photos", "Videos", "Other"],
    photos: [
      "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg",
      "https://dfstudio-d420.kxcdn.com/wordpress/wp-content/uploads/2019/06/digital_camera_photo-1080x675.jpg",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
    ],
  },
];

const FolderCustomerFiles = () => {
  const params = useParams();

  const [files, setFiles] = useState(
    params.slug === "my-files"
      ? MY_FILES
      : params.slug === "shared-files"
      ? SHARED_FILES
      : ""
  );

  const [customer, setCustomer] = useState("");
  const [showOpenImage, setShowOpenImage] = useState(false);
  const [image, setImage] = useState("");
  const [showShare, setShowShare] = useState(false);
  const location = useLocation();
  const images = location.state;
  // console.log(, "=====haisiijasjis:state:ajsnaj");

  useEffect(() => {
    const customer = files.find((item) => item.id == params.id);
    setCustomer(customer);
  }, [files]);

  const openImage = (index) => {
    setImage(customer[params.folder][index]);
    setShowOpenImage(true);
  };

  const downloadImg = (src) => {
    let anchorTag = document.createElement("a");
    document.body.appendChild(anchorTag);
    fetch(src)
      .then((res) => res.blob())
      .then((value) => {
        const url = URL.createObjectURL(value);

        anchorTag.href = url;
        anchorTag.download = "my_image.png";

        anchorTag.click();
      })
      .catch((err) => console.log(err));

    document.body.removeChild(anchorTag);
  };

  console.log(params, "===ggrddrdeesesesesessseeeees");

  return (
    <>
      <Header />
      <section className="w-[80%] mx-auto p-4 min-h-full">
        <div className="w-[80%] mx-auto">
          <h1 className="md:text-3xl text-xl text-main font-bold text-center">
            {params?.myfolder}
          </h1>
          <BackButton />
        </div>
        {images?.length > 0 ? 
          <div className="grid gap-3 grid-cols-2 md:grid-cols-5 my-10">
            {images?.map((item, index) => {
              return (
                <img
                  src={`${process.env.REACT_APP_BACKEND_BASE_URL}/api/files/${encodeURIComponent(item)}`}
                  alt={"Image " + index}
                  // onContextMenu={handleContextMenu}
                  // onDoubleClick={selectImg}
                  // onClick={handleCheck}
                  // onDoubleClick={open}
                  style={{ border: '1px solid gray' }}
                />
                // <Image
                //   key={index}
                //   src={`${process.env.REACT_APP_BACKEND_BASE_URL}/api/files/${encodeURIComponent(item)}`}
                //   alt="photo"
                //   id={Date.now() + index}
                //   open={() => openImage(index)}
                //   download={() => downloadImg(item)}
                //   share={() => setShowShare(true)}
                //   onlyOpenOption={params.slug === "shared-files"}
                //   canSelect={false}
                // />
              );
            })}
          </div>
          :
          <p className="flex justify-center mt-5">No Image Found</p>
        }
      </section>
      <OpenImage
        showModal={showOpenImage}
        setShowModal={setShowOpenImage}
        user="Rahul"
        download={() => downloadImg(image)}
        share={() => setShowShare(true)}
        // allComments={comments}
        image={image}
      />
      <ShareFolder showModal={showShare} setShowModal={setShowShare} />
    </>
  );
};

export default FolderCustomerFiles;
