import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import Image from "../../Components/Image";
import OpenImage from "../../Components/OpenImage";
import Header from "../../Components/Header";
import CreateFolder from "../../Components/Modals/CreateFolder";
import Folder from "../../Components/Folder";
import BackButton from "../../Components/BackButton";
import ShareFolder from "../../Components/Modals/ShareFolder";
import AddToFolder from "../../Components/Modals/AddToFolder";
import SortFilter from "../../Components/SortFilter";
import authApi from "../../Utils/axiosConfig";

// these are dynamic pages and data are on behalf on helpers: MY_FILES & SHARED_FILES
// example url of this page:
// http://localhost:3000/my-footage/my-files/58814/photos

const MY_FILES = [
  {
    id: 58814,
    folderName: "Rahul Wed Nandhini",
    expiresInDays: 20,
    files: ["Photos", "Videos", "Other"],
    photos: [
      "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg",
      "https://dfstudio-d420.kxcdn.com/wordpress/wp-content/uploads/2019/06/digital_camera_photo-1080x675.jpg",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
    ],
  },
  {
    id: 64586,
    folderName: "Ibrahim Birthday",
    expiresInDays: 10,
    files: ["Photos", "Videos", "Other"],
    photos: [
      "https://t4.ftcdn.net/jpg/02/80/16/37/360_F_280163743_Ux10gTObj9YdQU86pXK75KRgdJllQlPk.jpg",
      "https://t4.ftcdn.net/jpg/02/80/16/37/360_F_280163743_Ux10gTObj9YdQU86pXK75KRgdJllQlPk.jpg",
      "https://t4.ftcdn.net/jpg/02/80/16/37/360_F_280163743_Ux10gTObj9YdQU86pXK75KRgdJllQlPk.jpg",
      "https://t4.ftcdn.net/jpg/02/80/16/37/360_F_280163743_Ux10gTObj9YdQU86pXK75KRgdJllQlPk.jpg",
      "https://t4.ftcdn.net/jpg/02/80/16/37/360_F_280163743_Ux10gTObj9YdQU86pXK75KRgdJllQlPk.jpg",
      "https://t4.ftcdn.net/jpg/02/80/16/37/360_F_280163743_Ux10gTObj9YdQU86pXK75KRgdJllQlPk.jpg",
      "https://t4.ftcdn.net/jpg/02/80/16/37/360_F_280163743_Ux10gTObj9YdQU86pXK75KRgdJllQlPk.jpg",
      "https://t4.ftcdn.net/jpg/02/80/16/37/360_F_280163743_Ux10gTObj9YdQU86pXK75KRgdJllQlPk.jpg",
      "https://t4.ftcdn.net/jpg/02/80/16/37/360_F_280163743_Ux10gTObj9YdQU86pXK75KRgdJllQlPk.jpg",
      "https://t4.ftcdn.net/jpg/02/80/16/37/360_F_280163743_Ux10gTObj9YdQU86pXK75KRgdJllQlPk.jpg",
      "https://t4.ftcdn.net/jpg/02/80/16/37/360_F_280163743_Ux10gTObj9YdQU86pXK75KRgdJllQlPk.jpg",
      "https://t4.ftcdn.net/jpg/02/80/16/37/360_F_280163743_Ux10gTObj9YdQU86pXK75KRgdJllQlPk.jpg",
      "https://t4.ftcdn.net/jpg/02/80/16/37/360_F_280163743_Ux10gTObj9YdQU86pXK75KRgdJllQlPk.jpg",
      "https://t4.ftcdn.net/jpg/02/80/16/37/360_F_280163743_Ux10gTObj9YdQU86pXK75KRgdJllQlPk.jpg",
    ],
  },
  {
    id: 5489,
    folderName: "Mahima Maternity Shoot",
    files: ["Photos", "Videos", "Other"],
    expiresInDays: 30,
    photos: [
      "https://dfstudio-d420.kxcdn.com/wordpress/wp-content/uploads/2019/06/digital_camera_photo-1080x675.jpg",
      "https://dfstudio-d420.kxcdn.com/wordpress/wp-content/uploads/2019/06/digital_camera_photo-1080x675.jpg",
      "https://dfstudio-d420.kxcdn.com/wordpress/wp-content/uploads/2019/06/digital_camera_photo-1080x675.jpg",
      "https://dfstudio-d420.kxcdn.com/wordpress/wp-content/uploads/2019/06/digital_camera_photo-1080x675.jpg",
      "https://dfstudio-d420.kxcdn.com/wordpress/wp-content/uploads/2019/06/digital_camera_photo-1080x675.jpg",
      "https://dfstudio-d420.kxcdn.com/wordpress/wp-content/uploads/2019/06/digital_camera_photo-1080x675.jpg",
      "https://dfstudio-d420.kxcdn.com/wordpress/wp-content/uploads/2019/06/digital_camera_photo-1080x675.jpg",
      "https://dfstudio-d420.kxcdn.com/wordpress/wp-content/uploads/2019/06/digital_camera_photo-1080x675.jpg",
      "https://dfstudio-d420.kxcdn.com/wordpress/wp-content/uploads/2019/06/digital_camera_photo-1080x675.jpg",
      "https://dfstudio-d420.kxcdn.com/wordpress/wp-content/uploads/2019/06/digital_camera_photo-1080x675.jpg",
      "https://dfstudio-d420.kxcdn.com/wordpress/wp-content/uploads/2019/06/digital_camera_photo-1080x675.jpg",
    ],
  },
];

const SHARED_FILES = [
  {
    id: 54782,
    folderName: "Test folder",
    expiresInDays: 20,
    files: ["Photos", "Videos", "Other"],
    photos: [
      "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg",
      "https://dfstudio-d420.kxcdn.com/wordpress/wp-content/uploads/2019/06/digital_camera_photo-1080x675.jpg",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
    ],
  },
  {
    id: 7895,
    folderName: "Shared Folder",
    expiresInDays: 10,
    files: ["Photos", "Videos", "Other"],
    photos: [
      "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg",
      "https://dfstudio-d420.kxcdn.com/wordpress/wp-content/uploads/2019/06/digital_camera_photo-1080x675.jpg",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
      "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
    ],
  },
];

const CustomerFiles = () => {
  const params = useParams();

  const navigate = useNavigate();

  const [files, setFiles] = useState();
  // params.slug === "my-files"
  //   ? MY_FILES
  //   : params.slug === "shared-files"
  //   ? SHARED_FILES
  //   : ""

  const [customer, setCustomer] = useState("");
  const [assets, setAssets] = useState([]);
  const [filterAssets, setFilterAssets] = useState([]);
  const [showOpenImage, setShowOpenImage] = useState(false);
  const [image, setImage] = useState("");

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedImageName, setSelectedImageName] = useState([]);
  const [showCreateFolder, setShowCreateFolder] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [showAddFolder, setShowAddFolder] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const [selectedImageToShare, setSelectedImageToShare] = useState([]);
  const [folders, setFolders] = useState([]);
  const [selectedSortFilter, setSelectedSortFilter] = useState("See All");
  const [selectedFilter, SetSelectedFilter] = useState("");
  const [allFavourites, setAllFavourites] = useState([]);
  const location = useLocation();

  console.log(location, "==Loc:selectedSortFilterselectedSortFilter");

  useEffect(() => {
    // const customer = files.find((item) => item.id == params.id);
    // setCustomer(customer);
    // setSelectedFiles(new Array(customer[params.folder].length).fill(false));
    // setAssets(customer[params.folder]);
    
    console.log(
      params.slug,
      " = params.slug, ",
      params.id,
      " = params.id, ",
      params.folder,
      " = folder"
    );
    const imageExtensions = [
      "jpg",
      "jpeg",
      "png",
      "gif",
      "bmp",
      "tiff",
      "svg",
      "webp",
    ];
    let hash = params.id;

    if (params.id === "editor-uploads") {
      hash = hash + "/" + params.folder;
    }

    if(params.id?.includes("folder-share-by-")){
      setFilterAssets(location.state);
    }
    else {
      setImageLoading(true);
      authApi
        .get(`/share/get-all-files-from-s3/${encodeURIComponent(hash)}`)
        .then((res) => {
          console.log(res.data, "res:get-all-files");
          // const images = res.data.data?.filter(imageUrl => imageExtensions.includes(imageUrl.slice(-3).toLocaleLowerCase()));
          // setAssets(images);
          setAssets(res.data.data);
          setFilterAssets(res.data.data);
          setImageLoading(false);
        })
        .catch((error) => {
          setImageLoading(false);
        });
  
      authApi
        .get(`/fav-pic/get-favourites/${encodeURIComponent(hash)}`)
        .then((res) => {
          console.log(res.data, "res:get-all-files:fav");
          setAllFavourites(res.data.data);
        })
        .catch((error) => {});
      
        authApi
        .get(`/fav-pic/get-folder/${encodeURIComponent(hash)}`)
        .then((res) => {
          console.log(res.data, "res:get-all-folder:");
          // setAllFavourites(res.data.data);
          let updatedFolders = [];
          res.data?.data?.forEach(data => {
            let newFolder = { folderName: data.folderName, selectedFiles: JSON.parse(data.images), };
            updatedFolders.push(newFolder);
          })
          // console.log(newFolder, "Heddddddyttfftf:2");
          setFolders(updatedFolders);
        })
        .catch((error) => {});
    }

  }, []);

  const openImage = (index) => {
    setImage(customer[params.folder][index]);
    setShowOpenImage(true);
  };

  const downloadImg = (src) => {
    let anchorTag = document.createElement("a");
    document.body.appendChild(anchorTag);
    fetch(src)
      .then((res) => res.blob())
      .then((value) => {
        const url = URL.createObjectURL(value);

        anchorTag.href = url;
        anchorTag.download = "my_image.png";

        anchorTag.click();
      })
      .catch((err) => console.log(err));

    document.body.removeChild(anchorTag);
  };

  const handleImageSelect = (index, imageName) => {
    const updatedImagesSelection = [...selectedFiles];
    // console.log(imageName, " ===handleImageSelect ");
    updatedImagesSelection[index] = !updatedImagesSelection[index];
    setSelectedFiles(updatedImagesSelection);
    if(selectedImageName?.includes(imageName)){
      const filteredImage = selectedImageName.filter(image => image !== imageName);
      setSelectedImageName(filteredImage);
    }
    else {
      setSelectedImageName(prev => [...prev, imageName]);
    }
  };

  const handleCreateFolder = (folderName) => {
    console.log(folderName, "====folderName_Create, ", params.id);
    authApi.post("/fav-pic/create-folder", { folderName, folderHash: params.id, images: selectedImageName })
    .then(res => {
      console.log(res.data, "===folderCreate_res");
    }).catch(err => {
      console.log(err, "===folderCreate_res:error");
    })
    
    let updatedFolders = [...folders];

    let newFolder = { folderName, selectedFiles: selectedImageName };
    console.log(newFolder, "Heddddddyttfftf:2");
    updatedFolders.push(newFolder);
    setFolders(updatedFolders);
    setSelectedImageName([]);
  };

  const handleAddImagesInFolder = (folderName) => {
    console.log(folderName, "====folderName_Create, ", params.id);
    authApi.post("/fav-pic/add-image-in-folder", { folderName, folderHash: params.id, images: selectedImageName })
    .then(res => {
      console.log(res.data, "===folderCreate_res");
    }).catch(err => {
      console.log(err, "===folderCreate_res:error");
    })
    
    // let updatedFolders = [...folders];

    let newFolder = { folderName, selectedFiles: selectedImageName };
    // console.log(newFolder, "Heddddddyttfftf:2");
    // updatedFolders.push(newFolder);
    const allFolders = folders.map(folder => {
      if(folder.folderName === folderName) return newFolder;
      return folder
    })
    setFolders(allFolders);
    setSelectedImageName([]);
  };

  const handleAddToFolder = (folderName) => {
    console.log(folderName);
    setShowAddFolder(false);
  };

  const filteredBySort = (by) => {
    console.log(by, "===byyyy");
    setSelectedSortFilter(by);
    if (by === "See All") {
      setFilterAssets(assets);
    } else {
      const allFiltered = [];
      console.log(assets, "===assettssssss");
      assets.forEach((asset) => {
        const arr = asset.split("/");
        const imageName = arr.pop();
        if (allFavourites.includes(imageName)) {
          console.log(imageName, "===assettssssss:if");
          allFiltered.push(asset);
        }
      });
      setFilterAssets(allFiltered);
    }
  };

  return (
    <>
      <Header />
      <section className="w-[80%] mx-auto p-4 min-h-full">
        <div className="w-full  flex justify-between items-center">
          <BackButton />
          <h1 className="md:text-3xl text-xl text-main font-bold text-center">
            {customer.folderName}
          </h1>
          {!location.state && <SortFilter
            selectedSortFilter={selectedSortFilter}
            setSelectedSortFilter={filteredBySort}
            options={["See All", "My Favorites"]}
          />}
        </div>
        <div></div>
        {/* <div className="text-xs md:text-sm rounded-md bg-[#f1c0b5] text-[#9f606d] text-center font-bold p-2 my-4">
          Some footages will be expired in 20 days. If you want to extend, click
          here or buy Lifetime Accessibility.
        </div> */}
        {!location.state && selectedFiles.some((item) => item === true) && (
          <div className="flex justify-end">
            <button
              className="px-2 py-1 bg-main rounded-md text-white my-3"
              onClick={() => setShowAddFolder(true)}
            >
              + Add to Folder
            </button>
          </div>
        )}
        <div className="grid gap-3 grid-cols-2 md:grid-cols-5 my-10">
          {imageLoading ? (
            <p>Loading...</p>
          ) : selectedSortFilter === "My Favorites" &&
            filterAssets?.length === 0 ? (
            <p>No Favorites Image Found</p>
          ) : (
            filterAssets?.map((item, index) => {
              return (
                <Image
                  key={item}
                  src={`${
                    process.env.REACT_APP_BACKEND_BASE_URL
                  }/api/files/${encodeURIComponent(item)}`}
                  alt="photo"
                  item={item}
                  allFavourites={allFavourites}
                  setAllFavourites={(data) => setAllFavourites(data)}
                  id={Date.now() + index}
                  open={() => openImage(index)}
                  download={() => downloadImg(item)}
                  share={() => setShowShare(true)}
                  onlyOpenOption={params.slug === "shared-files"}
                  onSelect={() => handleImageSelect(index, item)}
                  addToFolder={() => setShowAddFolder(true)}
                  hideMenu={location.state?.length > 0}
                />
              );
            })
          )}
          {folders?.map((item, index) => (
            <Folder
              images={item.selectedFiles}
              key={index}
              color="#54adef"
              text={item.folderName}
              id={`customer_${item?.folderName?.split(" ").join("")}_${index}`}
              href={`/my-footage/${params.slug}/${params.id}/${params.folder}/${item?.folderName?.trim()?.split(" ")?.join("")?.toLowerCase()}`}
              open={() => {
                navigate(
                  `/my-footage/${params.slug}/${params.id}/${
                    params.folder
                  }/${item.folderName
                    .trim()
                    .split(" ")
                    .join("")
                    .toLowerCase()}`
                );
              }}
              addToFolder={() => setShowAddFolder(true)}
              share={() => {setShowShare(true); setSelectedImageToShare(item.selectedFiles)}}
              onlyOpenOption={params.slug === "shared-files"}
            />
          ))}
        </div>
      </section>
      <OpenImage
        showModal={showOpenImage}
        setShowModal={setShowOpenImage}
        user="Rahul"
        download={() => downloadImg(image)}
        // allComments={comments}
        share={() => setShowShare(true)}
        image={image}
      />
      {/* <CreateFolder
        showModal={showCreateFolder}
        setShowModal={setShowCreateFolder}
        handleCreateFolder={(folderName) => handleAddToFolder(folderName)}
      /> */}
      <AddToFolder
        showModal={showAddFolder}
        setShowModal={setShowAddFolder}
        // options={["Favorites", "My Folder", "My Files"]}
        options={folders.map(folder => folder.folderName)}
        handleAddToFolder={(folderName) => handleAddToFolder(folderName)}
        handleCreateFolder={(folderName) => handleCreateFolder(folderName)}
        handleAddImagesInFolder={(folderName) => handleAddImagesInFolder(folderName)}
      />

      <ShareFolder showModal={showShare} setShowModal={setShowShare} images={selectedImageToShare} removeImages={()=>setSelectedImageToShare([])} />
    </>
  );
};

export default CustomerFiles;
