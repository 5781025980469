import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import PhotographerSidebar from "../Components/Sidebar";
import Swal from "sweetalert2";
import { uploadChunksByChunks } from "../../Utils/uploadChunks";
import { useId } from "react";
import authApi from "../../Utils/axiosConfig";
import Image from "../../Components/Image";
import { createTimelineAsync } from "../../Redux/slice/mainSlice";
import { useDispatch } from "react-redux";

const EditorProgressEventDetail = ({ isEditor = false }) => {
  let { pageId } = useParams();
  console.log(pageId, "====pageIdddddddddd");
  const navigate = useNavigate();
  const location = useLocation();
  const eventDetails = location.state;
  const [folder, SetFolder] = useState(false);
  const [assets, setAssets] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [selctedShareFolder, setSelctedShareFolder] = useState(null);
  const openFolder = (type) => {
    SetFolder(true);
    setSelectedFolder(type);
  };
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});
  const [isUploading, setIsUploading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [selected, setSelected] = useState([]);
  const [allShare, setAllShare] = useState([]);
  const dispatch = useDispatch();
  // const [id1, id2, id3] = [useId(), useId(), useId()];

  console.log(location.state, "state:ProgressEventDetail");
  console.log(selectedFolder, "==selectedFolderselectedFolder");

  useEffect(() => {
    console.log(selctedShareFolder, "==selctedShareFolderselctedShareFolder");
    if (selectedFolder === "raw") {
      setImageLoading(true);
      // const hash = location.state?.sharedUrl?.replace("photographer-uploads/", "photographer-uploads-");
      const hash = selctedShareFolder?.sharedUrl?.replace(
        "photographer-uploads/",
        "photographer-uploads-"
      );
      authApi
        .get(`/share/get-all-files-from-s3/${encodeURIComponent(hash)}`)
        .then((res) => {
          console.log(res.data, "res:get-all-files");
          // const images = res.data.data?.filter(imageUrl => imageExtensions.includes(imageUrl.slice(-3).toLocaleLowerCase()));
          // setAssets(images);
          setAssets(res.data.data);
          setImageLoading(false);
        })
        .catch((error) => {
          setImageLoading(false);
        });
    }
  }, [selectedFolder, selctedShareFolder]);

  const uploadedFilesProgress = (name, status) => {
    setUploadProgress((prev) => ({ ...prev, [name]: status }));
  };

  const handleFileUpload = async (e) => {
    const files = e.target.files;
    // const newUploadedFiles = [...uploadedFiles];
    // for (let i = 0; i < files.length; i++) {
    //   newUploadedFiles.push(files[i]);
    // }
    console.log(files, "files:handleFileUpload");
    setUploadedFiles([...files]);
  };

  const renderFilesList = () => {
    return uploadedFiles.map((file, index) => (
      <div className="single-file col-xl-2 col-lg-3 col-md-3 col-4" key={index}>
        <img
          src={URL.createObjectURL(file)}
          alt={file.name}
          style={{ width: "100%", height: "200px", objectFit: "cover" }}
        />
        <p>{file.name}</p>
      </div>
    ));
  };

  const showAlert = (isForEditor = false) => {
    // Display the SweetAlert2 dialog
    Swal.fire({
      title: "Successfully Send",
      text: isForEditor
        ? "Photos and Videos will be sent to Editor"
        : "Photos and Videos will be sent to Customer post review",
      icon: "success",
      showCancelButton: false,
      confirmButtonText: "Ok",
    }).then((result) => {
      if (result.isConfirmed) {
        // Redirect to the desired page
        window.location.href = isEditor
          ? "/editor/upcomingevents"
          : "/photographer/upcomingevents/0";
        console.log("Redirecting...");
      }
    });
  };

  function generateRandomString(length) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  }

  const sendToCustomer = async (isForEditor = false) => {
    try {
      // const uploadFilePromise = [];
      setIsUploading(true);
      const random = generateRandomString(20);
      const path = isEditor
        ? `editor-uploads/${random}`
        : `photographer-uploads/${random}`;
      for (const file of uploadedFiles) {
        uploadedFilesProgress(file.name, 0);
        // uploadFilePromise.push();
        await uploadChunksByChunks(file, path, uploadedFilesProgress);
      }

      // await Promise.allSettled(uploadFilePromise);
      setIsUploading(false);

      const data = {
        userId: isForEditor ? null : location?.state?.userId,
        sharedUrl: path,
        bookingId: location.state.id,
      };

      if (isEditor) {
        const editorId = localStorage.getItem("userId");
        data["editorId"] = editorId;
        const resp = await authApi.post("/share/editor-share-with-user", data);
        dispatch(
          createTimelineAsync({
            timelineData: {
              title: "Edited Footage Shared With Customer From Editor",
            },
            bookingId: location.state.id,
          })
        );
        console.log(resp.data, "resp:sendByEditor");
        showAlert();
      } else {
        data["isForEditor"] = isForEditor;
        data["pgId"] = location?.state?.BookingUser?.id;
        const resp = await authApi.post(
          "/share/photographer-share-with-user",
          data
        );
        console.log(resp.data, "resp:sendToCustomer");
        showAlert();
      }

      // console.log(data, "data:sendToCustomer");
      // const resp = await authApi.post("/share/editor-share-with-user", data);
      // console.log(resp.data, "resp:sendToCustomer");
      // showAlert();
    } catch (error) {
      Swal.fire({
        title: "Error in Sending Image/Video",
        text: "Please try again to share Photos/Videos",
        icon: "error",
        showCancelButton: false,
        confirmButtonText: "Ok",
      });
    }
  };

  const downloadFiles = async (fileNames) => {
    if (selected.length > 0) {
      for (let i = 0; i < selected.length; i++) {
        const src = selected[i];
        await downloadFile(src);
        // Wait a bit between downloads to avoid triggering browser's pop-up blocker
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
      setSelected([]);
    } else {
      downloadFile(fileNames);
    }
  };

  const downloadFile = (src) => {
    // Since this is a async operation, returning the fetch promise allows us to await on it
    return new Promise((resolve, reject) => {
      const anchorTag = document.createElement("a");
      document.body.appendChild(anchorTag);

      fetch(
        `${
          process.env.REACT_APP_BACKEND_BASE_URL
        }/api/files/${encodeURIComponent(src)}`
      )
        .then((res) => res.blob())
        .then((value) => {
          const url = URL.createObjectURL(value);
          anchorTag.href = url;
          anchorTag.download = src.split("/").pop(); // Assuming you might want just the file name, not the entire path

          anchorTag.click();
          URL.revokeObjectURL(url); // Cleanup the object URL
          document.body.removeChild(anchorTag);
          resolve(); // Resolve the promise after the download is initiated
        })
        .catch((err) => {
          console.log(err);
          reject(err); // Reject the promise if there's an error
        });
    });
  };

  const onSelect = (item) => {
    let updated;
    if (selected?.includes(item)) {
      updated = selected?.filter((data) => data !== item);
    } else {
      updated = [...selected, item];
    }
    setSelected(updated);
  };

  const FolderOrFilesJsx = () => {
    console.log(selectedFolder, "======selectedFoollldeerrr, ", location.state);
    if (selectedFolder === "raw" && selctedShareFolder !== null) {
      return (
        <div className="grid gap-3 grid-cols-2 md:grid-cols-5 my-10">
          {imageLoading ? (
            <p>Loading...</p>
          ) : (
            assets?.map((item, index) => {
              return (
                <Image
                  key={index}
                  src={`${
                    process.env.REACT_APP_BACKEND_BASE_URL
                  }/api/files/${encodeURIComponent(item)}`}
                  alt="photo"
                  id={Date.now() + index}
                  // open={() => openImage(index)}
                  download={() => downloadFiles(item)}
                  // share={() => setShowShare(true)}
                  // onlyOpenOption={params.slug === "shared-files"}
                  onSelect={() => onSelect(item)}
                  // addToFolder={() => setShowAddFolder(true)}
                />
              );
            })
          )}
        </div>
      );
    } else if (selectedFolder === "raw") {
      return (
        <div className="flex gap-3">
          {location.state?.allShares?.map((folder, index) => (
            <div className="folders-list d-flex flex-wrap gap-4">
              <div className="single-folder ">
                <button
                  className="openfolder bg-transparent border-0 "
                  onClick={() => setSelctedShareFolder(folder)}
                >
                  <span className="main-floder d-block">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      width="100"
                      height="100"
                      viewBox="0 0 48 48"
                    >
                      <path
                        fill="#FFA000"
                        d="M40,12H22l-4-4H8c-2.2,0-4,1.8-4,4v8h40v-4C44,13.8,42.2,12,40,12z"
                      ></path>
                      <path
                        fill="#FFCA28"
                        d="M40,12H8c-2.2,0-4,1.8-4,4v20c0,2.2,1.8,4,4,4h32c2.2,0,4-1.8,4-4V16C44,13.8,42.2,12,40,12z"
                      ></path>
                    </svg>
                  </span>
                  <span className="folder-name">{index + 1}</span>
                </button>
              </div>
            </div>
          ))}
        </div>
      );
    } else return <></>;
  };

  return (
    <>
      {isUploading && (
        <div className="fixed z-[9999999] top-0 left-0 w-full h-screen bg-gray-300/80 flex justify-center items-center flex-col gap-y-3">
          {Object.keys(uploadProgress).map((fileName) => (
            <div
              className="bg-orange-900 text-white flex justify-center items-center px-8 py-2 rounded-md"
              key={fileName}
            >
              {fileName} - {uploadProgress[fileName]}%
            </div>
          ))}
        </div>
      )}
      <div
        className={`photographer-page ${isUploading ? "overflow-hidden" : ""}`}
      >
        <Header />
        <section className="photographer-main pt-5">
          <div className="container-fluid px-0">
            <div className="photgrapher-main-start">
              <div className="row mx-0">
                <PhotographerSidebar isEditor={true} />
                <div className="col-md-9 px-0">
                  <div className="single-detail page-with-upload">
                    <div className="events">
                      <div className="events-list">
                        <div className="single-event">
                          <div className="event-top-data">
                            <h5 className="event-user">
                              {eventDetails?.BookingUser?.name}
                            </h5>
                            <span className="event-name normal-text">
                              Birthday
                            </span>
                            <span className="event-number normal-text">
                              {eventDetails?.BookingUser?.phoneNo}
                            </span>
                          </div>
                          <div className="d-flex justify-content-between event-other-details mb-3">
                            <span className="map-marker normal-text">
                              {location.state?.City?.name}
                            </span>
                            <span className="event-date normal-text">
                              Event Date: {eventDetails?.startDate} -{" "}
                              {eventDetails?.endDate}
                            </span>
                          </div>
                          <p className="main-location normal-text">
                            Outer Ring Rd, 7 Block, Banagirinagara, Banashankari
                            3rd Stage, Banashankari, Bengaluru, Karnataka
                            560070, India
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="p-4">
                      {!folder && (
                        <div className="folders-list d-flex flex-wrap gap-4">
                          <div className="single-folder ">
                            <button
                              className="openfolder bg-transparent border-0 "
                              onClick={() => openFolder("raw")}
                            >
                              <span className="main-floder d-block">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  x="0px"
                                  y="0px"
                                  width="100"
                                  height="100"
                                  viewBox="0 0 48 48"
                                >
                                  <path
                                    fill="#FFA000"
                                    d="M40,12H22l-4-4H8c-2.2,0-4,1.8-4,4v8h40v-4C44,13.8,42.2,12,40,12z"
                                  ></path>
                                  <path
                                    fill="#FFCA28"
                                    d="M40,12H8c-2.2,0-4,1.8-4,4v20c0,2.2,1.8,4,4,4h32c2.2,0,4-1.8,4-4V16C44,13.8,42.2,12,40,12z"
                                  ></path>
                                </svg>
                              </span>
                              <span className="folder-name">
                                Raw Photos/Videos
                              </span>
                            </button>
                          </div>
                          <div className="single-folder ">
                            <button
                              className="openfolder bg-transparent border-0 "
                              onClick={() => openFolder("edited")}
                            >
                              <span className="main-floder d-block">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  x="0px"
                                  y="0px"
                                  width="100"
                                  height="100"
                                  viewBox="0 0 48 48"
                                >
                                  <path
                                    fill="#FFA000"
                                    d="M40,12H22l-4-4H8c-2.2,0-4,1.8-4,4v8h40v-4C44,13.8,42.2,12,40,12z"
                                  ></path>
                                  <path
                                    fill="#FFCA28"
                                    d="M40,12H8c-2.2,0-4,1.8-4,4v20c0,2.2,1.8,4,4,4h32c2.2,0,4-1.8,4-4V16C44,13.8,42.2,12,40,12z"
                                  ></path>
                                </svg>
                              </span>
                              <span className="folder-name">
                                Edited Photos/Videos
                              </span>
                            </button>
                          </div>
                        </div>
                      )}
                      {folder && selectedFolder !== "raw" && (
                        <div className="the-files-here">
                          <div className="upload-file mb-4">
                            <label
                              htmlFor="uploadfile"
                              className="btn site-btn px-4"
                            >
                              Upload Photos/Videos
                            </label>
                            <input
                              type="file"
                              name=""
                              id="uploadfile"
                              hidden
                              multiple
                              onChange={handleFileUpload}
                            />
                          </div>
                          <div className="files-list row">
                            {renderFilesList()}
                          </div>
                          <div className="d-flex gap-4 upload-btn-for-user">
                            {!isEditor && (
                              <button
                                className="site-btn px-4"
                                onClick={() => sendToCustomer(true)}
                              >
                                Send to Editor{" "}
                                <i className="fa fa-angle-right"></i>
                              </button>
                            )}
                            <button
                              className="site-btn px-4"
                              onClick={() => sendToCustomer(false)}
                            >
                              Send to Customer{" "}
                              <i className="fa fa-angle-right"></i>
                            </button>
                          </div>
                        </div>
                      )}
                      {<FolderOrFilesJsx />}
                      {/* {selectedFolder === 'raw' ? (
                        <></>
                      ):(<></>)} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};
export default EditorProgressEventDetail;
