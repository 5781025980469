import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import PhotographerSidebar from "../Components/Sidebar";
import Card from "../Components/Card";
import axios from "axios";

const EditorUpcomingEvents = () => {
  const token = localStorage.getItem("authToken");
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [cardData, setCardData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiUrl = `${baseUrl}/editor/bookings`;
        const headers = {
          "x-auth-token": token,
        };
        const response = await axios.get(apiUrl, {
          params: {
            status: "CONFIRMED",
            // limit: 10,
            // offset: (currentPage - 1) * 10,
          },
          headers: headers,
        });
        console.log(response.data.data.booking, "====responseresponse:booking");
        if (response.status === 200) {
          const responseData = response.data.data;
          // if (Array.isArray(responseData.booking)) {
          if (typeof responseData.booking === 'object' && responseData.booking !== null) {
            console.log(responseData);
            // Ensure rows is an array
            setCardData(responseData.booking);
            // setTotalPages(Math.ceil(responseData.count / 10));
          } else {
            console.error("Invalid data format: rows is not an array.");
          }
        } else {
          console.error("Unable to fetch data.");
        }
      } catch (error) {
        console.error("Error while fetching data:", error);
      }
    };
    fetchData();
  }, [token]);


  console.log(cardData, "===cardDaaataatatatataat");

  return (
    <>
      <div className="photographer-page">
        <Header />
        <section className="photographer-main pt-5">
          <div className="container-fluid px-0">
            <div className="photgrapher-main-start">
              <div className="row mx-0">
                <PhotographerSidebar />
                <div className="col-md-9 px-3">
                  {/* <div className="single-detail">
                                        <div className="events">
                                            <div className="events-list">
                                                <Link className="single-event" to="">
                                                    <div className="event-top-data">
                                                        <h5 className="event-user">Rahul</h5>
                                                        <span className="event-name normal-text">Birthday</span>
                                                        <span className="event-number normal-text">+91 903XX XXXXX</span>
                                                    </div>
                                                    <div className="d-flex justify-content-between event-other-details mb-3">
                                                        <span className="map-marker normal-text">Bengaluru </span>
                                                        <span className="event-date normal-text">Event Date: 01st Sep 2022 - 03rd Sep 2022</span>
                                                    </div>
                                                    <p className="main-location normal-text">Outer Ring Rd, 7 Block, Banagirinagara, Banashankari 3rd Stage, Banashankari, Bengaluru, Karnataka 560070, India</p>
                                                </Link>
                                                <Link className="single-event" to="">
                                                    <div className="event-top-data">
                                                        <h5 className="event-user">Komal</h5>
                                                        <span className="event-name normal-text">Birthday</span>
                                                        <span className="event-number normal-text">+91 95786 95678</span>
                                                    </div>
                                                    <div className="d-flex justify-content-between event-other-details mb-3">
                                                        <span className="map-marker normal-text">Bengaluru </span>
                                                        <span className="event-date normal-text">Event Date: 10th Sep 2022 - 17th Sep 2022</span>
                                                    </div>
                                                    <p className="main-location normal-text">Outer Ring Rd, 7 Block, Banagirinagara, Banashankari 3rd Stage, Banashankari, Bengaluru, Karnataka 560070, India</p>
                                                </Link>
                                            </div>
                                        </div>
                                    </div> */}
                  <div className="grid grid-cols-2 gap-2">
                    {Object.keys(cardData)?.map((key, index) => {
                      const allShares = cardData[key];
                      const item = allShares[0];
                      // console.log(item, "----iteemmmmmsssssssssss");
                      return (
                      <Card
                        href="/editor/progress/folder/0"
                        name={item?.Booking?.BookingUser?.name}
                        city="Bengaluru"
                        phone={
                          item?.Booking?.BookingUser?.phoneNo ||
                          item?.Booking?.BookingUser?.email
                        }
                        eventStartDate={item?.Booking?.startDate}
                        eventEndDate={item?.Booking?.endDate}
                        location="Outer Ring Rd, 7 Block, Banagirinagara, Banashankari 3rd Stage, Banashankari, Bengaluru, Karnataka 560070, India"
                        btn={true}
                        btnText="Start Event"
                        btnBg="green"
                        btnHandleClick={() => console.log("hey")}
                        eventDetails={{ ...item, ...item.Booking, allShares }}
                        id={item?.id}
                        inProgress={true}
                      />
                    )})}
                    {/* <Card
                      //   href="/photographer/progress/folder/1"
                      name="Komal"
                      city="Bengaluru"
                      phone="+91 95786 95678"
                      eventStartDate="10st Sep 2022"
                      eventEndDate="17rd Sep 2022"
                      location="Outer Ring Rd, 7 Block, Banagirinagara, Banashankari 3rd Stage, Banashankari, Bengaluru, Karnataka 560070, India"
                      btn={true}
                      btnText="Start Event"
                      btnBg="green"
                      btnHandleClick={() => console.log("hey")}
                    /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};
export default EditorUpcomingEvents;
