import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import PhotographerSidebar from "../Components/Sidebar";
import Swal from "sweetalert2";
import { AiOutlineDownload, AiOutlineCloudUpload } from "react-icons/ai";

const EditorAllEventDetail = () => {
  const navigate = useNavigate();

  const [folder, setFolder] = useState("");

  //   const openFolder = () => {
  //     setFolder(true);
  //   };

  const [uploadedFiles, setUploadedFiles] = useState([]);

  const handleFileUpload = (e) => {
    const files = e.target.files;
    const newUploadedFiles = [...uploadedFiles];

    for (let i = 0; i < files.length; i++) {
      newUploadedFiles.push(files[i]);
    }

    setUploadedFiles(newUploadedFiles);
  };

  const renderFilesList = () => {
    return uploadedFiles.map((file, index) => (
      <div className="single-file col-xl-2 col-lg-3 col-md-3 col-4" key={index}>
        <img
          src={URL.createObjectURL(file)}
          alt={file.name}
          style={{ width: "100%", height: "200px", objectFit: "cover" }}
        />
        <p>{file.name}</p>
      </div>
    ));
  };
  const showAlert = () => {
    // Display the SweetAlert2 dialog
    Swal.fire({
      title: "Booking Confirmation",
      text: "Photos and Videos will be sent to  Customer post review",
      icon: "success",
      showCancelButton: false,
      confirmButtonText: "Ok",
    }).then((result) => {
      if (result.isConfirmed) {
        // Redirect to the desired page
        window.location.href = "/photographer/upcomingevents";
        console.log("Redirecting...");
      }
    });
  };
  return (
    <>
      <div className="photographer-page">
        <Header />
        <section className="photographer-main pt-5">
          <div className="container-fluid px-0">
            <div className="photgrapher-main-start">
              <div className="row mx-0">
                <PhotographerSidebar />
                <div className="col-md-9 px-0">
                  <div className="single-detail page-with-upload">
                    <div className="events">
                      <div className="events-list">
                        <div className="single-event">
                          <div className="event-top-data">
                            <h5 className="event-user">Rahul</h5>
                            <span className="event-name normal-text">
                              Birthday
                            </span>
                            <span className="event-number normal-text">
                              +91 903XX XXXXX
                            </span>
                          </div>
                          <div className="d-flex justify-content-between event-other-details mb-3">
                            <span className="map-marker normal-text">
                              Bengaluru{" "}
                            </span>
                            <span className="event-date normal-text">
                              Event Date: 01st Sep 2022 - 03rd Sep 2022
                            </span>
                          </div>
                          <p className="main-location normal-text">
                            Outer Ring Rd, 7 Block, Banagirinagara, Banashankari
                            3rd Stage, Banashankari, Bengaluru, Karnataka
                            560070, India
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="p-4">
                      {!folder && (
                        <div className="folders-list d-flex flex-wrap gap-3">
                          <div className="single-folder ">
                            <button
                              className="openfolder bg-transparent border-0 "
                              onClick={() => setFolder("raw")}
                            >
                              <span className="main-floder d-block">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  x="0px"
                                  y="0px"
                                  width="100"
                                  height="100"
                                  viewBox="0 0 48 48"
                                >
                                  <path
                                    fill="#FFA000"
                                    d="M40,12H22l-4-4H8c-2.2,0-4,1.8-4,4v8h40v-4C44,13.8,42.2,12,40,12z"
                                  ></path>
                                  <path
                                    fill="#FFCA28"
                                    d="M40,12H8c-2.2,0-4,1.8-4,4v20c0,2.2,1.8,4,4,4h32c2.2,0,4-1.8,4-4V16C44,13.8,42.2,12,40,12z"
                                  ></path>
                                </svg>
                              </span>
                              <span className="folder-name">
                                Raw Photos/Videos
                              </span>
                            </button>
                          </div>
                          <div className="single-folder ">
                            <button
                              className="openfolder bg-transparent border-0 "
                              onClick={() => setFolder("edited")}
                            >
                              <span className="main-floder d-block">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  x="0px"
                                  y="0px"
                                  width="100"
                                  height="100"
                                  viewBox="0 0 48 48"
                                >
                                  <path
                                    fill="#FFA000"
                                    d="M40,12H22l-4-4H8c-2.2,0-4,1.8-4,4v8h40v-4C44,13.8,42.2,12,40,12z"
                                  ></path>
                                  <path
                                    fill="#FFCA28"
                                    d="M40,12H8c-2.2,0-4,1.8-4,4v20c0,2.2,1.8,4,4,4h32c2.2,0,4-1.8,4-4V16C44,13.8,42.2,12,40,12z"
                                  ></path>
                                </svg>
                              </span>
                              <span className="folder-name">
                                {" "}
                                Edited Photos/Videos{" "}
                              </span>
                            </button>
                          </div>
                        </div>
                      )}
                      {folder === "edited" && (
                        <div className="the-files-here">
                          <div className="flex justify-end">
                            <div className="upload-file mb-4">
                              <label
                                htmlFor="uploadfile"
                                className="btn site-btn px-4 flex items-center gap-1"
                              >
                                Upload Photos/Videos
                                <AiOutlineCloudUpload className="text-xl" />
                              </label>
                              <input
                                type="file"
                                name=""
                                id="uploadfile"
                                hidden
                                multiple
                                onChange={handleFileUpload}
                              />
                            </div>
                          </div>
                          <div className="files-list row">
                            {renderFilesList()}
                          </div>
                          <button
                            className="upload-btn-for-user site-btn px-4"
                            onClick={showAlert}
                          >
                            Send to Customer{" "}
                            <i className="fa fa-angle-right"></i>
                          </button>
                        </div>
                      )}
                      {folder === "raw" && (
                        <button className="upload-btn-for-user site-btn px-4 flex items-center gap-1 ">
                          Download Footages
                          <AiOutlineDownload className="text-xl" />
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};
export default EditorAllEventDetail;
