import React, { useState, useEffect } from "react";
import { MdEmail } from "react-icons/md";
import { BiSupport } from "react-icons/bi";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Banner from "../../Components/banner";
import axios from "axios";
import Swal from "sweetalert2";

const ContactUs = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const token = localStorage.getItem("authToken");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    subject: "",
    message: "",
  });

  const [formErrors, setFormErrors] = useState({
    firstName: false,
    lastName: false,
    email: false,
    subject: false,
    message: false,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: !value.trim(),
    }));

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Basic form validation
    const newFormErrors = {
      firstName: !formData.firstName.trim(),
      lastName: !formData.lastName.trim(),
      email: !formData.email.trim(),
      subject: formData.subject.trim().length < 10,
      message: formData.message.trim().length < 10,
    };

    setFormErrors(newFormErrors);

    // Check if there are any errors before submitting
    if (!Object.values(newFormErrors).some((error) => error)) {
      // TODO: Handle form submission (e.g., send data to server)
      console.log("Form submitted:", formData);
      const data = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        subject: formData.subject,
        message: formData.message,
        email: formData.email,
      };

      try {
        const response = await axios.post(`${baseUrl}/user/contact-us`, data);

        if (response.status === 200) {
          console.log(response);
          setSuccessMessage("Form Submitting successfully");
          setErrorMessage(""); // Reset error message
          // Reset form data after successful submission if needed
          setFormData({
            firstName: "",
            lastName: "",
            email: "",
            subject: "",
            message: "",
          });
          Swal.fire({
            icon: "success",
            text:
              "Form submitted successfully, our team will get back to you shortly.",
          });
        }
      } catch (error) {
        console.log(error);
        setSuccessMessage(""); // Reset success message
        setErrorMessage("Failed to submit form");
      }
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setSuccessMessage("");
      setErrorMessage("");
    }, 5000); // Adjust the time (in milliseconds) as needed

    return () => clearTimeout(timer);
  }, [successMessage, errorMessage]);

  return (
    <>
      <main className="site-front-page">
        <Header />
        <Banner home="Home" currenturl="/contactus" current="Contact Us" />

        <section className="p-2 md:p-5 w-full flex justify-center items-center">
          <div className="md:w-[80%] md:grid md:grid-cols-3 min-h-[750px] lg:min-h-[650px]">
            <div className="md:col-span-2 shadow-2xl p-5 flex flex-col gap-4 bg-white my-auto h-[90%]">
              <h2
                className="text-main font-bold"
                style={{ textShadow: "none" }}
              >
                Get In Touch
              </h2>
              {/* {Object.values(formErrors).some((error) => error) && (
                <p style={{ color: "red" }}>Please fill in all fields</p>
              )} */}
              {/* {successMessage && (
                <p style={{ color: "green" }}>{successMessage}</p>
              )} */}
              {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
              <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
                <div className="grid lg:grid-cols-2 gap-3">
                  <div>
                    <input
                      type="text"
                      name="firstName"
                      placeholder="First Name*"
                      value={formData.firstName}
                      onChange={handleInputChange}
                      className={`w-full outline-none text-sm border-[1px] ${
                        formErrors.firstName ? "border-red-500" : "border-main"
                      } text-main rounded-md px-4 py-[10px] placeholder:text-main`}
                    />
                    {formErrors.firstName && (
                      <p className="mt-1 text-xs text-red-500">
                        First Name is required
                      </p>
                    )}
                  </div>
                  <div>
                    <input
                      type="text"
                      name="lastName"
                      placeholder="Last Name*"
                      value={formData.lastName}
                      onChange={handleInputChange}
                      className={`w-full  outline-none text-sm border-[1px] ${
                        formErrors.lastName ? "border-red-500" : "border-main"
                      } text-main rounded-md px-4 py-[10px] placeholder:text-main`}
                    />
                    {formErrors.lastName && (
                      <p className="mt-1 text-xs text-red-500">
                        Last Name is required
                      </p>
                    )}
                  </div>
                </div>
                <div className="grid lg:grid-cols-2 gap-3">
                  <div>
                    <input
                      type="email"
                      name="email"
                      placeholder="Email ID*"
                      value={formData.email}
                      onChange={handleInputChange}
                      className={`w-full outline-none text-sm border-[1px] ${
                        formErrors.email ? "border-red-500" : "border-main"
                      } text-main rounded-md px-4 py-[10px] placeholder:text-main`}
                    />
                    {formErrors.email && (
                      <p className="mt-1 text-xs text-red-500">
                        Email is required
                      </p>
                    )}
                  </div>
                  <div>
                    <input
                      type="text"
                      name="subject"
                      placeholder="Subject*"
                      value={formData.subject}
                      onChange={handleInputChange}
                      className={`w-full outline-none text-sm border-[1px] ${
                        formErrors.subject ? "border-red-500" : "border-main"
                      } text-main rounded-md px-4 py-[10px] placeholder:text-main`}
                    />
                    {formErrors.subject && (
                      <p className="mt-1 text-xs text-red-500">
                        Subject is required and must be 10 characters long
                      </p>
                    )}
                  </div>
                </div>
                <div className="w-full">
                  <textarea
                    name="message"
                    id=""
                    rows="10"
                    value={formData.message}
                    onChange={handleInputChange}
                    className={`w-full text-sm rounded-md resize-none border-[1px] ${
                      formErrors.message ? "border-red-500" : "border-main"
                    } outline-none px-4 py-[10px] text-main placeholder:text-main`}
                    placeholder="Message*"
                  ></textarea>
                  {formErrors.message && (
                    <p className="text-xs text-red-500">
                      Message is required and must be 10 characters long
                    </p>
                  )}
                </div>
                <div>
                  <button
                    type="submit"
                    className="py-[10px] px-[25px] text-white text-xs bg-main rounded-md"
                  >
                    Send Message
                  </button>
                </div>
              </form>
            </div>
            <div className="md:col-span-1 flex flex-col items-center py-4 bg-main text-white rounded-sm">
              <h1 className=" font-bold" style={{ textShadow: "none" }}>
                Contact Us
              </h1>

              <div className="flex flex-col gap-5 items-center my-auto h-[70%] justify-evenly">
                <div className="flex flex-col gap-2 items-center">
                  <div className="flex gap-3 items-center">
                    <div className="w-10 p-2 h-10 flex items-center justify-center rounded-full bg-white">
                      <BiSupport className="text-main text-3xl text-center" />
                    </div>
                    <h3 className="font-extrabold">Call</h3>
                  </div>

                  <p className="font-bold">+91 7643 991 050</p>
                  <p className="">(Between 10 AM to 6PM)</p>
                  <p className="">Monday to Friday</p>
                </div>

                <div className="flex flex-col gap-2 items-center">
                  <div className="flex gap-3 items-center">
                    <div className="w-10 p-2 h-10 flex items-center justify-center rounded-full bg-white">
                      <MdEmail className="text-main text-3xl text-center" />
                    </div>

                    <h3 className="font-extrabold">Email</h3>
                  </div>

                  <p className="font-bold">support@boopho.com</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </main>
    </>
  );
};

export default ContactUs;
