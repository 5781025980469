import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Pagination from "../../Components/Pagination";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

import {
  Studio1,
  Studio2,
  Studio3,
  Studio4,
  Studio5,
  Studio6,
  Baricon,
} from "../../Constant/Index";
import { IoLocationSharp } from "react-icons/io5";
import StudioCard from "../../Components/StudioCard";

const StudioCompnent = ({ categoryName }) => {
  const [wish, setWish] = useState([]);
  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem("loggedIn") === "true"
  );
  const toggleWishItem = (index) => {
    setWish((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = !newStates[index];
      // newStates[index]
      //   ? toast.success("Added to wishlist!", {
      //       position: toast.POSITION.TOP_RIGHT,
      //       autoClose: 3000, // Auto close the toast after 3 seconds
      //       hideProgressBar: false,
      //       closeOnClick: true,
      //       pauseOnHover: true,
      //     })
      //   : toast.error("Removed From WIshlist!", {
      //       position: toast.POSITION.TOP_RIGHT,
      //       autoClose: 3000, // Auto close the toast after 3 seconds
      //       hideProgressBar: false,
      //       closeOnClick: true,
      //       pauseOnHover: true,
      //     });
      return newStates;
    });
    // const showToast = () => {

    // };
  };

  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [photographer, setPhotographer] = useState([]);

  const id = useParams();

  const photoGrapherSearch = () => {
    const params = {
      categoryId: id.type,
      limit: 8,
    };

    console.log("params", params);
    axios
      .get(`${baseUrl}/photographer/search`, { params })
      .then((response) => {
        console.log(response.data.data.data);
        setPhotographer(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  };

  useEffect(() => {
    photoGrapherSearch();
  }, []);
  return (
    <>
      <section className="filters-section">
        <div className="bootstrap-container">
          <div className="filters-start">
            <h2 className="top-heading lg-heading mb-2 wow animate__animated animate__rubberBand animate__delay-1s">
              Our Top {categoryName} Photographers
            </h2>
          </div>

          {/* <TheDate />

                    <div className="filter-list-2 mt-4">

                        <div className="normal-filter wow animate__animated animate__fadeInLeft animate__delay-1s">
                            <select name="" id="" className='form-select form-control'>
                                <option value="">Price</option>
                                <option value="100">100</option>
                                <option value="1000">1000</option>
                            </select>
                            <select name="" id="" className='form-select form-control'>
                                <option value="">Rating</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                            </select>
                            <select name="" id="" className='form-select form-control'>
                                <option value="">Services</option>
                                <option value="Wedding">Wedding</option>
                                <option value="Maternity">Maternity</option>
                                <option value="Baby">Baby</option>
                                <option value="Birthday">Birthday</option>
                                <option value="Business">Business</option>
                            </select>
                            <select name="" id="" className='form-select form-control'>
                                <option value="">Discount</option>
                                <option value="10">10%</option>
                                <option value="20">20%</option>
                                <option value="30">30%</option>
                                <option value="40">40%</option>
                                <option value="50">50%</option>
                            </select>
                            <button className="clear-filter btn btn-warning">Clear All Filters</button>
                        </div>
                        <div className="sorting d-flex gap-2 align-items-center wow animate__animated animate__fadeInRight animate__delay-1s">
                            <label htmlFor="" className="sort">Sort:</label>
                            <select name="" id="" className='form-select form-control'>
                                <option value="Featured">Featured</option>
                            </select>
                        </div>
                    </div> */}
        </div>
      </section>

      <section className="studio-section overflow-hidden">
        <div className="bootstrap-container">
          <div className="studio-start-here pb-5">
            <div className="row">
              {photographer?.count > 0 ? (
                photographer?.data?.map((studio, index) => (
                  <a
                    href={`/photography/${id.type}/${studio.Photographer?.id}`}
                    target="_blank"
                    className={`hover:text-black col-lg-3 col-md-6 my-3`}
                  >
                    {/* <Link to="/studio-details" style={{textDecoration: "none"}}> */}
                    <div
                      className={`single-studio  pb-4`}
                      // ${
                      //   studio.availability ? "" : "outstock"
                      // }
                    >
                      {studio.Discount && (
                        <span className="discount-offer">
                          <span className="discount-text">
                            {studio.Discount} off
                          </span>
                        </span>
                      )}
                      <figure className="sutdio-img">
                        <img
                          src={
                            studio?.Photographer?.FavouritePictures[0]
                              ?.imageUrl ??
                            studio?.Photographer?.category[id.type].image?.[0]
                          }
                          alt=""
                          className="w-100"
                        />
                        {loggedIn ? (
                          <button
                            onClick={(e) => {
                              e.preventDefault(); // Prevent the link from being followed
                              toggleWishItem(index);
                            }}
                            className="absolute heart-icon !top-[80%] z-10"
                          >
                            <i
                              class={`fa fa-heart${wish[index] ? "" : "-o"}`}
                            ></i>
                          </button>
                        ) : (
                          <a
                            className="absolute heart-icon !top-[80%] z-10"
                            data-bs-toggle="modal"
                            data-bs-target="#LoginModal"
                            role="button"
                          >
                            <i class={`fa fa-heart-o`}></i>
                          </a>
                        )}
                      </figure>
                      <div className="studio-text">
                        <h6 className="name">
                          {studio.Photographer?.studioName}
                        </h6>
                        <div className="rating-place">
                          <p className="place-name">
                            {/* <img src={Baricon} alt="" /> */}
                            <IoLocationSharp className="text-xl" />
                            {studio.Photographer?.serviceLocation}
                          </p>
                          <div className="text-green-500 text-lg font-bold flex gap-2 items-center">
                            {Math.round(studio.averageRating * 10) / 10}
                            <i className="fa fa-star"></i>{" "}
                          </div>
                        </div>
                        <div className="flex flex-col gap-1">
                          <p className="px-2 text-[#666666]">Average rate</p>
                          <div className="price d-flex gap-1 align-items-center">
                            <p className="flex gap-2 items-center text-xl font-bold text-[#666666]">
                              ₹ {studio?.basicPrice}{" "}
                              <p className="text-sm font-extralight">per day</p>
                            </p>
                          </div>
                        </div>
                        {/* <Link
                          className="btn btn-primary w-100 d-block mt-3"
                          to={`/photography/${id.type}/${studio.Photographer?.id}`}
                        >
                          View Details
                        </Link> */}
                      </div>
                    </div>
                  </a>
                ))
              ) : (
                <div className="col-12 text-center">
                  <p>No results found</p>
                </div>
              )}
            </div>
          </div>
          {/* <Pagination total={5} current={3} /> */}
          {/* <ToastContainer /> */}
        </div>
      </section>
    </>
  );
};
export default StudioCompnent;
