import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../Components/Header";
import PopupMessage from "../../Components/PopupMessage";
import Footer from "../../Components/Footer";
import { UploadIcon } from "../../Constant/Index";
import axios from "axios";
// import { dA } from "@fullcalendar/core/internal-common";

const EnterPhotographer = () => {
  const navigate = useNavigate();
  const id = localStorage.getItem("userId");
  const name = localStorage.getItem("userName");
  const email = localStorage.getItem("email");
  const phoneNo = localStorage.getItem("phoneNo");
  const status = localStorage.getItem("photographer_status");
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [emailData, setEmailData] = useState("");
  const [otp, setOtp] = useState("");
  const [otpValidation, setOtpValidation] = useState(false);
  const token = localStorage.getItem("authToken");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [inputValue, setInputValue] = useState("");

  const [usingVer, setUsingVeri] = useState(false);
  const [usingPhone, setUsingPhone] = useState(false);
  const [usingEmail, setUsingEmail] = useState(false);
  const [otpSend, setOtpSend] = useState(false);
  const [photographerId, setPhotographerId] = useState(null);
  const asPhone = () => {
    setUsingVeri(true);
    setUsingPhone(true);
  };
  const asEmail = () => {
    setUsingVeri(true);
    setUsingEmail(true);
  };
  const sendOtp = () => {
    setUsingEmail(false);
    setOtpSend(true);
    setUsingPhone(false);
  };
  const redirectTOHome = () => {
    navigate("/editor/pastevents"); // Replace '/other' with the desired page URL
  };
  const backBtn = () => {
    setUsingEmail(false);
    setOtpSend(false);
    setUsingPhone(false);
    setUsingVeri(false);
    setErrorMessage("");
    setSuccessMessage("");
  };
  const handleOTP = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");

    const headers = {
      "x-auth-token": token,
    };

    const data = {
      photographerId: photographerId,
      otp: otp,
    };

    try {
      const response = await axios.post(`${baseUrl}/editor/register`, data, {
        headers,
      });
      console.log(response.data);
      setSuccessMessage(response.data.message);
      setOtp("");
      setEmailData("");
      navigate("/editor/pastevents");
    } catch (error) {
      console.log(error.response);

      setErrorMessage(error.response.data.error.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");
    setUsingEmail(false);
    setUsingPhone(false);
  
    try {
      if (inputValue && inputValue.match(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/)) {
        const response = await axios.post(
          `${baseUrl}/editor/send-otp-email`,
          { email: inputValue },
          {
            headers: {
              "x-auth-token": token,
            },
          }
        );
        console.log(response.data, "====res:otpSend:editor");
        setPhotographerId(response.data.data.photographerId);
        setOtpSend(true);
        setSuccessMessage(response.data.message);
      } else {
        const response = await axios.post(`${baseUrl}/auth/resend-otp`, {
          phoneNo: "+91" + inputValue,
        });
        console.log(response.data, "====res:otpReSend:editor");
        setOtpSend(true);
        setSuccessMessage(response.data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage(
        error.response?.data?.error.message || "An error occurred"
      );
    }
  };
  

  useEffect(() => {
    setErrorMessage("");
    setSuccessMessage("");
  }, [inputValue, otp]);

  return (
    <>
      <div className="">
        <Header />
        <section className="profile-prohotgrapher mt-5">
          <div className="bootstrap-container relative">
            <h1 className=" mt-lg-5 text-center position-relative">
              {/* <button
                className="site-btn position-md-absolute d-block w-fit"
                style={{ left: "12px" }}
                onClick={() => navigate(-1)}
              >
                <i
                  className="fa fa-angle-left"
                  style={{ fontSize: "23px" }}
                ></i>
              </button> */}
              <strong className="text-main font-bold">
                List Your Business
              </strong>
            </h1>
            <Link
              to="/photographer/register"
              className="absolute top-[-20px] md:top-1/2 right-6 md:right-0 text-white bg-main px-3 py-1 rounded-md"
            >
              SignUp As Photographer
            </Link>
            <p className="text text-center col-lg-8 col-m-9 mx-auto">
              <strong className="text-gray-600">
                We only onboard editor who is associated with one of our
                photographer. If you still want to be onboarded, please send us
                an email at{" "}
                <a href="mailto: support@boopho.com">support@boopho.com</a> with
                all your details.{" "}
              </strong>
            </p>
          </div>

          {/* <hr className="mb-5" /> */}
          <div className="bootstrap-container   py-5 mt-t">
            <div className="col-xl-10 col-lg-11 mx-auto">
              <div className="col-lg-10">
                <div className="profile-upate-satrt">
                  {status === "APPROVED" || status === null ? (
                    <form onSubmit={handleSubmit}>
                      <div class="mb-3 form-group">
                        <div className="row">
                          <div className="col-md-3">
                            <label htmlFor="">Name *</label>
                          </div>
                          <div className="col-md-9">
                            <div class="input-group mb-3">
                              <strong>{name}</strong>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="mb-3 form-group">
                        <div className="row">
                          <div className="col-md-3">
                            <label htmlFor="">Phone Number *</label>
                          </div>
                          <div className="col-md-9">
                            <div class="input-group mb-3">
                              <strong>{phoneNo}</strong>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="mb-3 form-group">
                        <div className="row">
                          <div className="col-md-3">
                            <label htmlFor="">Email ID *</label>
                          </div>
                          <div className="col-md-9">
                            <div class="input-group mb-3">
                              <strong>{email}</strong>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 mx-auto" style={{ height: 200 }} >
                        {!usingVer && (
                          <p className="text-center px-lg-4">
                            Enter photographer’s email or Phone <br /> number
                            who is already registered with us{" "}
                          </p>
                        )}
                        <div className="col-lg-10 col-md-4 mx-auto mt-3">
                          {!usingVer && (
                            <div className="d-flex flex-column gap-2">
                              {/* <p className="text-center px-lg-4">Enter photographer’s email or Phone <br /> number who is already registered with us </p> */}
                              <button
                                class="btn site-btn d-block w-100 py-3"
                                onClick={asPhone}
                              >
                                Verify using phone number
                              </button>
                              <button
                                class="btn site-btn d-block w-100 py-3"
                                onClick={asEmail}
                              >
                                Verify using email ID
                              </button>
                            </div>
                          )}
                          {usingVer && (
                            <button
                              className="btn site-btn pb-1 d-block w-fit"
                              onClick={backBtn}
                            >
                              <i className="fa fa-angle-left fa-2x"></i>
                            </button>
                          )}

                          {successMessage && (
                            <p className="text-success mt-3">
                              {successMessage}
                            </p>
                          )}
                          {errorMessage && (
                            <p className="text-danger mt-3">{errorMessage}</p>
                          )}

                          {usingPhone && usingVer && (
                            <>
                              <div className="form-group pt-3">
                                <p className="text-center px-lg-4 mb-3">
                                  Enter Phone Number
                                </p>
                                <div class="mb-3 form-group">
                                  <div class="input-group mb-3">
                                    <span
                                      class="input-group-text fit-content"
                                      id="basic-addon1"
                                    >
                                      <span class="flag-icon flag-icon-in flag-icon-squared"></span>
                                    </span>
                                    <span
                                      class="input-group-text fit-content  border-left-0 ps-0"
                                      id="basic-addon1"
                                    >
                                      <span class="">+91</span>
                                    </span>
                                    <input
                                      type="number"
                                      class="form-control"
                                      placeholder="Phone Number"
                                      aria-label="Phone Number"
                                      required=""
                                      name="input_value"
                                      onChange={(e) =>
                                        setInputValue(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                                <button
                                  className="send-otp btn site-btn w-100 d-block"
                                  onClick={handleSubmit}
                                >
                                  Send OTP
                                </button>
                              </div>
                            </>
                          )}
                          {usingEmail && usingVer && (
                            <>
                              <div className="form-group pt-3">
                                <label htmlFor="">
                                  Enter Your Email Address
                                </label>
                                <input
                                  type="email"
                                  name="input_value"
                                  onChange={(e) =>
                                    setInputValue(e.target.value)
                                  }
                                  id=""
                                  placeholder="Email Address"
                                  className="form-control mb-4"
                                />
                                <button
                                  className="send-otp btn site-btn w-100 d-block"
                                  onClick={handleSubmit}
                                >
                                  Send OTP
                                </button>
                              </div>
                            </>
                          )}

                          {otpSend && usingVer && (
                            <>
                              <div className="form-group pt-3">
                                <label htmlFor="">Enter Your OTP</label>
                                <input
                                  type="number"
                                  name="otp"
                                  id=""
                                  placeholder="Enter OTP"
                                  className="form-control mb-4"
                                  onChange={(e) => setOtp(e.target.value)}
                                />
                                <button
                                  className="send-otp btn site-btn w-100 d-block"
                                  onClick={handleOTP}
                                >
                                  Verify OTP
                                </button>
                                <button
                                  className="send-otp btn site-btn w-100 d-block mt-4"
                                  onClick={handleSubmit}
                                >
                                  Resend OTP
                                </button>
                              </div>
                            </>
                          )}
                        </div>

                        {/* <input type="text" name="" id="" className="form-control mb-4" /> */}
                        {/* <p className="error text-danger">Photographer is not registered with us. Please ask your photographer to register with us <Link to="/photographer/register">here</Link> before you can register. </p> */}
                        {/* <button className="btn site-btn d-block w-fit mx-auto px-4">Send OTP on Photographer’s mobile number <i className="fa fa-angle-right ps-3"></i></button> */}
                      </div>

                      {/* <button type="submit" class="btn btn-primary d-block mx-auto site-btn px-5">Submit the details</button> */}
                    </form>
                  ) : (
                    <PopupMessage message="You have requested to signup as photographer. Please wait for that process to be completed" />
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};
export default EnterPhotographer;
