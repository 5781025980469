import React from "react";
import { Link } from "react-router-dom";
import { ReportIcon } from "../Constant/Index";
import BookingInfo from "./Modals/BookingInfo";

const CancelledBookingCard = ({
  id,
  image,
  name,
  area,
  startDate,
  endDate,
  handleShowChat,
}) => {
  console.log(startDate, endDate, "DATATATATATATT");
  return (
    <>
      <div className="single-booking wow animate__animated animate__fadeIn animate__delay-1s">
        <div className="row">
          <div className="col-md-3 overflow-hidden">
            <figure className="studio-image" style={{ height: "100px" }}>
              <img src={image} className=" w-100" alt="" />
            </figure>
          </div>
          <div className="col-md-9">
            <div className="booking-data h-100">
              <button
                className="report-studio"
                data-bs-toggle="modal"
                data-bs-target="#bookingInfo"
                data-bs-dismiss="modal"
              >
                <img src={ReportIcon} className="report-image" alt="" />
              </button>
              <div className="studio-data">
                <Link to="" className="!text-[#620808]">
                  {name}
                </Link>
                <p className="location">{area}</p>
              </div>
              <div className="about-booking h-100 d-flex flex-column justify-content-between py-lg-4 text-end">
                Event Date:
                <p className="date pt-lg-3 !text-main">
                  {startDate} - {endDate}
                </p>
                <p className="issue-contact text-main !font-bold">
                  Any Issues ?{" "}
                  <button
                    className="issue-contact text-decoration-none primmary-site-color !font-bold"
                    onClick={() => handleShowChat("admin")}
                  >
                    Contact boopho.com{" "}
                  </button>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BookingInfo />
    </>
  );
};

export default CancelledBookingCard;
