import React, { useState, useEffect } from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import PhotographyModals from "../Photography/PhotographyModals";
import Pagination from "../../Components/Pagination";
import axios from "axios";
// Range Slider In Choose Us Form
import Banner from "../../Components/banner";
import "react-range-slider-input/dist/style.css";
// End Here
// SLick Slider Lirbaries Imported Here

import { Link } from "react-router-dom";
// SLick Slider Lirbaries Imported Here

import {
  Baby1,
  Baby2,
  Baby3,
  Birthday1,
  Birthday2,
  Birthday3,
  Business1,
  Business2,
  Business3,
  Maternity1,
  Maternity2,
  Maternity3,
  Wedding1,
  Wedding2,
  Wedding3,
  Studio1,
  Studio2,
  Studio3,
  Studio4,
  Studio5,
  Studio6,
  Baricon,
} from "../../Constant/Index";
import { toast, ToastContainer } from "react-toastify";
import { IoLocationSharp } from "react-icons/io5";

const WishList = () => {
  const [wish, setWish] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page) => {
    // Your logic to handle page change
    setCurrentPage(page);
  };

  const toggleWishItem = (index) => {
    setWish((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = !newStates[index];
      newStates[index]
        ? toast.success("Added to wishlist!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000, // Auto close the toast after 3 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        })
        : toast.error("Removed From WIshlist!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000, // Auto close the toast after 3 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      return newStates;
    });
    // const showToast = () => {

    // };
  };
  let studios = [
    {
      studioname: "Photo Wale Studio",
      location: "Delhi",
      price: "16,000",
      rating: 4.7,
      img: Studio1,
    },
    {
      studioname: "Lorem Studio",
      location: "Mumbai",
      price: "18,000",
      rating: 4.2,
      img: Studio2,
    },
    {
      studioname: "Ipsum Sutdio",
      location: "Chennai",
      price: "19,000",
      rating: 4.3,
      img: Studio3,
    },
    {
      studioname: "Dolor Studio",
      location: "Punjab",
      price: "22,000",
      rating: 4.4,
      img: Studio4,
    },
    {
      studioname: "Mega Studio",
      location: "Punjab",
      price: "23,000",
      rating: 4.5,
      img: Studio5,
    },
    {
      studioname: "Alpha Studio",
      location: "Delhi",
      price: "27,000",
      rating: 4.6,
      img: Studio6,
    },
  ];

  const id = localStorage.getItem("photoGrapherUser");
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const token = localStorage.getItem("authToken");
  const [photographer, setPhotographer] = useState([]);
  const [categoryId, setCategoryId] = useState([]);

  const getAllWishlist = async () => {
    try {
      const apiUrl = `${baseUrl}/user/wishlist`;

      const response = await axios.get(apiUrl, {
        headers: {
          "x-auth-token": token,
        },
      });

      if (response.status === 200) {
        const responseData = response.data.data;
        console.log({ responseData });
        setPhotographer(responseData);
        // const id = Object.keys(responseData.User.Photographer?.category);

        console.log("id", responseData);
        // setCategoryId(id);
      }
    } catch (error) {
      console.error("Error while fetching data:", error);
    }
  };

  const getCurrentPageItems = () => {
    const itemsPerPage = 4; // Adjust this based on your design
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return photographer?.rows?.slice(startIndex, endIndex) || [];
  };

  console.log(categoryId, photographer);
  useEffect(() => {
    getAllWishlist();
  }, []);
  return (
    <>
      <main className="site-front-page">
        <Header />
        <Banner home="Home" currenturl="/wishlist" current="Wishlist" />
        <section className="studio-section overflow-hidden">
          <div className="bootstrap-container">
            <div className="studio-start-here pb-5">
              <div className="row">
                
                {getCurrentPageItems().map((studio, index) => {
                  const firstCategoryKey = Object.keys(
                    studio?.User?.Photographer?.category || {}
                  )[0];
                  return (
                    <div
                      className={`col-lg-3 col-md-6 my-3 wow animate__animated animate__fadeInDown animate__delay-${index < 3 ? index : "2"
                        }s`}
                    >
                      <div className="single-studio">
                        <span className="discount-offer">
                          <span className="discount-text">20% off</span>
                        </span>
                        <figure className="sutdio-img">
                          {studio?.User?.Photographer?.category[
                            firstCategoryKey
                          ]?.images?.length > 0 && (
                              <img
                                src={
                                  studio?.User?.Photographer?.category[
                                    firstCategoryKey
                                  ]?.images[0]
                                }
                                alt=""
                                className="w-100"
                              />
                            )}
                        </figure>
                        <div className="studio-text">
                          <h6 className="name">
                            {studio?.User?.Photographer?.studioName}
                          </h6>
                          <div className="rating-place">
                            <span className="place-name">
                              <img src={Baricon} alt="" />
                              {studio?.User?.Photographer?.serviceLocation}
                            </span>
                            <div className="rating">
                              <i className="fa fa-star"></i>{" "}
                              {studio?.User?.Photographer?.rating}
                            </div>
                          </div>
                          <div className="price d-flex pt-3 gap-1 align-items-center">
                            <h6 className="md-heading mb-0 !text-main">
                              ₹ {studio?.User?.Photographer?.price}
                            </h6>
                            <p className="!text-main mb-0">Per Day</p>
                          </div>
                          <Link
                            className="btn btn-primary w-100 d-block mt-3"
                            to={`/photography/${firstCategoryKey}/${studio?.User?.Photographer?.id}`}
                          >
                            View Details
                          </Link>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            {photographer?.rows?.length > 10 && (
              <Pagination
                current={currentPage}
                total={5} // Replace with the actual total number of pages
                onPageChange={handlePageChange} // Ensure onPageChange is a function
                onPrevPage={() => handlePageChange(currentPage - 1)}
                onNextPage={() => handlePageChange(currentPage + 1)} />
            )}
          </div>
        </section>
        <Footer />
        <PhotographyModals />
      </main>
    </>
  );
};
export default WishList;
