import React from "react";
import ModalLayout from "../ModalLayout";
import { useState } from "react";

const ReportInAccurateInfo = ({ showModal, setShowModal, handleSubmit }) => {
  const [report, setReport] = useState("");

  return (
    <ModalLayout
      heading="Report Inaccurate Info!"
      showModal={showModal}
      setShowModal={setShowModal}
    >
      <div className="flex flex-col items-center gap-3 ">
        <textarea
          rows="10"
          className="w-full resize-none outline-none p-1 border border-gray-400 focus:border-gray-500 placeholder:text-gray-400"
          placeholder="Report innacurate info..."
          value={report}
          onChange={(e) => setReport(e.target.value)}
        ></textarea>
        <button
          className="text-white bg-main px-3 py-1"
          onClick={() => {
            setReport("");
            handleSubmit(report);
          }}
        >
          Submit
        </button>
      </div>
    </ModalLayout>
  );
};

export default ReportInAccurateInfo;
