import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import Banner from "../../Components/banner";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { Studio1, CommentIcon, ReportIcon } from "../../Constant/Index";
import BookingTabs from "../../Components/BookingTabs";

const BookingPast = () => {
  const BookingsList = [
    {
      Name: "Lens Queen Studio",
      Area: "Mumbai",
      Date: "Maternity Date: 01st Sep 2022 - 03rd Sep 2022",
      image: Studio1,
      Text: "",
    },
    {
      Name: "Lens Queen Studio",
      Area: "Mumbai",
      Date: "Maternity Date: 01st Sep 2022 - 03rd Sep 2022",
      image: Studio1,
      Text: "",
    },
  ];

  return (
    <>
      <main className="site-front-page">
        <Header />
        <Banner home="Home" currenturl="/booking" current="My Bookings" />
        <section className="booking-section">
          <div className="bootstrap-container">
            <div className="booking-start wow animate__animated animate__fadeInDown animate__delay-1s">
              {/* <ul className="tabs-list wow animate__animated animate__fadeInDown animate__delay-1s">
                <li className="single-item">
                  <Link to="/booking" className="tab-btn !text-main">
                    Requested Bookings
                  </Link>
                </li>
                <li className="single-item">
                  <Link to="/confirmedbooking" className="tab-btn !text-main">
                    Confirmed Bookings
                  </Link>
                </li>
                <li className="single-item active">
                  <Link
                    to="/booking-past"
                    className="tab-btn !text-main font-bold"
                  >
                    Past Bookings
                  </Link>
                </li>
                <li className="single-item">
                  <Link to="/bookingcanceled" className="tab-btn !text-main">
                    Cancelled Bookings
                  </Link>
                </li>
              </ul> */}
              <BookingTabs />
              <div className="booking-list overflow-hidden">
                {BookingsList.map((SingleBooking, index) => (
                  <div className="single-booking wow animate__animated animate__fadeInDown animate__delay-1s">
                    <div className="row">
                      <div className="col-md-3 overflow-hidden">
                        <figure className="studio-image wow animate__animated animate__fadeInLeft animate__delay-2s">
                          <img
                            src={SingleBooking.image}
                            className=" w-100"
                            alt=""
                          />
                        </figure>
                      </div>
                      <div className="col-md-9">
                        <div className="booking-data h-100">
                          <div className="report-studio">
                            <img
                              src={ReportIcon}
                              className="report-image"
                              alt=""
                            />
                          </div>
                          <div className="studio-data">
                            <Link
                              to=""
                              className="wow animate__animated animate__rubberBand animate__delay-2s !text-[#620808]"
                            >
                              {SingleBooking.Name}
                            </Link>
                            <span className="location wow animate__animated animate__fadeInDown animate__delay-2s">
                              {SingleBooking.Area}
                            </span>
                          </div>
                          <div className="about-booking h-100 d-flex flex-column justify-content-between py-lg-4 wow animate__animated animate__fadeInRight animate__delay-2s">
                            <span className="date text-main">
                              {SingleBooking.Date}
                            </span>
                            <div className="buttons-here">
                              {/* <button className="cancel"><img src={CloseIcon} alt="" /> Write Review</button> */}
                              <button
                                className="add-button bg-transparent primmary-site-color font-17"
                                data-bs-toggle="modal"
                                data-bs-target="#ReviewForm"
                              >
                                <img src={CommentIcon} alt="" /> Write a review
                              </button>
                              <Link className="btn site-btn px-4">
                                <i className="fa fa-eye text-white"></i> View
                                Footage
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
        <Footer />
        <section className="write-review">
          <div
            class="modal fade"
            id="ReviewForm"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabindex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="staticBackdropLabel">
                    Write a Review
                  </h5>
                  <button
                    type="button"
                    class="btn-close text-lg font-bold"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    X
                  </button>
                </div>
                <div class="modal-body">
                  <form>
                    <div class="mb-3 form-group">
                      <label htmlFor="">Rating *</label>

                      <div className="col-md-12">
                        <div class="input-group star-rating">
                          <div class="rate">
                            <input
                              type="radio"
                              id="star5"
                              name="rate"
                              value="5"
                            />
                            <label for="star5" title="text">
                              5 stars
                            </label>
                            <input
                              type="radio"
                              id="star4"
                              name="rate"
                              value="4"
                            />
                            <label for="star4" title="text">
                              4 stars
                            </label>
                            <input
                              type="radio"
                              id="star3"
                              name="rate"
                              value="3"
                            />
                            <label for="star3" title="text">
                              3 stars
                            </label>
                            <input
                              type="radio"
                              id="star2"
                              name="rate"
                              value="2"
                            />
                            <label for="star2" title="text">
                              2 stars
                            </label>
                            <input
                              type="radio"
                              id="star1"
                              name="rate"
                              value="1"
                            />
                            <label for="star1" title="text">
                              1 star
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mb-3 form-group">
                      <label htmlFor="">Review *</label>

                      <div className="col-md-12 mt-2">
                        <div class="input-group mb-3">
                          <textarea
                            type="text"
                            class="form-control resize-none"
                            placeholder="Enter Your Review"
                            aria-label="Name"
                            rows={"5"}
                          ></textarea>
                        </div>
                      </div>
                      <button
                        type="submit"
                        class="btn btn-primary d-block mx-auto site-btn px-5"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
export default BookingPast;
