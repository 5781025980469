import React, { useEffect, useState } from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { useNavigate, useParams } from "react-router";

// react-icons
import { GrShareOption } from "react-icons/gr";
import { FaLocationDot } from "react-icons/fa6";

//helpers
import { Wedding3 } from "../../Constant/Index";
import { useDispatch, useSelector } from "react-redux";
import {
  getMyBookingsAsync,
  getPhotographerDetailAsync,
  setConfirmBooking,
  selectCurrentBooking,
  generateLinkAsync,
} from "../../Redux/slice/mainSlice";
import { getMyBookings } from "../../api/userApis";

const BookingSummary = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [photographer, setPhotographer] = useState({});
  const [days, setDays] = useState(0);
  const [booking, setBooking] = useState({});
  const currentBooking = useSelector(selectCurrentBooking);
  console.log(currentBooking, "CURRENT");
  const { id, category, bookingId } = useParams();
  function getDaysDifference(startDateStr, endDateStr) {
    // Parse the date strings into Date objects
    const startDate = new Date(startDateStr);
    const endDate = new Date(endDateStr);

    // Calculate the difference in milliseconds
    const difference = endDate.getTime() - startDate.getTime();

    // Convert milliseconds to days
    const oneDay = 1000 * 60 * 60 * 24;
    const differenceInDays = Math.floor(difference / oneDay);

    return differenceInDays;
  }
  useEffect(() => {
    dispatch(getPhotographerDetailAsync(id)).then((x) => {
      console.log(x, "HELLL");
      setPhotographer(x?.payload?.Photographer);
    });
    dispatch(getMyBookingsAsync()).then((x) => {
      let data = x.payload.rows.filter((o) => {
        return o.id == bookingId;
      });
      console.log(data, "DATA", x.payload.rows, bookingId);
      setDays(getDaysDifference(data[0]?.startDate, data[0]?.endDate));
      setBooking(data);
    });
  }, []);

  const handlePayNow = (amount, packageData) => {
    const bookingData = {
      packageData,
      photographer,
      bookingId,
    };
    dispatch(setConfirmBooking(bookingData));
    const paymentData = {
      amount: 500,
      purpose: "booking",
    };
    if (paymentData.amount > 100000) {
      alert("Payment gateway doesnnt support payment over 100k");
    } else {
      dispatch(generateLinkAsync(paymentData))
        .then((x) => {
          const url = x.payload.data.longurl;
          window.location.href = url;
          console.log(console.log(x, "RESPONSSDASDASDADASDASD"));
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };
  function calculateTotalAmount(services, multiplier) {
    // Initialize total
    let total = 0;

    // Loop through the amounts array
    for (let i = 0; i < services?.length; i++) {
      // Multiply each amount by the multiplier and add it to the total
      total += services[i]?.amount * multiplier;
    }

    return total;
  }

  return (
    <>
      <main className="site-front-page">
        <Header />

        <section className="w-full p-5">
          {/* Photographer Details starts here*/}
          <div className="w-max mx-auto flex flex-col md:flex-row gap-4">
            <img src={Wedding3} alt="studio-img" className="max-h-[200px]" />
            <div className="flex flex-col gap-2">
              <div className="flex items-center gap-2">
                <h2 className="text-main">{photographer?.studioName}</h2>
                <button>
                  <GrShareOption className="text-2xl" />
                </button>
              </div>
              <div className="flex gap-1 items-center text-sm text-main">
                <FaLocationDot />
                <p>{photographer?.serviceLocation}</p>
              </div>
              <p className="text-main text-sm">
                Event Date: {booking[0]?.startDate} to {booking[0]?.endDate}
              </p>
            </div>
          </div>
          {/* Photographer Details Ends here*/}

          {/* Package Details Starts here */}
          <div className="w-full mx-auto my-6 rounded-md shadow-md border border-gray-700">
            <div className="bg-[#cff1c0] w-full py-2">
              {/* <h3 className="text-main text-center">{currentBooking?.name}</h3> */}
            </div>
            <div className="grid md:grid-cols-3 gap-5 p-4">
              {currentBooking?.services?.map((x) => {
                if (x?.isCustom) {
                  return (
                    <div className="flex gap-1 justify-between items-center ">
                      <div className="flex flex-col text-main bg-[#cef2ce] rounded-md p-2 w-full">
                        <b>{x?.label}</b>
                        <p>{x?.amount} per day</p>
                      </div>
                      <div className="bg-green-500 text-white flex justify-center items-center rounded-full w-[30px] h-[30px]">
                        {days}
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div className="flex justify-between items-center">
                      <div className="flex flex-col text-main">
                        <b>{x?.label}</b>
                        <p>{x?.amount} per day</p>
                      </div>
                      <div className="bg-green-500 text-white flex justify-center items-center rounded-full w-[30px] h-[30px]">
                        {days}
                      </div>
                    </div>
                  );
                }
              })}

              {/* Additional Services Opted */}
            </div>
          </div>
          {/* Package Details Ends here */}

          {/* Address of Event Starts here */}
          <div className="flex flex-col gap-3">
            <p className="text-main text-sm">
              Complete address of the event:
              <span className="text-red-500">*</span>
            </p>
            <p className="text-main bg-main/20 p-3 rounded-sm">
              {currentBooking?.address}
            </p>
          </div>
          {/* Address of Event Ends here*/}

          {/* Coupon Code Starts here*/}
          <div className="flex flex-col gap-3 my-4">
            <p className="text-main text-sm">Coupons code (if any):</p>
            <input
              type="text"
              placeholder="Boopho15"
              className="border border-main/50 p-2 outline-none w-full text-main"
            />
          </div>
          {/* Coupon Code Ends here*/}

          {/* Payment summary starts here */}
          <div className="w-full md:w-[50%] mx-auto p-3 border border-red-900 rounded-md flex flex-col gap-4 items-center">
            <h3 className="text-main">Payment Summary</h3>
            <hr className="border-b border-b-red-950 w-1/2" />
            <div className="w-full md:w-[65%] mx-auto flex flex-col gap-3 text-sm">
              {currentBooking?.services?.map((x) => {
                return (
                  <div className="flex justify-between text-main">
                    <b>{x?.label}</b>
                    <p>
                      {x?.amount} x {days} = {x?.amount * days}
                    </p>
                  </div>
                );
              })}
              <div className="flex justify-between text-main">
                <b>Discount applied</b>
                <p>₹ 24,000/- at 20%</p>
              </div>
              <div className="flex justify-between text-main">
                <b>Coupon code</b>
                <p>₹ 4000/- by Boopho15</p>
              </div>
              <div className="flex justify-between text-main">
                <b>Total</b>
                <p>₹ 92,000/-</p>
              </div>
              <div className="flex justify-between text-main">
                <b>GST</b>
                <p>₹ 17,200/- at 18%</p>
              </div>
              <div className="flex justify-between text-main">
                <b>Grand Total</b>
                <p>₹ 1,09,200/-</p>
              </div>
            </div>
            <div className="w-full md:w-[65%] mx-auto ">
              <div className="flex justify-between text-main text-base md:text-lg">
                <b>Amount to Pay</b>
                <b>{calculateTotalAmount(currentBooking?.services, days)}</b>
              </div>
            </div>
          </div>
          {/* Payment summary ends here */}
          <div className="flex justify-center my-3">
            <button
              className="bg-main text-white font-bold px-4 py-2"
              onClick={() =>
                handlePayNow(
                  calculateTotalAmount(currentBooking?.services, days),
                  currentBooking
                )
              }
            >
              Pay Now
            </button>
          </div>
        </section>

        <Footer />
      </main>
    </>
  );
};

export default BookingSummary;
