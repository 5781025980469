import React, { useEffect, useState } from "react";
import {
  Button,
  CalendarCell,
  CalendarGrid,
  DateInput,
  DateRangePicker,
  DateSegment,
  Dialog,
  Group,
  Heading,
  I18nProvider,
  Label,
  Popover,
  RangeCalendar,
} from "react-aria-components";
import { today, parseDate } from "@internationalized/date";
import axios from "axios";
import { BsFillCaretDownFill } from "react-icons/bs";
import { IoIosArrowDown } from "react-icons/io";

const TheDate = ({ handleSearch, defaultDates }) => {


  // const [selectedStartDate, setSelectedStartDate] = useState(localStorage.getItem('startDate') || null);
  // const [selectedEndDate, setSelectedEndDate] = useState(localStorage.getItem('endDate') || null);
  const [dateOpen, setDateOpen] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedCity, setSelectedCity] = useState(
    localStorage.getItem("city") || ""
  );
  const [message, setMessage] = useState("");
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [cities, setCities] = useState([]);
  const storedRange = JSON.parse(localStorage.getItem("date")) || null;
  const [range, setRange] = useState(storedRange);
  const [selectedStartDate, setSelectedStartDate] = useState(
    storedRange && storedRange.start ? new Date(storedRange.start) : null
  );
  const [selectedEndDate, setSelectedEndDate] = useState();
  // storedRange && storedRange.end ? new Date(storedRange.end) : null

  const getCities = () => {
    const apiUrl = `${baseUrl}/state/states`;
    axios
      .get(apiUrl)
      .then((response) => {
        const data = response.data.data;
        console.log(data, "STATATAT");
        const initialCitiesOfStates = data
          .filter((state) => state.Cities.length > 0)
          .reduce((mergedCities, state) => {
            return mergedCities.concat(state.Cities.map((city) => city.name));
          }, []);

        setCities(initialCitiesOfStates);
      })
      .catch((error) => {
        console.error("Error fetching states:", error);
      });
  };

  const handleDateChange = (date) => {
    // const endDate = `${date.end.month < 10 ? '0' : ''}${date.end.month}/${date.end.day < 10 ? '0' : ''}${date.end.day}/${date.end.year}`;

    // const startDate = `${date.start.month < 10 ? '0' : ''}${date.start.month}/${date.start.day < 10 ? '0' : ''}${date.start.day}/${date.start.year}`;

    setSelectedStartDate(date.start);
    setSelectedEndDate(date.end);

    console.log(date);
    localStorage.setItem("date", JSON.stringify(date));
  };

  const formatDate = (date) => {
    if (!date || isNaN(new Date(date))) return "";
  const formattedDate = new Date(date);
  const day = formattedDate.getDate().toString().padStart(2, '0');
  const month = (formattedDate.getMonth() + 1).toString().padStart(2, '0');
  const year = formattedDate.getFullYear();

  const date2 = `${day}-${month}-${year}`;

  console.log(date2, "datetetetette")
  
    return date2
  };
  

  useEffect(() => {
    getCities();
  }, []);

  console.log("djnkj", cities);

  return (
    <>
      <div className="search-feild col-lg-9 mx-auto with-border wow animate__animated animate__fadeInDown animate__delay-1s">

        <div className="form-group">
          <select
            name="city"
            id=""
            className="form-select form-control-1"
            value={selectedCity}
            onChange={(e) => setSelectedCity(e.target.value)}
          >
            <option value="">-- Select City --</option>
            {cities?.map((item) => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
          </select>

        </div>
        <div className="form-group the-second">
        <I18nProvider dateTimeFormatOptions={{ day: '2-digit', month: '2-digit', year: 'numeric' }}>
          <DateRangePicker
            onChange={handleDateChange}
            isRequired
            // ranges={range}
            minValue={today()}
          // defaultValue={{
          //   start: defaultDates ? parseDate(defaultDates?.startDate) : null,
          //   end: defaultDates ? parseDate(defaultDates?.endDate) : null,
          // }}
          >
            <Label>Trip dates</Label>
            <Group onClick={() => setDateOpen(!dateOpen)}>
              <DateInput
                slot="start"
                value={selectedStartDate}
                onClick={() => setDateOpen(!dateOpen)}
              >
                {(segment) => <DateSegment segment={segment} />}
              </DateInput>
              <span aria-hidden="true">–</span>
              <DateInput
                slot="end"
                value={selectedStartDate}
                onClick={() => setDateOpen(!dateOpen)}
              >
                {(segment) => <DateSegment segment={segment} />}
              </DateInput>
              {/* <Button >▼</Button> */}
              <IoIosArrowDown onClick={() => setDateOpen(!dateOpen)} />

            </Group>
            

            <Popover
              isOpen={dateOpen}
              onOpenChange={(isOpen) => setDateOpen(isOpen)}
            >
              <Dialog>
                <RangeCalendar>
                  <header>
                    <Button slot="previous">◀</Button>
                    <Heading />
                    <Button slot="next">▶</Button>
                  </header>
                  <CalendarGrid>
                    {(date) => <CalendarCell date={date} />}
                  </CalendarGrid>
                </RangeCalendar>
              </Dialog>
            </Popover>
          </DateRangePicker>
          </I18nProvider>
        </div>
        <div className="form-submit">
          <button
            className="primary-btn search-btn"
            onClick={() =>
              handleSearch(selectedEndDate, selectedStartDate, selectedCity)
            }
          >
            <i className="fa fa-search"></i> Search
          </button>
        </div>
      </div>
    </>

  );
};

export default TheDate;
