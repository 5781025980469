import React from "react";
import { Link, useNavigate } from "react-router-dom";

//react-icons
import { IoLocationSharp } from "react-icons/io5";

const Card = ({
  name,
  city,
  phone,
  eventStartDate,
  eventEndDate,
  location,
  href,
  btn = false,
  btnText,
  btnHandleClick,
  btnBg,
  eventDetails,
  id,
  inProgress,
}) => {
  const navigate = useNavigate();
  const handleClick = (e) => {
    if (inProgress) {
      navigate(href, { state: eventDetails });
    }
    e.preventDefault();
    btnHandleClick(id);
  };

  console.log(eventDetails, "=eventDetailseventDetails:Card");
  return (
    <Link
      // to={href}
      state={eventDetails}
      className="bg-white p-4 rounded-md shadow-md hover:text-black"
    >
      <div className="flex flex-col gap-3">
        <div className="flex justify-between">
          <p className="text-xl text-main font-bold">{name}</p>
          <p className="text-sm">{phone}</p>
        </div>
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <IoLocationSharp />
            <p className="text-xs">{city}</p>
          </div>
          <p className="text-xs">
            <b>Event Date:</b> {eventStartDate} - {eventEndDate}
          </p>
        </div>
        <p className="text-xs">{location}</p>
        {btn && (
          <div className="flex justify-end">
            <button
              className="text-white px-3 py-1 outline-none rounded-md"
              style={{ backgroundColor: btnBg }}
              onClick={handleClick}
            >
              {btnText}
            </button>
          </div>
        )}
      </div>
    </Link>
  );
};

export default Card;
