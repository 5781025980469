import React from "react";
import ModalLayout from "../ModalLayout";

const SubscriptionEmail = ({ showModal, setShowModal }) => {
  return (
    <ModalLayout
      heading="Thanks for Subscribing!"
      showModal={showModal}
      setShowModal={setShowModal}
    >
      <div className="flex flex-col items-center gap-3 ">
        <p>Your subscription to our newsletter is now confirmed!</p>
        <button
          className="text-white bg-main px-3 py-1"
          onClick={() => setShowModal(false)}
        >
          Ok
        </button>
      </div>
    </ModalLayout>
  );
};

export default SubscriptionEmail;
