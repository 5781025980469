import React, { useState, useEffect } from "react";
import Header from "../../Components/Header";
import { Link, useParams } from "react-router-dom";
import Footer from "../../Components/Footer";
import PhotographyModals from "./PhotographyModals";
import TheDate from "../../Components/TheDate";
import StudioCompnent from "../StudioComponent/StudioComponent";
import Pagination from "../../Components/Pagination";
import { toast, ToastContainer } from "react-toastify";
import SortFilter from "../../Components/SortFilter";
import bannerImg from "../../Assets/Img/photography/banner.png";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  Wedding1,
  Wedding2,
  Wedding3,
  Studio1,
  Studio2,
  Studio3,
  Studio4,
  Studio5,
  Studio6,
  Baricon,
} from "../../Constant/Index";
import { getExclusiveDealAsync } from "../../Redux/slice/mainSlice";
import ExclusiveModal from "./ExclusiveModel";

let studios = [
  {
    studioname: "Photo Wale Studio",
    location: "Delhi",
    price: "16,000",
    rating: 4.7,
    img: Studio1,
    Discount: "25%",
    availability: true,
  },
  {
    studioname: "Lorem Studio",
    location: "Mumbai",
    price: "18,000",
    rating: 4.2,
    img: Studio2,
    Discount: "17%",
    availability: true,
  },
  {
    studioname: "Ipsum Sutdio",
    location: "Chennai",
    price: "19,000",
    rating: 4.3,
    img: Studio3,
    Discount: "32%",
    availability: false,
  },
  {
    studioname: "Dolor Studio",
    location: "Punjab",
    price: "22,000",
    rating: 4.4,
    img: Studio4,
    Discount: "05%",
    availability: true,
  },
  {
    studioname: "Mega Studio",
    location: "Punjab",
    price: "23,000",
    rating: 4.5,
    img: Studio5,
    Discount: "40%",
    availability: true,
  },
  {
    studioname: "Alpha Studio",
    location: "Delhi",
    price: "27,000",
    rating: 4.6,
    img: Studio6,
    Discount: "04%",
    availability: true,
  },
];

const PhotographerCategories = () => {
  const [wish, setWish] = useState([]);
  const [selectedFilter, SetSelectedFilter] = useState(0);
  const [showFilter, SetShowFilter] = useState(false);
  const [selectedSortFilter, setSelectedSortFilter] = useState("Featured");
  const [priceFilter, setPriceFilter] = useState("");
  const [ratingFilter, setRatingFilter] = useState("");
  const [photoFilter, setPhotoFilter] = useState([]);
  const [videoFilter, setVideoFilter] = useState([]);
  const [discountFilter, setDiscountFilter] = useState("");
  const [locationFilter, setLocationFilter] = useState("");
  const [photographer, setPhotographer] = useState([]);
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const [showStudioDetails, setShowStudioDetails] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [hasFilterChanged, setHasFilterChanged] = useState(false);
  const [exclusiveDeal, setExclusiveDeal] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const openModal = () => {
    console.log("openenenen");
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };
  const id = useParams();

  const photoGrapherSearch = () => {
    let fromPrice = "";
    let toPrice = "";
    if (priceFilter) {
      const priceRangeArray = priceFilter.split("-");
      fromPrice = priceRangeArray[0].trim();
      toPrice = priceRangeArray[1].trim();
    }

    console.log(fromPrice, toPrice);

    const categoryId = id.type;
    const priceFrom = fromPrice;
    const priceTo = toPrice;

    const params = {
      categoryId: categoryId,
      priceFrom: priceFrom,
      priceTo: priceTo,
      photographyServices: photoFilter,
      videographyServices: videoFilter,
      discount: discountFilter,
      location: locationFilter,
      rating: ratingFilter,
      startDate: localStorage.getItem("startDate"),
      endDate: localStorage.getItem("endDate"),
      // Add other filter parameters here
    };
    console.log("params", params);
    axios
      .get(`${baseUrl}/photographer/search`, { params })
      .then((response) => {
        console.log(response.data);
        setPhotographer(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  };

  useEffect(() => {
    // photoGrapherSearch();

    if (id.type == 17) {
      setCategoryName("Wedding");
    } else if (id.type == 18) {
      setCategoryName("Maternity");
    } else if (id.type == 22) {
      setCategoryName("New-Born Baby");
    } else if (id.type == 24) {
      setCategoryName("Birthday");
    } else {
      setCategoryName("Business");
    }

    // if (hasFilterChanged) {
    photoGrapherSearch();
    // }
  }, [
    priceFilter,
    locationFilter,
    photoFilter,
    videoFilter,
    discountFilter,
    ratingFilter,
  ]);

  const handleFilterChange = (newValue) => {
    SetSelectedFilter(newValue);
  };

  const toggleWishItem = (index) => {
    setWish((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = !newStates[index];
      newStates[index]
        ? toast.success("Added to wishlist!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000, // Auto close the toast after 3 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        })
        : toast.error("Removed From WIshlist!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000, // Auto close the toast after 3 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      return newStates;
    });
    // const showToast = () => {

    // };
  };

  const toggleFilters = () => {
    SetShowFilter(!showFilter);
  };

  const handlePriceFilter = (value) => {
    console.log(value);
    setPriceFilter(value);
    // setHasFilterChanged(true);
  };

  const handleRatingFilter = (value) => {
    console.log(value);
    setRatingFilter(value);
    // setHasFilterChanged(true);
  };

  const handlePhotoFilters = (value) => {
    const updatedFilters = [...photoFilter]; // Create a copy of the existing array
    const filterIndex = updatedFilters.indexOf(value);

    if (filterIndex === -1) {
      updatedFilters.push(value);
    } else {
      updatedFilters.splice(filterIndex, 1);
    }

    setPhotoFilter(updatedFilters);
    // setHasFilterChanged(true);
  };

  const handleVideoFilters = (value) => {
    const updatedFilters = [...photoFilter]; // Create a copy of the existing array
    const filterIndex = updatedFilters.indexOf(value);

    if (filterIndex === -1) {
      updatedFilters.push(value);
    } else {
      updatedFilters.splice(filterIndex, 1);
    }

    setVideoFilter(updatedFilters);
    // setHasFilterChanged(true);
  };

  const handleDiscountFilter = (value) => {
    console.log(value);
    setDiscountFilter(value);
    // setHasFilterChanged(true);
  };

  const handleLocationFilter = (value) => {
    console.log(value);
    setLocationFilter(value);
    // setHasFilterChanged(true);
  };

  const categories = [
    { id: 17, name: "Wedding" },
    { id: 18, name: "Maternity" },
    { id: 22, name: "New Born Baby" },
    { id: 24, name: " Birthday" },
    { id: 25, name: " Business" },
  ];

  const handleSearch = (selectedEndDate, selectedStartDate, selectedCity) => {
    if (selectedEndDate && selectedStartDate && selectedCity) {
      localStorage.setItem("startDate", selectedStartDate);
      localStorage.setItem("endDate", selectedEndDate);
      localStorage.setItem("city", selectedCity);
      window.location.href = `${id.type}/search?startDate=${selectedStartDate}&endDate=${selectedEndDate}&city=${selectedCity}`;
    } else {
      seterrorMessage("Required all search parameters")
    }
    console.log("pho", selectedEndDate, selectedStartDate);
    // if (selectedStartDate || selectedEndDate) {
    //   localStorage.setItem("startDate", selectedStartDate);
    //   localStorage.setItem("endDate", selectedEndDate);
    // }
    // localStorage.setItem("city", selectedCity);

    // window.location.href = `${id.type}/search?startDate=${selectedStartDate}&endDate=${selectedEndDate}&city=${selectedCity}`;
  };
  const dispatch = useDispatch();
  useEffect(() => {
    let category = "";
    switch (id.type) {
      case "22":
        category = "baby";
        break;
      case "17":
        category = "wedding";
        break;
      case "24":
        category = "birthday";
        break;
      case "25":
        category = "business";
        break;
      case "18":
        category = "maternity";
        break;

      default:
        break;
    }
    dispatch(getExclusiveDealAsync(category))
      .then((x) => {
        console.log(x, "Excluisve");
        setExclusiveDeal(x?.payload?.data[0]);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <>
      <Header />
      <ExclusiveModal
        isOpen={isOpen}
        onClose={closeModal}
        deal={exclusiveDeal}
      />

      <section className="photography-banner">
        {/* top-heading */}
        <div className="absolute top-7 left-[33%]">
          {/* <h2
            className="xl-heading mb-4 text-main font-bold"
            style={{ textShadow: "none" }}
          >
            {categoryName} Photography
          </h2> */}
        </div>

        <div className="hidden md:grid md:grid-cols-4 mx-0">
          {categories
            .filter((category) => category.id !== parseInt(id.type, 10))
            .map((category) => (
              <div className="w-full px-0" key={category.id}>
                <a href={`/photography/${category.id}`} className="page-link">
                  {category.name}
                </a>
              </div>
            ))}
        </div>
        {/* <div className="banner-photo-page"> */}
        <div className="relative w-full">
          <img src={bannerImg} alt="" className="w-full h-[200px]" />
          <div className="flex flex-col w-full justify-center absolute top-1/4">
            <h2
              className="mb-7 text-center text-white font-bold "
              style={{ textShadow: "none" }}
            >
              Get your {categoryName} photographers here
            </h2>
            {errorMessage && (
              <div className="text-danger flex justify-center">{errorMessage}</div>
            )}
            <TheDate
              handleSearch={(
                selectedEndDate,
                selectedStartDate,
                selectedCity
              ) =>
                handleSearch(selectedEndDate, selectedStartDate, selectedCity)
              }
            />
          </div>
        </div>
      </section>
      <section className="exclusive-deails">
        <div className="bootstrap-container">
          <div className="deals-start">
            <h2 className="lg-heading mb-5 !text-main">Exclusive Deals</h2>
            <div className="deals-list">
              <div className="row">
                <div className="col-lg-4 col-md-6 my-3">
                  <div className="single-deal">
                    <button
                      className="d-block w-100 simple-btn"
                      type="button"
                      onClick={exclusiveDeal ? openModal : () => { }}
                    >
                      <figure className="deals-img">
                        <img
                          src={
                            exclusiveDeal?.imageUrl
                              ? exclusiveDeal?.imageUrl
                              : Wedding1
                          }
                          alt=""
                          className="the-image img-fluid w-100"
                        />
                        <p>
                          {exclusiveDeal?.heading
                            ? exclusiveDeal?.heading
                            : "Exclusive Deal"}
                        </p>
                      </figure>
                    </button>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 my-3">
                  <div className="single-deal">
                    <button
                      className="d-block w-100 simple-btn"
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#PlansModal"
                    >
                      <figure className="deals-img">
                        <img
                          src={Wedding2}
                          alt=""
                          className="the-image img-fluid w-100"
                        />
                        <p>Membership Plans*</p>
                      </figure>
                    </button>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 my-3">
                  <div className="single-deal">
                    <button
                      className="d-block w-100 simple-btn"
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#LifeTImeModal"
                    >
                      <figure className="deals-img">
                        <img
                          src={Wedding3}
                          alt=""
                          className="the-image img-fluid w-100"
                        />
                        <p>Lifetime Accessibility*</p>
                      </figure>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* <p className="text-center wight-lg">
              Join Us and become ultimate lifetime member of Boopho Pro*
            </p> */}
          </div>
        </div>
      </section>

      <ToastContainer />
      <StudioCompnent categoryName={categoryName} />
      <PhotographyModals />
      <Footer />
    </>
  );
};
export default PhotographerCategories;
