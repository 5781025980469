import React, { useState, useEffect } from "react";
import Banner from "../../Components/banner";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import axios from "axios";
import Swal from "sweetalert2";

const FaqsComponent = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [allFaqs, setAllFaqs] = useState([]);
  const askQuestionApi = `${process.env.REACT_APP_BASE_URL}/user/ask-question`;


  const token = sessionStorage.getItem("authToken");


  const getAllFaqsUs = () => {
    axios
      .get(`${baseUrl}/admin/faq`)
      .then((response) => {
        console.log(response.data.data);
        setAllFaqs(response.data.data.rows);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  };

  useEffect(() => {
    getAllFaqsUs();
  }, []);

  console.log(allFaqs);
  const askQuestion = (e) => {
    const data = {
      question: e,
    };
    axios
      .post(`${baseUrl}/user/ask-question`, data, {
        headers: {
          "x-auth-token": token,
        },
      })
      .then((response) => {
        console.log(response, "responseeeeee")
        Swal.fire({
          text: "Submitted successfully!",
          icon: "info",
        });
        // setAllFaqs(response.data.data.rows);
        // return response.data
      })
      .catch((error) => {
        console.error("Error in POST request:", error);
        throw error;
      });
  }

  const handleFaqSubmit = (e) => {
    const data = document.getElementById("question").value
    console.log(data, "dataaaa")
    e.preventDefault();
    askQuestion(data)
    const data2 = document.getElementById("question").value = ""


  };

  return (
    <main className="site-front-page">
      <Header />
      <Banner home="Home" currenturl="/faqs" current="FAQs" />

      <div class="relative mx-auto w-full py-16 px-5 font-sans text-gray-800 sm:px-20 md:max-w-screen-lg">
        <ul class="space-y-4 min-h-[300px]">
          {allFaqs.map((item, index) => (
            <li class="text-left">
              <label
                for={index}
                class="relative flex flex-col rounded-md border border-gray-100 shadow-md"
              >
                <input class="peer hidden" type="checkbox" id={index} />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="absolute right-0 top-4 ml-auto mr-5 h-4 text-gray-500 transition peer-checked:rotate-180"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
                <div class="relative ml-4 cursor-pointer select-none items-center py-4 pr-12">
                  <h3 class="text-sm text-gray-600 lg:text-base">
                    {item.question}
                  </h3>
                </div>
                <div class="max-h-0 overflow-hidden transition-all duration-500 peer-checked:max-h-96">
                  <div class="px-5 pb-5 pt-2">
                    <p class="text-sm">{item.answer}</p>
                  </div>
                </div>
              </label>
            </li>
          ))}

          {/* <li class="text-left">
            <label
              for="accordion-3"
              class="relative flex flex-col rounded-md border border-gray-100 shadow-md"
            >
              <input class="peer hidden" type="checkbox" id="accordion-3" />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="absolute right-0 top-4 ml-auto mr-5 h-4 text-gray-500 transition peer-checked:rotate-180"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
              <div class="relative ml-4 cursor-pointer select-none items-center py-4 pr-12">
                <h3 class="text-sm text-gray-600 lg:text-base">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Distinctio.?
                </h3>
              </div>
              <div class="max-h-0 overflow-hidden transition-all duration-500 peer-checked:max-h-96">
                <div class="px-5 pb-5 pt-2">
                  <p class="text-sm">
                    Lorem ipsum, <b>dolor sit amet</b> consectetur adipisicing
                    elit. Adipisci eligendi, recusandae voluptatum distinctio
                    facilis necessitatibus aperiam ut? Dolor mollitia modi
                    aliquam, non sint at reprehenderit commodi dignissimos quo
                    unde asperiores officiis quos laboriosam similique nihil.
                  </p>
                </div>
              </div>
            </label>
          </li>

          <li class="text-left">
            <label
              for="accordion-4"
              class="relative flex flex-col rounded-md border border-gray-100 shadow-md"
            >
              <input class="peer hidden" type="checkbox" id="accordion-4" />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="absolute right-0 top-4 ml-auto mr-5 h-4 text-gray-500 transition peer-checked:rotate-180"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
              <div class="relative ml-4 cursor-pointer select-none items-center py-4 pr-12">
                <h3 class="text-sm text-gray-600 lg:text-base">
                  Lorem ipsum dolor sit amet.?
                </h3>
              </div>
              <div class="max-h-0 overflow-hidden transition-all duration-500 peer-checked:max-h-96">
                <div class="px-5 pb-5 pt-2">
                  <p class="text-sm">
                    Lorem ipsum, <b>dolor sit amet</b> consectetur adipisicing
                    elit. Adipisci eligendi, recusandae voluptatum distinctio
                    facilis necessitatibus aperiam ut? Dolor mollitia modi
                    aliquam, non sint at reprehenderit commodi dignissimos quo
                    unde asperiores officiis quos laboriosam similique nihil.
                  </p>
                </div>
              </div>
            </label>
          </li> */}
        </ul>
      </div>

      {/* <div className="ask-question">
        <h4 className="text-center  mb-5">Still have a question? Ask Below</h4>
        <form>
          <div className="row">
            <div className="col-lg-10 ">
              <input
                type="text"
                className="form-control"
                placeholder="Ask a Question"
              />
            </div>
            <div className="col-4 col-md-2">
              <button className="submit btn site-btn w-100">Submit</button>
            </div>
          </div>
        </form>
      </div> */}

      <div className="w-[80%] mx-auto flex flex-col gap-3 justify-center mb-10">
        <h4 className="text-main text-center">
          Still have a question? Ask Below
        </h4>

        <form className="flex gap-2" onSubmit={handleFaqSubmit}>
          <input
            type="text"
            id="question"
            className="w-full outline-none border border-gray-400 p-2 placeholder:text-gray-400 "
            placeholder="Ask a Question"
          />
          <button className="text-white bg-main px-5 py-1 rounded-md">
            Submit
          </button>
        </form>
      </div>

      <Footer />
    </main>
  );
};

export default FaqsComponent;
