import React from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const ConfirmDeal = () => {
  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();

  const photographerId = query.get("photographer");
  const customerId = query.get("customer");

  return (
    <>
      <Header />
      <section className="flex flex-col gap-3 justify-center items-center min-h-[70vh]">
        <h2 className="text-main font-bold" style={{ textShadow: "none" }}>
          Feedback for Deal with Photographer
        </h2>
        <p className="text-main">
          If deal is confirmed click on the button confirm
        </p>
        <button className="bg-main text-white px-3 py-1 rounded-md">
          Confirm
        </button>
      </section>
      <Footer />
    </>
  );
};

export default ConfirmDeal;
