import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";

import Banner from "../../Components/banner";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { UpiIcon, BankingIcon } from "../../Constant/Index";

const Payment = () => {
  return (
    <>
      <main className="site-front-page">
        <Header />
        <section className="payment-section">
          <div className="bootstrap-container">
            <div className="row">
              <div className="col-md-6">
                <div className="payment-here">
                  <h2 className="main-heading">Payment Method:</h2>
                  <aside class="col-lg-0">
                    <article class="card mt-5">
                      <div class="card-body p-5">
                        <ul
                          class="nav bg-light nav-pills rounded nav-fill mb-3"
                          role="tablist"
                        >
                          <li class="nav-item">
                            <a
                              class="nav-link btn site-btn"
                              data-toggle="pill"
                              href="#nav-tab-card"
                            >
                              <i class="fa fa-credit-card"></i> Card
                            </a>
                          </li>
                          <li class="nav-item">
                            <a
                              class="btn text-dark"
                              data-toggle="pill"
                              href="#nav-tab-paypal"
                            >
                              {/* <img src={BankingIcon} width={"80px"} alt="" /> */}
                              Banking
                            </a>
                          </li>
                          <li class="nav-item">
                            <a
                              class="btn text-dark"
                              data-toggle="pill"
                              href="#nav-tab-paypal"
                            >
                              {/* <img src={BankingIcon} width={"80px"} alt="" /> */}
                              Upi
                            </a>
                          </li>
                          <li class="nav-item">
                            <a
                              class="btn text-dark"
                              data-toggle="pill"
                              href="#nav-tab-paypal"
                            >
                              {/* <img src={BankingIcon} width={"80px"} alt="" /> */}
                              Bank Transfer
                            </a>
                          </li>
                          {/* <li class="nav-item">
                                                    <a class="nav-link" data-toggle="pill" href="#nav-tab-paypal">
                                                        <img src={UpiIcon} width={"50px"} alt="" /></a>
                                                </li>
                                                <li class="nav-item">
                                                    <a class="nav-link" data-toggle="pill" href="#nav-tab-bank">
                                                        <i class="fa fa-university"></i> Bank Transfer</a>
                                                </li> */}
                        </ul>

                        <div class="tab-content">
                          <div
                            class="tab-pane fade show active"
                            id="nav-tab-card"
                          >
                            <form role="form">
                              <div class="form-group mb-4">
                                <label for="username">
                                  Full name (on the card)
                                </label>
                                <input
                                  type="text"
                                  class="form-control"
                                  name="username"
                                  placeholder=""
                                  required=""
                                />
                              </div>

                              <div class="form-group mb-4">
                                <label for="cardNumber">Card number</label>
                                <div class="input-group">
                                  <input
                                    type="text"
                                    class="form-control"
                                    name="cardNumber"
                                    placeholder=""
                                  />
                                  <div class="input-group-append">
                                    <span class="input-group-text text-muted">
                                      <i class="fa fa-cc-visa"></i>  {" "}
                                      <i class="fa fa-cc-amex"></i>
                                      <i class="fa fa-cc-mastercard"></i>
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div class="row">
                                <div class="col-sm-8">
                                  <div class="form-group mb-4">
                                    <label>
                                      <span class="hidden-xs">Expiration</span>{" "}
                                    </label>
                                    <div class="input-group">
                                      <input
                                        type="number"
                                        class="form-control"
                                        placeholder="MM"
                                        name=""
                                      />
                                      <input
                                        type="number"
                                        class="form-control"
                                        placeholder="YY"
                                        name=""
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div class="col-sm-4">
                                  <div class="form-group mb-4">
                                    <label
                                      data-toggle="tooltip"
                                      title=""
                                      data-original-title="3 digits code on back side of the card"
                                    >
                                      CVV <i class="fa fa-question-circle"></i>
                                    </label>
                                    <input
                                      type="number"
                                      class="form-control"
                                      required=""
                                    />
                                  </div>
                                </div>
                              </div>
                              <Link
                                to="/bookingconfirmed"
                                class="subscribe btn site-btn btn-block px-5"
                              >
                                {" "}
                                Confirm{" "}
                              </Link>
                            </form>
                          </div>
                        </div>
                      </div>
                    </article>
                  </aside>
                </div>
              </div>
              <div className="col-lg-4 col-md-5 pt-5 ms-auto">
                <div className="order-summary">
                  <div className="card p-4">
                    <h3>Order Summar</h3>
                    <dl class="param  d-flex justify-content-between mb-2 mt-4">
                      <dt>Total </dt>
                      <dd> 2,35,000</dd>
                    </dl>
                    <dl class="param  d-flex justify-content-between mb-2">
                      <dt>GST </dt>
                      <dd> 35,000</dd>
                    </dl>
                    <hr />
                    <dl class="param  d-flex justify-content-between mb-2">
                      <dt>Grand Total </dt>
                      <dd>
                        <s>270,000</s>
                      </dd>
                    </dl>
                    <dl class="param  d-flex justify-content-between mb-2">
                      <dd>Discount @20% </dd>
                      <dd>20,000</dd>
                    </dl>
                    <hr />
                    <dl class="param  d-flex justify-content-between mb-2">
                      <dd></dd>
                      <dt>20,000</dt>
                    </dl>
                  </div>
                  {/* <p className='mt-3'>support no cost EMI here</p>
                                <div className="card p-4">
                                    <div className="form-group">
                                        <label htmlFor="disc">Promotional/ Discount coupon:</label>
                                        <input type="text" name="" className='form-control py-2' id="" />
                                    </div>
                                </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </main>
    </>
  );
};

export default Payment;
