import React from "react";

const ProgressLoading = ({ message }) => {
  return (
    <div className="z-[9999] w-full h-screen bg-white/80 flex items-center justify-center fixed top-0 right-0 left-0 bottom-0 ">
      <div className="relative w-[40%] h-[5px] rounded-lg bg-gray-300">
        <div className="absolute animate-progress h-full bg-main"></div>
        <div className="text-black mt-2 text-center">{message}</div>
      </div>
    </div>
  );
};

export default ProgressLoading;
