
export function getTimeFromDateTime(dateString) {
    const date = new Date(dateString);
  
    // Use local time rather than UTC
    let hours = date.getHours(); // get hours (0-23)
    const minutes = date.getMinutes(); // get minutes (0-59)
  
    // Convert to 12-hour format
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours || 12; // the hour '0' should be '12'
  
    // Format the time components into a string
    const timeString = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${ampm}`;
  
    return timeString;
}