import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Banner from "../../Components/banner";
import EditProfile from "./EditProfile";

const Profile = () => {
  const token = localStorage.getItem("authToken"); // Replace with your actual token
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    otp: "",
  });

  const status = "Edit";
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [successMessage, setSuccessMessage] = useState("");
  const [otpVerify, setOtpVerify] = useState(false)
  const [regenerate, setRegenrate] = useState(false)
  const baseUrl = process.env.REACT_APP_BASE_URL;

  console.log(errors, "errororororo")

  function trimPhoneNumber(phoneNumber) {
    // Check if the phone number starts with "+91"
    if (phoneNumber.startsWith("+91")) {
      // Extract the digits after "+91"
      const trimmedNumber = phoneNumber.substring(3);
      return trimmedNumber;
    }
    // If the phone number doesn't start with "+91", return it as is
    return phoneNumber;

  }
  const handleVerifyOtp = async (e) => {
    e.preventDefault()
    try {
      const response = await axios.post(`${baseUrl}/auth/verify-otp`, {
        phoneNo: "+91" + userData.phoneNumber,
        otp: userData.otp, // Assuming you have a password state
      });
      if (response.status === 200) {
        // Registration successful, handle the success here
        setOtpVerify(true);
        console.log("Registration successful", response.data);
        setSuccessMessage("Your Otp is verified");
      }
    } catch (error) {
      console.error("Error during registration:", error);
      setErrors({ otp: error.response.data.error.message });
      // Handle error state here
    }
  }

  const generateOTP = async (e) => {
    e.preventDefault()
    try {
      const response = await axios.post(`${baseUrl}/auth/resend-otp`, {
        phoneNo: "+91" + userData.phoneNumber,
        email: userData.email,
      });
      if (response.status === 200) {
        // Registration successful, handle the success here
        console.log("Registration successful", response.data);
        setSuccessMessage("Otp has been sent");
        setRegenrate(true)
      }
    } catch (error) {
      console.error("Error during registration:", error);
      setErrors({ otp: error.response.data.error.message });

    }
  }

  const fetchData = () => {
    if (token) {
      const apiUrl = `${baseUrl}/user/me`;

      axios
        .get(apiUrl, {
          headers: {
            "x-auth-token": token,
          },
        })
        .then((response) => {
          console.log(response.data.data, "USERDATA");

          const { name, email, phoneNo } = response.data.data;
          const trimNumber = trimPhoneNumber(phoneNo);
          console.log({ trimNumber })


          setUserData({ name, email, phoneNumber: trimNumber, otp: "" });
          localStorage.setItem("userName", response.data.data.name);
          localStorage.setItem("phoneNo", response.data.data.phoneNo);
          localStorage.setItem("email", response.data.data.email);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
          setIsLoading(false);
        });
    }
  };
  useEffect(() => {
    fetchData();
  }, [token]);

  const validateForm = () => {
    const newErrors = {};

    if (!userData.name.trim()) {
      newErrors.name = "Name is required";
    }

    if (!userData.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(userData.email)) {
      newErrors.email = "Invalid email address";
    }

    if (!userData.phoneNumber.trim()) {
      newErrors.phoneNumber = "Phone Number is required";
    } else if (!/^\d{10}$/.test(userData.phoneNumber)) {
      newErrors.phoneNumber = "Phone Number must be 10 digits";
    }
    if (!userData?.otp.trim()) {
      newErrors.otp = "Otp is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUserData({
      ...userData,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: undefined,
    });
  };

  console.log({ userData });
  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {

      if (otpVerify) {
        const updateApiUrl = `${baseUrl}/user`;
        const data = {
          name: userData.name,
          email: userData.email,
          phoneNo: "+91" + userData.phoneNumber,
        };
        axios
          .patch(updateApiUrl, data, {
            headers: {
              "x-auth-token": token,
            },
          })
          .then((response) => {
            console.log("Profile updated successfully:", response);
            fetchData();
            setSuccessMessage("Profile updated successfully.");
          })
          .catch((error) => {
            console.error("Error updating profile:", error);
          });
      } else {
        setErrors({ otp: "please verfiy otp first" })
      }
    }
  };

  return (
    <>
      <Header />
      <Banner home="Home" currenturl="/profile" current="My Profile" />
      <section className="profile-section py-5 mt-4">
        <div className="bootstrap-container">
          <div className="col-lg-8 mx-auto">
            <div className="profile-upate-satrt">
              {successMessage && (
                <div className="alert alert-success">{successMessage}</div>
              )}
              <form >
                <div className="mb-3 form-group">
                  <div className="row">
                    <div className="col-md-3">
                      <label htmlFor="name">Name *</label>
                    </div>
                    <div className="col-md-9">
                      <div className="input-group mb-3">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Name"
                          aria-label="Name"
                          name="name"
                          value={userData.name}
                          onChange={handleInputChange}
                        />
                      </div>
                      {errors.name && (
                        <div className="text-danger">{errors.name}</div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="mb-3 form-group">
                  <div className="row">
                    <div className="col-md-3">
                      <label htmlFor="gender">Gender *</label>
                    </div>
                    <div className="col-md-9">
                      <div className="form-group mb-3">
                        {/* <input
                          type="text"
                          className="form-control"
                          placeholder="Gender"
                          aria-label="Gender"
                          name="gender"
                          //   value={userData.name}
                          //   onChange={handleInputChange}
                        /> */}
                        <select className="form-select" name="" id="">
                          <option value="">Male</option>
                          <option value="">Female</option>
                          <option value="">Rather not to say</option>
                        </select>
                      </div>
                      {/* {errors.name && (
                        <div className="text-danger">{errors.name}</div>
                      )} */}
                    </div>
                  </div>
                </div>

                <div className="mb-3 form-group">
                  <div className="row">
                    <div className="col-md-3">
                      <label htmlFor="dateOfBirth">Date of Birth *</label>
                    </div>
                    <div className="col-md-9">
                      <div className="input-group mb-3">
                        <input
                          type="date"
                          className="form-control"
                          aria-label="dateOfBirth"
                          name="dateOfBirth"
                        //   value={userData.name}
                        //   onChange={handleInputChange}
                        />
                      </div>
                      {/* {errors.name && (
                        <div className="text-danger">{errors.name}</div>
                      )} */}
                    </div>
                  </div>
                </div>

                <div className="mb-3 form-group">
                  <div className="row">
                    <div className="col-md-3">
                      <label htmlFor="phoneNumber">Phone Number *</label>
                    </div>
                    <div className="col-md-9">
                      <div className="input-group mb-3">
                        <span
                          class="input-group-text fit-content"
                          id="basic-addon1"
                        >
                          <span class="flag-icon flag-icon-in flag-icon-squared"></span>
                        </span>
                        <span
                          class="input-group-text fit-content  border-left-0 ps-0"
                          id="basic-addon1"
                        >
                          <span className="">+91</span>
                        </span>
                        <input
                          type="tel"
                          className="form-control"
                          placeholder="Phone Number"
                          aria-label="Phone Number"
                          name="phoneNumber"
                          value={userData.phoneNumber}
                          onChange={handleInputChange}
                        />
                      </div>
                      {errors.phoneNumber && (
                        <div className="text-danger">{errors.phoneNumber}</div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="mb-3 form-group">
                  <div className="row">
                    <div className="col-md-3">
                      <label htmlFor="otp">OTP *</label>
                    </div>
                    <div className="col-md-3">
                      <div className="input-group mb-3">
                        <input
                          type="tel"
                          className="form-control"
                          placeholder="Otp"
                          aria-label="otp"
                          name="otp"
                          value={userData.otp}
                          onChange={handleInputChange}
                        />
                      </div>
                      {errors.otp && (
                        <div className="text-danger">{errors.otp}</div>
                      )}
                    </div>
                    <div className="col-md-6 gap-3">
                      <div className="flex flex-col md:flex-row  gap-3">
                        <button onClick={handleVerifyOtp} className="text-white bg-main rounded-md px-3 py-1">
                          Verify OTP
                        </button>
                        <button onClick={generateOTP} className="text-white bg-main rounded-md px-3 py-1">
                          {regenerate == true ? "Resend Otp" : "Generate Otp"}

                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mb-3 form-group">
                  <div className="row">
                    <div className="col-md-3">
                      <label htmlFor="email">Email</label>
                    </div>
                    <div className="col-md-9">
                      <div className="input-group mb-3">
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Email"
                          aria-label="Email"
                          name="email"
                          disabled
                          value={userData.email}
                          onChange={handleInputChange}
                        />
                      </div>
                      {errors.email && (
                        <div className="text-danger">{errors.email}</div>
                      )}
                    </div>
                  </div>
                </div>

                {/* <div className="mb-3 form-group">
                  <div className="row">
                    <div className="col-md-3">
                      <label htmlFor="password">Password *</label>
                    </div>
                    <div className="col-md-9">
                      <div className="input-group mb-3">
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Password"
                          aria-label="Password"
                          name="password"
                          value={userData.password}
                          onChange={handleInputChange}
                        />
                        <span className="input-group-text" id="basic-addon1">
                          <i className="fa fa-eye"></i>
                        </span>
                      </div>
                      {errors.password && (
                        <div className="text-danger">{errors.password}</div>
                      )}
                    </div>
                  </div>
                </div> */}

                <button

                  onClick={handleSubmit}
                  className="!bg-main btn btn-primary d-block mx-auto site-btn px-5"
                >
                  Save Details
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
      {status === "Edit" ? <EditProfile /> : ""}
      <Footer />
    </>
  );
};

export default Profile;
