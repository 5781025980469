import React, { useState, useEffect } from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Banner from "../../Components/banner";
import axios from "axios";
import { useParams } from "react-router";

const CAncellationPolicy = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [allPrivacy, setAllPrivacy] = useState([]);

  const params = {
    page: "cancellation",
  };

  const getAllPrivacy = () => {
    axios
      .get(`${baseUrl}/admin/content`, { params: params })
      .then((response) => {
        console.log(response.data.data);
        setAllPrivacy(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  };

  useEffect(() => {
    getAllPrivacy();
  }, []);
  return (
    <>
      <main className="site-front-page">
        <Header />
        <Banner
          home="Home"
          currenturl="/cancellation"
          current="Cancellation Policy"
        />
        {/* Terms Start Here */}

        <section class="legal_text">
          {allPrivacy.map((item, index) => (
            <div class="bootstrap-container">
              <div class="text_start col-md-10 mx-auto">
                <h4 className="mt-5 !text-[21px]">{item.title}</h4>

                <p className="sm_text">{item.description}</p>
              </div>
            </div>
          ))}
        </section>

        {/* Terms End Here */}

        <Footer />
      </main>
    </>
  );
};
export default CAncellationPolicy;
