import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import PhotographerSidebar from "../Components/Sidebar";
import Card from "../../Editor/Components/Card";
import NoResults from "../../Components/NoResults";
import axios from "axios";
import { createTimelineAsync } from "../../Redux/slice/mainSlice";
import { useDispatch } from "react-redux";

const UpcomingEvents = () => {
  const token = localStorage.getItem("authToken");
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [cardData, setCardData] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiUrl = `${baseUrl}/photographer/bookings`;
        const headers = {
          "x-auth-token": token,
        };

        const response = await axios.get(apiUrl, {
          params: {
            status: "CONFIRMED",
            // limit: 10,
            // offset: (currentPage - 1) * 10,
          },
          headers: headers,
        });

        if (response.status === 200) {
          const responseData = response.data.data;
          if (Array.isArray(responseData.rows)) {
            console.log(responseData);
            // Ensure rows is an array
            setCardData(responseData.rows);

            // setTotalPages(Math.ceil(responseData.count / 10));
          } else {
            console.error("Invalid data format: rows is not an array.");
          }
        } else {
          console.error("Unable to fetch data.");
        }
      } catch (error) {
        console.error("Error while fetching data:", error);
      }
    };

    fetchData();
  }, [token]);

  console.log(cardData, "cardData:UpcomingEvents");

  const handleStart = async (id) => {
    const token = localStorage.getItem("authToken");
    const baseUrl = process.env.REACT_APP_BASE_URL;
    try {
      const apiUrl = `${baseUrl}/photographer/booking/${id}`;
      const headers = {
        "x-auth-token": token,
      };

      const data = {
        status: "INPROGRESS",
      };

      const response = await axios.patch(apiUrl, data, {
        headers: headers,
      });

      if (response.status === 200) {
        const responseData = response.data.data;
        console.log(responseData, "START EVENT");
        dispatch(
          createTimelineAsync({
            timelineData: { title: "Event Started" },
            bookingId: id,
          })
        );
        navigate("/photographer/progressevents/1");
      } else {
        console.error("Unable to fetch data.");
      }
    } catch (error) {
      console.error("Error while fetching data:", error);
    }
  };
  return (
    <>
      <div className="photographer-page">
        <Header />
        <section className="photographer-main pt-5">
          <div className="container-fluid px-0">
            <div className="photgrapher-main-start">
              <div className="row mx-0">
                <PhotographerSidebar />
                {/* <div className="col-md-9 px-0">
                                    <div className="single-detail">
                                        <div className="events">
                                            <div className="events-list">
                                                <Link className="single-event" to="/photographer/progress/folder/0">
                                                    <div className="event-top-data">
                                                        <h5 className="event-user">Rahul</h5>
                                                        <span className="event-name normal-text">Birthday</span>
                                                        <span className="event-number normal-text">+91 903XX XXXXX</span>
                                                    </div>
                                                    <div className="d-flex justify-content-between event-other-details mb-3">
                                                        <span className="map-marker normal-text">Bengaluru </span>
                                                        <span className="event-date normal-text">Event Date: 01st Sep 2022 - 03rd Sep 2022</span>
                                                    </div>
                                                    <p className="main-location normal-text">Outer Ring Rd, 7 Block, Banagirinagara, Banashankari 3rd Stage, Banashankari, Bengaluru, Karnataka 560070, India</p>
                                                </Link>
                                                <Link className="single-event" to="/photographer/progress/folder/0">
                                                    <div className="event-top-data">
                                                        <h5 className="event-user">Komal</h5>
                                                        <span className="event-name normal-text">Birthday</span>
                                                        <span className="event-number normal-text">+91 95786 95678</span>
                                                    </div>
                                                    <div className="d-flex justify-content-between event-other-details mb-3">
                                                        <span className="map-marker normal-text">Bengaluru </span>
                                                        <span className="event-date normal-text">Event Date: 10th Sep 2022 - 17th Sep 2022</span>
                                                    </div>
                                                    <p className="main-location normal-text">Outer Ring Rd, 7 Block, Banagirinagara, Banashankari 3rd Stage, Banashankari, Bengaluru, Karnataka 560070, India</p>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                <div className="col-md-9 px-3">
                  {cardData.length > 0 ? (
                    <div className="grid grid-cols-2 gap-2">
                      {cardData?.map((item, index) => (
                        <Card
                          key={index}
                          // href="/photographer/progress/folder/0"
                          name={item?.BookingUser?.name}
                          city={item?.address}
                          phone={item?.BookingUser?.phoneNo}
                          eventStartDate={item?.startDate}
                          eventEndDate={item?.endDate}
                          location={item?.address}
                          btn={true}
                          btnText="Start Event"
                          btnBg="green"
                          btnHandleClick={handleStart}
                          eventDetails={item}
                          id={item?.id}
                        />
                      ))}
                    </div>
                  ) : (
                    <NoResults />
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};
export default UpcomingEvents;
