import React from "react";
import Folder from "../../Components/Folder";
import { useNavigate } from "react-router-dom";
import Header from "../../Components/Header";
import BackButton from "../../Components/BackButton";

const MyFootage = () => {
  const navigate = useNavigate();

  return (
    <>
      <Header />
      <section className="w-full p-4">
        <div className="w-[80%] mx-auto">
          <BackButton />
          <h1 className="md:text-3xl text-xl text-main font-bold text-center">
            My Footage
          </h1>
        </div>
        <div className="w-[80%] mx-auto grid grid-cols-2 md:grid-cols-6 my-5 gap-4">
          <Folder
            color="#54adef"
            text="My Files"
            href="/my-footage/my-files"
            showContext={false}
          />

          <Folder
            color="#54adef"
            text="Shared Files"
            href="/my-footage/shared-files"
            showContext={false}
          />
        </div>
      </section>
    </>
  );
};

export default MyFootage;
