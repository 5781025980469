import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom'
import { Logo } from '../../Constant/Index';
import axios from 'axios';



const Dashboard = () => {
    const LogOut = () => {
        localStorage.removeItem('loggedIn');
        localStorage.removeItem('userId');
        localStorage.removeItem('userName');

        window.location.reload();

    }
    return (
        <>
        <header className="main-heaer">
                <div className="container-fluid">
                    <div className="header-start">
                        <ul className="header-list">
                            <li className="list-item logo-area">
                                <figure className='logo-figure'>
                                    <Link to="/">
                                        <img src={Logo} alt="" />
                                    </Link>
                                </figure>
                            </li>
                            <li className="list-item ms-auto"><Link to="">My Footage</Link></li>
                            <li className="list-item"><Link to="/dashboard">Dashboard</Link></li>
                            <li className="list-item"><a href='#' onClick={LogOut}>Log Out</a></li>
                            <li className="list-item drop-down">
                                <button className="nav-link" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="30px" height="30px"><path d="M 5 8 A 2.0002 2.0002 0 1 0 5 12 L 45 12 A 2.0002 2.0002 0 1 0 45 8 L 5 8 z M 5 23 A 2.0002 2.0002 0 1 0 5 27 L 45 27 A 2.0002 2.0002 0 1 0 45 23 L 5 23 z M 5 38 A 2.0002 2.0002 0 1 0 5 42 L 45 42 A 2.0002 2.0002 0 1 0 45 38 L 5 38 z" /></svg>
                                </button>
                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                    <li><a class="dropdown-item" href="#">About Us</a></li>
                                    <li><a class="dropdown-item" href="#">Contact Us</a></li>
                                    <li><a class="dropdown-item" href="#">My Bookings</a></li>
                                    <li><a class="dropdown-item" href="#">My Wishlist</a></li>
                                    <li><a class="dropdown-item" href="#">My Profile</a></li>
                                    <li><a class="dropdown-item" href="#">List Your Business</a></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </header>


            <div>
                Welcome to Dashboard
            </div>
        </>
        )
}

export default Dashboard;