import React from "react";
import { Menu, Item, useContextMenu } from "react-contexify";
import { Link } from "react-router-dom";

import "react-contexify/dist/ReactContexify.css";

//react-icons
import { FaFolder } from "react-icons/fa";
import { AiOutlineFolderOpen, AiOutlinePlus } from "react-icons/ai";
import { PiShareFatFill } from "react-icons/pi";
import { HiOutlineDownload } from "react-icons/hi";
import { BsClockHistory } from "react-icons/bs";

const Folder = ({
  color,
  text,
  href,
  open,
  share,
  download,
  addToFolder,
  lifetimeAccess,
  id,
  showContext = true,
  onlyOpenOption = false,
  sharedUrl,
  images,
}) => {
  const { show } = useContextMenu({
    id: id,
  });

  console.log(images, "===images:test:Folderrrrrrrrrrrrrrr");

  function handleContextMenu(event) {
    event.stopPropagation();
    show({
      event,
      props: {
        key: id,
      },
    });
  }

  return (
    <>
      <Link
        to={href}
        state={images}
        className="flex flex-col items-center h-max w-full"
        onContextMenu={handleContextMenu}
      >
        <FaFolder
          className="text-8xl cursor-pointer"
          style={{ color: color }}
        />
        <p className="text-sm font-semibold text-center text-gray-700">
          {text}
        </p>
      </Link>

      {showContext && (
        <Menu id={id} className="bg-white border border-main">
          <Item className="" onClick={open}>
            <div className="flex gap-2 text-sm items-center">
              <AiOutlineFolderOpen className="text-xl" /> <p>Open Folder</p>
            </div>
          </Item>
          {!onlyOpenOption && (
            <>
              <Item className="" onClick={share}>
                <div className="flex gap-2 text-sm items-center">
                  <PiShareFatFill className="text-xl" />{" "}
                  <p className="">Share</p>
                </div>
              </Item>
              <Item className="" onClick={download}>
                <div className="flex gap-2 text-sm items-center">
                  <HiOutlineDownload className="text-xl" /> <p>Download</p>
                </div>
              </Item>
              <Item className="" onClick={addToFolder}>
                <div className="flex gap-2 text-sm items-center">
                  <AiOutlinePlus className="text-xl" /> <p>Add to Folder</p>
                </div>
              </Item>
              <Item className="" onClick={lifetimeAccess}>
                <div className="flex gap-2 text-sm items-center">
                  <BsClockHistory className="text-xl" /> <p>Lifetime Access</p>
                </div>
              </Item>
            </>
          )}
        </Menu>
      )}
    </>
  );
};

export default Folder;
