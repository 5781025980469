import React, { useState, useEffect, useRef } from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { Link, useParams, useLocation } from "react-router-dom";
// import { useLocation, navigate } from '@reach/router';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { toast, ToastContainer } from "react-toastify";
import { CheckIcon, Plan1Img, Plan2Img, Plan3Img } from "../../Constant/Index";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import videoThumbnail from "../../Assets/Img/video-thumbnail.jpg";
import { FaPlay } from "react-icons/fa";
import VideoGrid from "../Modals/VideoGrid";
import { today, parseDate } from "@internationalized/date";

import {
  Studio1,
  Studio2,
  Studio3,
  Studio4,
  Studio5,
  Studio6,
  Studio7,
  CallIcon,
  CommentIcon,
  SpeakerIcon,
  Baricon,
} from "../../Constant/Index";
import ReportInAccurateInfo from "../../Components/Modals/ReportInAccurateInfo";
import { getSpaceUntilMaxLength } from "@testing-library/user-event/dist/utils";
import {
  Button,
  CalendarCell,
  CalendarGrid,
  DateInput,
  DateRangePicker,
  DateSegment,
  Dialog,
  Group,
  Heading,
  Label,
  Popover,
  RangeCalendar,
} from "react-aria-components";
import MorePicsModal from "../../Components/MorePicsModal";

import {
  EmailShareButton,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  WhatsappIcon,
} from "react-share";

// react-icons
import { MdNavigateNext, MdNavigateBefore } from "react-icons/md";
import { IoLocation } from "react-icons/io5";
import Swal from "sweetalert2";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";

import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import { createTimelineAsync } from "../../Redux/slice/mainSlice";
import ReviewComponent from "./ReviewComponent";
const StudioDetails = () => {
  const [callClick, SetCallClick] = useState(false);
  const [wish, setWish] = useState(false);
  const location = useLocation();
  const currentLocation = location.pathname;
  const images = [
    Studio1,
    Studio2,
    Studio3,
    Studio4,
    Studio5,
    Studio6,
    Studio7,
  ];

  const [photoData, setPhotoData] = useState(null);
  const tabsList = ["Package", "Photos", "Videos", "Reviews", "About"];
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [photographer, setPhotographer] = useState([]);
  const [packages, setPackages] = useState([]);
  const [showReportInaccurate, setShowReportInaccurate] = useState(false);
  const params = useParams();
  const token = localStorage.getItem("authToken");
  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem("loggedIn") === "true"
  );
  const dispatch = useDispatch();
  const [isBooked, setisBooked] = useState(false);
  const id = useParams();
  let { type } = useParams();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const startDate = searchParams.get("startDate");
  const endDate = searchParams.get("endDate");
  const city = searchParams.get("city");
  const [photoTab, setPhotoTab] = useState(false);
  const [packageTab, setPackageTab] = useState(false);
  const [videosTab, setVideosTab] = useState(false);
  const [aboutTab, setAboutTab] = useState(false);
  const [reviewTab, setReviewTab] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [isPhotographer, setIsPhotographer] = useState(false);
  const [userId, setUserId] = useState("");
  const [showMorePics, setShowMorePics] = useState(false);
  const [showShareOptions, setShowShareOptions] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState(startDate);
  const [selectedEndDate, setSelectedEndDate] = useState(endDate);
  const [heartReact, setHeartReact] = useState("");
  const [cities, setCities] = useState([]);
  const [placeId, setPlaceId] = useState("");
  const navigate = useNavigate();
  console.log(cities, "cities");

  const [selectedCity, setSelectedCity] = useState("");
  let sliderRef1 = useRef(null);
  let sliderRef2 = useRef(null);
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [dateOpen, setDateOpen] = useState(false);

  useEffect(() => {
    setNav1(sliderRef1);
    setNav2(sliderRef2);
  }, []);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  const handleDateChange = (date) => {
    const endDate = `${date.end.month < 10 ? "0" : ""}${date.end.month}/${
      date.end.day < 10 ? "0" : ""
    }${date.end.day}/${date.end.year}`;

    const startDate = `${date.start.month < 10 ? "0" : ""}${date.start.month}/${
      date.start.day < 10 ? "0" : ""
    }${date.start.day}/${date.start.year}`;

    console.log(startDate, endDate, "FUNCTION");
    setSelectedStartDate(startDate);
    setSelectedEndDate(endDate);
  };

  const handleThumbnailClick = (videoUrl) => {
    setSelectedVideo(videoUrl);
  };
  const handleTabs = (tab) => {
    setPackageTab(tab === "Package");
    setReviewTab(tab === "Reviews");
    setVideosTab(tab === "Videos");
    setAboutTab(tab === "About");
    setPhotoTab(tab === "Photos");
  };

  const CallClickHandle = () => {
    console.log(callClick);

    const headers = {
      "x-auth-token": token,
    };

    const data = {
      photographerId: userId,
      bookedCity: 4, // id of city
      startDate: selectedStartDate
        ? selectedStartDate
        : localStorage.getItem("startDate"),
      endDate: selectedEndDate
        ? selectedEndDate
        : localStorage.getItem("endDate"),
      bookedCity: selectedCity,
      category: type,
    };

    console.log(data);
    axios
      .post(`${baseUrl}/booking`, data, {
        headers: headers,
      })
      .then((response) => {
        console.log(response.data.data, "BOOOOOOOOOKES");
        localStorage.setItem(`${params.id}-booked`, response.data.data.status);
        setisBooked(true);
        toast.success(response.data.message);
        setSuccessMessage(response.data.message);
        SetCallClick(!callClick);
        dispatch(
          createTimelineAsync({
            timelineData: { title: "Booked Photographer" },
            bookingId: response.data.data.id,
          })
        );
      })
      .catch((error) => {
        console.error("Error fetching photographer:", error);
        // toast.error(error.response.data.error.message);
        setErrorMessage(error.response.data.error.message);
        SetCallClick(false);
      });
  };

  const getCities = () => {
    const apiUrl = `${baseUrl}/state/states`;
    axios
      .get(apiUrl)
      .then((response) => {
        const data = response.data.data;
        console.log(response);

        const mergedCities = data
          .filter((state) => state.Cities.length > 0)
          .reduce((mergedCities, state) => {
            const citiesWithId = state.Cities.map((city) => ({
              id: city.id,
              name: city.name,
            }));
            return mergedCities.concat(citiesWithId);
          }, []);

        setCities(mergedCities);
        setSelectedCity(mergedCities?.find((x) => x?.name === city)?.id);
      })
      .catch((error) => {
        console.error("Error fetching states:", error);
      });
  };

  const CustomToast = ({ type, message }) => {
    const icon =
      type === "success" ? (
        <span style={{ color: "green" }}>
          {/* Use your "check" icon here */}
          &#10003;
        </span>
      ) : (
        <span style={{ color: "red" }}>
          {/* Use your "close" icon here */}
          &#10005;
        </span>
      );

    return (
      <div>
        {icon} {message}
      </div>
    );
  };

  const getPhotographerById = () => {
    const params = {
      categoryId: id.type,
    };

    axios
      .get(`${baseUrl}/photographer/search`, { params })
      .then((response) => {
        console.log(response.data.data);
        const photographers = response.data.data.data;
        const foundPhotographer = photographers.find(
          (photographer) => photographer.Photographer?.id == id.id
        );

        setUserId(foundPhotographer.id);
        if (foundPhotographer) {
          console.log(foundPhotographer, "PHOTOTOTOTOOT");
          setPlaceId(foundPhotographer?.Photographer?.placeId);
          setPhotographer(foundPhotographer);
          setIsPhotographer(true);
        } else {
          console.log("Photographer not found.");
        }
      })
      .catch((error) => {
        console.error("Error fetching photographer:", error);
      });
  };
  console.log(photographer, "PHOTOTOTOTOT", packages);
  const getAllPackages = () => {
    if (id.id && id.type) {
      axios
        .get(`${baseUrl}/photographer/${id.id}/packages`)
        .then((response) => {
          const services = response.data.data.rows;
          console.log(response.data, "===responseeee:setPackage");
          const obj = services[2]?.services;
          setPackages(services[2]?.services[id.type]);
          setPhotoData(Object.entries(services[0]?.services?.photos));
        })
        .catch((error) => {
          console.error("Error fetching photographer:", error);
        });
    }
  };

  console.log(packages);
  useEffect(() => {
    // if (params.id && params.type) {
    getPhotographerById();
    getAllPackages();
    setPackageTab(true);
    getCities();
  }, [id]);

  const showToast = (type, message) => {
    toast.success(<CustomToast type={type} message={message} />, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      bodyClassName: type === "success" ? "success-toast" : "error-toast",
    });
  };
  console.log(heartReact);
  const toggleWishItem = () => {
    let CurrentState = !wish;
    console.log("ct", CurrentState);

    // if (CurrentState) {
    const headers = {
      "x-auth-token": token,
    };

    axios
      .post(`${baseUrl}/user/${userId}/wishlist`, {}, { headers: headers })
      .then((response) => {
        console.log(response.data.data);
        setHeartReact(response.data.data);
        setWish(CurrentState);

        // toast.success(response.data.message, {
        //   position: toast.POSITION.TOP_RIGHT,
        //   autoClose: 3000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        // });
      })
      .catch((error) => {
        console.error("Error fetching photographer:", error);

        // toast.error("Failed to add to wishlist!", {
        //   position: toast.POSITION.TOP_RIGHT,
        //   autoClose: 3000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        // });
      });
    // }
    // else {
    //   toast.error("Removed From Wishlist!", {
    //     position: toast.POSITION.TOP_RIGHT,
    //     autoClose: 3000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //   });
    // }
  };

  const handleSubmitReport = (report) => {
    Swal.fire({ icon: "info", text: "Report submitted successfully!" });
    console.log(report);
    setShowReportInaccurate(false);
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);

    if (section) {
      section.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const handleConfirm = () => {
    // navigate(`/booking-details/${category}/${photographerId}/${id}`);
  };

  return (
    <>
      <main className="site-front-page bg-white">
        <Header />
        <section className="studio-details">
          <div className="bootstrap-container">
            <div className="detials-start">
              <div className="main-details">
                <div className="w-full flex flex-col-reverse md:flex-row gap-6 justify-center">
                  <div className="col-xxl-5 col-xl-5 col-lg-6 col-md-6 order-1 order-md-0">
                    <Swiper
                      style={{
                        "--swiper-navigation-color": "#fff",
                        "--swiper-pagination-color": "#fff",
                      }}
                      spaceBetween={10}
                      navigation={true}
                      thumbs={{ swiper: thumbsSwiper }}
                      modules={[FreeMode, Navigation, Thumbs]}
                      className="mySwiper2 h-[400px] w-full"
                    >
                      {photographer?.Photographer?.category[
                        id.type
                      ]?.image?.map(
                        (image, index) => (
                          console.log(image),
                          (
                            <SwiperSlide>
                              <img
                                src={image}
                                key={index}
                                alt={` ${index + 1}`}
                                className="h-full w-full"
                                // onClick={() => setShowMorePics(true)}
                                // data-bs-toggle="modal"
                                // data-bs-target="#MorePicsModal"
                              />
                            </SwiperSlide>
                          )
                        )
                      )}
                    </Swiper>
                    <Swiper
                      onSwiper={setThumbsSwiper}
                      spaceBetween={10}
                      slidesPerView={4}
                      freeMode={true}
                      watchSlidesProgress={true}
                      modules={[FreeMode, Navigation, Thumbs]}
                      className="mySwiper mt-4"
                    >
                      {photographer?.Photographer?.category[
                        id.type
                      ]?.image?.map(
                        (image, index) => (
                          console.log(image),
                          (
                            <SwiperSlide>
                              <img
                                src={image}
                                key={index}
                                alt={` ${index + 1}`}
                                className="h-full"
                                // onClick={() => setShowMorePics(true)}
                                // data-bs-toggle="modal"
                                // data-bs-target="#MorePicsModal"
                              />
                            </SwiperSlide>
                          )
                        )
                      )}
                    </Swiper>
                    {/* <div className=" ">
                      <Slider
                        asNavFor={nav2}
                        ref={(slider) => (sliderRef1 = slider)}
                      >
                        {photographer?.Photographer?.category[
                          id.type
                        ]?.image?.map(
                          (image, index) => (
                            console.log(image),
                            (
                              <div
                                // key={index}
                                className="h-[500px] mx-1 slider-nav-here wow animate__animated animate__fadeInLeft animate__delay-1s"
                              >
                                <img
                                  src={image}
                                  key={index}
                                  alt={` ${index + 1}`}
                                  className="h-full"
                                  // onClick={() => setShowMorePics(true)}
                                  // data-bs-toggle="modal"
                                  // data-bs-target="#MorePicsModal"
                                />
                              </div>
                            )
                          )
                        )}
                      </Slider>
                      <div className="slider-nav-here mt-1 ">
                        <Slider
                          asNavFor={nav1}
                          ref={(slider) => (sliderRef2 = slider)}
                          slidesToShow={3}
                          slidesToScroll={1}
                          swipeToSlide={true}
                          focusOnSelect={true}
                          nextArrow={false}
                          prevArrow={false}
                          arrows={false}
                          variableWidth={true}
                          className="slider variable-width"
                        >
                          {photographer?.Photographer?.category[
                            id.type
                          ]?.image?.map(
                            (image, index) => (
                              console.log(image),
                              (
                                <div
                                  // key={index}
                                  className="h-[100px] mx-1 slider-nav-here wow animate__animated animate__fadeInLeft animate__delay-1s"
                                >
                                  <img
                                    src={image}
                                    key={index}
                                    alt={` ${index + 1}`}
                                    className="h-full"
                                    // onClick={() => setShowMorePics(true)}
                                    // data-bs-toggle="modal"
                                    // data-bs-target="#MorePicsModal"
                                  />
                                </div>
                              )
                            )
                          )}
                        </Slider>
                      
                      </div>
                    </div> */}
                  </div>
                  <div className="col-xxl-4 col-xl-5 col-lg-6 col-md-6">
                    <div className="main-details">
                      <div className="studio-reviews-data">
                        <div className="share-main">
                          <h2 className="!text-main font-bold">
                            {photographer?.Photographer?.studioName}
                          </h2>
                          <div className="other-option">
                            <div
                              className="relative social-option"
                              onMouseEnter={() =>
                                setShowShareOptions(!showShareOptions)
                              }
                              onMouseLeave={() =>
                                setShowShareOptions(!showShareOptions)
                              }
                            >
                              <button>
                                <i
                                  class="fa fa-share-alt"
                                  aria-hidden="true"
                                ></i>
                              </button>
                              <div
                                className={`${
                                  !showShareOptions ? "hidden" : ""
                                } p-2 rounded-md absolute right-0 flex gap-1 bg-black/50`}
                              >
                                <FacebookShareButton url={window.location.href}>
                                  {" "}
                                  <FacebookIcon size={32} round={true} />
                                </FacebookShareButton>
                                <WhatsappShareButton url={window.location.href}>
                                  {" "}
                                  <WhatsappIcon size={32} round={true} />
                                </WhatsappShareButton>
                                <TwitterShareButton url={window.location.href}>
                                  {" "}
                                  <TwitterIcon size={32} round={true} />
                                </TwitterShareButton>
                                <EmailShareButton url={window.location.href}>
                                  {" "}
                                  <EmailIcon size={32} round={true} />
                                </EmailShareButton>
                              </div>
                            </div>
                            {token ? (
                              <button
                                className="social-option"
                                onClick={toggleWishItem}
                              >
                                <i
                                  class={`fa fa-heart${
                                    wish ? "" : "-o"
                                  } !text-red-500`}
                                  aria-hidden="true"
                                ></i>
                              </button>
                            ) : (
                              <a
                                className="social-option"
                                data-bs-toggle="modal"
                                data-bs-target="#LoginModal"
                                role="button"
                              >
                                <i
                                  className={`fa fa-heart${
                                    heartReact ? "" : "-o"
                                  } text-red-500`}
                                  aria-hidden="true"
                                ></i>
                              </a>
                            )}
                          </div>
                        </div>
                        <p className="place-name flex items-center gap-1 my-2">
                          {/* <img src={Baricon} width={"25px"} alt="" /> */}
                          <IoLocation className="text-lg text-main" />
                          <span className="!text-main ">
                            {photographer.Photographer?.serviceLocation}
                          </span>
                        </p>
                        <div className="reviews mt-3">
                          <p className="text total-reviews">
                            <div class="rating">
                              <i
                                class="fa fa-star"
                                style={{ color: "rgb(34, 197, 94)" }}
                              ></i>{" "}
                              <span className="mx-1 font-extrabold !text-green-500">
                                {photographer.averageRating}
                              </span>
                            </div>
                            <p
                              className="cursor-pointer text-sm !text-main"
                              onClick={() => {
                                setReviewTab(true);
                                setPackageTab(false);
                                setVideosTab(false);
                                setAboutTab(false);
                                setPhotoTab(false);
                                scrollToSection("studio-tabs");
                              }}
                            >
                              0 Reviews
                            </p>

                            {/* <button className="add-button" data-bs-toggle="modal" data-bs-target="#ReviewForm"><img src={CommentIcon} alt="" /> Write a review</button> */}
                          </p>
                        </div>
                        <div className="price-estimate">
                          {/* <h5 className="price">Price per day estimate</h5> */}
                          <div className="packages">
                            <div className="single-package">
                              <p className="package mb-0 capitalize">
                                {packages[0]?.name} Package
                              </p>
                              <p className="name-price">
                                ₹ {photographer?.basicPrice}{" "}
                                <span>per day</span>
                              </p>
                            </div>
                            {packages[1] && (
                              <div className="single-package">
                                <p className="package mb-0">
                                  {packages[packages.length - 1]?.name} Package
                                </p>
                                <p className="name-price">
                                  ₹{" "}
                                  {packages[1].services?.reduce(
                                    (accumulator, service) => {
                                      const amount = parseFloat(
                                        service.amount.replace(/,/g, "")
                                      );
                                      return accumulator + amount;
                                    },
                                    0
                                  )}
                                  <span> per day</span>
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                        <div
                          className="cursor-pointer promote-here !flex gap-1 items-center"
                          onClick={() => setShowReportInaccurate(true)}
                        >
                          <img src={SpeakerIcon} alt="" />
                          <span className="text-sm !text-main">
                            Report inaccurate info
                          </span>
                        </div>
                        {/* {successMessage && <p>{successMessage}</p>}
                        {errorMessage && <p>{errorMessage}</p>} */}

                        <div className="flex flex-col gap-4">
                          <div className="w-full flex">
                            <div className="flex flex-col md:flex-row gap-3">
                              <h3 className="font-bold !text-main">
                                Wedding Date:
                              </h3>
                              <DateRangePicker
                                onChange={handleDateChange}
                                minValue={today()}
                                defaultValue={
                                  startDate && {
                                    start: parseDate(startDate),
                                    end: parseDate(endDate),
                                  }
                                }
                              >
                                <Label>Trip dates</Label>
                                <Group onClick={() => setDateOpen(!dateOpen)}>
                                  <DateInput
                                    slot="start"
                                    onClick={() => setDateOpen(!dateOpen)}
                                  >
                                    {(segment) => (
                                      <DateSegment segment={segment} />
                                    )}
                                  </DateInput>
                                  <span aria-hidden="true">–</span>
                                  <DateInput
                                    slot="end"
                                    onClick={() => setDateOpen(!dateOpen)}
                                  >
                                    {(segment) => (
                                      <DateSegment segment={segment} />
                                    )}
                                  </DateInput>

                                  <Button
                                    onClick={() => setDateOpen(!dateOpen)}
                                  >
                                    ▼
                                  </Button>
                                </Group>
                                <Popover
                                  isOpen={dateOpen}
                                  onOpenChange={(isOpen) => setDateOpen(isOpen)}
                                >
                                  <Dialog>
                                    <RangeCalendar>
                                      <header>
                                        <Button slot="previous">◀</Button>
                                        <Heading />
                                        <Button slot="next">▶</Button>
                                      </header>
                                      <CalendarGrid>
                                        {(date) => <CalendarCell date={date} />}
                                      </CalendarGrid>
                                    </RangeCalendar>
                                  </Dialog>
                                </Popover>
                              </DateRangePicker>
                            </div>
                          </div>
                          <div className="w-full flex ">
                            <div className="flex flex-col md:flex-row gap-3 md:!gap-10">
                              <h3 className="font-bold !text-main">
                                Booked City:
                              </h3>
                              <div className="form-group">
                                <select
                                  name="city"
                                  id=""
                                  className="form-select form-control-1"
                                  value={selectedCity}
                                  onChange={(e) =>
                                    setSelectedCity(e.target.value)
                                  }
                                >
                                  <option value="">-- Select City --</option>
                                  {cities?.map((item) => (
                                    <option value={item.id} key={item.id}>
                                      {item.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="flex mt-4">
                          {isBooked ? (
                            <p>
                              Congrats you successfully booked this
                              photographer. To book for another event please
                              click{" "}
                              <button
                                style={{ color: "rgb(158, 96, 109)" }}
                                className=" font-bold hover:underline focus:outline-none"
                                onClick={() => {
                                  window.location.reload();
                                }}
                              >
                                here
                              </button>
                              .
                              <br />
                              You can manage your bookings in{" "}
                              <button
                                style={{ color: "rgb(158, 96, 109)" }}
                                className=" font-bold hover:underline focus:outline-none"
                                onClick={() => {
                                  navigate("/booking");
                                }}
                              >
                                My Bookings
                              </button>
                            </p>
                          ) : (
                            <button
                              className={`site-btn ${
                                !selectedStartDate && !selectedEndDate
                                  ? "!cursor-not-allowed "
                                  : ""
                              }`}
                              style={{ paddingLeft: "15px" }}
                              disabled={!selectedStartDate && !selectedEndDate}
                              onClick={CallClickHandle} // Invoke the function
                              data-bs-toggle="modal"
                              data-bs-target={"#MessageModal"}
                            >
                              <img src={CallIcon} alt="" /> Book Now
                            </button>
                          )}
                          {/* <button
                            className={`site-btn ml-4`}
                            style={{ backgroundColor: "#7ed957" }}
                            disabled={!selectedStartDate && !selectedEndDate}
                            onClick={handleConfirm}
                          >
                            <img src={CheckIcon} alt="" /> Confirm
                          </button> */}
                        </div>

                        <p className="call-option">
                          no payment required at this time*
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="studio-tabs" id="studio-tabs">
          <div className="bootstrap-container">
            <div className="studio-tabs-start">
              <ul className="tabs-list">
                <li className={`single-tab ${packageTab ? "active" : ""}`}>
                  <button
                    className="tab-btn"
                    onClick={() => handleTabs("Package")}
                  >
                    Package
                  </button>
                </li>
                <li className={`single-tab ${photoTab ? "active" : ""}`}>
                  <button
                    className="tab-btn"
                    onClick={() => handleTabs("Photos")}
                  >
                    Photos
                  </button>
                </li>
                <li className={`single-tab ${reviewTab ? "active" : ""}`}>
                  <button
                    className="tab-btn"
                    onClick={() => handleTabs("Reviews")}
                  >
                    Reviews
                  </button>
                </li>
                <li className={`single-tab ${videosTab ? "active" : ""}`}>
                  <button
                    className="tab-btn"
                    onClick={() => handleTabs("Videos")}
                  >
                    Videos
                  </button>
                </li>
                <li className={`single-tab ${aboutTab ? "active" : ""}`}>
                  <button
                    className="tab-btn"
                    onClick={() => handleTabs("About")}
                  >
                    About
                  </button>
                </li>
              </ul>
              {/* The Modal Place */}

              {packageTab && (
                <div className="modal-plans col-xxl-10 pt-5">
                  <div className="row flex ">
                    {packages?.map((item, index) => {
                      const totalAmount = item.services?.reduce(
                        (accumulator, service) => {
                          const amount = parseFloat(
                            service.amount.replace(/,/g, "")
                          );
                          return accumulator + amount;
                        },
                        0
                      );
                      return (
                        <div className="col-md-4" key={index}>
                          <div className="single-plans px-3 h-100">
                            <div className="icon-here">
                              <h3 className="md-heading z-index-999 position-relative !text-main">
                                {item.name}
                              </h3>
                            </div>
                            <div class="single-data pt-4 px-3">
                              <div class="package-list">
                                <div class="row">
                                  <div class="col-md-12 mb-4">
                                    {item.services?.map((service, i) => {
                                      return (
                                        <div className="col-md-12 mb-4" key={i}>
                                          <div class="single-package gap-1 mb-3">
                                            <span class="question">
                                              {service.label}
                                            </span>
                                            <a
                                              class="answer"
                                              href="/studio-details"
                                            >
                                              {service.amount}
                                            </a>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <button className="primary-btn">
                              {totalAmount}
                            </button>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <p className="text text-end mt-4 !text-main">
                    Click here for{" "}
                    <Link href="" className="font-bold !text-main">
                      Terms and Conditions*
                    </Link>
                  </p>
                  {/* <div className="tabs-data  md:mt-20">
                    <h2 className="font-bold wow animate__animated animate__rubberBand animate__delay-1s !text-main">
                      Customisable additional services*
                    </h2>
                    <div className="single-data">
                      <div className="package-list col-lg-10 mt-5 pb-5">
                        <div className="row">
                          {photographer?.Photographer &&
                            photographer?.Photographer?.typesOfPhotography?.map(
                              (item, index) => (
                                <div className="col-md-6 mb-4 pe-md-5 wow animate__animated animate__fadeInDown animate__delay-1s">
                                  <div className="single-package">
                                    <span className="question">{item} </span>
                                    <span className="question">
                                      {" "}
                                      Price {1000}
                                    </span>
                                  </div>
                                </div>
                              )
                            )}
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              )}

              {reviewTab && <ReviewComponent id={placeId} />}

              {videosTab && (
                <div className="slider-nav-here my-3  px-lg-3">
                  <Slider
                    // asNavFor={nav1}
                    // ref={(slider2) => setNav2(slider2)}
                    slidesToShow={
                      photographer?.Photographer?.category[id.type]?.videos
                        ?.length
                    }
                    className="slider variable-width"
                    variableWidth={true}
                    // infinite={true}
                    centerMode={true}
                    slidesToScroll={1}
                    // slidesToShow={1}
                    dots={true}
                    swipeToSlide={true}
                    // focusOnSelect={true}
                    // nextArrow={true}
                    // prevArrow={false}
                    // arrows={true}
                  >
                    {photographer?.Photographer?.category[id.type]?.videos?.map(
                      (image, index) => (
                        console.log(image),
                        (
                          <div
                            key={index}
                            className="mx-4  slider-nav-here wow animate__animated animate__fadeInLeft animate__delay-1s"
                          >
                            <div
                              className="cursor-pointer relative w-[300px] max-w-[300px]"
                              onClick={() => handleThumbnailClick(image)}
                            >
                              <FaPlay
                                className="text-3xl absolute top-[35%] right-[42%]"
                                // onClick={() => handleThumbnailClick(image)}
                              />
                              <video
                                src={image}
                                className="w-full "
                                autoPlay
                                muted
                              />
                            </div>
                          </div>
                        )
                      )
                    )}
                  </Slider>
                </div>
              )}

              {photoTab && (
                <div className="slider-nav-here my-3  px-lg-3">
                  <Slider
                    slidesToShow={
                      photographer?.Photographer?.category[id.type]?.images
                        ?.length
                    }
                    variableWidth={true}
                  >
                    {photographer?.Photographer?.category[id.type]?.image?.map(
                      (image, index) => (
                        console.log(image),
                        (
                          <div key={index} className="mx-1 slider-nav-here">
                            <img
                              src={image}
                              alt={`Image ${index + 1}`}
                              className="h-[200px]"
                            />
                          </div>
                        )
                      )
                    )}
                  </Slider>
                </div>
              )}
              {aboutTab && (
                <div className="my-5">
                  <p className="text-main">
                    {photographer?.Photographer?.aboutYou}
                  </p>
                </div>
              )}

              {/* The Modal Place end Here */}

              {/* <div className="tabs-data  md:mt-20">
                <h2 className=" wow animate__animated animate__rubberBand animate__delay-1s !text-main">
                  Customisable additional services*
                </h2>
                <div className="single-data">
                  <div className="package-list col-lg-10 mt-5 pb-5">
                    <div className="row">
                      {photographer.Photographer &&
                        photographer.Photographer?.typesOfPhotography.map(
                          (item, index) => (
                            <div className="col-md-6 mb-4 pe-md-5 wow animate__animated animate__fadeInDown animate__delay-1s">
                              <div className="single-package">
                                <span className="question">{item}</span>
                              </div>
                            </div>
                          )
                        )}
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </section>
        <section className="more-pics-modal">
          {/* <!-- Modal --> */}
          <div
            class="modal fade"
            id="MorePicsModal"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabindex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered modal-lg">
              <div class="modal-content  relative">
                <button
                  type="button"
                  // class="btn-close text-lg font-bold"
                  class="z-10 absolute -right-1 md:-right-3 -top-3 bg-red-500 w-10 h-10 rounded-full flex items-center justify-center text-white text-lg font-bold"
                  data-bs-dismiss="modal"
                  // aria-label="Close"
                >
                  X
                </button>
                {/* <div class="modal-header">
                  <h5 class="modal-title" id="staticBackdropLabel">
                    All Photos
                  </h5> */}
                {/* </div> */}
                <div class="modal-body relative">
                  <button
                    className="z-10 absolute right-0 top-[30%]"
                    onClick={
                      () => nav2.slickNext()
                      // nav2?.current?.slickNext();
                    }
                  >
                    <MdNavigateNext className="text-black font-bold text-9xl" />
                  </button>

                  <button
                    className="z-10 absolute left-0 top-[30%]"
                    onClick={() => nav2.slickPrev()}
                  >
                    <MdNavigateBefore className="text-black font-bold text-9xl" />
                  </button>
                  <div className="detail-image">
                    <Slider
                      asNavFor={nav2}
                      ref={(slider1) => setNav1(slider1)}
                      arrows={false}
                    >
                      {photographer?.Photographer?.category[
                        id.type
                      ]?.images?.map((image, index) => (
                        <div key={index} className=" px-lg-3">
                          <img
                            src={image}
                            alt={`Image ${index + 1}`}
                            className="w-100 h-[400px] object-cover"
                          />
                        </div>
                      ))}
                    </Slider>
                    <div className="slider-nav-here  px-lg-3">
                      <Slider
                        asNavFor={nav1}
                        ref={(slider2) => setNav2(slider2)}
                        slidesToShow={
                          photographer?.Photographer?.category[id.type]?.images
                            ?.length > 6
                            ? 6
                            : photographer?.Photographer?.category[id.type]
                                ?.images?.length
                        }
                        swipeToSlide={true}
                        focusOnSelect={true}
                        nextArrow={false}
                        prevArrow={false}
                        arrows={false}
                      >
                        {photographer?.Photographer?.category[
                          id.type
                        ]?.images?.map((image, index) => (
                          <div key={index} className="slider-nav-here px-2">
                            <img
                              src={image}
                              alt={`Image ${index + 1}`}
                              className="h-[100px] object-cover w-100"
                            />
                          </div>
                        ))}
                      </Slider>
                    </div>
                  </div>
                </div>
                {/* <div class="modal-footer">
                  <button
                    type="button"
                    className="px-4 py-2 text-white rounded-sm bg-main"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                 </div> */}
              </div>
            </div>
          </div>
        </section>
        <section className="write-review">
          <div
            class="modal fade"
            id="ReviewForm"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabindex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="staticBackdropLabel">
                    Write a Review
                  </h5>
                  <button
                    type="button"
                    class="btn-close text-lg font-bold"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    X
                  </button>
                </div>
                <div class="modal-body">
                  <form>
                    <div class="mb-3 form-group">
                      <label htmlFor="">Rating *</label>

                      <div className="col-md-12">
                        <div class="input-group star-rating">
                          <div class="rate">
                            <input
                              type="radio"
                              id="star5"
                              name="rate"
                              value="5"
                            />
                            <label for="star5" title="text">
                              5 stars
                            </label>
                            <input
                              type="radio"
                              id="star4"
                              name="rate"
                              value="4"
                            />
                            <label for="star4" title="text">
                              4 stars
                            </label>
                            <input
                              type="radio"
                              id="star3"
                              name="rate"
                              value="3"
                            />
                            <label for="star3" title="text">
                              3 stars
                            </label>
                            <input
                              type="radio"
                              id="star2"
                              name="rate"
                              value="2"
                            />
                            <label for="star2" title="text">
                              2 stars
                            </label>
                            <input
                              type="radio"
                              id="star1"
                              name="rate"
                              value="1"
                            />
                            <label for="star1" title="text">
                              1 star
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mb-3 form-group">
                      <label htmlFor="">Review *</label>

                      <div className="col-md-12 mt-2">
                        <div class="input-group mb-3">
                          <textarea
                            type="text"
                            class="form-control resize-none"
                            placeholder="Enter Your Review"
                            aria-label="Name"
                            rows={"5"}
                          ></textarea>
                        </div>
                      </div>
                      <button
                        type="submit"
                        class="btn btn-primary d-block mx-auto site-btn px-5"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="write-review">
          {/* Message Modal Starts*/}
          <div
            class="modal fade"
            id="MessageModal"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabindex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="staticBackdropLabel">
                    Message
                  </h5>
                  <button
                    type="button"
                    class="btn-close text-lg font-bold"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    X
                  </button>
                </div>
                <div class="modal-body">
                  <div className="text-center">
                    <p className="px-5">
                      {errorMessage && !callClick ? (
                        <p style={{ color: "red" }}>{errorMessage}</p>
                      ) : (
                        "Request sent. You will receive a call in 48 hours. Thankyou!"
                      )}
                    </p>
                    <button
                      class="btn  mx-auto site-btn px-5"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      Ok
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Message Modal Ends*/}

          {/* Confirmation Modal Starts*/}
          {successMessage && (
            <div
              class="modal fade"
              id="ConfirmationModal"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabindex="-1"
              aria-labelledby="staticBackdropLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel">
                      Message
                    </h5>
                    <button
                      type="button"
                      class="btn-close text-lg font-bold"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      X
                    </button>
                  </div>
                  <div class="modal-body">
                    <div className="text-center">
                      <p className="px-5">
                        Are you sure you want to cancel request?
                      </p>
                      <div className="flex gap-2 justify-center my-2">
                        <button
                          className="px-3 py-1 text-white bg-green-600 rounded-md"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          Yes
                        </button>
                        <button
                          className="px-3 py-1 text-white bg-red-600 rounded-md"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          onClick={() => SetCallClick(true)}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* Confirmation Modal Starts*/}
        </section>
        <ToastContainer />
        <Footer />
      </main>
      <ReportInAccurateInfo
        showModal={showReportInaccurate}
        setShowModal={setShowReportInaccurate}
        handleSubmit={handleSubmitReport}
      />
      {selectedVideo && (
        <VideoGrid
          videoUrl={selectedVideo}
          closeModal={() => setSelectedVideo(null)}
        />
      )}
      {/* <MorePicsModal
        showModal={showMorePics}
        setShowModal={setShowMorePics}
        photographer={photographer}
        id={id}
      /> */}
    </>
  );
};
export default StudioDetails;
