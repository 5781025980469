import React, { useEffect, useState } from "react";
import { RxCross1 } from "react-icons/rx";

const Confirmation = ({
  showModal,
  setShowModal,
  handleConfirm,
  text,
  heading,
  btnText,
}) => {
  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (showModal === true) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "visible";
    }

    return () => {
      document.body.style.overflowY = "visible";
    };
  }, [showModal]);

  return (
    <>
      {showModal && (
        <section className={` fixed inset-0 flex justify-center z-50`}>
          <div
            className="bg-black/50 absolute w-full h-full top-0"
            onClick={closeModal}
          ></div>

          <div className="fixed top-1/4 z-20 flex flex-col gap-10 p-3 min-w-[80vw] max-w-[80vw] md:min-w-[40vw] md:max-w-[40vw] bg-white rounded-lg">
            <div className="relative flex justify-center items-centerw-full bg-main p-2 rounded-t-md">
              <p className="text-white">{heading}</p>
              <RxCross1
                className="absolute right-2 text-white text-xl cursor-pointer"
                onClick={closeModal}
              />
            </div>

            <p className="text-gray-800 text-center">{text}</p>

            <div className="flex justify-end gap-3">
              <button
                className="px-4 py-2 bg-red-500 text-white rounded-md"
                onClick={handleConfirm}
              >
                {btnText}
              </button>
              <button
                className="px-4 py-2 bg-green-500 text-white rounded-md"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Confirmation;
