import React from "react";
import ModalLayout from "../ModalLayout";
import { useState } from "react";

const CreateFolder = ({ showModal, setShowModal, handleCreateFolder }) => {
  const [folderName, setFolderName] = useState("");

  return (
    <ModalLayout
      showModal={showModal}
      setShowModal={setShowModal}
      heading="Enter Folder Name"
    >
      <div className="flex flex-col gap-3">
        <div className="flex flex-col items-center gap-1">
          <label htmlFor="folderName" className="text-center">
            Folder Name:
          </label>
          <input
            type="text"
            className="border border-gray-400 rounded-md w-full outline-none px-1 py-2"
            value={folderName}
            onChange={(e) => setFolderName(e.target.value)}
          />
        </div>
        <div className="w-full flex justify-center">
          <button
            className="bg-main rounded-md px-3 py-1 text-white"
            onClick={() => handleCreateFolder(folderName)}
          >
            Create Folder
          </button>
        </div>
      </div>
    </ModalLayout>
  );
};

export default CreateFolder;
