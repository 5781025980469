import React, { useState } from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import PhotographerSidebar from "../Components/Sidebar";
import { Calendar } from "primereact/calendar";
const PhotographerPayments = () => {
  const [dates, setDates] = useState(null);

  return (
    <>
      <div className="photographer-page">
        <Header />
        <section className="photographer-main pt-5">
          <div className="container-fluid px-0">
            <div className="photgrapher-main-start">
              <div className="row mx-0">
                <PhotographerSidebar />
                <div className="col-md-9 px-0">
                  <div className="single-detail the-pay-page">
                    <div className="payment-page ps-xl-5 ps-2 pt-3">
                      <table class="table table-bordered w-fit">
                        <tbody>
                          <tr>
                            <th scope="row" className="ps-2 pe-3">
                              Total income so far:
                            </th>
                            <td>12,000 ₹</td>
                          </tr>
                          <tr>
                            <th scope="row" className="ps-2 pe-3">
                              Total income in current year:
                            </th>
                            <td>12,000 ₹</td>
                          </tr>
                          <tr>
                            <th scope="row" className="ps-2 pe-3">
                              Total number of booking:
                            </th>
                            <td>12</td>
                          </tr>
                          <tr>
                            <th scope="row" className="ps-2 pe-3">
                              Total number of booking in current year:{" "}
                            </th>
                            <td>10</td>
                          </tr>
                          <tr>
                            <th scope="row" className="ps-2 pe-3">
                              5 start services:{" "}
                            </th>
                            <td></td>
                          </tr>
                          <tr>
                            <th scope="row" className="ps-2 pe-3">
                              Rating:
                            </th>
                            <td>4.6</td>
                          </tr>
                          <tr>
                            <th scope="row" className="ps-2 pe-3">
                              Number of reviews:
                            </th>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                      {/* <div className="d-flex flex-column flex-sm-row  gap-2 gap-sm-4 align-items-start align-sm-items-center payment-btns">
                        <button
                          className="btn site-btn"
                          data-bs-toggle="modal"
                          data-bs-target="#TwoModal"
                        >
                          Generate coupon code
                        </button>
                        <button
                          className="btn site-btn"
                          data-bs-toggle="modal"
                          data-bs-target="#ReviewForm"
                        >
                          Apply Discount
                        </button>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
      {/* <section className="coupon-form">
        <div
          class="modal fade"
          id="ReviewForm"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">
                  Message
                </h5>
                <button
                  type="button"
                  class="btn-close text-lg font-bold"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  X
                </button>
              </div>
              <div class="modal-body">
                <form>
                  <div className="form-group row mb-3">
                    <div className="col-lg-5">
                      <label htmlFor="">Enter the Discount</label>
                    </div>
                    <div className="col-lg-7">
                      <select
                        name=""
                        id=""
                        className="form-control form-select"
                      >
                        <option value="10%">10%</option>
                        <option value="20">20%</option>
                        <option value="30">30%</option>
                        <option value="40">40%</option>
                        <option value="50">50%</option>
                        <option value="60">60%</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-group row mb-3">
                    <div className="col-lg-5">
                      <label htmlFor="">Enter the Discount</label>
                    </div>
                    <div className="col-lg-7">
                      <div className="card flex justify-content-center">
                        <Calendar
                          value={dates}
                          onChange={(e) => setDates(e.value)}
                          selectionMode="range"
                          readOnlyInput
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group row mb-3">
                    <div className="col-lg-5">
                      <label htmlFor="">Event Type</label>
                    </div>
                    <div className="col-lg-7">
                      <select
                        name=""
                        id=""
                        className="form-control form-select"
                      >
                        <option value="Wedding">Wedding</option>
                        <option value="Birthday">Birthday</option>
                        <option value="Business">Business</option>
                        <option value="New Born">New Born</option>
                        <option value="Maternity">Maternity</option>
                      </select>
                    </div>
                  </div>
                  <div className="text-center mt-5 mb-3">
                    <button
                      className="btn site-btn w-fit mx-auto d-block"
                      type="button"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      data-bs-toggle="modal"
                      data-bs-target="#ConfirmCoupon"
                    >
                      Apply Discount
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section className="coupon-form">
        <div
          class="modal fade"
          id="ConfirmCoupon"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">
                  Message
                </h5>
                <button
                  type="button"
                  class="btn-close text-lg font-bold"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  X
                </button>
              </div>
              <div class="modal-body text-center">
                <p className="px-lg-5 px-3 text">
                  XX% discount has been applied from date to date for event type
                  - Wedding.
                </p>
                <div
                  className="btn site-btn px-4 d-block-mx-auto w-fit"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Ok
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="coupon-form">
        <div
          class="modal fade"
          id="TwoModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">
                  Message
                </h5>
                <button
                  type="button"
                  class="btn-close text-lg font-bold"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  X
                </button>
              </div>
              <div class="modal-body">
                <form>
                  <div className="form-group row mb-3">
                    <div className="col-lg-5">
                      <label htmlFor="">Enter the amount</label>
                    </div>
                    <div className="col-lg-7">
                      <input
                        type="text"
                        name=""
                        id=""
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="ultrapackage"
                    />
                    <label class="form-check-label" for="ultrapackage">
                      You must have Ultra packae
                    </label>
                  </div>
                  <div class="form-check mt-2">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="minpackage"
                    />
                    <label class="form-check-label" for="minpackage">
                      Minimum payment must be greater then 100,000
                    </label>
                  </div>

                  <div className="text-center mt-5 mb-3">
                    <button
                      className="btn site-btn w-fit mx-auto d-block"
                      type="button"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      data-bs-toggle="modal"
                      data-bs-target="#ConfirmDisc"
                    >
                      Generate Code
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="coupon-form">
        <div
          class="modal fade"
          id="ConfirmDisc"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">
                  Message
                </h5>
                <button
                  type="button"
                  class="btn-close text-lg font-bold"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  X
                </button>
              </div>
              <div class="modal-body text-center">
                <p className="px-lg-5 px-3 text">
                  Coupon code for amount XXX/- has been generated and sent to
                  your e-mail id.
                </p>
                <div
                  className="btn site-btn px-4 d-block-mx-auto w-fit"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Ok
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PhotographerPayments;
