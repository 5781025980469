import React, { useState, useEffect, useRef, useContext } from 'react';
import { sendMessage } from '../Utils/socket';
import { MyContext } from '../Context/MyContext';

const CustomChat = ({ headerTitle, currentChatWith, }) => {
  const inpRef = useRef();
  let [userId, setUserId] = useState(null);
  const [chatMessages, setChatMessages] = useState([]);
  // const [newMessage, setNewMessage] = useState('');
  const { messages, setMessages } = useContext(MyContext);

  // const handleInputChange = (event) => {
  //   setNewMessage(event.target.value);
  // };
// console.log(messages[currentChatWith], "messages:CustomChat, headerTitle = ", headerTitle);

  console.log(currentChatWith, "===currentChatWith:Idddddd,    ", headerTitle,);

  const handleSendMessage = () => {
    const msg = inpRef.current.value;
    // Handle sending the new message (e.g., send it to a server)
    console.log('Sending message:', msg);
    if(!msg.length) return;
    const data = { message: { text: msg, type: 'text' }, senderId: userId, receiverId: currentChatWith, messageType: 'text', isForAdmin: currentChatWith === -1, };

    console.log(data, "===mssgssgsggsgs:dataa:before");
    sendMessage(data, (ackData) => { console.log(ackData, "ACK Data From send message") });
    const currMsg = { id: (messages[currentChatWith]?.length || 0) + 1, senderId: userId, receiverId: currentChatWith, message: msg, messageType: 'text', isForAdmin: headerTitle === 'admin', }
    setMessages(prev => ({ ...prev, [currentChatWith]: messages[currentChatWith] ? [...messages[currentChatWith], currMsg]: [currMsg] }));
    inpRef.current.value = "";
  };

  useEffect(() => {
    const userId = Number(localStorage?.getItem("userId"));
    setUserId(userId);
  }, []);

  return (
    <div className="w-[350px] h-[440px] rounded-lg bg-white relative">
        <div className="bg-main h-[60px] w-full rounded-t-lg font-bold text-white text-lg flex justify-center items-center">{headerTitle}</div>
        <div className="relative w-full h-[335px] px-3 py-2 overflow-y-auto overflow-x-hidden">
            {messages[currentChatWith] && messages[currentChatWith]?.map((msg, index) => {
                return(
                <div key={index} className={`message w-[200px] rounded-md py-1 px-2 mb-1 ${msg.senderId == userId ? "!ml-[100px] bg-gray-200": "left-2 bg-main/90 text-white"}`}>
                    {msg.message || "..."}
                </div>
            )})}
        </div>

      <div className="flex justify-between items-center px-3">
        <input
          type="text"
          ref={inpRef}
          placeholder="Type your message..."
          style={{ width: '75%', padding: '6px', marginRight: '8px', boxSizing: 'border-box', border: '1px solid #40262b', borderRadius: '5px' }}
        />
        <button
          onClick={handleSendMessage}
          style={{ width: '25%', backgroundColor: '#40262b', padding: '6px', color: 'white', border: 'none', cursor: 'pointer', boxSizing: 'border-box', borderRadius: '6px' }}
        >
          Send
        </button>
      </div>
    </div>
  );
};

export default CustomChat;
