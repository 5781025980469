import React,{useState, useEffect} from "react";
import Header from "../../Components/Header";
import { useNavigate } from "react-router-dom";

import Footer from "../../Components/Footer";
import { FaCheckCircle, FaArrowRight } from "react-icons/fa";
import axios from "axios";



const AccountCreated = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get('token');
  const [isValidToken, setIsValidToken] = useState(false);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [errorMessage, setErrorMessage] = useState('');
  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem("loggedIn") === "true"
  );  
  console.log(token);

  const navigate = useNavigate();

  const validateToken = async () => {
    try {

      const response = await axios.post(
        `${baseUrl}/auth/verify-email`,
        { token: token },
        {
          headers: {
            'Content-Type': 'application/json', // Make sure to set the appropriate content type
          },
        }
      );

      const status = await response.data.status;

      console.log(status);

      if(status == 200) {
        setIsValidToken(true);
        
        // setTimeout(() => {
        //   navigate('/');
        // }, 3000);
      }      
        

    } catch (error) {
      console.error('Error validating token:', error);
      setErrorMessage(error?.response?.data?.error?.message || 'Error validating token');
    }
  };

  useEffect(() => {
    if(!loggedIn) {
      validateToken();  
    } else {
      navigate('/');
    }
  }, [token]);

  return (
    <>
      <Header />
      <div className="min-h-[80vh] flex items-center justify-center">
          <div className="bg-white p-8 rounded-md shadow-md max-w-lg w-full flex flex-col gap-3">
      {isValidToken && (
        <>
            <div className="flex justify-center ">
              <FaCheckCircle className="text-main text-7xl" />
            </div>
            <div className="text-3xl font-bold text-main mb-4 flex items-center justify-center">
              Account Created!
            </div>
            <p className="text-gray-700 mb-6">
              Congratulations! Your account has been successfully created.
            </p>

            <button
              className="bg-main flex items-center justify-center gap-3 text-white font-bold py-2 px-4 rounded"
              data-bs-toggle="modal"
              data-bs-target="#LoginModal"
              role="button"
            >
              <p>Continue</p>
              <FaArrowRight className="text-white " />
            </button>
            </>
      )}  
        {!loggedIn && errorMessage && (
          <p style={{ color: 'red' }}>{errorMessage}</p>
        
      )}
          </div>

      </div>
      <Footer />
    </>
  );
};

export default AccountCreated;
