import React from "react";
import ModalLayout from "../../Components/ModalLayout";

const ConfirmHandleTurnOff = ({ showModal, setShowModal, message }) => {
  return (
    <ModalLayout
      showModal={showModal}
      setShowModal={setShowModal}
      heading="Message"
    >
      <div className="flex flex-col gap-2 items-center w-[80%] mx-auto">
        <p>{message}</p>
        <button
          className="px-3 py-1 bg-main text-white rounded-md"
          onClick={() => setShowModal(false)}
        >
          {" "}
          Ok
        </button>
      </div>
    </ModalLayout>
  );
};

export default ConfirmHandleTurnOff;
