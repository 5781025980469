import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { CommentIcon, ReportIcon } from "../Constant/Index";
import BookingInfo from "./Modals/BookingInfo";
import Swal from "sweetalert2";

const PastBookingCard = ({ id, image, name, area, startDate, endDate }) => {
  const modalClose = useRef();

  const handleReviewSubmit = (e) => {
    e.preventDefault();

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, confirm submit!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Review Submit!",
          text: "Review submitted successfully.",
          icon: "success",
        });
        // window.location.reload();
        modalClose.current.click();
      }
    });
  };

  return (
    <>
      <div className="single-booking wow animate__animated animate__fadeIn animate__delay-1s">
        <div className="row">
          <div className="col-md-3 overflow-hidden">
            <figure className="studio-image " style={{ height: "100px" }}>
              <img src={image} className=" w-100" alt="" />
            </figure>
          </div>
          <div className="col-md-9">
            <div className="booking-data h-100">
              <button
                className="report-studio"
                data-bs-toggle="modal"
                data-bs-target="#bookingInfo"
                data-bs-dismiss="modal"
              >
                <img src={ReportIcon} className="report-image" alt="" />
              </button>
              <div className="studio-data">
                <Link to="" className=" !text-[#620808]">
                  {name}
                </Link>
                <p className="location ">{area}</p>
              </div>
              <div className="about-booking h-100 d-flex flex-column justify-content-between py-lg-4 ">
                Event Date:
                <p className="date text-main">{startDate - endDate}</p>
                <div className="buttons-here">
                  {/* <button className="cancel"><img src={CloseIcon} alt="" /> Write Review</button> */}
                  <button
                    className="add-button bg-transparent primmary-site-color font-17"
                    data-bs-toggle="modal"
                    data-bs-target="#ReviewForm"
                  >
                    <img src={CommentIcon} alt="" /> Write a review
                  </button>
                  <Link to="/my-footage" className="btn site-btn px-4">
                    <i className="fa fa-eye text-white"></i> View Footage
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class={`modal fade`}
        id="ReviewForm"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">
                Write a Review
              </h5>
              <button
                type="button"
                class="btn-close text-lg font-bold"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={modalClose}
              >
                X
              </button>
            </div>
            <div class="modal-body">
              <form onSubmit={handleReviewSubmit}>
                <div class="mb-3 form-group">
                  <label htmlFor="">Rating *</label>

                  <div className="col-md-12">
                    <div class="input-group star-rating">
                      <div class="rate">
                        <input type="radio" id="star5" name="rate" value="5" />
                        <label for="star5" title="text">
                          5 stars
                        </label>
                        <input type="radio" id="star4" name="rate" value="4" />
                        <label for="star4" title="text">
                          4 stars
                        </label>
                        <input type="radio" id="star3" name="rate" value="3" />
                        <label for="star3" title="text">
                          3 stars
                        </label>
                        <input type="radio" id="star2" name="rate" value="2" />
                        <label for="star2" title="text">
                          2 stars
                        </label>
                        <input type="radio" id="star1" name="rate" value="1" />
                        <label for="star1" title="text">
                          1 star
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mb-3 form-group">
                  <label htmlFor="">Review *</label>

                  <div className="col-md-12 mt-2">
                    <div class="input-group mb-3">
                      <textarea
                        type="text"
                        class="form-control resize-none"
                        placeholder="Enter Your Review"
                        aria-label="Name"
                        rows={"5"}
                      ></textarea>
                    </div>
                  </div>
                  <div className="w-full text-center">
                    <button
                      type="submit"
                      class="px-[48px] py-[10px] text-white text-sm bg-main rounded-md"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <BookingInfo />
    </>
  );
};

export default PastBookingCard;
