import React from "react";
import { Link } from "react-router-dom";
import { CloseIcon, ReportIcon } from "../Constant/Index";
import BookingInfo from "./Modals/BookingInfo";
import axios from "axios";

const ConfirmedBookingCard = ({
  id,
  image,
  name,
  area,
  startDate,
  endDate,
  photographerId,
  handleShowChat,
  photographerName,
}) => {
  const token = localStorage.getItem("authToken");
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const handleDelete = async () => {
    try {
      const apiUrl = `${baseUrl}/photographer/booking/${id}`;
      const headers = {
        "x-auth-token": token,
      };

      const data = {
        status: "CANCELLED",
      };

      const response = await axios.patch(apiUrl, data, {
        headers: headers,
      });

      if (response.status === 200) {
        const responseData = response.data.data;
        console.log(responseData);
        // if (Array.isArray(responseData.rows)) {
        //   console.log(responseData);
        //   // setCardData(responseData.rows);
        // } else {
        //   console.error("Invalid data format: rows is not an array.");
        // }
      } else {
        console.error("Unable to fetch data.");
      }
    } catch (error) {
      console.error("Error while fetching data:", error);
    }
  };
  return (
    <>
      <div className="single-booking wow animate__animated animate__fadeIn animate__delay-1s">
        <div className="row">
          <div className="col-md-3 overflow-hidden">
            <figure className="studio-image " style={{ height: "100px" }}>
              <img src={image} className=" w-100" alt="" />
            </figure>
          </div>
          <div className="col-md-9">
            <div className="booking-data h-100">
              <button
                className="report-studio"
                data-bs-toggle="modal"
                data-bs-target="#bookingInfo"
                data-bs-dismiss="modal"
              >
                <img src={ReportIcon} className="report-image" alt="" />
              </button>
              <div className="studio-data">
                <Link to="" className="!text-[#620808]">
                  {name}
                </Link>
                <p className="location">{area}</p>
              </div>
              <div className="about-booking  d-flex flex-column justify-content-between py-lg-4">
                Event Date:
                <p className="date !text-main">
                  {startDate} - {endDate}
                </p>
                <div className="buttons-here pt-4">
                  <button
                    className="other-btn px-5 abcd-main"
                    onClick={() =>
                      handleShowChat(photographerName, photographerId)
                    }
                    id="abcd-main"
                  >
                    {" "}
                    Chat
                  </button>
                  <button onClick={handleDelete} className="cancel">
                    <img src={CloseIcon} alt="" /> Cancel
                  </button>
                </div>
                <p className="text-end contact-ous-booking font-bold text-main">
                  Any Issues ?{" "}
                  <button
                    className="font-bold"
                    onClick={() => handleShowChat("admin", -1)}
                  >
                    Contact boopho.com
                  </button>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BookingInfo />
    </>
  );
};

export default ConfirmedBookingCard;
