import axios from "axios";
const BASEURL = `${process.env.REACT_APP_BASE_URL}`;

const uploadFileUsingSignedUrl = async (files, uniqueFolderName="resources/photographer", callback) => {
    if (!files.length) {
        alert('Please select files first!');
        return;
    }

    const fileNames = Array.from(files).map(file => file.name);
console.log(fileNames, "fileNames");
    // Add the unique folder name to each file path
    const filePaths = fileNames.map(name => `${uniqueFolderName}/${name}`);

    try {
        const token = localStorage.getItem("authToken");
        const response = await axios.post(`${BASEURL}/share/generate-presigned-url`, {
            files: filePaths
        }, { headers: { "x-auth-token": token } });

console.log(response, "fileUrl:resp");
        // Upload each file to S3
        response.data.map(async (data, index) => {
            await axios.put(data.url, files[index], {
                headers: {
                    // 'Content-Type': files[index].type,
                    'Content-Type': 'binary/octet-stream',
                }
            });
        })
        return { success: true, message: 'Files uploaded successfully!', data: '' };
        // callback(index);

        // alert('Files uploaded successfully!');
    } catch (error) {
        console.error('Error during file upload:', error);
        return { success: false, message: 'Files can not be uploaded this time' };
    }
};

export default uploadFileUsingSignedUrl;