import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "../Pages/Home/Home";
import WeddingPhotography from "../Pages/Photography/WeedingPhotography";
import BusinessPhotography from "../Pages/Photography/BusinessPhotography";
import MaternityPhotography from "../Pages/Photography/MaternityPhotography";
import BirthdayPhotography from "../Pages/Photography/BirthdayPhotography";
import BornBabyPhotography from "../Pages/Photography/BornBabyPhotography";
import AboutUs from "../Pages/AboutUs/AboutUs";
import ContactUs from "../Pages/ContactUs/ContactUs";
import Faqs from "../Pages/Faqs/Faqs";
import TermsConditions from "../Pages/Terms/TermsConditions";
import Privacy from "../Pages/Privacy/Privacy";
import Gallery from "../Pages/Gallery/Gallery";
import StudioDetails from "../Pages/AboutUs/StudioDetails";
import StudioDetails1 from "../Pages/AboutUs/StudioDetails1";
import Booking from "../Pages/Booking/Booking";
import BookingDetails from "../Pages/Booking/BookingDetails";
import Payment from "../Pages/Booking/Payment";
import ConfirmedBooking from "../Pages/Booking/ConfirmedBooking";
import WishList from "../Pages/WishList/WishList";
import Profile from "../Pages/Profile/Profile";
import Explorer from "../Pages/explorer/Explorer";
import BookingConfirmed from "../Pages/Booking/BookingConfirmed";
import ListYourBusiness from "../Pages/ListYourBusiness/ListYourBusiness";
import ChangePassword from "../Pages/ChangePassword/ChangePassword";
import BookForm from "../Pages/Home/BookForm";
// Photgrapher Pages Start Here
// import Register from '../Photographer/Register/Register';
import EditorRegister from "../Editor/Register/EditorRegistor";
// Photgrapher Pages Start Here
import Dashboard from "../Pages/Dashboard/Dashboard";
import PhotographerProfile from "../Photographer/PhotographerProfile/PhotographerProfile";
import PhotographerRegister from "../Photographer/Register/Register";
import PastEvents from "../Photographer/Events/PastEvents";
import ProgressEvents from "../Photographer/Events/ProgressEvents";
// import BusyCalendar from "../Photographer/BusyCalendar/BusyCalendar";
import MarkCalendarBusyPage from "../Photographer/MarkCalendarBusy/MarkCalendarBusyPage";
import PhotographerPayments from "../Photographer/Payment/PhotographerPayments";
import UpcomingEvents from "../Photographer/Events/UpcomingEvents";
import AllEventDetail from "../Photographer/Events/AllEventDetail";
// import ProgressEventDetail from '../Photographer/Events/ProgressEventDetail';
import ProgressEventDetail from "../Photographer/Events/ProgressEventDetail";
// import ProtectedRoute from '../Components/ProtectedRoute';
import EnterPhotographer from "../Editor/Register/EnterPhotographer";
import EditorPastEvents from "../Editor/Events/EditorPastEvents";
import EditorProgressEvents from "../Editor/Events/EditorProgressEvents";
import EditorUpcomingEvents from "../Editor/Events/EditorUpcomingEvents";
import EditorAllEventDetail from "../Editor/Events/EditorAllEventDetail";
import EditorChangePassword from "../Editor/Register/ChangePassword";
import BookingPast from "../Pages/Booking/BookingPast";
import ReportStudio from "../Pages/Booking/ReportStudio";
import BookingCancelled from "../Pages/Booking/BookingCancelled";
import MyFootage from "../Pages/MyFootage/MyFootage";
import Files from "../Pages/MyFootage/Files";
import CustomerFiles from "../Pages/MyFootage/CustomerFiles";
import CustomerFolders from "../Pages/MyFootage/CustomerFolders";
import FolderCustomerFiles from "../Pages/MyFootage/FolderCustomerFiles";
import FaqsComponent from "../Pages/Faqs/FaqsComponent";
import BookingSummary from "../Pages/Booking/BookingSummary";
import PayNow from "../Pages/Booking/PayNow";
import PhotographerCategories from "../Pages/Photography/PhotographerCategories";
import CAncellationPolicy from "../Pages/Privacy/CancellationPolicy";
import GenerateCouponPage from "../Photographer/GenerateCouponCode/GenerateCouponPage";
import axios from "axios";
import PhotographerSearch from "../Pages/Photography/PhotographerSearch";
import ConfirmDeal from "../Pages/ConfirmDeal/ConfirmDeal";
import BookingInfo from "../Pages/Booking/BookingInfo";
import RequestedCalls from "../Photographer/RequestedCalls/RequestedCalls";
import CustomerChats from "../Photographer/CustomerChats/CustomerChats";
import ChatPage from "../Photographer/CustomerChats/ChatPage";
// import { initiateSocket } from "../Utils/socket";
import ResetPassword from "../Pages/ResetPassword/ResetPassword";
import AccountCreated from "../Pages/AccountCreated/AccountCreated";
import WebsiteNotEnabled from "../Pages/WebsiteNotEnabled";
import PaymentSuccess from "../Pages/PaymentSuccess/Payment_success";
import EditorProgressEventDetail from "../Photographer/Events/EditorProgressEventDetails";
import BookingForm from "../Components/BookingForm";

const ProtectedRoute = ({ element, isAuthenticated }) => {
  return isAuthenticated ? element : <Navigate to="/" />;
};

const PublicRoutes = () => {
  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem("loggedIn") === "true"
  );

  const baseUrl = process.env.REACT_APP_BASE_URL;
  const token = localStorage.getItem("authToken");
  const [photographer, setPhotographer] = useState("");

  const photographerDetail = () => {
    if (token) {
      const headers = {
        "x-auth-token": token,
      };

      axios
        .get(`${baseUrl}/photographer/me`, { headers }, {})
        .then((response) => {
          console.log(response.data);
          setPhotographer(response.data.data.Photographer.status);
          localStorage.setItem(
            "photographer_status",
            response.data.data.Photographer.status
          );
        })
        .catch((error) => {
          console.error("Error fetching categories:", error);
        });
    }
  };

  useEffect(() => {
    const role = localStorage.getItem("role");
    if (role === "EDITOR") {
      console.log(window.location.pathname, "====pathhhhnnnaaammmmmeeeee");
      if (
        !window.location.pathname.includes("editor") &&
        window.location.pathname !== "/editor/upcomingevents"
      ) {
        window.location.href = "/editor/upcomingevents";
      }
    } else {
      photographerDetail();
    }
  }, [token]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/service-unavailable" element={<WebsiteNotEnabled />} />
        <Route
          path="/"
          element={
            photographer !== "APPROVED" && photographer !== "HOLD" ? (
              <Home />
            ) : (
              <Navigate to="/photographer/upcomingevents/0" />
            )
          }
        />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute
              element={<Dashboard />}
              isAuthenticated={loggedIn}
            />
          }
        />
        {/* <Route path="/photography/:type" element={<WeddingPhotography />} /> */}
        {/* <Route path='/dashboard' element={<Dashboard />} /> */}
        {/* <Route path="/photography/:type" element={<BusinessPhotography />} /> */}
        {/* <Route
          path="/photography/:type"
          element={<MaternityPhotography />}
        /> */}
        <Route
          path="/photography/:type"
          element={
            photographer !== "APPROVED" && photographer !== "HOLD" ? (
              <PhotographerCategories />
            ) : (
              <Navigate to="/photographer/upcomingevents/0" />
            )
          }
        />
        <Route
          path="/photography/:type/search"
          element={<PhotographerSearch />}
        />
        {/* <Route path="/photography/:type" element={<BirthdayPhotography />} />
        <Route path="/photography/:type" element={<BornBabyPhotography />} /> */}
        <Route
          path="/aboutus"
          element={
            photographer !== "APPROVED" && photographer !== "HOLD" ? (
              <AboutUs />
            ) : (
              ""
            )
          }
        />
        <Route
          path="/contactus"
          element={
            photographer !== "APPROVED" && photographer !== "HOLD" ? (
              <ContactUs />
            ) : (
              ""
            )
          }
        />
        <Route path="/faqs" element={<FaqsComponent />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/terms" element={<TermsConditions />} />
        <Route path="/cancellation" element={<CAncellationPolicy />} />
        <Route path="/privacy_policy" element={<Privacy />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route
          path="/photography/:type/:id"
          element={
            photographer !== "APPROVED" && photographer !== "HOLD" ? (
              <StudioDetails />
            ) : (
              <Navigate to="/photographer/upcomingevents/0" />
            )
          }
        />

        {/* <Route path="/photography/:type/:id" element={photographer !== "APPROVED" ? <StudioDetails /> : <Navigate to="/photographer/upcomingevents/0" />} /> */}
        <Route path="/studio-details-1" element={<StudioDetails1 />} />
        {/* <Route path='/booking' element={<Booking />} /> */}
        {/* <Route path="/booking-past" element={<BookingPast />} /> */}

        {/* <Route
          path="/booking"
          element={
            <ProtectedRoute
              element={photographer !== "APPROVED" ? <Booking /> : ""}
              isAuthenticated={loggedIn}
            />
          }
        /> */}

        <Route path="/booking-info" element={<BookingInfo />} />
        <Route path="/registration-success" element={<AccountCreated />} />
        <Route
          path="/booking-details/:category/:id/:bookingId"
          element={<BookingDetails />}
        />
        <Route
          path="/booking-summary/:category/:id/:bookingId"
          element={<BookingSummary />}
        />
        <Route path="/report-studio" element={<ReportStudio />} />
        <Route path="/payment" element={<PayNow />} />
        <Route path="/change-password" element={<ChangePassword />} />
        {/* <Route path="/payment" element={<Payment />} /> */}
        {/* <Route
          path="/wishlist"
          element={
            <ProtectedRoute
              element={photographer !== "APPROVED" ? <WishList /> : ""}
              isAuthenticated={loggedIn}
            />
          }
        /> */}

        <Route
          path="/payment-success"
          element={
            <ProtectedRoute
              element={<PaymentSuccess />}
              isAuthenticated={loggedIn}
            />
          }
        />
        {/* <Route path="/confirmedbooking" element={<ConfirmedBooking />} /> */}
        {/* <Route path='/explorer' element={<Explorer />} /> */}
        {/* <Route exact element={<MyFootage />} path="/my-footage" /> */}
        {/* <Route exact element={<Files />} path="/my-footage/:slug" /> */}
        {/* <Route
          exact
          element={<CustomerFolders />}
          path="/my-footage/:slug/:id"
        />
        <Route
          exact
          element={<CustomerFiles />}
          path="/my-footage/:slug/:id/:folder"
        />
        <Route
          exact
          element={<FolderCustomerFiles />}
          path="/my-footage/:slug/:id/:folder/:myfolder"
        /> */}
        <Route
          path="/profile"
          element={
            <ProtectedRoute
              element={
                photographer === "APPROVED" || photographer === "HOLD" ? (
                  <PhotographerProfile />
                ) : (
                  <Profile />
                )
              }
              isAuthenticated={loggedIn}
            />
          }
        />
        {/* <Route path="/bookingconfirmed" element={<BookingConfirmed />} /> */}
        {/* <Route path="/bookingcanceled" element={<BookingCancelled />} /> */}
        {/* <Route path='/listyourbusiness' element={<ListYourBusiness />} /> */}
        {/* <Route
          path="/listyourbusiness"
          element={
            <ProtectedRoute
              element={<ListYourBusiness />}
              isAuthenticated={loggedIn}
            />
          }
        /> */}

        <Route path="/editor/pastevents" element={<EditorPastEvents />} />
        <Route
          path="/editor/progressevents"
          element={<EditorProgressEvents />}
        />
        {/* <Route
          path="/editor/upcomingevents"
          element={<EditorUpcomingEvents />}
        /> */}
        <Route
          path="/editor/upcomingevents"
          element={<EditorUpcomingEvents />}
        />
        <Route
          path="/editor/events/single"
          element={<EditorAllEventDetail />}
        />
        {/* Photographer Start */}
        <Route
          path="/photographer/register"
          element={
            <ProtectedRoute
              element={<PhotographerRegister />}
              isAuthenticated={loggedIn}
            />
          }
        />
        <Route
          path="/photographer/profile"
          element={
            photographer === "APPROVED" || photographer === "HOLD" ? (
              <PhotographerProfile />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/photographer/pastevents/:pageId"
          element={
            photographer === "APPROVED" || photographer === "HOLD" ? (
              <PastEvents />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/photographer/progressevents/:pageId"
          element={
            photographer === "APPROVED" || photographer === "HOLD" ? (
              <ProgressEvents />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/photographer/upcomingevents/:pageId"
          element={
            photographer === "APPROVED" || photographer === "HOLD" ? (
              <UpcomingEvents />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/photographer/progress/folder/:pageId"
          element={<ProgressEventDetail />}
        />
        {/* Editor */}
        <Route
          path="/editor/progress/folder"
          element={<EditorProgressEventDetail isEditor={true} />}
        />
        <Route
          path="/editor/progress/folder/:pageId"
          element={<EditorProgressEventDetail isEditor={true} />}
        />
        {/* <Route path="/photographer/busydays" element={<BusyCalendar />} /> */}
        <Route
          path="/photographer/busydays"
          element={
            photographer === "APPROVED" || photographer === "HOLD" ? (
              <MarkCalendarBusyPage />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/photographer/events/single/:pageId"
          element={<AllEventDetail />}
        />
        <Route
          path="/photographer/payments"
          element={
            photographer === "APPROVED" || photographer === "HOLD" ? (
              <PhotographerPayments />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/photographer/generate-coupon-code"
          element={
            photographer === "APPROVED" || photographer === "HOLD" ? (
              <GenerateCouponPage />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/photographer/requested-calls"
          element={
            photographer === "APPROVED" || photographer === "HOLD" ? (
              <RequestedCalls />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/photographer/customer-chats"
          element={
            photographer === "APPROVED" || photographer === "HOLD" ? (
              <CustomerChats />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/photographer/customer-chats/:id"
          element={
            photographer === "APPROVED" || photographer === "HOLD" ? (
              <ChatPage />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route path="/editor/profile" element={<EditorRegister />} />
        <Route path="/editor/password" element={<EditorChangePassword />} />
        {/* Editor Start Here */}
        <Route
          path="/editor/photographer"
          element={
            <ProtectedRoute
              element={<EnterPhotographer />}
              isAuthenticated={loggedIn}
            />
          }
        />
        {/* Payment Success */}
        <Route
          path="/payment-success"
          element={
            <ProtectedRoute
              element={<h1>Payment Success</h1>}
              isAuthenticated={loggedIn}
            />
          }
        />

        <Route path="/confirm-deal" element={<ConfirmDeal />} />
        <Route path="/bookPhotographer" element={<BookForm />} />
      </Routes>
    </BrowserRouter>
  );
};
export default PublicRoutes;
