import React, { useState, useEffect } from "react";
import axios from "axios";

const GenerateCoupon = ({getAllCoupons}) => {
  const token = localStorage.getItem("authToken");
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [discountData, setDiscountData] = useState({
    discount: "",
    categoryId: 17,
    photographerId: localStorage.getItem('photoGrapherUser'),
    startDate: "",
    endDate: ""
  });

  const handleDiscountChange = (e) => {
    setDiscountData({
      ...discountData,
      discount: e.target.value,
    });
  };

  const handleDateStartChange = (e) => {
    setDiscountData({
      ...discountData,
      startDate: e.target.value,
    });
  };

  const handleDateEndChange = (e) => {
    setDiscountData({
      ...discountData,
      endDate: e.target.value,
    });
  };

  const handleEventTypeChange = (e) => {
    setDiscountData({
      ...discountData,
      categoryId: e.target.value,
    });
  };

  const applyDiscount = async() => {
    try {
      setErrorMessage('');
      setErrorMessage('');
      const apiUrl = `${baseUrl}/photographer-coupon-code/create`;
  
      const response = await axios.post(apiUrl, discountData, {
        headers: {
          "x-auth-token": token,
        },
      });
  
      if (response.status === 200) {
        const responseData = response.data.data;
        setSuccessMessage(response.data.message);
        getAllCoupons();
      }
    } catch (error) {
      console.error("Error while fetching data:", error);
      setErrorMessage(error.response.data.error.message);
    } 
  };
  
  return (
    <section className="coupon-form">
      <div
        className="modal fade"
        id="GenerateCoupon"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header bg-main">
              <h5 className="modal-title text-white" id="staticBackdropLabel">
                Message
              </h5>
              <button
                type="button"
                className="btn-close text-lg font-bold text-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                X
              </button>
            </div>
            {errorMessage && <p style={{color:"red"}}>{errorMessage}</p>}
            {successMessage && <p style={{color:"green"}}>{successMessage}</p>}
            <div className="modal-body">
              <form>
                <div className="form-group row mb-3">
                  <div className="col-lg-5">
                    <label htmlFor="discount">Enter the Discount (%)</label>
                  </div>
                  <div className="col-lg-7">
                    <input
                      id="discount"
                      type="number"
                      className="p-2 border border-gray-300 outline-none w-full rounded-md"
                      placeholder="In Percent %"
                      onChange={handleDiscountChange}
                    />
                  </div>
                </div>
                <div className="form-group row mb-3">
                  <div className="col-lg-5">
                    <label htmlFor="">Date range</label>
                  </div>
                  <div className="col-lg-7">
                    <div className="w-full p-2 rounded-md flex gap-2 outline-none border border-gray-300">
                      <input
                        type="date"
                        className="outline-none"
                        id="dateStart"
                        onChange={handleDateStartChange}
                      />
                      <input
                        type="date"
                        className="outline-none"
                        id="dateEnd"
                        onChange={handleDateEndChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group row mb-3">
                  <div className="col-lg-5">
                    <label htmlFor="">Event Type</label>
                  </div>
                  <div className="col-lg-7">
                    <select
                      name=""
                      id=""
                      className="form-control form-select rounded-md"
                      onChange={handleEventTypeChange}
                    >
                      <option value="17">Wedding</option>
                      <option value="18">Maternity</option>
                      <option value="22">New Born</option>
                      <option value="24">Birthday</option>
                      <option value="25">Business</option>
                    </select>
                  </div>
                </div>
                <div className="text-center mt-5 mb-3">
                  <button
                    className="btn site-btn w-fit mx-auto d-block"
                    type="button"
                    onClick={applyDiscount}
                  >
                    Apply Discount
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GenerateCoupon;
