import Header from "../../Components/Header"
import BookingForm from "../../Components/BookingForm"

const BookForm = () => {
    return (
        <>
            <Header />
            <BookingForm />
        </>
    )
}

export default BookForm