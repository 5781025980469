import React, { useEffect, useState } from "react";
import { RxCross1 } from "react-icons/rx";

const ModalLayout = ({ children, showModal, setShowModal, heading }) => {
  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (showModal === true) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "visible";
    }

    return () => {
      document.body.style.overflowY = "visible";
    };
  }, [showModal]);

  return (
    <>
      {showModal && (
        <section className={` fixed inset-0 flex justify-center z-[999]`}>
          <div
            className="bg-black/50 absolute w-full h-full top-0"
            onClick={closeModal}
          ></div>

          <div className="fixed top-[15%] z-20 flex flex-col gap-10 p-3 min-w-[80vw] max-w-[80vw] md:min-w-[40vw] md:max-w-[40vw] bg-white rounded-lg max-h-full overflow-auto">
            <div className="relative flex justify-center items-centerw-full bg-main p-2 rounded-t-md">
              <p className="text-white">{heading}</p>
              <RxCross1
                className="absolute right-2 text-white text-xl cursor-pointer"
                onClick={closeModal}
              />
            </div>
            {children}
          </div>
        </section>
      )}
    </>
  );
};

export default ModalLayout;
