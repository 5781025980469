import React, { useEffect } from "react";

import { RxCross1 } from "react-icons/rx";

const MorePhotos = ({ showModal, setShowModal, morePhotos }) => {
  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (showModal === true) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "visible";
    }

    return () => {
      document.body.style.overflowY = "visible";
    };
  }, [showModal]);

  return (
    <>
      {showModal && (
        <section className={` fixed inset-0 flex justify-center z-50`}>
          <div
            className="bg-black/50 absolute w-full h-full top-0"
            onClick={closeModal}
          ></div>

          <div className="fixed top-[10%] bg-white z-20 flex flex-col gap-1 pb-8 min-w-[80vw] md:min-w-[60vw] md:max-w-[60vw] md:max-h-[500px] bg-bgMain rounded-lg">
            <div className="relative flex justify-center items-centerw-full bg-secondary p-2 rounded-t-md">
              <p className="text-white">More Photos</p>
              <RxCross1
                className="absolute right-2 text-white text-xl cursor-pointer"
                onClick={closeModal}
              />
            </div>

            <div className="flex flex-wrap gap-3 justify-center items-center min-h-[300px] overflow-auto py-5">
              {morePhotos?.map((item, index) => {
                return (
                  <div key={index} className="relative">
                    <img key={index} src={item} alt="" width={200} />
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default MorePhotos;
