import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Banner from "../../Components/banner";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { Studio1, CloseIcon, ReportIcon } from "../../Constant/Index";
import ChatBot from "react-simple-chatbot";
import { ThemeProvider } from "styled-components";
import BookingTabs from "../../Components/BookingTabs";

const ConfirmedBooking = () => {
  const [TheChat, SetTheChat] = useState(false);
  const [adminChat, setShowAdminChat] = useState(false);

  const ToggleChat = () => {
    SetTheChat((currentChat) => !currentChat);
  };
  const BookingsList = [
    {
      Name: "Lens Queen Studio",
      Area: "New Delhi",
      Date: "Event Date: 18th Apr 2022 - 22nd Apr 2022",
      LastBtn: "Awaiting Call",
      image: Studio1,
      Text: "",
    },
  ];

  // all available props
  const theme = {
    background: "#f5f8fb",
    fontFamily: "Helvetica Neue",
    headerBgColor: "#737373",
    headerFontColor: "#fff",
    headerFontSize: "15px",
    botBubbleColor: "#737373",
    botFontColor: "#fff",
    userBubbleColor: "#fff",
    userFontColor: "#4a4a4a",
  };

  const steps = [
    {
      id: "1",
      message: "Hello World",
      end: true,
    },
  ];

  // useEffect(() => {
  //     const chatContent = document.querySelector('.chat-content');
  //     const toggleButton = document.querySelector('.chat-header button');
  //     const chatBox = document.querySelector('.chat-box');
  //     const abcdMain = document.querySelector('.abcd-main'); // Select the .abcd-main element

  //     const toggleChat = () => {
  //         if (chatContent.style.maxHeight) {
  //             chatContent.style.maxHeight = null;
  //             chatContent.classList.remove('active');
  //             toggleButton.innerText = 'Show';
  //         } else {
  //             chatContent.style.maxHeight = chatContent.scrollHeight + 30 + 'px';
  //             chatContent.classList.add('active');
  //             toggleButton.innerText = 'Hide';
  //         }
  //     };

  //     const triggerButtonClick = () => {
  //         toggleButton.click(); // Trigger a click event on the toggleButton
  //     };

  //     // Add event listener to the button
  //     toggleButton.addEventListener('click', toggleChat);

  //     // Add event listener to handle outside click
  //     window.addEventListener('click', function (e) {
  //         if (!chatBox.contains(e.target)) {
  //             chatContent.style.maxHeight = null;
  //             chatContent.classList.remove('active');
  //             toggleButton.innerText = 'Show';
  //         }
  //     });

  //     // Add event listener to .abcd-main to trigger button click
  //     abcdMain.addEventListener('click', triggerButtonClick);

  //     // Initialize the chat content
  //     chatContent.style.maxHeight = chatContent.scrollHeight + 30 + 'px';
  //     chatContent.classList.add('active');
  //     toggleButton.innerText = 'Hide';

  //     // Cleanup: Remove event listeners when the component unmounts
  //     return () => {
  //         toggleButton.removeEventListener('click', toggleChat);
  //         window.removeEventListener('click', function (e) {
  //             if (!chatBox.contains(e.target)) {
  //                 chatContent.style.maxHeight = null;
  //                 chatContent.classList.remove('active');
  //                 toggleButton.innerText = 'Show';
  //             }
  //         });

  //         // Remove event listener from .abcd-main
  //         abcdMain.removeEventListener('click', triggerButtonClick);
  //     };
  // }, []);

  return (
    <>
      <main className="site-front-page">
        <Header />
        <Banner home="Home" currenturl="/booking" current="My Bookings" />
        <section className="booking-section">
          <div className="bootstrap-container">
            <div className="booking-start">
              {/* <ul className="tabs-list wow animate__animated animate__fadeInDown animate__delay-1s">
                <li className="single-item ">
                  <Link to="/booking" className="tab-btn !text-main">
                    Requested Bookings
                  </Link>
                </li>
                <li className="single-item active">
                  <Link
                    to="/confirmedbooking"
                    className="tab-btn !text-main font-bold"
                  >
                    Confirmed Bookings
                  </Link>
                </li>
                <li className="single-item">
                  <Link to="/booking-past" className="tab-btn !text-main">
                    Past Bookings
                  </Link>
                </li>
                <li className="single-item">
                  <Link to="/bookingcanceled" className="tab-btn !text-main">
                    Cancelled Bookings
                  </Link>
                </li>
              </ul> */}
              <BookingTabs />
              <div className="booking-list overflow-hidden">
                {BookingsList.map((SingleBooking, index) => (
                  <div className="single-booking wow animate__animated animate__fadeInDown animate__delay-1s">
                    <div className="row">
                      <div className="col-md-3 overflow-hidden">
                        <figure className="studio-image wow animate__animated animate__fadeInLeft animate__delay-2s">
                          <img
                            src={SingleBooking.image}
                            className=" w-100"
                            alt=""
                          />
                        </figure>
                      </div>
                      <div className="col-md-9">
                        <div className="booking-data h-100">
                          <div className="report-studio">
                            <img
                              src={ReportIcon}
                              className="report-image"
                              alt=""
                            />
                          </div>
                          <div className="studio-data">
                            <Link
                              to=""
                              className="wow animate__animated animate__rubberBand animate__delay-2s !text-[#620808]"
                            >
                              {SingleBooking.Name}
                            </Link>
                            <span className="location wow animate__animated animate__fadeInDown animate__delay-2s">
                              {SingleBooking.Area}
                            </span>
                          </div>
                          <div className="about-booking  d-flex flex-column justify-content-between py-lg-4 wow animate__animated animate__fadeInRight animate__delay-2s">
                            <span className="date !text-main">
                              {SingleBooking.Date}
                            </span>
                            <div className="buttons-here pt-4">
                              <button
                                className="other-btn px-5 abcd-main"
                                onClick={ToggleChat}
                                id="abcd-main"
                              >
                                {" "}
                                Chat
                              </button>
                              <button className="cancel">
                                <img src={CloseIcon} alt="" /> Cancel
                              </button>
                            </div>
                            <p className="text-end contact-ous-booking font-bold text-main">
                              Any Issues ?{" "}
                              <button
                                className="font-bold"
                                onClick={() => setShowAdminChat(true)}
                              >
                                Contact boopho.com
                              </button>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
        {/* chat-btn-here */}
        {!TheChat && (
          <button
            className={`flex justify-center items-center fixed bottom-2 right-2 bg-black rounded-full w-[62px] h-[62px]`}
            onClick={ToggleChat}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="currentColor"
              class="bi bi-chat-square-text"
              viewBox="0 0 16 16"
              id="IconChangeColor"
            >
              <path
                d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1h-2.5a2 2 0 0 0-1.6.8L8 14.333 6.1 11.8a2 2 0 0 0-1.6-.8H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h2.5a1 1 0 0 1 .8.4l1.9 2.533a1 1 0 0 0 1.6 0l1.9-2.533a1 1 0 0 1 .8-.4H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"
                id="mainIconPathAttribute"
                fill="#FFF"
              ></path>{" "}
              <path
                d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6zm0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z"
                id="mainIconPathAttribute"
                fill="#FFF"
              ></path>{" "}
            </svg>
          </button>
        )}
        {TheChat && (
          <div className="react-simple-chatbot-container">
            <button
              className={`btn-here bg-transparent border-0 ms-auto w-fit d-block pb-2 `}
              onClick={ToggleChat}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="53"
                height="53"
                fill="currentColor"
                class="bi bi-x-circle-fill"
                viewBox="0 0 16 16"
                id="IconChangeColor"
              >
                {" "}
                <path
                  d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"
                  id="mainIconPathAttribute"
                  fill="#000000"
                ></path>{" "}
              </svg>
            </button>
            <ThemeProvider theme={theme}>
              <ChatBot
                // floating="true"
                steps={[
                  {
                    id: "1",
                    message: "What is your name?",
                    trigger: "2",
                  },
                  {
                    id: "2",
                    user: true,
                    trigger: "3",
                  },
                  {
                    id: "3",
                    message: "Hi {previousValue}, nice to meet you!",
                    end: true,
                  },
                ]}
              />
            </ThemeProvider>
          </div>
        )}
        {adminChat && (
          <div className="w-full h-full fixed top-5 bottom-5 z-50">
            <div className="flex w-[80%] flex-col mx-auto">
              <div className="self-end">
                <button
                  className="w-[50px] h-[50px] rounded-full bg-[#737373] text-white font-extrabold"
                  onClick={() => setShowAdminChat(false)}
                >
                  X
                </button>
              </div>
              <ThemeProvider theme={theme}>
                <ChatBot
                  headerTitle="Chat with Boopho.com"
                  width="100%"
                  steps={[
                    {
                      id: "1",
                      message: "Hi I am Admin?",
                      trigger: "2",
                    },
                    {
                      id: "2",
                      user: true,
                      trigger: "3",
                    },
                    {
                      id: "3",
                      message: "Hi {previousValue}, nice to meet you!",
                      end: true,
                    },
                  ]}
                />
              </ThemeProvider>
            </div>
          </div>
        )}
        <Footer />
      </main>
    </>
  );
};
export default ConfirmedBooking;
