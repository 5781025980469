import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import PhotographerSidebar from "../Components/Sidebar";
import Swal from "sweetalert2";
import { uploadChunksByChunks } from "../../Utils/uploadChunks";
import { useId } from "react";
import authApi from "../../Utils/axiosConfig";
import { useDispatch } from "react-redux";
import { createTimelineAsync } from "../../Redux/slice/mainSlice";
const ProgressEventDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location, "LOCATION");
  const eventDetails = location.state;
  const [folder, SetFolder] = useState(false);
  const openFolder = () => {
    SetFolder(true);
  };
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});
  const [isUploading, setIsUploading] = useState(false);
  const [footageName, setFootageName] = useState("");
  // const [id1, id2, id3] = [useId(), useId(), useId()];
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(window.location.search);

  // Extract individual query parameters
  const photographerId = queryParams.get("photographerId");
  const userId = queryParams.get("userId");
  const bookingId = queryParams.get("bookingId");
  console.log(location.state, "state:ProgressEventDetail");

  const uploadedFilesProgress = (name, status) => {
    setUploadProgress((prev) => ({ ...prev, [name]: status }));
  };

  const handleFileUpload = async (e) => {
    const files = e.target.files;
    // const newUploadedFiles = [...uploadedFiles];
    // for (let i = 0; i < files.length; i++) {
    //   newUploadedFiles.push(files[i]);
    // }
    console.log(files, "files:handleFileUpload");
    setUploadedFiles([...files]);
  };

  const renderFilesList = () => {
    return uploadedFiles.map((file, index) => (
      <div className="single-file col-xl-2 col-lg-3 col-md-3 col-4" key={index}>
        <img
          src={URL.createObjectURL(file)}
          alt={file.name}
          style={{ width: "100%", height: "200px", objectFit: "cover" }}
        />
        <p>{file.name}</p>
      </div>
    ));
  };

  const showAlert = () => {
    // Display the SweetAlert2 dialog
    Swal.fire({
      title: "Success",
      text: "Photos and Videos will be sent to  Customer post review",
      icon: "success",
      showCancelButton: false,
      confirmButtonText: "Ok",
    }).then((result) => {
      if (result.isConfirmed) {
        // Redirect to the desired page
        window.location.href = "/photographer/upcomingevents/0";
        console.log("Redirecting...");
      }
    });
  };

  function generateRandomString(length) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  }

  const sendToCustomer = async () => {
    // const uploadFilePromise = [];
    if (footageName.length > 3) {
      setIsUploading(true);
      const random = generateRandomString(20);
      const path = `photographer-uploads/${random}`;
      for (const file of uploadedFiles) {
        uploadedFilesProgress(file.name, 0);
        // uploadFilePromise.push();
        await uploadChunksByChunks(file, path, uploadedFilesProgress);
      }

      // await Promise.allSettled(uploadFilePromise);
      setIsUploading(false);

      const data = {
        pgId: photographerId,
        userId: userId,
        sharedUrl: path,
        bookingId: bookingId,
        name: footageName,
        expiry: 30,
      };

      const resp = await authApi.post(
        "/share/photographer-share-with-user",
        data
      );
      dispatch(
        createTimelineAsync({
          timelineData: {
            title: "Raw Footage Shared With Customer From Photographer",
          },
          bookingId: bookingId,
        })
      );
      console.log(resp.data, "resp:sendToCustomer");
      showAlert();
    } else {
      alert("Footage Name should contain 3 letters at least");
    }
    // console.log(data, "data:sendToCustomer");
  };

  const sendToEditor = async () => {
    // const uploadFilePromise = [];
    if (footageName.length > 3) {
      setIsUploading(true);
      const random = generateRandomString(20);
      const path = `photographer-uploads/${random}`;
      for (const file of uploadedFiles) {
        uploadedFilesProgress(file.name, 0);
        // uploadFilePromise.push();
        await uploadChunksByChunks(file, path, uploadedFilesProgress);
      }

      // await Promise.allSettled(uploadFilePromise);
      setIsUploading(false);

      const data = {
        pgId: photographerId,
        sharedUrl: path,
        bookingId: bookingId,
        isForEditor: true,
        name: footageName,
        expiry: 30,
      };

      // console.log(data, "data:sendToCustomer");

      const resp = await authApi.post(
        "/share/photographer-share-with-user",
        data
      );
      await dispatch(
        createTimelineAsync({
          timelineData: {
            title: "Raw Footage Shared With Editor From Photographer",
          },
          bookingId: bookingId,
        })
      );
      console.log(resp.data, "resp:sendToCustomer");
    } else {
      alert("Footage Name should contain 3 letters at least");
    }
    // showAlert();
  };

  return (
    <>
      {isUploading && (
        <div className="fixed z-[9999999] top-0 left-0 w-full h-screen bg-gray-300/80 flex justify-center items-center flex-col gap-y-3">
          {Object.keys(uploadProgress)?.map((fileName) => (
            <div
              className="bg-orange-900 text-white flex justify-center items-center px-8 py-2 rounded-md"
              key={fileName}
            >
              {fileName} - {uploadProgress[fileName]}%
            </div>
          ))}
        </div>
      )}
      <div
        className={`photographer-page ${isUploading ? "overflow-hidden" : ""}`}
      >
        <Header />
        <section className="photographer-main pt-5">
          <div className="container-fluid px-0">
            <div className="photgrapher-main-start">
              <div className="row mx-0">
                <PhotographerSidebar />
                <div className="col-md-9 px-0">
                  <div className="single-detail page-with-upload">
                    <div className="events">
                      <div className="events-list">
                        <div className="single-event">
                          <div className="event-top-data">
                            <h5 className="event-user">
                              {eventDetails?.BookingUser?.name}
                            </h5>
                            <span className="event-name normal-text">
                              Birthday
                            </span>
                            <span className="event-number normal-text">
                              {eventDetails?.BookingUser?.phoneNo}
                            </span>
                          </div>
                          <div className="d-flex justify-content-between event-other-details mb-3">
                            <span className="map-marker normal-text">
                              Bengaluru{" "}
                            </span>
                            <span className="event-date normal-text">
                              Event Date: {eventDetails?.startDate} -{" "}
                              {eventDetails?.endDate}
                            </span>
                          </div>
                          <p className="main-location normal-text">
                            Outer Ring Rd, 7 Block, Banagirinagara, Banashankari
                            3rd Stage, Banashankari, Bengaluru, Karnataka
                            560070, India
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="p-4">
                      {!folder && (
                        <div className="folders-list d-flex flex-wrap gap-4">
                          <div className="single-folder ">
                            <button
                              className="openfolder bg-transparent border-0 "
                              onClick={openFolder}
                            >
                              <span className="main-floder d-block">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  x="0px"
                                  y="0px"
                                  width="100"
                                  height="100"
                                  viewBox="0 0 48 48"
                                >
                                  <path
                                    fill="#FFA000"
                                    d="M40,12H22l-4-4H8c-2.2,0-4,1.8-4,4v8h40v-4C44,13.8,42.2,12,40,12z"
                                  ></path>
                                  <path
                                    fill="#FFCA28"
                                    d="M40,12H8c-2.2,0-4,1.8-4,4v20c0,2.2,1.8,4,4,4h32c2.2,0,4-1.8,4-4V16C44,13.8,42.2,12,40,12z"
                                  ></path>
                                </svg>
                              </span>
                              <span className="folder-name">
                                Raw Photos/Videos
                              </span>
                            </button>
                          </div>
                          <div className="single-folder ">
                            <button
                              className="openfolder bg-transparent border-0 "
                              onClick={openFolder}
                            >
                              <span className="main-floder d-block">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  x="0px"
                                  y="0px"
                                  width="100"
                                  height="100"
                                  viewBox="0 0 48 48"
                                >
                                  <path
                                    fill="#FFA000"
                                    d="M40,12H22l-4-4H8c-2.2,0-4,1.8-4,4v8h40v-4C44,13.8,42.2,12,40,12z"
                                  ></path>
                                  <path
                                    fill="#FFCA28"
                                    d="M40,12H8c-2.2,0-4,1.8-4,4v20c0,2.2,1.8,4,4,4h32c2.2,0,4-1.8,4-4V16C44,13.8,42.2,12,40,12z"
                                  ></path>
                                </svg>
                              </span>
                              <span className="folder-name">
                                Edited Photos/Videos
                              </span>
                            </button>
                          </div>
                        </div>
                      )}
                      {folder && (
                        <div className="the-files-here">
                          <div className="upload-file mb-4">
                            <label
                              htmlFor="uploadfile"
                              className="btn site-btn px-4"
                            >
                              Upload Photos/Videos
                            </label>
                            <input
                              type="file"
                              name=""
                              id="uploadfile"
                              hidden
                              multiple
                              onChange={handleFileUpload}
                            />
                          </div>
                          <div className="files-list row">
                            {renderFilesList()}
                          </div>

                          <div className="d-flex gap-4 upload-btn-for-user w-2/3">
                            <input
                              type="text"
                              className="form-control pl-4 w-1/2"
                              placeholder="Enter Footage Name"
                              required
                              value={footageName}
                              onChange={(e) => setFootageName(e.target.value)}
                            />
                            <button
                              className="site-btn px-4"
                              onClick={sendToEditor}
                            >
                              Send to Editor{" "}
                              <i className="fa fa-angle-right"></i>
                            </button>
                            <button
                              className="site-btn px-4"
                              onClick={sendToCustomer}
                            >
                              Send to Customer{" "}
                              <i className="fa fa-angle-right"></i>
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};
export default ProgressEventDetail;
