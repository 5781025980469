import React from "react";
import { useNavigate } from "react-router";

// react-icons
import { IoIosArrowBack } from "react-icons/io";
const BackButton = () => {
  const navigate = useNavigate();

  return (
    <button
      className="bg-main px-3 py-1 rounded-lg"
      onClick={() => navigate(-1)}
    >
      <IoIosArrowBack className="text-white text-2xl" />
    </button>
  );
};

export default BackButton;
