import React from "react";
import { Link } from "react-router-dom";

const Chat = ({
  href,
  profilePic,
  customerName,
  lastMsg,
  lastMsgTime,
  unread,
  customer,
  msgCount
}) => {
  return (
    <Link
      to={href}
      state={customer}
      className={`${
        msgCount ? "bg-gray-300/40" : "bg-white"
      } w-full p-2 flex justify-between  border-b-[1px] border-b-gray-300`}
    >
      <div className="flex items-center gap-5">
        <div>
          <img
            src={
              profilePic
                ? profilePic
                : "https://img.freepik.com/premium-vector/account-icon-user-icon-vector-graphics_292645-552.jpg"
            }
            alt="profile"
            className="rounded-full w-[50px] h-[50px]"
          />
        </div>
        <div className="flex flex-col">
          <p className="font-bold text-black">{customerName} {msgCount > 0 && <span style={{ display: 'inline-block', fontSize: '12px', fontWeight: '300', width: '18px', height: '18px', marginLeft: '30px' }} className="bg-red-600 rounded-full text-center text-white">{msgCount}</span>}</p>
          {/* <p className="text-gray-500 text-sm">{lastMsg}</p> */}
        </div>
      </div>
      <div className="flex items-end">
        <p className="text-xs text-gray-500">{lastMsgTime}</p>
      </div>
    </Link>
  );
};

export default Chat;
