import React from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";

import { VisaImg } from "../../Constant/Index";

const PayNow = () => {
  return (
    <>
      <main className="site-front-page">
        <Header />
        <section className="px-5 py-3">
          <div className="grid md:grid-cols-2 gap-4">
            <div className="flex flex-col gap-3">
              <h3 className="text-main">Payment Method:</h3>
              <div className="shadow-md rounded-md p-3 bg-gray-100">
                <div className="flex flex-col gap-4">
                  <div className="flex flex-col">
                    <label htmlFor="cardnumber" className="text-gray-700">
                      Card number
                    </label>
                    <div className="w-full flex justify-between items-center p-2 rounded-sm shadow-sm bg-white">
                      <input
                        type="text"
                        placeholder="1234 1234 1234 1234"
                        className="w-full outline-none"
                      />
                      <img src={VisaImg} alt="" width={50} className="h-max" />
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-2">
                    <div className="flex flex-col">
                      <label htmlFor="expiry" className="text-gray-700">
                        Expiry
                      </label>
                      <input
                        type="text"
                        placeholder="MM/YY"
                        className="w-full p-2 rounded-sm shadow-sm outline-none"
                      />
                    </div>
                    <div className="flex flex-col">
                      <label htmlFor="cvc" className="text-gray-700">
                        CVC
                      </label>
                      <input
                        type="text"
                        placeholder="CVC"
                        className="w-full p-2 rounded-sm shadow-sm outline-none"
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-2">
                    <div className="flex flex-col">
                      <label htmlFor="country" className="text-gray-700">
                        Country
                      </label>
                      <select
                        name=""
                        id="country"
                        className="w-full p-2 rounded-sm shadow-sm outline-none"
                      >
                        <option value="us">United States</option>
                        <option value="in">India</option>
                      </select>
                    </div>
                    <div className="flex flex-col">
                      <label htmlFor="cvc" className="text-gray-700">
                        Zip
                      </label>
                      <input
                        type="text"
                        placeholder="90210"
                        className="w-full p-2 rounded-sm shadow-sm outline-none"
                      />
                    </div>
                  </div>
                  <p className="text-xs text-gray-600">
                    By providing your card information, you allow Boopho to
                    charge your card for future payments according to terms
                  </p>
                  <button className="bg-main p-2 rounded-md text-white">
                    Pay Now
                  </button>
                </div>
              </div>
            </div>
            <div className="bg-gray-100 p-4 shadow-md rounded-md flex flex-col gap-4 text-lg">
              <h3 className="text-gray-600">Order Summary</h3>
              <div className="flex justify-between">
                <p>Total</p>
                <p>2,35,000</p>
              </div>
              <hr />
              <div className="flex justify-between">
                <p>Grand Total</p>
                <p className="line-through">2,35,000</p>
              </div>
              <hr />
              <div className="flex justify-between">
                <p>Discount @20%</p>
                <p className="line-through">20,000</p>
              </div>
              <hr />
              <div className="flex justify-end">
                <p>2,50,000</p>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </main>
    </>
  );
};

export default PayNow;
