import React, { useState } from "react";
import ModalLayout from "../../Components/ModalLayout";
import axios from "axios";

const TurnOffEvents = ({ showModal, setShowModal, eventCategory }) => {
  // Initialize state variables for date range and event type
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [categoryId, setCategoryId] = useState("25"); // Default to "Business"
  const id = localStorage.getItem("photoGrapherUser");
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const token = localStorage.getItem("authToken");

  const handleTurnOff = async () => {
    // Prepare the data to send to your API
    const requestData = {
      startDate,
      endDate,
      categoryId,
      photographerId: id,
      isTurnedOff: true,
    };

    console.log(requestData);

    try {
      const apiUrl = `${baseUrl}/photographer/turn-on-off-event`;

      const response = await axios.patch(apiUrl, requestData, {
        headers: {
          "x-auth-token": token,
        },
      });

      if (response.status === 200) {
        setShowModal(false);
        const responseData = response.data.data;

        console.log(responseData);
      }
    } catch (error) {
      console.error("Error while fetching data:", error);
    }
    setStartDate("");
    setEndDate("");
  };

  return (
    <ModalLayout
      showModal={showModal}
      setShowModal={setShowModal}
      heading="Message"
    >
      <div className="flex flex-col gap-3 justify-center">
        {/* Date Range Starts */}
        <div className="flex items-center gap-2 text-main">
          <label htmlFor="dateRange">Date Range</label>
          <div
            className="flex items-center rounded-md gap-2 border border-gray-400 bg-white text-main px-4 py-2"
            id="dateRange"
          >
            <input
              type="date"
              className="border-none outline-none text-sm"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
            <input
              type="date"
              className="border-none outline-none text-sm"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
        </div>
        {/* Date Range Ends */}

        {/* Event Type Starts */}
        <div className="flex items-center gap-2 text-main">
          <label htmlFor="eventType">Event Type</label>
          <select
            id="eventType"
            className="px-4 py-2 border border-gray-400 outline-none rounded-md"
            value={categoryId}
            onChange={(e) => setCategoryId(e.target.value)}
          >
            {Array.isArray(eventCategory) &&
              eventCategory?.map((x) => {
                return <option value={x.id}>{x.name}</option>;
              })}
          </select>
        </div>
        {/* Event Type Ends */}

        <div className="flex justify-center">
          <button
            className="bg-main text-white px-3 py-1 rounded-md"
            onClick={handleTurnOff}
          >
            Turn Off
          </button>
        </div>
      </div>
    </ModalLayout>
  );
};

export default TurnOffEvents;
