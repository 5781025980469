import React, { useState, useEffect, useContext } from "react";
import Banner from "../../Components/banner";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import BookingTabs from "../../Components/BookingTabs";
import RequestedBookingCard from "../../Components/RequestedBookingCard";
import ConfirmedBookingCard from "../../Components/ConfirmedBookingCard";
import PastBookingCard from "../../Components/PastBookingCard";
import CancelledBookingCard from "../../Components/CancelledBookingCard";
import { Studio1 } from "../../Constant/Index";
import ChatBot from "react-simple-chatbot";
import { ThemeProvider } from "styled-components";
import { getSocket, readMessageAndUpdateStatus } from "../../Utils/socket";
import CustomChat from "../../Components/CustomChat";
import axios from "axios";
import { MyContext } from "../../Context/MyContext";
import wedding from "../../Assets/Img/wedding/Wedding1.jpg";
import baby from "../../Assets/Img/baby/Baby2.jpg";
import birthday from "../../Assets/Img/birthday/Birthday1.jpg";
import business from "../../Assets/Img/business/Business1.jpg";
import maternity from "../../Assets/Img/maternity/Maternity1.jpg";
const theme = {
  background: "#f5f8fb",
  fontFamily: "Helvetica Neue",
  headerBgColor: "#9e606d",
  headerFontColor: "#fff",
  headerFontSize: "15px",
  botBubbleColor: "#9e606d",
  botFontColor: "#fff",
  userBubbleColor: "#fff",
  userFontColor: "#4a4a4a",
};

const BookingsList = [
  {
    Name: "Photowale Studio",
    Area: "New Delhi",
    Date: "Event Date: 18th Apr 2022 - 22nd Apr 2022",
    LastBtn: "Awaiting Call",
    image: Studio1,
    Text: "",
  },
];

const Booking = () => {
  const [currentTab, setCurrentTab] = useState("requestedBooking");
  const [chatType, setChatType] = useState("");
  const [showChat, setShowChat] = useState(false);
  const [currentChatWith, setCurrentChatWith] = useState(null);
  const [steps, setSteps] = useState([]);
  const { messages, setMessages } = useContext(MyContext);
  let socket = getSocket();

  useEffect(() => {
    socket = getSocket();
    readMessageAndUpdateStatus({ userId: currentChatWith }, (data) => {
      console.log(data.data, "daaaaaaaaaaaaaataaa323");
      if (data.success) {
        // setSteps(data.data);
        // const messageData = messages[currentChatWith] ? [...messages[currentChatWith], data.data]: [...data.data];
        setMessages((prev) => ({ ...prev, [currentChatWith]: data.data }));
      }
    });
  }, [socket, currentChatWith]);

  console.log(messages, "Booking:messages");
  const token = localStorage.getItem("authToken");
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [cardData, setCardData] = useState([]);

  const handleShowChat = (chat, photographerId) => {
    console.log(chat, photographerId, " chat, photographerId");
    setChatType(chat);
    setShowChat(true);
    setCurrentChatWith(photographerId);
  };

  console.log({ cardData });

  const handleChatIcon = () => {
    setChatType("photographer");
    setShowChat(!showChat);
  };

  console.log(currentTab);
  const fetchData = async () => {
    try {
      const apiUrl = `${baseUrl}/photographer/bookings`;
      const headers = {
        "x-auth-token": token,
      };

      let status = "";

      if (currentTab == "confirmedBooking") {
        status = "CONFIRMED";
      } else if (currentTab == "pastBooking") {
        status = "COMPLETED";
      } else if (currentTab == "cancelledBooking") {
        status = "CANCELLED";
      } else {
        status = "PENDING";
      }
      const response = await axios.get(apiUrl, {
        params: {
          status: status,
        },
        headers: headers,
      });

      if (response.status === 200) {
        const responseData = response.data.data;
        console.log({ responseData }, "responseData:test:claaa");

        if (Array.isArray(responseData.rows)) {
          console.log({ responseData });
          setCardData(responseData.rows);
        } else {
          console.error("Invalid data format: rows is not an array.");
        }
      } else {
        console.error("Unable to fetch data.");
      }
    } catch (error) {
      console.error("Error while fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [token, currentTab]);

  const handleImage = (category) => {
    let image;
    switch (category) {
      case "22":
        image = baby;
        break;
      case "17":
        image = wedding;
        break;
      case "24":
        image = birthday;
        break;
      case "25":
        image = business;
        break;
      case "18":
        image = maternity;
        break;

      default:
        break;
    }
    console.log(image, "IMAGEEGG");
    return image;
  };
  return (
    <>
      <Header />
      <Banner home="Home" currenturl="/booking" current="My Bookings" />
      <section className="py-3 px-5">
        <section className="booking-section">
          <div className="bootstrap-container">
            <div className="booking-start">
              <BookingTabs
                currentTab={currentTab}
                setCurrentTab={setCurrentTab}
              />
              {currentTab === "requestedBooking" &&
                (cardData && cardData?.length > 0 ? (
                  <div className="booking-list overflow-hidden">
                    {cardData?.map((booking, index) => (
                      <RequestedBookingCard
                        key={index}
                        id={booking?.id}
                        name={booking?.PhotographerUser.name}
                        area={booking.address}
                        startDate={booking.startDate}
                        endDate={booking.endDate}
                        image={handleImage(booking?.category)}
                        lastBtn={booking.status}
                        handleShowChat={handleShowChat}
                        photographerId={booking?.photographerId}
                        category={booking?.category}
                        setCurrentTab={setCurrentTab}
                      />
                    ))}
                  </div>
                ) : (
                  <p>No Record Found</p>
                ))}

              {currentTab === "confirmedBooking" &&
                (cardData && cardData.length > 0 ? (
                  <div className="booking-list overflow-hidden">
                    {cardData.map((booking, index) => {
                      console.log(booking, "booking,");
                      return (
                        <ConfirmedBookingCard
                          key={index}
                          id={booking?.BookingUser.id}
                          name={booking?.BookingUser.name}
                          photographerName={booking?.PhotographerUser?.name}
                          area={booking.address}
                          startDate={booking.startDate}
                          endDate={booking.endDate}
                          image={handleImage(booking?.category)}
                          photographerId={booking?.photographerId}
                          handleShowChat={handleShowChat}
                        />
                      );
                    })}
                  </div>
                ) : (
                  <p>No Record Found</p>
                ))}

              {currentTab === "pastBooking" &&
                (cardData && cardData.length > 0 ? (
                  <div className="booking-list overflow-hidden">
                    {cardData.map((booking, index) => (
                      <PastBookingCard
                        key={index}
                        id={booking?.BookingUser.id}
                        name={booking?.BookingUser.name}
                        area={booking.address}
                        startDate={booking.startDate}
                        endDate={booking.endDate}
                        image={handleImage(booking?.category)}
                      />
                    ))}
                  </div>
                ) : (
                  <p>No Record Found</p>
                ))}

              {currentTab === "cancelledBooking" &&
                (cardData && cardData.length > 0 ? (
                  <div className="booking-list overflow-hidden">
                    {cardData.map((booking, index) => (
                      <CancelledBookingCard
                        key={index}
                        id={booking?.BookingUser.id}
                        name={booking?.BookingUser.name}
                        area={booking.address}
                        startDate={booking.startDate}
                        endDate={booking.endDate}
                        image={handleImage(booking?.category)}
                        handleShowChat={handleShowChat}
                      />
                    ))}
                  </div>
                ) : (
                  <p>No Record Found</p>
                ))}

              {(currentTab === "confirmedBooking" ||
                currentTab === "cancelledBooking") &&
                !showChat &&
                cardData.length !== 0 && (
                  <button
                    className={`flex justify-center items-center fixed bottom-2 right-2 bg-[#40262b] rounded-full w-[62px] h-[62px]`}
                    onClick={handleChatIcon}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      fill="currentColor"
                      class="bi bi-chat-square-text"
                      viewBox="0 0 16 16"
                      id="IconChangeColor"
                    >
                      <path
                        d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1h-2.5a2 2 0 0 0-1.6.8L8 14.333 6.1 11.8a2 2 0 0 0-1.6-.8H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h2.5a1 1 0 0 1 .8.4l1.9 2.533a1 1 0 0 0 1.6 0l1.9-2.533a1 1 0 0 1 .8-.4H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"
                        id="mainIconPathAttribute"
                        fill="#FFF"
                      ></path>{" "}
                      <path
                        d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6zm0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z"
                        id="mainIconPathAttribute"
                        fill="#FFF"
                      ></path>{" "}
                    </svg>
                  </button>
                )}

              {(currentTab === "confirmedBooking" ||
                currentTab === "cancelledBooking") &&
                showChat && (
                  <div className="react-simple-chatbot-container">
                    <button
                      className={` btn-here bg-transparent border-0 ms-auto w-fit d-block pb-2 `}
                      onClick={() => setShowChat(false)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="35"
                        height="35"
                        fill="currentColor"
                        class="bi bi-x-circle-fill"
                        viewBox="0 0 16 16"
                        id="IconChangeColor"
                      >
                        {" "}
                        <path
                          d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"
                          id="mainIconPathAttribute"
                          fill="red"
                        ></path>{" "}
                      </svg>
                    </button>
                    <ThemeProvider theme={theme}>
                      <CustomChat
                        headerTitle={
                          chatType === "admin"
                            ? "Chat with Boopho.com"
                            : chatType
                        }
                        // messages={messages[currentChatWith]}
                        // setMessage={(msg)=>setSteps(prev => ([...prev, msg]))}
                        currentChatWith={currentChatWith}
                      />
                      {/* <ChatBot
                        // floating="true"
                        height="450px"
                        headerTitle={
                          chatType === "admin"
                            ? "Chat with Boopho.com"
                            : chatType
                            ? "photographer"
                            : ""
                        }
                        steps={steps}
                        // steps={[
                        //   {
                        //     id: "1",
                        //     message: "What is your name?",
                        //     trigger: "2",
                        //   },
                        //   {
                        //     id: "2",
                        //     user: true,
                        //     trigger: "3",
                        //   },
                        //   {
                        //     id: "3",
                        //     message: "Hi {previousValue}, nice to meet you!",
                        //     end: true,
                        //   },
                        // ]}
                      /> */}
                    </ThemeProvider>
                  </div>
                )}
              {(currentTab === "requestedBooking" ||
                currentTab === "requestedBooking") &&
                showChat && (
                  <div className="react-simple-chatbot-container">
                    <button
                      className={` btn-here bg-transparent border-0 ms-auto w-fit d-block pb-2 `}
                      onClick={() => setShowChat(false)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="35"
                        height="35"
                        fill="currentColor"
                        class="bi bi-x-circle-fill"
                        viewBox="0 0 16 16"
                        id="IconChangeColor"
                      >
                        {" "}
                        <path
                          d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"
                          id="mainIconPathAttribute"
                          fill="red"
                        ></path>{" "}
                      </svg>
                    </button>
                    <ThemeProvider theme={theme}>
                      <CustomChat
                        headerTitle={
                          chatType === "admin"
                            ? "Chat with Boopho.com"
                            : chatType
                        }
                        // messages={messages[currentChatWith]}
                        // setMessage={(msg)=>setSteps(prev => ([...prev, msg]))}
                        currentChatWith={currentChatWith}
                      />
                      {/* <ChatBot
                        // floating="true"
                        height="450px"
                        headerTitle={
                          chatType === "admin"
                            ? "Chat with Boopho.com"
                            : chatType
                            ? "photographer"
                            : ""
                        }
                        steps={steps}
                        // steps={[
                        //   {
                        //     id: "1",
                        //     message: "What is your name?",
                        //     trigger: "2",
                        //   },
                        //   {
                        //     id: "2",
                        //     user: true,
                        //     trigger: "3",
                        //   },
                        //   {
                        //     id: "3",
                        //     message: "Hi {previousValue}, nice to meet you!",
                        //     end: true,
                        //   },
                        // ]}
                      /> */}
                    </ThemeProvider>
                  </div>
                )}
            </div>
          </div>
        </section>
      </section>
      <Footer />
    </>
  );
};

export default Booking;
