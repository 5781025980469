let eventGuid = 0;
let todayStr = new Date().toISOString().replace(/T.*$/, ""); // YYYY-MM-DD of today

export const INITIAL_EVENTS = [
  // {
  //   id: createEventId(),
  //   title: "All-day event",
  //   start: "2023-09-16",
  //   end: "2023-09-12",
  //   backgrounColor: "red",
  // },
];

export function createEventId() {
  return String(eventGuid++);
}
