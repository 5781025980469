import React from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";

const WebsiteNotEnabled = () => {

  const token = localStorage.getItem("authToken");

  return (
    <>
      <Header />
      <div className="bootstrap-container">
        <div className="min-h-[500px] flex flex-col items-center justify-center gap-4">
          <h3>
            Website is not yet enabled for customer, if you are a photographer,
            list your business here
          </h3>
        { token? <Link
            to="/photographer/register"
            className="bg-main text-white px-4 py-2 rounded-md"
          >
            List Your Business
          </Link> :  <a
            className="bg-main text-white px-4 py-2 rounded-md"
            data-bs-toggle="modal"
                                data-bs-target="#LoginModal"
                                role="button"
                              >
            List Your Business
                              </a>}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default WebsiteNotEnabled;
