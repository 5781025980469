import React from "react";

const ChatMessage = ({ role, profilePic, msg, time }) => {
  return (
    <div
      className="flex items-center gap-2"
      style={{ justifyContent: role == "admin" ? "flex-end" : "flex-start" }}
    >
      <img
        src={
          role === "admin"
            ? "https://img.freepik.com/premium-vector/account-icon-user-icon-vector-graphics_292645-552.jpg"
            : role === "customer" && profilePic
            ? profilePic
            : "https://img.freepik.com/premium-vector/account-icon-user-icon-vector-graphics_292645-552.jpg"
        }
        alt="profile"
        width={40}
        height={40}
        className="rounded-full"
      />
      <div className="flex flex-col items-end gap-1">
        <p className="text-xs text-gray-500">{time}</p>
        <p
          className="text-white text-sm px-3 py-1 rounded-sm"
          style={{ backgroundColor: role == "admin" ? "#3498DB" : "#2ECC71" }}
        >
          {msg}
        </p>
      </div>
    </div>
  );
};

export default ChatMessage;
